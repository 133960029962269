/**
 * @file 多语言文案
 * @author  fangsimin
 * @date    2019-01-02 09:59:50
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-02 10:00:33
 */

import en from './en';
import cn from './cn';
import ja from './ja';
import zhHant from './zhHant';
import ko from './ko';

export default {
    en,
    cn,
    ja,
    zhHant,
    ko
};
