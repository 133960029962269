/*
 * @file state-cn.js
 * @author liushengxgi
 * @date 2020-02-10 11:07:54
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-10 11:08:41
 */

export default {
    AL: '阿拉巴馬州',
    AK: '阿拉斯加州',
    AZ: '亞利桑那州',
    AR: '阿肯色州',
    CA: '加利福尼亞州',
    CO: '科羅拉多州',
    CT: '康涅狄格州',
    DE: '特拉華州',
    FL: '佛羅里達州',
    GA: '佐治亞州',
    HI: '夏威夷州',
    ID: '愛達荷州',
    IL: '伊利諾伊州',
    IN: '印第安納州',
    IA: '艾奧瓦州',
    KS: '堪薩斯州',
    KY: '肯塔基州',
    LA: '路易斯安那州',
    ME: '緬因州',
    MD: '馬里蘭州',
    MA: '馬薩諸塞州',
    MI: '密歇根州',
    MN: '明尼蘇達州',
    MS: '密西西比州',
    MO: '密蘇里州',
    MT: '蒙大拿州',
    NE: '內布拉斯加州',
    NV: '內華達州',
    NH: '新罕布什爾州',
    NJ: '新澤西州',
    NM: '新墨西哥州',
    NY: '紐約州',
    NC: '北卡羅來納州',
    ND: '北達科他州',
    OH: '俄亥俄州',
    OK: '俄克拉何馬州',
    OR: '俄勒岡州',
    PA: '賓夕法尼亞州',
    RI: '羅得島州',
    SC: '南卡羅來納州',
    SD: '南達科他州',
    TN: '田納西州',
    TX: '得克薩斯州',
    UT: '猶他州',
    VT: '佛蒙特州',
    VA: '弗吉尼亞州',
    WAI: '華盛頓州',
    WV: '西弗吉尼亞州',
    WI: '威斯康星州',
    WY: '懷俄明州',
    OT: '其他',
    DC: '其他',
    // 加拿大
    AB: '艾伯塔',
    BC: '卑詩',
    MB: '曼尼託巴',
    NB: '新不倫瑞克',
    NL: '紐芬蘭與拉布拉多',
    NS: '新斯科舍',
    NT: '西北領地',
    NU: '努納武特',
    ON: '安大略',
    PE: '愛德華王子島',
    QC: '魁北克',
    SK: '薩斯喀徹温',
    YT: '育空',
    // 澳大利亞
    NSW: '新南威爾士州',
    QLD: '昆士蘭州',
    SA: '南澳大利亞州',
    TAS: '塔斯馬尼亞州',
    VIC: '維多利亞州',
    WA: '西澳大利亞州',
    ACT: '澳大利亞首都領地',
    NT01: '北領地',
    // 英國
    ENG: '英格蘭',
    NIR: '愛爾蘭',
    SCT: '蘇格蘭',
    WLS: '威爾士',
    // 日本
    JP13: '東京都',
    JP01: '北海道',
    JP26: '京都府',
    JP27: '大阪府',
    JP02: '青森縣',
    JP03: '巖手縣',
    JP04: '宮城縣',
    JP05: '秋田縣',
    JP06: '山形縣',
    JP07: '福島縣',
    JP08: '茨城縣',
    JP09: '栃木縣',
    JP10: '羣馬縣',
    JP11: '埼玉縣',
    JP12: '千葉縣',
    JP14: '神奈川縣',
    JP15: '新潟縣',
    JP16: '富山縣',
    JP17: '石川縣',
    JP18: '福井縣',
    JP19: '山梨縣',
    JP20: '長野縣',
    JP21: '岐阜縣',
    JP22: '靜岡縣',
    JP23: '愛知縣',
    JP24: '三重縣',
    JP25: '滋賀縣',
    JP28: '兵庫縣',
    JP29: '奈良縣',
    JP30: '和歌山縣',
    JP31: '鳥取縣',
    JP32: '島根縣',
    JP33: '岡山縣',
    JP34: '廣島縣',
    JP35: '山口縣',
    JP36: '德島縣',
    JP37: '香川縣',
    JP38: '愛媛縣',
    JP39: '高知縣',
    JP40: '福岡縣',
    JP41: '佐賀縣',
    JP42: '長崎縣',
    JP43: '熊本縣',
    JP44: '大分縣',
    JP45: '宮崎縣',
    JP46: '鹿兒島縣',
    JP47: '沖繩縣',
    // 韓國
    KR11: '首爾特別市',
    KR26: '釜山廣域市',
    KR27: '大邱廣域市',
    KR28: '仁川廣域市',
    KR29: '光州廣域市',
    KR30: '大田廣域市',
    KR31: '蔚山廣域市',
    KR50: '世宗特別自治市',
    KR41: '京畿道',
    KR42: '江原道',
    KR43: '忠清北道',
    KR44: '忠清南道',
    KR45: '全羅北道',
    KR46: '全羅南道',
    KR47: '慶尚北道',
    KR48: '慶尚南道',
    KR49: '濟州特別自治道',
    // 臺灣
    NWT: '臺北',
    TPE: '新北',
    TAO: '桃園',
    TXG: '臺中',
    TNN: '臺南',
    KHH: '高雄',
    ILA: '宜蘭',
    HSQ: '新竹',
    MIA: '苗栗',
    CHA: '彰化',
    NAN: '南投',
    YUN: '雲林',
    CYQ: '嘉義',
    PIF: '屏東',
    TTT: '花蓮',
    HUA: '臺東',
    PEN: '澎湖',
    KEE: '基隆',
    // 印尼
    IDAC: '亞齊特區',
    IDBA: '巴厘省',
    IDBB: '邦加-勿裏洞省',
    IDBT: '萬丹省',
    IDBE: '明古魯省',
    IDGO: '中爪哇省',
    IDJA: '中加裏曼丹省',
    IDJB: '中蘇拉威西省',
    IDJT: '東爪哇省',
    IDJI: '東加裏曼丹省',
    IDKB: '東努沙登加拉省',
    IDKS: '哥倫打洛省',
    IDKT: '雅加達首都特區',
    IDKI: '占碑省',
    IDKU: '楠榜省',
    IDKR: '馬魯古省',
    IDLA: '北加裏曼丹省',
    IDMA: '北馬魯古省',
    IDMU: '北蘇拉威西省',
    IDNB: '北蘇門答臘省',
    IDNT: '巴布亞省',
    IDPA: '廖內省',
    IDPB: '廖內群島省',
    IDRI: '東南蘇拉威西省',
    IDSR: '南加裏曼丹省',
    IDSN: '南蘇拉威西省',
    IDST: '南蘇門答臘省',
    IDSG: '西爪哇省',
    IDSA: '西加裏曼丹省',
    IDSB: '西努沙登加拉省',
    IDSS: '西巴布亞省',
    IDSU: '西蘇拉威西省',
    IDJK: '西蘇門答臘省',
    IDYO: '日惹特區'
};
