<template>
    <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
            <slot name="dropdown-label">
                {{ currItem && currItem.label }}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </slot>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="item in langList" :key="item.label"
                :command="item.value"
            >
                {{ item.label }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import {langList} from '@/constants/language';
export default {
    data() {
        return{
            langList,
            command: langList[0].value
        };
    },
    watch: {
        lang() {
            this.handleCommand(this.lang);
        }
    },
    computed: {
        currItem() {
            const item = langList.find(lang => lang.value === this.lang);
            return item;
        }
    },
    props: {
        lang: {
            default: 'en',
            type: String
        },
        hasConfirm: Boolean
    },
    mounted() {
        this.command = this.lang;
    },
    methods: {
        handleCommand(command) {
            if(this.hasConfirm) {
                this.$confirm(
                    this.$t('msn.validations.confirmToSwitch'),
                    this.$t('msn.infos.info'),
                    {
                        type: 'warning',
                        customClass: 'msn-message-box',
                        showClose: false,
                        cancelButtonText: this.$t('msn.button.cancel'),
                        confirmButtonText: this.$t('msn.button.confirm'),
                        cancelButtonClass: 'msn-cancel-button',
                        confirmButtonClass: 'msn-confirm-button'
                    }
                )
                    .then(_ => {
                        this.command = command;
                        this.$emit('change', this.command);
                    })
                    .catch(_ => {});
            } else {
                this.command = command;
                this.$emit('change', this.command);
            }
        }
    }
};
</script>

<style>

</style>