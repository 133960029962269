<template>
    <div class="form-header">
        <div class="form-header--title">
            {{ title }}
        </div>
        <div class="form-header--text">
            {{ desc }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormHeader',
    props: {
        title: {
            type: String,
            default: 'title'
        },
        desc: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.form-header {
    margin-top: 35px;
    padding: 15px 0;
    &--title {
        font-size: 20px;
        color: #0067ff;
        line-height: 14px;
        font-weight: 700;
        position: relative;
        padding-left: 15px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 20px;
            background-color: #0067ff;
        }
    }
    &--text {
        margin-top: 10px;
        line-height: 18px;
        padding-left: 15px;
        color: #53575b;
        font-size: 12px;
    }
}
</style>
