/**
 * @file 每个菜单项，用于递归显示子菜单
 * @author  fangsimin
 * @date    2019-01-03 12:14:37
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:40:04
 */

<template>
    <div v-if="data && data['key'] && !data['hideInMenu']" class="msn-menu">
        <el-submenu
            v-if="data['children']"
            :key="data['key']"
            :index="getKey(routekey)"
        >
            <template slot="title">
                <!-- 右上角标 -->
                <div v-if="data['cornerIcon']" :class="['msn-menu-corner-icon', `msn-menu-corner-icon-${data['cornerIcon']}`]"></div>
                <i
                    v-if="data['icon']"
                    :class="[
                        'scaleIcon-transition',
                        getIconClass(data['icon']),
                        {scaleIcon: asideStatus},
                    ]"
                ></i>
                <span slot="title">
                    {{ $i18n.t(data["title"]) }}
                    <span
                        v-if="data['bageKey'] && (accountInfo && +accountInfo[data['bageKey']] > 0)"
                        class="menu-bage"
                    >{{ accountInfo[data["bageKey"]] }}</span>
                </span>
            </template>
            <el-menu-item-group>
                <template v-for="child in data['children']">
                    <menu-item
                        v-if="child && child['key']"
                        :key="child['key']"
                        :routekey="getKey(routekey, child['key'])"
                        :data="child"
                    />
                </template>
            </el-menu-item-group>
        </el-submenu>
        <el-menu-item
            v-else :key="data['key']"
            :index="getKey(routekey)"
        >
            <!-- 右上角标 -->
            <div v-if="data['cornerIcon']" :class="['msn-menu-corner-icon', `msn-menu-corner-icon-${data['cornerIcon']}`]"></div>
            <i
                v-if="data['icon']"
                :class="[
                    'scaleIcon-transition',
                    getIconClass(data['icon']),
                    {scaleIcon: asideStatus},
                ]"
            ></i>
            <span slot="title">
                {{ $i18n.t(data["title"]) }}
                <span
                    v-if="data['bageKey'] && (accountInfo && +accountInfo[data['bageKey']] > 0)"
                    class="menu-bage"
                >{{ accountInfo[data["bageKey"]] }}</span>
            </span>
        </el-menu-item>
    </div>
</template>

<script>
import { mapState } from 'vuex';
/**
 * 获取key，多级key之间用 - 分割
 * @param  {...string} args key数组
 * @return {string}
 */
export function getMenuKey(...args) {
    return args.join('/');
}

export default {
    name: 'MenuItem',
    props: {
        routekey: {
            default: ''
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        asideStatus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('msn', {
            accountInfo: state => state.accountInfo
        })
    },
    methods: {
        /**
         * 获取key，多级key之间用 - 分割
         * @param  {...string} args key数组
         * @return {string}
         */
        getKey: getMenuKey,

        /**
         * 获取icon的类名
         * @param  {string} icon icon名
         * @return {string}
         */
        getIconClass: function (icon) {
            return `menu-icon msn-fa msn-fa-${icon}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.scaleIcon-transition {
    //   transition: all 8s;
    //   display: inline-block;
    //   transform: scale(1);
    transform-origin: right center;
}
.scaleIcon {
    transform: scale(1.4);
}
</style>
