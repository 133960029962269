<template>
    <el-switch
        v-model="innerValue"
        :active-color="activeColor"
        :inactive-color="inactiveColor"
        :active-value="activeValue"
        :inactive-value="inactiveValue"
        @change="handleChange"
    />
</template>

<script>
export default {
    name: 'MsnSwitch',
    props: {
        value: {
            default: null
        },
        activeColor: {
            type: String,
            default: '#2DD1AC'
        },
        inactiveColor: {
            type: String,
            default: '#858D94'
        },
        activeValue: {
            default: true
        },
        inactiveValue: {
            default: false
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        handleChange(data) {
            this.$emit('change', data);
        }
    }
};
</script>

<style>
</style>
