/*
 * @file msn.js
 * @author liushengxgi
 * @date 2020-02-25 17:18:07
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-25 17:21:51
 */

import request from '@/common/request';
import api from '@/constants/api';
import PATH from '@/constants/path';
import moment from 'moment';
import {HELP_CENTER} from '@/constants/helpCenterPath';
import Cookie from 'js-cookie';

const getMenuPermissions = function () {
    const currency = localStorage.getItem('currency') || 'USD';
    return new Promise(resolve => {
        request({
            method: 'get',
            timeout: 10000000,
            url: api.getMenu
        }, {currency: currency}).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

const getAccountInfo = function () {
    return new Promise(resolve => {
        request({
            method: 'get',
            url: api.accountInfo
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

// 获取用户列表
const getUserList = function () {
    return new Promise(resolve => {
        request({
            method: 'get',
            url: api.getUserList
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

// 获得下拉框值
const getSelectData = function () {
    return new Promise(resolve => {
        request({
            method: 'get',
            url: api.initSelect
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

// 获取 用户上次操作信息(语言、币种、国家、new dashboard: 时区、日期、设置列、筛选框)
const getUserConfig = function (params) {
    return new Promise(resolve => {
        request({
            method: 'get',
            url: api.getUserConfig,
            params
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

// 更新 用户本次操作信息(语言、币种、国家、new dashboard: 时区、日期、设置列、筛选框)
const setUserConfig = function (data) {
    return new Promise(resolve => {
        request({
            method: 'post',
            url: api.setUserConfig,
            contentType: 'application/json; charset=UTF-8'
        }, data).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

const defaultDate1 = [
    new Date(moment().add( -1, 'days').format('YYYY/MM/DD')),
    new Date(moment().add( -1, 'days').format('YYYY/MM/DD'))
];
const defaultDate2 = [
    new Date(moment().add(-7, 'days').format('YYYY/MM/DD')),
    new Date()
];
const state = {
    menu: null,
    loginState: false,
    selectData: null,
    userName: '',
    email: '',
    userId: '',
    accountType: '',
    role: '',
    // 账户列表
    accountList: null,
    // no-permission 记录用户是否已经填写表单
    showFeedback: false,
    // 账户状态
    accountInfo: {},
    // 需要缓存的组件
    keepAliveComp: [],
    // 处理dashboard的缓存
    dashboardFilterData: null,
    useDashboardLastState: false,
    // 获取媒体数据
    mediaType: null,
    // 版本信息
    version: '',
    // 用户列表
    userList: [],
    // 代理商列表
    bmList: [],
    // dashboard domain cpc
    dashboardDomainFlag: false,
    ROIAnalysis: {
        date: defaultDate1
    },
    roiMgr: {
        date: defaultDate2
    },
    financialPay: {
        date: defaultDate2
    },
    cacheFilter: {
        account: {},
        campaign: {},
        ad: null
    },
    auditFilter: {
        mediaType: '21',
        status: [4],
        audience: [],
        location: [],
        date: [],
        reviewDate: [],
        geoedgeRejected: 0,
        isTidb: 1,
        pageIndex: 1,
        advertiserName: ''
    },
    isLoginState: false,
    userAccount: {},
    isSetSiteCpc: false,
    // 面包屑存储的ad层级
    breadcrumbList: [],
    // 新增素材跳转回dashboard是否返回ad层级
    isAddMaterialToAd: false,
    disabledReleaseStats: '',
    userLastOperationalInfo: {
        currency: '',
        timezone: '',
        lang: '',
        country: '',
        displayColumns: '',
        campaignsFilter: '',
        adsFilter: ''
    },
    sizeFlag: false,
    adSelectColFlag: false,
    adListRow: []
};

const mutations = {
    SET_USER_ACCOUNT(state, account) {
        state.userAccount = account;
    },
    SET_AUDIT_FILTER(state, filter) {
        state.auditFilter = Object.assign({}, state.auditFilter, filter);
    },
    SET_CACHE_FILTER(state, filter) {
        state.cacheFilter = Object.assign({}, state.cacheFilter, filter);
    },
    SET_MENU(state, menu) {
        state.menu = menu;
    },
    SET_EMAIL(state, email) {
        state.email = email;
    },
    SET_USER_NAME(state, name) {
        state.userName = name;
    },
    SET_USER_ID(state, id) {
        state.userId = id;
    },
    SET_ACCOUNT_TYPE(state, accountType) {
        state.accountType = accountType;
    },
    SET_ROLE(state, role) {
        state.role = role;
    },
    SET_ACCOUNT_LIST(state, accountList) {
        state.accountList = accountList;
    },
    SET_SHOW_FEED_BACK(state, showFeedback) {
        state.showFeedback = showFeedback;
    },
    SET_ACCOUNT_INFO(state, accountInfo) {
        state.accountInfo = accountInfo;
    },
    SET_KEEP_ALIVE(state, component) {
        let keepAliveComp = state.keepAliveComp.concat(component);
        state.keepAliveComp = [...new Set(keepAliveComp)];
    },
    SET_NO_KEEP_ALIVE(state, notKeepAliveComp) {
        let keepAliveComp = state.keepAliveComp;
        let index = keepAliveComp.indexOf(notKeepAliveComp);
        if (index > -1) {
            state.keepAliveComp.splice(index, 1);
        }
    },

    /**
     * 设置dashboard 过滤条件信息
     * @param {*} state
     * @param {Object} dashboardFilterData 由于数据比较分散，需要分开进行更新
     */
    SET_DASHBOARD_FILTER_DATA(state, dashboardFilterData) {
        if (!state.dashboardFilterData) {
            state.dashboardFilterData = {};
        }
        const filterData = {};
        Object.keys(dashboardFilterData || {}).forEach(key => {
            let value = dashboardFilterData[key];
            if (typeof value === 'object') {
                value = JSON.parse(JSON.stringify(dashboardFilterData[key]));
            }
            filterData[key] = value;
        });
        state.dashboardFilterData = filterData;
    },
    // 是否使用上次dashboard状态
    SET_USE_DASHBOARD_LAST_STATE(state, useDashboardLastState) {
        state.useDashboardLastState = useDashboardLastState;
    },
    // 获取媒体类型
    SET_MEDIA_TYPE(state, mediaType) {
        state.mediaType = mediaType;
    },
    SET_SELECTDATA(state, data) {
        state.selectData = data;
    },
    SET_VERSION(state, version) {
        state.version = version;
    },
    SET_USERLIST(state, userList) {
        state.userList = userList;
    },
    SET_BM_LIST(state, bmList) {
        state.bmList = bmList;
    },
    SET_LOGIN_STATE(state, loginState) {
        state.loginState = loginState;
    },
    SET_DASHBOARDDOMAINFLAG(state, dashboardDomainFlag) {
        state.dashboardDomainFlag = dashboardDomainFlag;
    },
    setROIAnalysis(state, params) {
        state.ROIAnalysis = params;
    },
    setRoiMgr(state, params) {
        state.roiMgr = params;
    },
    setFinancialPay(state, params) {
        state.financialPay = params;
    },
    SET_IS_LOGIN_STATE(state, isLoginState) {
        state.isLoginState = isLoginState;
    },
    setIsSetSiteCpc(state, isSetSiteCpc) {
        state.isSetSiteCpc = isSetSiteCpc;
    },
    setBreadcrumbList(state, breadcrumbList) {
        state.breadcrumbList = breadcrumbList;
    },
    setIsAddMaterialToAd(state, isAddMaterialToAd) {
        state.isAddMaterialToAd = isAddMaterialToAd;
    },
    setDisabledReleaseStats(state, disabledReleaseStats) {
        state.disabledReleaseStats = disabledReleaseStats;
    },
    setUserLastOperationalInfo(state, info) {
        state.userLastOperationalInfo = info;
    },
    // 判断是否勾选ad select
    setAdSelectColFlag(state, flag) {
        state.adSelectColFlag = flag;
    },
    // 判断是否选中size
    setColSizeSelected(state, sizeFlag) {
        state.sizeFlag = sizeFlag;
    },
    setAdListRow(state, flag) {
        state.adListRow = flag;
    }
};

const getters = {
    getUserAccount: (state) => state.userAccount,
    getAuditFilter: (state) => {
        return state.auditFilter;
    },
    getCacheFilter: (state) => {
        return state.cacheFilter;
    },
    getMenu: (state, getters, rootState) => {
        return state.menu;
    },
    getSelectData: (state, getters, rootState) => {
        return state.selectData;
    },
    getEmail: (state, getters, rootState) => {
        return state.email;
    },
    getUserName: (state, getters, rootState) => {
        return state.userName;
    },
    getUserId: (state, getters, rootState) => {
        return state.userId;
    },
    getAccountType: (state, getters, rootState) => {
        return state.accountType;
    },
    getRole: (state, getters, rootState) => {
        return state.role;
    },
    getAccountList: (state, getters, rootState) => {
        return state.accountList;
    },
    //  可读的
    getPermissionAccountList: (state, getters, rootState) => {
        return Array.isArray(state.accountList) ? state.accountList.filter((item) => {
            return item.writeable > 0;
        }) : [];
    },
    getAccountTotalBalance: (state, getters, rootState) => {
        let totalMoney = 0;
        state.accountList.forEach(item => {
            if (item.writeable > 0) {
                totalMoney += isNaN(parseFloat(item.balance)) ? 0 : parseFloat(item.balance);
            }
        });
        return totalMoney;
    },
    // 判断账号来源
    getAccountSource: (state, getters, rootState) => {
        let writeableList = [];
        let accountSourceFlag;
        state.accountList.forEach(item => {
            if (item.writeable > 0) {
                writeableList.push(item);
                accountSourceFlag = writeableList.some(item => item.accountSource === 'Discovery');
            }
        });
        return accountSourceFlag;
    },
    getAccountSourceList: (state, getters, rootState) => {
        let accountSourceFlag;
        accountSourceFlag = state.accountList.length > 0 && state.accountList.every(item => item.source === '直客');
        console.log(accountSourceFlag, 'getAccountSourceList');
        return accountSourceFlag;
    },
    getShowFeedback: (state, getters, rootState) => {
        return state.showFeedback;
    },
    getAccountInfo: (state, getters, rootState) => {
        return state.accountInfo;
    },
    getKeepAliveComp(state) {
        return state.keepAliveComp;
    },
    getDashboardFilterData(state) {
        return state.dashboardFilterData;
    },
    getUseDashboardLastState(state) {
        return state.useDashboardLastState;
    },
    getMediaType(state) {
        return state.mediaType;
    },
    getVersion(state) {
        return state.version;
    },
    getUserList(state) {
        return state.userList;
    },
    getBmList(state) {
        return state.bmList;
    },
    getLoginState(state) {
        return state.login;
    },
    getDashboardDomainFlag(state) {
        return state.dashboardDomainFlag;
    },
    getROIAnalysis(state) {
        return state.ROIAnalysis;
    },
    getRoiMgr(state) {
        return state.roiMgr;
    },
    getFinancialPay(state) {
        return state.financialPay;
    },
    getIsLoginState() {
        return state.isLoginState;
    },
    getIsSetSiteCpc(state) {
        return state.isSetSiteCpc;
    },
    getBreadcrumbList(state) {
        return state.breadcrumbList;
    },
    getIsAddMaterialToAd() {
        return state.isAddMaterialToAd;
    },
    getDisabledReleaseStats: (state) => {
        return state.disabledReleaseStats;
    },
    getLastOperateInfo: (state) => {
        return state.userLastOperationalInfo;
    },
    getColSizeSelected(state) {
        return state.sizeFlag;
    },
    getAdSelectColFlag(state) {
        return state.adSelectColFlag;
    },
    getAdListRow(state) {
        return state.adListRow;
    }
};

const actions = {
    setSelectData: async ({state, commit}) => {
        if (state.selectData) {
            return;
        }
        const {data} = await getSelectData();
        commit('SET_SELECTDATA', data);
    },
    setMenu: async ({state, commit, dispatch, rootState}, payload) => {
        if (state.menu && !payload) {
            return;
        }
        // console.log(rootState);
        const {isDiscovery} = rootState.common.platformInfo;
        const {data} = await getMenuPermissions();
        // help center 默认只读权限
        let menu = {
            [PATH['helpCenter']]: 'r',
            ...HELP_CENTER,
            ...data.menus
        };
        if(isDiscovery) {
           delete menu['/platform/helpCenter/adPolicy'];
           delete menu['/platform/helpCenter/priceAndBill'];
           delete menu['/platform/helpCenter/reportAndOptimizing'];
        }
        commit('SET_MENU', menu);
        dispatch('setEmail', data.email || '');
        dispatch('setUserName', data.userName || '');
        dispatch('setAccountType', data.accountType || '');
        dispatch('setRole', data.role || '');
        dispatch('setUserId', data.userId || '');
        dispatch('setAccountList', data.accountList || []);
        dispatch('setVersion', data.version || '');
        const loginState = Boolean(data.accountType);
        dispatch('setLoginState', loginState || false);
        const isLoginState = Boolean(data.userName);
        dispatch('setIsLoginState', isLoginState || false);
        if (data.menus && Object.keys(data.menus).length > 0) {
            // 加载资源
            dispatch('setSelectData');
        }
    },
    setEmail: ({commit}, email) => {
        commit('SET_EMAIL', email);
    },
    setUserName: ({commit}, name) => {
        commit('SET_USER_NAME', name);
    },
    setAccountType: ({commit}, accountType) => {
        commit('SET_ACCOUNT_TYPE', accountType);
    },
    setRole: ({commit}, role) => {
        commit('SET_ROLE', role);
    },
    setUserId: ({commit}, userId) => {
        commit('SET_USER_ID', userId);
    },
    setAccountList: ({commit}, accountList) => {
        commit('SET_ACCOUNT_LIST', accountList);
    },
    setShowFeedback: ({commit}, showFeedback) => {
        commit('SET_SHOW_FEED_BACK', showFeedback);
    },
    setAccountInfo: async ({commit, dispatch}) => {
        const {data} = await getAccountInfo();
        commit('SET_ACCOUNT_INFO', data || {});
        dispatch('setMediaType', data.sspInfo || []);
    },
    setKeepAlive({commit}, keepAliveComp) {
        commit('SET_KEEP_ALIVE', keepAliveComp);
    },
    setNoKeepAlive({commit}, notKeepAliveComp) {
        commit('SET_NO_KEEP_ALIVE', notKeepAliveComp);
    },
    setDashboardFilterData({commit}, dashboardFilterData) {
        commit('SET_DASHBOARD_FILTER_DATA', dashboardFilterData);
    },
    setUseDashboardLastState({commit}, useDashboardLastState) {
        commit('SET_USE_DASHBOARD_LAST_STATE', useDashboardLastState);
    },
    setMediaType({commit}, mediaType) {
        commit('SET_MEDIA_TYPE', mediaType);
    },
    setVersion({commit}, version) {
        commit('SET_VERSION', version);
    },
    setUserList: async ({commit, dispatch}) => {
        const {data} = await getUserList();
        commit('SET_USERLIST', data || {});
    },
    setBmList({commit}, bmList) {
        commit('SET_BM_LIST', bmList);
    },
    setLoginState({commit}, loginState) {
        commit('SET_LOGIN_STATE', loginState);
    },
    setDashboardDomainFlag({commit}, dashboardDomainFlag) {
        commit('SET_DASHBOARDDOMAINFLAG', dashboardDomainFlag);
    },
    setIsLoginState({ commit }, isLoginState) {
        commit('SET_IS_LOGIN_STATE', isLoginState);
    },
    getUserLastOperationalInfo: async ({state, commit, dispatch}) => {
        let data = {
            currency: '',
            timezone: '',
            lang: '',
            country: '',
            displayColumns: '',
            campaignsFilter: '',
            adsFilter: ''
        };
        try {
            const res = await getUserConfig({email: state.email});
            if (res.data) {
                data = Object.assign(data, res.data);
            }
        } catch (error) {
            return console.log(error);
        }

        commit('setUserLastOperationalInfo', data);
        // new dashboard 时区
        data.timezone && dispatch('common/handleSetTimezone', data.timezone, { root: true });
        // new dashboard ad 筛选表单
        try {
            data.adsFilter && commit('SET_CACHE_FILTER', { ad: JSON.parse(data.adsFilter) });
        } catch (error) { console.log(error); }
        // new dashboard campaign 筛选表单
        try {
            data.campaignsFilter && commit('SET_CACHE_FILTER', { campaign: JSON.parse(data.campaignsFilter) });
        } catch (error) { console.log(error); }
        // new dashboard 设置列
        try {
            if(!data.displayColumns) return;
            let displayColumns = JSON.parse(data.displayColumns);
            for (const key in displayColumns) {
                if (displayColumns.hasOwnProperty(key)) {
                    displayColumns[key] && Cookie.set(key, displayColumns[key]);
                }
            }
        } catch (error) { console.log(error); }
    },
    setUserLastOperationalInfo: async ({state, commit}, data) => {
        // 注意：多传字段容易覆盖 只传 需更新的字段 即data
        let params = {...data, email: state.email};
        await setUserConfig(params);

        const newInfo = Object.assign(state.userLastOperationalInfo, data);
        commit('setUserLastOperationalInfo', newInfo);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
