<template>
    <div
        ref="panel"
        class="btn-wrapper fixed"
    >
        <template v-for="action in filterActions">
            <span
                :key="action.name" class="btn-wrap"
                :style="action.style || null"
            >
                <span v-if="action.type === 'tipIcon'" class="table-opt-btn">
                    <el-popover
                        :key="$index" :placement="action.placement || top - start"
                        title :popper-class="action.popoverClass || 'max-height-500'"
                        :visible-arrow="false" :width="action.popoverWidth || 150"
                        :trigger="action.trigger || 'hover'"
                    >
                        <template v-if="action.component">
                            <component
                                :is="action.component" v-if="!!action.component"
                                :row="row" :info="action"
                            />
                        </template>
                        <!--bca-disable-->
                        <div v-else v-html="action.content"></div>
                        <!--bca-enable-->
                        <template v-if="action.btnType === 'text'">
                            <span
                                slot="reference" :style="action.iconStyle || null"
                                class="btn-text"
                            >{{ $t(action.name) }}</span>
                        </template>
                        <template v-else>
                            <i
                                slot="reference" :style="action.iconStyle || null"
                                :class="`msn-fa msn-fa-${action.icon}`"
                            ></i>
                        </template>
                    </el-popover>
                </span>
                <template v-else>
                    <el-tooltip
                        effect="light" :content="$t(action.name)"
                        placement="bottom-start" :visible-arrow="false"
                        :disabled="action.icon === ''" popper-class="msn-icon-popertip"
                    >
                        <span class="table-opt-btn" @click="action.click(row)">
                            <template v-if="action.btnType === 'text'">
                                <span
                                    slot="reference" :style="action.iconStyle || null"
                                    class="btn-text"
                                >{{ $t(action.name) }}</span>
                            </template>
                            <template v-else>
                                <i :style="action.iconStyle || null" :class="`msn-fa msn-fa-${action.icon}`"></i>
                            </template>
                        </span>
                    </el-tooltip>
                </template>
            </span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        row: {
            default: ()=>{},
            type: Object
        },
        head: {
            default: ()=>{},
            type: Object
        }
    },
    data() {
        return {
            visible: false
        };
    },
    computed: {
        filterActions() {
            const actions = this.head.actions(this.row);
            const btns = (actions || []).filter(action => action.show);
            return btns;
        }
    },
    methods: {
        handleClose() {
            this.visible = false;
        },
        handleShow() {
            this.visible = true;
        }
    },
    mounted() {
        const el = this.$refs.panel;
        el.parentElement.addEventListener('mouseenter', this.handleShow);
        el.parentElement.addEventListener('mouseleave', this.handleClose);
    }
};
</script>

<style lang="scss">
.fixed{
    position: absolute;
    top:0;
    right: 0;
    z-index: 2;
    background: #F4F8FC;
    height: 100%;
}
</style>