/*
 * @file country-zh.js
 * @author liushengxgi
 * @date 2019-12-31 14:47:20
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2019-12-31 14:47:30
 */

export default {
    ALB: '阿爾巴尼亞',
    DZA: '阿爾及利亞',
    AFG: '阿富汗',
    ARG: '阿根廷',
    ARE: '阿聯酋',
    ABW: '阿魯巴',
    OMN: '阿曼',
    AZE: '阿塞拜疆',
    EGY: '埃及',
    ETH: '埃塞俄比亞',
    IRL: '愛爾蘭',
    EST: '愛沙尼亞',
    AND: '安道爾',
    AGO: '安哥拉',
    AIA: '安圭拉',
    ATG: '安提瓜和巴布達',
    AUT: '奧地利',
    ALA: '奧蘭羣島',
    AUS: '澳大利亞',
    MAC: '澳門',
    BRB: '巴巴多斯',
    PNG: '巴布亞新幾內亞',
    BHS: '巴哈馬',
    PAK: '巴基斯坦',
    PRY: '巴拉圭',
    PSE: '巴勒斯坦',
    BHR: '巴林',
    PAN: '巴拿馬',
    BRA: '巴西',
    BLR: '白俄羅斯',
    BMU: '百慕大',
    BGR: '保加利亞',
    MNP: '北马里亞納羣島',
    BEN: '貝寧',
    BEL: '比利時',
    ISL: '冰島',
    PRI: '波多黎各',
    BIH: '波黑',
    POL: '波蘭',
    BOL: '玻利維亞',
    BLZ: '伯利茲',
    BWA: '博茨瓦納',
    BTN: '不丹',
    BFA: '布基納法索',
    BDI: '布隆迪',
    BVT: '布韋島',
    PRK: '朝鮮 北朝鮮',
    GNQ: '赤道幾內亞',
    DNK: '丹麥',
    DEU: '德國',
    TLS: '東帝汶',
    TGO: '多哥',
    DOM: '多米尼加',
    DMA: '多米尼克',
    RUS: '俄羅斯',
    ECU: '厄瓜多爾',
    ERI: '厄立特里亞',
    FRA: '法國',
    FRO: '法羅羣島',
    PYF: '法屬波利尼西亞',
    GUF: '法屬圭亞那',
    ATF: '法屬南部領地',
    MAF: '法屬聖馬丁',
    VAT: '梵蒂岡',
    PHL: '菲律賓',
    FJI: '斐濟羣島',
    FIN: '芬蘭',
    CPV: '佛得角',
    GMB: '岡比亞',
    COG: '剛果（布）',
    COD: '剛果（金）',
    COL: '哥倫比亞',
    CRI: '哥斯達黎加',
    GRD: '格林納達',
    GRL: '格陵蘭',
    GEO: '格魯吉亞',
    GGY: '根西島',
    CUB: '古巴',
    GLP: '瓜德羅普',
    GUM: '關島',
    GUY: '圭亞那',
    KAZ: '哈薩克斯坦',
    HTI: '海地',
    KOR: '韓國 南朝鮮',
    NLD: '荷蘭',
    BES: '荷蘭加勒比區',
    HMD: '赫德島和麥克唐納羣島',
    MNE: '黑山',
    HND: '洪都拉斯',
    KIR: '基里巴斯',
    DJI: '吉布提',
    KGZ: '吉爾吉斯斯坦',
    GIN: '幾內亞',
    GNB: '幾內亞比紹',
    CAN: '加拿大',
    GHA: '加納',
    GAB: '加蓬',
    KHM: '柬埔寨',
    CZE: '捷克',
    ZWE: '津巴布韋',
    CMR: '喀麥隆',
    QAT: '卡塔爾',
    CYM: '開曼羣島',
    CCK: '科科斯羣島',
    COM: '科摩羅',
    CIV: '科特迪瓦',
    KWT: '科威特',
    HRV: '克羅地亞',
    KEN: '肯尼亞',
    COK: '庫克羣島',
    LVA: '拉脱維亞',
    LSO: '萊索托',
    LAO: '老撾',
    LBN: '黎巴嫩',
    LTU: '立陶宛',
    LBR: '利比里亞',
    LBY: '利比亞',
    LIE: '列支敦士登',
    REU: '留尼汪',
    LUX: '盧森堡',
    RWA: '盧旺達',
    ROU: '羅馬尼亞',
    MDG: '馬達加斯加',
    IMN: '馬恩島',
    MDV: '馬爾代夫',
    FLK: '馬爾維納斯羣島（ 福克蘭）',
    MLT: '馬耳他',
    MWI: '馬拉維',
    MYS: '馬來西亞',
    MLI: '馬裏',
    MKD: '馬其頓',
    MHL: '馬紹爾羣島',
    MTQ: '馬提尼克',
    MYT: '馬約特',
    MUS: '毛里求斯',
    MRT: '毛里塔尼亞',
    USA: '美國',
    UMI: '美國本土外小島嶼',
    ASM: '美屬薩摩亞',
    VIR: '美屬維爾京羣島',
    MNG: '蒙古國 蒙古',
    MSR: '蒙塞拉特島',
    BGD: '孟加拉',
    PER: '秘魯',
    FSM: '密克羅尼西亞聯邦',
    MMR: '緬甸',
    MDA: '摩爾多瓦',
    MAR: '摩洛哥',
    MCO: '摩納哥',
    MOZ: '莫桑比克',
    MEX: '墨西哥',
    NAM: '納米比亞',
    ZAF: '南非',
    ATA: '南極洲',
    SGS: '南喬治亞島和南桑威奇羣島',
    SSD: '南蘇丹',
    NRU: '瑙魯',
    NPL: '尼泊爾',
    NIC: '尼加拉瓜',
    NER: '尼日爾',
    NGA: '尼日利亞',
    NIU: '紐埃',
    NOR: '挪威',
    NFK: '諾福克島',
    PLW: '帕勞',
    PCN: '皮特凱恩羣島',
    PRT: '葡萄牙',
    JPN: '日本',
    SWE: '瑞典',
    CHE: '瑞士',
    SLV: '薩爾瓦多',
    WSM: '薩摩亞',
    SRB: '塞爾維亞',
    SLE: '塞拉利昂',
    SEN: '塞內加爾',
    CYP: '塞浦路斯',
    SYC: '塞舌爾',
    SAU: '沙特阿拉伯',
    BLM: '聖巴泰勒米島',
    CXR: '聖誕島',
    STP: '聖多美和普林西比',
    SHN: '聖赫勒拿',
    KNA: '聖基茨和尼維斯',
    LCA: '聖盧西亞',
    SMR: '聖馬力諾',
    SPM: '聖皮埃爾和密克隆',
    VCT: '聖文森特和格林納丁斯',
    LKA: '斯里蘭卡',
    SVK: '斯洛伐克',
    SVN: '斯洛文尼亞',
    SJM: '斯瓦爾巴羣島和 揚馬延島',
    SWZ: '斯威士蘭',
    SDN: '蘇丹',
    SUR: '蘇里南',
    SLB: '所羅門羣島',
    SOM: '索馬里',
    TJK: '塔吉克斯坦',
    THA: '泰國',
    TZA: '坦桑尼亞',
    TON: '湯加',
    TCA: '特克斯和凱科斯羣島',
    TTO: '特立尼達和多巴哥',
    TUN: '突尼斯',
    TUV: '圖瓦盧',
    TUR: '土耳其',
    TKM: '土庫曼斯坦',
    TKL: '托克勞',
    WLF: '瓦利斯和富圖納',
    VUT: '瓦努阿圖',
    GTM: '危地馬拉',
    VEN: '委內瑞拉',
    BRN: '文萊',
    UGA: '烏干達',
    UKR: '烏克蘭',
    URY: '烏拉圭',
    UZB: '烏茲別克斯坦',
    ESP: '西班牙',
    ESH: '西撒哈拉',
    GRC: '希臘',
    HKG: '香港',
    SGP: '新加坡',
    NCL: '新喀里多尼亞',
    NZL: '新西蘭',
    HUN: '匈牙利',
    SYR: '敍利亞',
    JAM: '牙買加',
    ARM: '亞美尼亞',
    YEM: '也門',
    IRQ: '伊拉克',
    IRN: '伊朗',
    ISR: '以色列',
    ITA: '意大利',
    IND: '印度',
    IDN: '印尼',
    GBR: '英國',
    VGB: '英屬維爾京羣島',
    IOT: '英屬印度洋領地',
    JOR: '約旦',
    VNM: '越南',
    ZMB: '贊比亞',
    JEY: '澤西島',
    TCD: '乍得',
    GIB: '直布羅陀',
    CHL: '智利',
    CAF: '中非',
    CHN: '中國內地',
    TWN: '台灣'
};
