<template>
    <msn-dialog
        class="fixed-dialog"
        :visible="visible" :title="$t('msn.amDashboard.noticeListTitle')"
        width="950px"
        :before-close="handleClose"
        :append-to-body="true"
        :modal-append-to-body="false"
    >
        <div
            v-if="moreMessageList.length > 0"
            v-loading="moreMessageLoading"
            class="fixed-dialog-list" element-loading-spinner="el-icon-loading"
        >
            <messageItem
                v-for="(item,index) in moreMessageList" :key="index"
                :item="item"
                @handleDetail="handleDetailId"
            />
        </div>
        <div v-else class="fixed-dialog-list-notice">
            <span> {{ $t("msn.infos.emptyText") }}</span>
        </div>
        <div
            slot="footer"
            class="footer-box footer-container"
        >
            <div class="pagination">
                <el-pagination
                    layout="prev, pager, next, jumper, slot, sizes"
                    popper-class="pagination-select"
                    :current-page.sync="pageIndex"
                    :page-size="pageSize"
                    :total="total"
                    :page-sizes="[10, 20, 50]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                    <span style="font-weight: normal; margin: 0 10px">
                        {{ $t("msn.button.total") }}&nbsp;
                        <b class="total">{{ total }}</b>
                        {{ ($t("msn.button.total") === "共" && " 条") || "" }}
                    </span>
                </el-pagination>
            </div>
        </div>
        <message-detail
            :visible="showMessageDetail"
            :detail-loading="detailLoading"
            :detail="detail"
            :is-list-message="isListMessage"
            @close="handleShowDetail"
            @getData="getData"
        />
    </msn-dialog>
</template>
<script>
import messageItem from './messageItem.vue';
import MessageDetail from './message/messageDetail.vue';
import api from '@/constants/api';
import { mapGetters } from 'vuex';
export default {
    name: 'MessageList',
    props: {
        visible: {
            type: Boolean
        }
    },
    data() {
        return {
            messageList: [],
            showMessageDetail: false,
            detail: {},
            detailLoading: false,
            isListMessage: false,
            // 单页展示数量
            pageSize: 10,
            // 页码
            pageIndex: 1,
            total: 0,
            moreMessageList: [],
            moreMessageLoading: false
        };
    },
    computed: {
        ...mapGetters('common', {
            curLang: 'getLang'
        }),
        handleCurLang() {
            return this.curLang === 'cn' ? 'zh' : this.curLang;
        }
    },
    components: {
        // 'msn-dialog': MsnDialog,
        messageItem,
        MessageDetail
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        // 当前页码更新
        handleCurrentChange(index) {
            console.log(index, 'czy');
            this.pageIndex = index;
            this.getData(this.pageIndex);
        },
        // 每页展示条数更新
        handleSizeChange(size) {
            console.log(size, 'czy');
            this.pageIndex = 1;
            this.pageSize = size;
            this.getData(this.pageIndex);
        },
        getData(index) {
            this.pageIndex = !isNaN(index) ? index : this.pageIndex;
            const pagination = {
                language: this.handleCurLang,
                page: +this.pageIndex || 1,
                pageSize: +this.pageSize || 10
            };
            let params = pagination;
            this.moreMessageLoading = true;
            return new Promise((resolve, reject) => {
                this.$request(
                    {
                        url: api.getMessagePages,
                        contentType: 'application/json; charset=UTF-8',
                        method: 'get',
                        success(res) {
                            this.moreMessageLoading = false;
                            const data = res.data || {};
                            this.moreMessageList = data.detail || [];
                            this.total = data.total || 0;
                        },
                        error(err) {
                            this.moreMessageLoading = false;
                        }
                    },
                    params
                );
            });
        },
        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
        handleDetailId(id) {
            this.showMessageDetail = true;
            this.isListMessage = true;
            this.detailLoading = true;
            this.handleClose();
            this.$request({
                method: 'get',
                url: api.getMessageDetail,
                success(res) {
                    this.detailLoading = false;
                    this.detail = res.data;
                },
                error(err) {
                    this.detailLoading = false;
                }
            }, {userMessageId: +id});
        },
        handleShowDetail() {
            this.showMessageDetail = false;
            this.$parent.showMessageDialog = true;
        }
    },
    mounted() {
    },
    watch: {
    }
};
</script>
<style lang="scss" scoped>
.fixed-dialog{
    /deep/ .msn-dialog .el-dialog__body{
        padding: 0px !important;
    }
    /deep/.msn-dialog .el-dialog__header .dialog-title .title{
        text-align: left;
    }
}
.fixed-dialog-list{
    &-slot{
        position: relative;
        top: 0px;
        right: -14px;
        min-height: 500px;
    }
}
.fixed-dialog-list-notice{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
}
.fixed-dialog /deep/ .dialog-body {
    height: 580px;
    min-height: 580px;
    overflow-y: auto;
    &/deep/::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.7);
        border-radius: 3px;
        width: 3px;
    }
    &/deep/::-webkit-scrollbar{
        width: 4px !important;
    }
}
.fixed-dialog /deep/ .footer-container {
    display: flex;
    justify-content: flex-end;
        &-more{
            cursor: pointer;
        }
}
.pagination {
    text-align: right;
    margin: 20px;
    display: flex;
    margin-right: 10px;
    justify-content: space-between;
    /deep/ .el-input__suffix {
        .el-select__caret {
            font-size: 14px;
        }
    }
}
</style>
<style lang="scss">
.fixed-dialog{
    .msn-dialog .el-dialog__header .dialog-title .title{
        text-align: left;
    }
    .msn-dialog .el-dialog__body .dialog-body::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.7) ;
        border-radius: 3px ;
        width: 3px ;
    }
    .msn-dialog .el-dialog__body .dialog-body::-webkit-scrollbar {
        width: 3px ;
    }
    .pagination-select .el-select-dropdown__item.selected {
        color: #2dd1ac;
    }
}
</style>