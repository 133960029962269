/**
 * @file 菜单
 * @author  fangsimin
 * @date    2019-01-02 10:55:08
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:11:36
 */
<template>
    <el-menu
        v-if="data && data.length"
        :default-active="activeIndex"
        :mode="mode"
        :collapse="isCollapse"
        :router="true"
        class="el-menu-demo" @select="handleSelect"
    >
        <template v-for="item in data">
            <menu-item
                v-if="item && item['key']"
                :key="item['key']"
                :routekey="'/' + (base ? base + '/' : '') + item['key']"
                :data="item"
            />
        </template>
    </el-menu>
</template>

<script>

import MenuItem from './MenuItem';

export default {
    props: {
        mode: {
            default: 'vertical',
            type: String
        }, // 模式：horizontal / vertical
        isCollapse: {
            default: true
        }, // 是否是合起状态，合起模式只显示icon
        data: {
            type: Array,
            default: () => []
        },
        base: {
            default: ''
        }
    },
    data() {
        let activePath = this.$route['path'];
        return {
            activeIndex: activePath // 当前激活的menu
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        }
    },
    components: {
        MenuItem
    }
};
</script>
