var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column-container"},[_c('div',{staticClass:"column-item"},[_c('div',{staticClass:"list"},_vm._l((_vm.columnList),function(value,key,index){return _c('div',{key:index},[_c('msn-checkbox-group',{staticClass:"msn-checkbox-group",attrs:{"check-options":value,"type":_vm.type,"default-val":_vm.checkboxDefaultCols[key] &&
                            _vm.checkboxDefaultCols[key].length > 0
                            ? _vm.checkboxDefaultCols[key]
                            : _vm.disableCheckboxKey[key] || [],"title":_vm.$t(
                            ("msn.dashboard.col" + (key[0].toUpperCase()) + (key.slice(
                                1
                            )))
                        ),"title-suffix":_vm.extraInfo[key] && _vm.extraInfo[key].hasTitleSuffix
                            ? _vm.$t(
                                ("msn.dashboard.suffix" + (key[0].toUpperCase()) + (key.slice(
                                    1
                                )))
                            )
                            : '',"min":(_vm.extraInfo[key] && _vm.extraInfo[key].min) || 0,"max":(_vm.extraInfo[key] && _vm.extraInfo[key].max) || 100,"no-select-all":_vm.extraInfo[key] && _vm.extraInfo[key].noSelectAll},model:{value:(_vm.selected[key]),callback:function ($$v) {_vm.$set(_vm.selected, key, $$v)},expression:"selected[key]"}})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-header"},[_c('div',{staticClass:"column-title"},[_vm._v("\n                "+_vm._s(_vm.$t("msn.infos.selected"))+" \n                "),_c('span',{staticClass:"info"},[_vm._v(_vm._s(_vm.$t("msn.infos.dragTip")))])]),_vm._v(" "),_c('div',{staticClass:"reset-btn",on:{"click":function($event){return _vm.reset()}}},[_vm._v("\n                "+_vm._s(_vm.$t("msn.button.reset"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"list right"},[_c('msn-draggable',{attrs:{"type":_vm.type,"default-cols":_vm.defaultColumn.length > 0 ? _vm.defaultColumn : _vm.disableKey,"label-and-value-map":_vm.labelAndValueMap,"before-delete":_vm.beforeDelete,"extra-info":_vm.extraInfo},on:{"del":_vm.handleDel,"error":_vm.handleDraggableError},model:{value:(_vm.dragData),callback:function ($$v) {_vm.dragData=$$v},expression:"dragData"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }