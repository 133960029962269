import PATH from '@/constants/path';
import filter from '@/common/filters';
import utils from '@/common/utils';
import {copyTextToClipboard} from '@/common/clipboard';
const Status = {
    props: ['row', 'handler', 'disabled'],
    template: `
        <el-switch
            v-if="row.accountStatus < 2"
            v-model="row.accountStatus"
            active-color="#2DD1AC"
            inactive-color="#858D94"
            :active-value="1"
            :inactive-value="0"
            :disabled="disabled"
            @change="handler(row)">
        </el-switch>
    `
};
export const accountSourceList = [
    {
        label: 'MediaGo',
        value: 'MediaGo'
    },
    {
        label: 'Discovery',
        value: 'Discovery'
    }
];
// export const contractualSubjectList = [
//     {
//         label: 'mediago',
//         value: 'mediago'
//     },
//     {
//         label: 'jp.popin.cc',
//         value: 'jp.popin.cc'
//     },
//     {
//         label: 'kr.popin.cc',
//         value: 'kr.popin.cc'
//     },
//     {
//         label: 'tw.popin.cc',
//         value: 'tw.popin.cc'
//     }
// ];
export const apiTokenList = [
    {
        label: 'msn.supplement.aPIhasinstalled',
        value: 1
    },
    {
        label: 'msn.supplement.withoutSettingAPI',
        value: 0
    }
];
export const statusList = [
    {
        label: 'msn.dashboard.off',
        value: 0
    },
    {
        label: 'msn.dashboard.on',
        value: 1
    },
    {
        label: 'msn.audit.inReview',
        value: 2
    },
    {
        label: 'msn.audit.fail',
        value: 3
    }
];
export const accountTypeList = [
    {
        label: 'msn.supplement.businessManagement',
        value: 'Aggregator'
    },
    {
        label: 'msn.supplement.advertiser',
        value: 'Advertiser'
    }
];
export const headers = function () {
    const self = this;
    return [
        {
            field: 'accountStatus',
            title: 'msn.permissionMgr.accountStatus',
            handler: () => this.handleSwitch,
            disabled: () => self.menu[PATH['accountStatus']] !== 'rw',
            component: Status,
            minWidth: '160px',
            fixed: 'left'
        },
        {
            field: 'accountName',
            title: 'msn.permissionMgr.accountName',
            minWidth: '130px',
            fixed: 'left'
        },
        {
            field: 'accountId',
            title: 'msn.permissionMgr.accountId',
            minWidth: '100px'
        },
        {
            field: 'companyName',
            title: 'msn.permissionMgr.companyName',
            minWidth: '200px'
        },
        {
            field: 'serviceType',
            title: 'msn.roiMgr.serviceType',
            minWidth: '230px',
            format: (row) => {
                if (row.serviceType === '0') {
                    return this.$t('msn.supplement.openAccountIndependently');
                }
                if (row.serviceType === '1') {
                    return this.$t('msn.supplement.inviteToOpenAccount');
                }
                return this.$t('msn.supplement.undef');
            }
        },
        {
            field: 'locationOfCorporation',
            title: 'msn.permissions.companyLegalPerson',
            minWidth: '300px',
            format: row => {
                return row.locationOfCorporation ? this.$t(`msn.${row.locationOfCorporation}`) : '';
            }
        },
        {
            field: 'country',
            title: 'msn.supplement.country',
            format: row => {
                return row.country ? this.$t(`msn.${row.country}`) : '';
            },
            minWidth: '100px'
        },
        {
            field: 'accountOrigin',
            title: 'msn.supplement.accountSource',
            minWidth: '150px'
        },
        {
            field: 'accountType',
            title: 'msn.financiaPay.accountType',
            format: row => {
                // return utils.getLabelFromVal(row.accountType, accountTypeList);
                return this.$t(utils.getLabelFromVal(row.accountType, accountTypeList));
            },
            minWidth: '150px'
        },
        {
            field: 'contractualSubject',
            title: 'msn.supplement.contractType',
            minWidth: '200px'
        },
        {
            field: 'agentName',
            title: 'msn.supplement.customerSource',
            minWidth: '170px',
            format: row => {
                return row.agentName || this.$t('msn.supplement.terminalclient');
            }
        },
        {
            field: 'billingEmail',
            title: 'msn.supplement.billingEmail',
            minWidth: '100px'
        },
        {
            field: 'sourceCompanyName',
            title: 'msn.supplement.organizationName',
            minWidth: '200px',
            format: row => {
                return row.sourceCompanyName || this.$t('msn.supplement.none');
            }
        },
        {
            field: 'rebateRatio',
            number: true,
            format: (row) => {
                return filter.numberFormat((row.rebateRatio && +row.rebateRatio) || 0, 4) + '%';
            },
            title: 'msn.supplement.businessRebate',
            minWidth: '150px'
        },
        {
            field: 'email',
            title: 'msn.supplement.email',
            minWidth: '230px'
        },
        {
            field: 'payMethod',
            title: 'msn.supplement.paymentMethod',
            minWidth: '200px',
            format: (row) => {
                if (row.payMethod === '到付') {
                    return this.$t('msn.openAccount.delivery');
                }
                if (row.payMethod === '预付') {
                    return this.$t('msn.openAccount.prepayment');
                }
                return '';
            }
        },
        {
            field: 'adMethod',
            title: 'msn.supplement.deliverymethod',
            minWidth: '200px',
            format: (row) => {
                if (row.adMethod === '自投') {
                    return this.$t('msn.openAccount.selfService');
                }
                if (row.adMethod === '代投') {
                    return this.$t('msn.supplement.managed');
                }
                return '';
            }
        },
        {
            field: 'timezone',
            title: 'msn.supplement.accountTimeZone',
            minWidth: '200px'
        },
        {
            field: 'amResponsible',
            title: 'msn.amDashboard.colAmResponsible',
            minWidth: '200px'
        },
        {
            field: 'businessManager',
            title: 'msn.supplement.businessManager',
            minWidth: '170px'
        },
        {
            field: 'category',
            title: 'msn.supplement.industryCategory',
            minWidth: '170px'
        },
        {
            field: 'createTime',
            title: 'msn.supplement.creationTime',
            sortable: 'custom',
            minWidth: '160px'
        },
        {
            field: null,
            title: 'msn.supplement.operate',
            width: '200px',
            // minWidth: '200px',
            format: (row) => {
                return [
                    {
                        icon: 'info',
                        name: 'msn.button.detail',
                        show: self.menu[PATH['accountDetail']],
                        click: (row) => {
                            this.handleDetail(row);
                        }
                    },
                    {
                        icon: 'edit',
                        name: 'msn.button.edit',
                        show: self.menu[PATH['accountEdit']] === 'rw' && +row.accountStatus < 2,
                        click: (row) => {
                            this.handleEdit(row);
                        }
                    },
                    {
                        icon: 'check',
                        name: 'msn.button.authorize',
                        show: self.menu[PATH['accountAuthorize']] === 'rw' && +row.accountStatus === 2,
                        click: (row) => {
                            this.handleEdit(row);
                        }
                    },
                    {
                        icon: 'check-i',
                        name: 'msn.button.ignore',
                        show: self.menu[PATH['accountIgnore']] === 'rw' && +row.accountStatus === 3,
                        click: (row) => {
                            this.handleEdit(row);
                        }
                    },
                    {
                        icon: 'copy',
                        name: 'msn.button.copy',
                        show: self.menu[PATH['accountInvite']] === 'rw' && row.token && row.token.length > 0,
                        click: (row) => {
                            this.copyOpenAccountLink(row);
                        }
                    },
                    {
                        icon: 'api-token',
                        name: 'msn.button.apiToken',
                        show: row.apiToken !== '',
                        click: (row) => {
                            copyTextToClipboard(row.apiToken);
                        }
                    },
                    {
                        icon: 'diagnosis_info',
                        name: 'msn.button.sendReport',
                        show: true,
                        click: (row) => {
                            this.handleSendReport(row);
                        }
                    },
                    {
                        icon: 'config',
                        name: 'msn.amDashboard.configableCpc',
                        // 只有在账户维度下有效, 本迭代不做，等下个迭代加上就好了
                        show: true,
                        // show: self.menu[PATH['accountEdit']] === 'rw' && +row.accountStatus < 2,
                        click: (row) => {
                            console.log(row);
                            this.handleConnfigCpc(row);
                        }
                    }
                ];
            }
        }
    ];
};

export const VALIDATORS = function () {
    const numReg = /^(([0-9]\d{0,})|(([0]\.\d{1,2}|[1-9]\d{0,}\.\d{1,2})))$/;
    const checkNum = (rule, value, callback) => {
        if (!numReg.test(value) || +value < 0) {
            return callback(new Error(this.$t('msn.validations.money')));
        }
        //  else if (+value > 1000000000) {
        //     return callback(new Error(this.$t('msn.validations.numberBeyond')));
        // }
        callback();
    };
    const checkEmail = (rule, value, callback) => {
        // eslint-disable-next-line
        const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
        if (value && !reg.test(value)) {
            callback(new Error(this.$t('msn.validations.email')));
        }
        callback();
    };
    const checkRebateRatio = (rule, value, callback) => {
        const numReg = /^-?(([1-9]\d{0,})|[0]|(([0]\.\d{1})|([1-9]\d{0,}\.\d{1})))$/;
        if (this.account.type === 'Aggregator') {
            if (!numReg.test(value)) {
                callback(new Error(this.$t('msn.supplement.enterValid')));
            }
        }
        callback();
    };
    const checkAgent = (rule, value, callback) => {
        if (this.account.source === '代理商') {
            if (!value) {
                callback(new Error(this.$t('msn.supplement.selectAppropriate')));
            }
        }
        callback();
    };
    const checkCompany = (rule, value, callback) => {
        if (!this.showNewCompany) {
            if (!value) {
                callback(new Error(this.$t('msn.supplement.pleaseselectacompany')));
            }
        }
        callback();
    };
    const billingEmail = (rule, value, callback) => {
        if (value === undefined) {
            callback();
            return;
        }
        if (value.includes('；')) {
            callback(new Error(this.$t('msn.openAccount.billingEmailSymbol')));
            return;
        }
        if (value.length > 1000) {
            callback(new Error(this.$t('msn.openAccount.billingEmailLength')));
            return;
        }
        callback();
    };
    return {
        checkNum,
        checkEmail,
        checkRebateRatio,
        checkAgent,
        checkCompany,
        billingEmail
    };
};
export const billingEmailFormat = function (v) {
    if (typeof v === 'string') {
        const r = /\s/g;
        return v.replace(r, '');
    }
    return v;
};
export const accountRules = function () {
    const validator = VALIDATORS.call(this);
    return {
        companyId: [{validator: validator.checkCompany, trigger: 'change'}],
        name: [{required: true, message: this.$t('msn.supplement.pleaseentertheaccountname'), trigger: 'blur'}],
        website: [
            {required: true, message: this.$t('msn.supplement.pleaseentercompanyURL'), trigger: 'blur'}
            // {validator: validator.checkHttp, trigger: 'blur'}
        ],
        email: [
            {required: true, message: this.$t('msn.supplement.pleaseenteravalidemailaddress'), trigger: 'blur'},
            {validator: validator.checkEmail, trigger: 'blur'}
        ],
        budget: [
            {required: true, message: this.$t('msn.supplement.pleaseenterbudget'), trigger: 'blur'},
            {validator: validator.checkNum, trigger: 'blur'}
        ],
        payMethod: [{required: true, message: this.$t('msn.validations.payMethod'), trigger: 'change'}],
        adMethod: [{required: true, message: this.$t('msn.validations.adMethod'), trigger: 'change'}],
        timezone: [{required: true, message: this.$t('msn.validations.timezone'), trigger: 'change'}],
        // currency: [{required: true, message: '请选择账户结算币种', trigger: 'change'}],
        currency: [{ required: true, message: this.$t('msn.validations.tradingCurrencyCheck'), trigger: 'change' }],
        settlementCurrency: [{ required: true, message: this.$t('msn.validations.currencyCheck'), trigger: 'change' }],
        rebateRatio: [{validator: validator.checkRebateRatio, trigger: 'blur'}],
        agentId: [{validator: validator.checkAgent, trigger: 'change'}],
        locationOfCorporation: [{ required: true, message: this.$t('msn.validations.legalRepresentativeCheck'), trigger: 'change' }],
        country: [{required: true, message: this.$t('msn.validations.country'), trigger: 'change'}],
        businessManager: [{required: true, message: this.$t('msn.supplement.enterBM'), trigger: 'blur'}],
        category: [{required: true, message: this.$t('msn.supplement.enterIndustryClassification'), trigger: 'blur'}],
        categoryDetail: [{required: true, message: this.$t('msn.supplement.enterIndustry'), trigger: 'blur'}],
        amResponsibleId: [{required: true, message: this.$t('msn.supplement.selectAM'), trigger: 'change'}],
        billingEmail: [{ validator: validator.billingEmail, trigger: 'blur' }],
        contractualSubject: [{ required: true, message: this.$t('msn.supplement.selectContractType'), trigger: 'blur' }],
        langList: [{ required: true, message: this.$t('msn.supplement.selectAccountLanguage'), trigger: 'blur' }],
        accountNature: [{ required: true, message: this.$t('msn.supplement.selectAccountLanguage'), trigger: 'blur' }]
    };
};

export const companyRules = function () {
    const validator = VALIDATORS.call(this);
    return {
        name: [{required: true, message: this.$t('msn.validations.account'), trigger: 'blur'}],
        registerAddress: [{required: true, message: this.$t('msn.supplement.addressCompany'), trigger: 'blur'}],
        businessAddress: [{required: true, message: this.$t('msn.supplement.enterAddress'), trigger: 'blur'}],
        website: [
            {required: true, message: this.$t('msn.validations.companySite'), trigger: 'blur'},
            {validator: validator.checkHttp, trigger: 'blur'}
        ],
        type: [{required: true, message: this.$t('msn.supplement.selectCompanyType'), trigger: 'change'}]
    };
};

export const BAIDU_TOKEN = 'a7EAq0wO03HoCowaSpNJv_ypRIV_0asEdJR5zidKTkw';

export const tipData = [
    {
        user: 'AM & BD用户',
        account: '用户权限的可读账户增加该账户',
        bdAccount: '用户权限的BM账户增加该商务管理账户，同时可读账户增加所有代理商账户为该BM账户的账户'
    },
    {
        user: 'BM账户对应的用户',
        account: '',
        bdAccount: '用户权限的BM账户增加该商务管理账户，同时可读账户增加所有代理商账户为该BM账户的账户'
    },
    {
        user: '广告主用户',
        account: '用户权限的可写、可读账户增加该账户',
        bdAccount: ''
    }
];

export const accountOriginList = [
    {
        value: 'MediaGo',
        label: 'MediaGo'
    },
    {
        value: 'Discovery',
        label: 'Discovery'
    }
];
export const contractualSubjectList = [
    {
        value: 'mediago',
        label: 'msn.constants.mediago'
    },
    {
        value: 'jp.popin.cc',
        label: 'msn.constants.jpSubject'
    },
    {
        value: 'kr.popin.cc',
        label: 'msn.constants.krSubject'
    },
    {
        value: 'tw.popin.cc',
        label: 'msn.constants.twSubject'
    },
    {
        value: 'discovery线上合同',
        label: 'msn.supplement.discoveryOnline'
    }
];