/**
 * @file 登录页面
 * @author  fangsimin
 * @date    2019-01-11 16:13:02
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-14 21:43:01
 */

<template>
    <section class="page-modify">
        <header class="login-header">
            <div class="modify-logo modify-pwd-logo"></div>
        </header>
        <el-card
            v-loading="loading" class="box-card"
            element-loading-spinner="el-icon-loading"
        >
            <div class="box-header">
                {{ $t('msn.login.forgetPwd') }}
            </div>
            <section class="card-login">
                <el-form
                    ref="modify" :model="modify"
                    :rules="rules" label-width="160px"
                    class="form-modify"
                >
                    <el-form-item
                        :label="$t('msn.login.account')" class="form-item-container"
                        prop="email"
                    >
                        <el-input v-model="modify.email" :placeholder="$t('msn.login.loginEmail')"/>
                    </el-form-item>
                    <el-form-item
                        :label="$t('msn.login.pwdBefore')" class="form-item-container"
                        prop="pwdBefore"
                    >
                        <el-input
                            v-model="modify.pwdBefore" type="password"
                            :placeholder="$t('msn.login.pwdBefore')"
                        />
                    </el-form-item>
                    <el-form-item
                        :label="$t('msn.login.pwdAfter')" class="form-item-container"
                        prop="pwdAfter"
                    >
                        <el-popover
                            placement="right"
                            title=""
                            popper-class="tip-poper-class"
                            width="200"
                            trigger="focus"
                            :content="$t('msn.validations.pwd')"
                        >
                            <!-- <el-button >hover 激活</el-button> -->
                            <el-input
                                slot="reference"
                                v-model="modify.pwdAfter"
                                type="password"
                                :placeholder="$t('msn.login.pwdAfter')"
                            />
                        </el-popover>
                    </el-form-item>
                    <el-form-item
                        :label="$t('msn.login.confirmPwd')" class="form-item-container"
                        prop="confirmPwd"
                    >
                        <el-input
                            v-model="modify.confirmPwd" type="password"
                            :placeholder="$t('msn.login.confirmPwd')"
                        />
                    </el-form-item>
                </el-form>
            </section>
            <div class="card-footer">
                <el-button @click="handleCancel">
                    {{ $t('msn.button.cancel') }}
                </el-button>
                <el-button type="primary" @click="handlModify">
                    {{ $t('msn.button.confirm') }}
                </el-button>
            </div>
        </el-card>
    </section>
</template>

<script>
import md5 from 'md5';
import api from '@/constants/api';
import PATH from '@/constants/path';
export default {
    data() {
        const checkEmail = (rule, value, callback) => {
            // eslint-disable-next-line
            const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
            if (value && !reg.test(value)) {
                callback(new Error(this.$t('msn.validations.email')));
            }
            callback();
        };
        const checkPassword = (rule, value, callback) => {
            const reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*])[a-z\d#@!~%^&*]{8,}/i;
            if (value && !reg.test(value)) {
                callback(new Error(this.$t('msn.validations.pwd')));
            }
            callback();
        };
        const checkConfirm = (rule, value, callback) => {
            if (this.modify.pwdAfter !== value) {
                callback(new Error(this.$t('msn.validations.pwdNotMatch')));
            }
            callback();
        };
        return {
            loading: false,
            modify: {},
            rules: {
                email: [
                    {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
                    {validator: checkEmail, tigger: 'blur'}
                ],
                pwdBefore: [
                    {required: true, message: this.$t('msn.validations.pwdRequired'), trigger: 'blur'}
                ],
                pwdAfter: [
                    {required: true, message: this.$t('msn.validations.pwd'), trigger: 'blur'},
                    {validator: checkPassword, tigger: 'blur'}
                ],
                confirmPwd: [
                    {validator: checkConfirm, tigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        // 取消修改密码
        handleCancel() {
            this.$router.push({path: PATH['login']});
        },
        // 确认修改
        handlModify() {
            this.$refs.modify.validate(valid => {
                if (!valid) return;
                this.loading = true;
                this.$request({
                    url: api.modifyPwd,
                    method: 'post',
                    contentType: 'application/json; charset=UTF-8',
                    success(res) {
                        this.loading = false;
                        this.$message.success(this.$t('msn.requestMsg.modified'));
                        this.$router.push({path: PATH['login']});
                    },
                    error() {
                        this.loading = false;
                        // this.$message.warning(this.$t('msn.requestMsg.requestError'));
                    }
                }, {
                    email: this.modify.email,
                    pwdBefore: md5(this.modify.pwdBefore).toUpperCase(),
                    pwdAfter: md5(this.modify.pwdAfter).toUpperCase()
                });
            });
        }
    }
};
</script>

<style lang="scss">
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
textarea,
input,
button,
select {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.page-modify{
    height: 100vh;
    position: relative;
    font-weight: 700;
    text-align: left;
    box-shadow: -2px 6px 30px 0 rgba(0,0,0,0.40);

    .login-header {
        height: 80px;
        padding: 30px 0 20px;
        margin: 0 40px;
        box-sizing: border-box;
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            opacity: 0.2;
            background: #0067FF;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
        }
    }

    .box-card {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 28px));
        width: 900px;
        height: 440px;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0 2px 20px 0 rgba(20, 70, 199, .1) !important;
        border: none!important;
        .box-header {
            height: 80px;
            background-image: linear-gradient(234deg, #1446C7 14%, #1967F2 100%);
            box-shadow: 0 3px 5px 0 rgba(20, 70, 199, .2);
            border-radius: 6px 6px 0 0;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .el-card__body {
            padding: 0!important;
        }
        .form-modify {
            width: 560px;
            margin: 50px 200px 0 auto;
        }
        .card-footer {
            text-align: right;
            padding: 5px 30px 30px;
        }
        .el-form-item {
            margin-bottom: 25px!important;
            .el-form-item__label,
            .el-form-item__content {
                line-height: 35px !important;
            }
            .el-input__inner {
                height: 35px;
                background: #ECECEC;
                border: 1px solid transparent;
                box-sizing: border-box;
                font-size: 14px;
                border-radius: 17.5px;
                color:  #53575B;
                font-weight: 500;
                &:active,
                &:hover,
                &:focus {
                    background: rgba(236, 236, 236, .5);
                    border: 1px solid #0067FF;
                }
                &::placeholder {
                    color: rgba(83, 87, 91, .3);
                    font-weight: 500;
                }
            }
        }
        .card-footer {
            @keyframes scaling {
                0% {
                    transform: scale(1)
                }
                50% {
                    transform: scale(.85);
                }
                100% {
                    transform: scale(1);
                }
            }
            .el-button {
                height: 35px;
                box-sizing: border-box;
                border-radius: 17.5px;
                font-size: 13px;
                font-weight: 700;
                line-height: 1;
                &--default {
                    border: 1px solid #53575B;
                    background-color: #fff;
                    color: #53575B!important;
                    &:hover {
                        border-color: transparent;
                        background: rgba(83, 87, 91, .20);
                    }
                    &:active {
                        border-color: transparent;
                        background: rgba(83, 87, 91, .20);
                        animation: .5s ease scaling;
                    }
                    &:focus {
                        border-color: transparent;
                        background: rgba(83, 87, 91, .20);
                    }
                }
                &--primary {
                    background: #0067FF;
                    color: #fff!important;
                    margin-left: 30px;
                    &:active,
                    &:hover,
                    &:focus {
                        border-color: #0067FF;
                        background: #0067FF;
                    }
                    &:active {
                        animation: .5s ease scaling;
                    }
                }
            }
        }
    }
}
</style>
