/*
 * @file state-cn.js
 * @author liushengxgi
 * @date 2020-02-10 11:07:54
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-10 11:08:41
 */

export default {
    // 美国
    AL: '阿拉巴马州',
    AK: '阿拉斯加州',
    AZ: '亚利桑那州',
    AR: '阿肯色州',
    CA: '加利福尼亚州',
    CO: '科罗拉多州',
    CT: '康涅狄格州',
    DE: '特拉华州',
    FL: '佛罗里达州',
    GA: '佐治亚州',
    HI: '夏威夷州',
    ID: '爱达荷州',
    IL: '伊利诺伊州',
    IN: '印第安纳州',
    IA: '艾奥瓦州',
    KS: '堪萨斯州',
    KY: '肯塔基州',
    LA: '路易斯安那州',
    ME: '缅因州',
    MD: '马里兰州',
    MA: '马萨诸塞州',
    MI: '密歇根州',
    MN: '明尼苏达州',
    MS: '密西西比州',
    MO: '密苏里州',
    MT: '蒙大拿州',
    NE: '内布拉斯加州',
    NV: '内华达州',
    NH: '新罕布什尔州',
    NJ: '新泽西州',
    NM: '新墨西哥州',
    NY: '纽约州',
    NC: '北卡罗来纳州',
    ND: '北达科他州',
    OH: '俄亥俄州',
    OK: '俄克拉何马州',
    OR: '俄勒冈州',
    PA: '宾夕法尼亚州',
    RI: '罗得岛州',
    SC: '南卡罗来纳州',
    SD: '南达科他州',
    TN: '田纳西州',
    TX: '得克萨斯州',
    UT: '犹他州',
    VT: '佛蒙特州',
    VA: '弗吉尼亚州',
    WAI: '华盛顿州',
    WV: '西弗吉尼亚州',
    WI: '威斯康星州',
    WY: '怀俄明州',
    OT: '其他',
    DC: '其他',
    // 加拿大
    AB: '艾伯塔',
    BC: '卑诗',
    MB: '曼尼托巴',
    NB: '新不伦瑞克',
    NL: '纽芬兰与拉布拉多',
    NS: '新斯科舍',
    NT: '西北领地',
    NU: '努纳武特',
    ON: '安大略',
    PE: '爱德华王子岛',
    QC: '魁北克',
    SK: '萨斯喀彻温',
    YT: '育空',
    // 澳大利亚
    NSW: '新南威尔士州',
    QLD: '昆士兰州',
    SA: '南澳大利亚州',
    TAS: '塔斯马尼亚州',
    VIC: '维多利亚州',
    WA: '西澳大利亚州',
    ACT: '澳大利亚首都领地',
    NT01: '北领地',
    // 英国
    ENG: '英格兰',
    NIR: '爱尔兰',
    SCT: '苏格兰',
    WLS: '威尔士',
    // 日本
    JP13: '东京都',
    JP01: '北海道',
    JP26: '京都府',
    JP27: '大阪府',
    JP02: '青森县',
    JP03: '岩手县',
    JP04: '宫城县',
    JP05: '秋田县',
    JP06: '山形县',
    JP07: '福岛县',
    JP08: '茨城县',
    JP09: '栃木县',
    JP10: '群马县',
    JP11: '埼玉县',
    JP12: '千叶县',
    JP14: '神奈川县',
    JP15: '新潟县',
    JP16: '富山县',
    JP17: '石川县',
    JP18: '福井县',
    JP19: '山梨县',
    JP20: '长野县',
    JP21: '岐阜县',
    JP22: '静冈县',
    JP23: '爱知县',
    JP24: '三重县',
    JP25: '滋贺县',
    JP28: '兵库县',
    JP29: '奈良县',
    JP30: '和歌山县',
    JP31: '鸟取县',
    JP32: '岛根县',
    JP33: '冈山县',
    JP34: '广岛县',
    JP35: '山口县',
    JP36: '德岛县',
    JP37: '香川县',
    JP38: '爱媛县',
    JP39: '高知县',
    JP40: '福冈县',
    JP41: '佐贺县',
    JP42: '长崎县',
    JP43: '熊本县',
    JP44: '大分县',
    JP45: '宫崎县',
    JP46: '鹿儿岛县',
    JP47: '冲绳县',
    // 韩国
    KR11: '首尔特别市',
    KR26: '釜山广域市',
    KR27: '大邱广域市',
    KR28: '仁川广域市',
    KR29: '光州广域市',
    KR30: '大田广域市',
    KR31: '蔚山广域市',
    KR50: '世宗特别自治市',
    KR41: '京畿道',
    KR42: '江原道',
    KR43: '忠清北道',
    KR44: '忠清南道',
    KR45: '全罗北道',
    KR46: '全罗南道',
    KR47: '庆尚北道',
    KR48: '庆尚南道',
    KR49: '济州特别自治道',
    // 台湾
    NWT: '台北',
    TPE: '新北',
    TAO: '桃园',
    TXG: '台中',
    TNN: '台南',
    KHH: '高雄',
    ILA: '宜兰',
    HSQ: '新竹',
    MIA: '苗栗',
    CHA: '彰化',
    NAN: '南投',
    YUN: '云林',
    CYQ: '嘉义',
    PIF: '屏东',
    TTT: '花莲',
    HUA: '台东',
    PEN: '澎湖',
    KEE: '基隆',
    // 印尼
    IDAC: '亚齐特区',
    IDBA: '巴厘省',
    IDBB: '邦加-勿里洞省',
    IDBT: '万丹省',
    IDBE: '明古鲁省',
    IDGO: '中爪哇省',
    IDJA: '中加里曼丹省',
    IDJB: '中苏拉威西省',
    IDJT: '东爪哇省',
    IDJI: '东加里曼丹省',
    IDKB: '东努沙登加拉省',
    IDKS: '哥伦打洛省',
    IDKT: '雅加达首都特区',
    IDKI: '占碑省',
    IDKU: '楠榜省',
    IDKR: '马鲁古省',
    IDLA: '北加里曼丹省',
    IDMA: '北马鲁古省',
    IDMU: '北苏拉威西省',
    IDNB: '北苏门答腊省',
    IDNT: '巴布亚省',
    IDPA: '廖内省',
    IDPB: '廖内群岛省',
    IDRI: '东南苏拉威西省',
    IDSR: '南加里曼丹省',
    IDSN: '南苏拉威西省',
    IDST: '南苏门答腊省',
    IDSG: '西爪哇省',
    IDSA: '西加里曼丹省',
    IDSB: '西努沙登加拉省',
    IDSS: '西巴布亚省',
    IDSU: '西苏拉威西省',
    IDJK: '西苏门答腊省',
    IDYO: '日惹特区'
};
