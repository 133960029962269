// 本组件解决输入数字时自动四舍五入
<template>
    <el-input
        ref="input"
        :value="displayValue"
        :placeholder="placeholder"
        :class="inputClass"
        :disabled="disabled"
        :type="type"
        :rows="rows"
        :autosize="autosize"
        :show-word-limit="showWordLimit"
        :maxlength="maxlength"
        :minlength="minlength"
        :readonly="readonly"
        :clearable="clearable"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
        @change="handleInputChange"
    >
        <span slot="prefix" class="fix prefix">
            <slot name="prefix"></slot>
        </span>
        <span slot="suffix" class="fix suffix">
            <slot name="suffix"></slot>
        </span>
    </el-input>
</template>
<script>
export default {
    name: 'MsnNumberInput',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            default: 'text'
        },
        placeholder: {
            default: ''
        },
        autosize: {
            default: null
        },
        disabled: {
            default: false
        },
        readonly: {
            default: false
        },
        rows: {
            type: Number,
            default: 2
        },
        showWordLimit: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: undefined
        },
        minlength: {
            type: Number,
            default: undefined
        },
        clearable: {
            type: Boolean,
            default: false
        },
        prefixLength: {
            default: 2,
            type: Number
        },
        precision: {
            type: Number,
            validator(val) {
                return val >= 0 && val === parseInt(val, 10);
            }
        }
    },
    data() {
        return {
            currentValue: '',
            userInput: null
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                let newVal = (value === undefined || value === '') ? value : Number(value);
                if (newVal !== undefined) {
                    if (isNaN(newVal) || newVal === '') {
                        this.$emit('input', value);
                        return;
                    }
                    if (this.precision !== undefined) {
                        newVal = this.toPrecision(newVal, this.precision);
                    }
                }
                this.currentValue = newVal;
                this.userInput = null;
                this.$emit('input', newVal);
            }
        }
    },
    computed: {
        inputClass() {
            const classList = ['', '', 'middle', 'max'];
            const className = this.disabled ? 'msn-input disabled' : 'msn-input';
            return className + ' ' + (classList[this.prefixLength - 1] || '');
        },
        displayValue() {
            if (this.userInput !== null) {
                return this.userInput;
            }
            let currentValue = this.currentValue;
            if (typeof currentValue === 'number') {
                if (this.precision !== undefined && currentValue !== 0) {
                    currentValue = this.toPrecision(currentValue, this.precision);
                }
            }
            return currentValue;
        }
    },
    methods: {
        toPrecision(num, precision) {
            if (precision === undefined) precision = 0;
            return parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
        },
        handleBlur(event) {
            this.$emit('blur', event);
        },
        handleFocus(event) {
            this.$emit('focus', event);
        },
        setCurrentValue(newVal) {
            const oldVal = this.currentValue;
            if (typeof newVal === 'number' && this.precision !== undefined) {
                newVal = this.toPrecision(newVal, this.precision);
            }
            if (oldVal === newVal) return;
            this.userInput = null;
            this.$emit('input', newVal);
            this.$emit('change', newVal, oldVal);
            this.currentValue = newVal;
        },
        handleInput(value) {
            this.userInput = value;
        },
        handleInputChange(value) {
            const newVal = value === '' ? undefined : Number(value);
            if (!isNaN(newVal)) {
                this.setCurrentValue(newVal);
            } else {
                const oldVal = this.currentValue;
                if (oldVal === value) return;
                this.userInput = null;
                this.$emit('input', value);
                this.$emit('change', value, oldVal);
                this.currentValue = value;
            }
            this.userInput = null;
        }
    }
};
</script>

<style lang="scss">
.msn-input.el-input {
    .prefix {
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        left: 20px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
    }
    .suffix {
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        left: 20px;
        position: absolute;
        top: 50%;
        right: 15px;
        left: auto;
        transform: translateY(-50%);
    }
    .el-input__inner {
        height: 35px;
        background-color: #EEF1F4;
        border-radius: 17.5px;
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid transparent;
        padding-left: 15px;

        &:hover {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
        }
        &:focus {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
            font-weight: 400;
        }
        &:focus + .el-input__prefix .prefix {
            font-weight: 400;
        }
    }
    &.msn-input-normal {
        .el-input__inner {
            border-radius: 6px;
        }
    }
}
.msn-input.msn-input--prefix {
    .el-input__inner {
        padding-left: 33px;
    }
    &.middle .el-input__inner {
        padding-left: 40px; // 考虑货币符号有两个字符
    }
    &.middle .fix.prefix {
        min-width: 22px;
    }
    &.max .el-input__inner {
        padding-left: 50px; // 考虑货币符号有三个字符
    }
    &.max .fix.prefix {
        min-width: 30px;
    }
}
.msn-input.el-textarea {
    .el-textarea__inner {
        min-height: 35px;
        background-color: #EEF1F4;
        border-radius: 25px;
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid transparent;
        resize: none;

        &:hover {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
        }
        &:focus {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
            font-weight: 400;
        }
        &:focus + .el-input__prefix .prefix {
            font-weight: 400;
        }
    }
    &.msn-input-normal {
        .el-textarea__inner {
            border-radius: 6px;
        }
    }
}
.msn-input.disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.msn-is-light {
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, .16);
    border-radius: 17.5px;
    .el-input__inner {
        background-color: #fff!important;
    }
}

.el-form-item.is-error .msn-input.el-input .el-input__inner {
    border: 1px solid #E92754;
}
</style>
