<template>
    <div class="msn-pw-tip">
        <div
            class="msn-pw-tip--item"
            :class="{'msn-pw-tip--item__active': passwordClass[0]}"
        >
            {{ $t('msn.supplement.password1') }}
        </div>
        <div class="msn-pw-tip--item" :class="{'msn-pw-tip--item__active': passwordClass[1]}">
            {{ $t('msn.supplement.password2') }}
            <span class="msn-pw-tip--item--illu">{{ '(#@!~%^&*)' }}</span>
        </div>
        <div class="msn-pw-tip--item" :class="{'msn-pw-tip--item__active': passwordClass[2]}">
            {{ $t('msn.supplement.password3') }}
        </div>
        <div
            class="msn-pw-tip--item"
            :class="{'msn-pw-tip--item__active': passwordClass[3]}"
        >
            {{ $t('msn.supplement.password4') }}
        </div>
    </div>
</template>

<script>
import { passwordReg } from '../constants';
export default {
    name: 'PasswordTip',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {
        passwordClass() {
            let value = this.value;
            let result = [false, false, false, false];
            passwordReg.oneLC.test(value) ? (result[0] = true) : (result[0] = false);
            passwordReg.oneSC.test(value) ? (result[1] = true) : (result[1] = false);
            passwordReg.oneNumber.test(value) ? (result[2] = true) : (result[2] = false);
            passwordReg.more.test(value) ? (result[3] = true) : (result[3] = false);
            return result;
        }
    }
};
</script>

<style lang="scss">
.msn-pw-tip {
    &--item {
        word-break: normal;
        text-align: left;
        font-size: 12px;
        line-height: 2em;
        color: #4e4f51;
        &--illu {
            color: #a6a7a7;
        }
        &__active &--illu {
            color: #0067ff;
        }
        &__active {
            color: #0067ff;
        }
    }
}
</style>
