/**
 * @file 中文文案
 * @author  fangsimin
 * @date    2019-01-02 09:54:13
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:33:58
 */

import jaLocale from 'element-ui/lib/locale/lang/ja';
import msnlang from './msn/ja'; // msn模块的语言
import medialang from './media/ja'; // msn模块的语言

const ja = {
    message: {
        submit: '送信',
        reset: 'リセット',
        login: 'ログイン',
        password: 'パスワード',
        username: 'ユーザー名',
        hintRequireUsername: 'ユーザー名を入力してください',
        hintRequirePassword: 'パスワードを入力してください',
        networkError: 'ネットワークエラー',
        somethingWrong: '不明なエラー',
        loginError: 'ログインエラー',
        notFound: '対応するページがありません',
        triggerType: 'トリガータイプ',
        triggerWord: 'トリガーワード',
        language: 'トリガーワード',
        permission: 'アクセス不可',
        triggerTypes: {
            keyword: 'キーワード',
            category: '分類',
            source: 'ニュースソース',
            author: '著者',
            picSex: '性的な写真'
        },
        button: {
            add: '増加',
            edit: '編集',
            delete: '削除',
            cancel: 'キャンセル',
            confirm: '確定',
            submit: '提出',
            reset: 'リセット',
            previous: '戻る',
            next: '次へ',
            logout: 'サインアウト',
            login: 'ログイン',
            simulatedLogin: 'アカウントを入力してください',
            returnAccount: 'アカウントに戻る',
            addAccount: 'アカウントを追加する',
            detailConfig: '運用構成',
            export: '書き出す',
            search: '検索',
            create: '作成'
        },
        isConfirmDelete: '削除をしますか？'
    },
    ...jaLocale,
    msn: msnlang,
    media: medialang
};

export default ja;
