<template>
    <div class="msn-collapse">
        <div class="msn-collapse__title" @click="toggleState">
            <div class="msn-collapse__title-content">
                <slot name="title"></slot>
            </div>
            <i :class="`msn-fa msn-collapse-toggle-icon msn-fa-${active ? 'up' : 'down'}`"></i>
        </div>
        <div v-show="active" class="msn-collapse__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MsnCollapse',
    props: {
        value: {
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        active: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        toggleState() {
            this.active = !this.active;
        }
    }
};
</script>

<style lang="scss">
.msn-collapse {
    border-radius: 3px;
    background-color: #f8f8f8;
    .msn-collapse__title {
        min-height: 35px;
        font-size: 13px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #E8EBED;
        border-radius: 3px 3px 0 0;
        .msn-collapse__title-content {
            flex: 1;
        }
        .msn-collapse-toggle-icon {
            margin-right: 10px;
            transition: all .2s;
        }
    }
    .msn-collapse__content {
        padding: 10px 0;
    }
}
</style>
