export default {
    bind(el, binding, vnode) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAPDOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAPDOM.addEventListener('scroll', function () {
            const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight + 1;
            CONDITION && binding.value();
        });
    }
};
