<template>
    <msn-dialog
        width="600px"
        :visible="visible"
        class="msn-column-config"
        top="1vh"
        :title="$t('msn.dashboard.colCfgTitle')"
        :before-close="handleClose"
    >
        <msn-cols
            ref="col"
            :type="obj.cacheName"
            :obj="obj"
            :last-columns="rawCols"
            :last-drag-data="sortedVal"
            @change="handleChange"
            @reset="handleReset"
        />
        <div slot="footer" class="footer-container">
            <el-button @click="handleClose">
                {{ $t("msn.button.cancel") }}
            </el-button>
            <el-button type="primary" @click="handleConfirm">
                {{ $t("msn.button.confirm") }}
            </el-button>
        </div>
    </msn-dialog>
</template>

<script>
import Cookie from 'js-cookie';
import MsnDialog from '@/components/MsnDialog';
import PlatformCols from '@/modules/msn/components/PlatformColumn/columns';
import utils from '@/common/utils';

export default {
    name: 'MsnColumnConfig',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        obj: {
            type: Object,
            required: true
        },
        headers: {
            type: Array,
            required: true
        }
    },
    data() {
        let _default = this.obj.defaultCols;
        let cookieSortedVal = this.getCookies();
        let sortedVal = cookieSortedVal
            ? cookieSortedVal.split(',')
            : utils.deepClone(_default);
        let rawCols = cookieSortedVal
            ? cookieSortedVal.split(',')
            : utils.deepClone(_default);
        return {
            rawCols,
            sortedVal,
            defaultCols: utils.deepClone(_default)
        };
    },
    methods: {
        // 过滤设置列
        getSetCol() {
            let cache = this.getCookies();
            let filterItems = null;
            const rawHeaders = this.headers;
            let headers = [];
            let cookie = cache;
            // cookie不存在时展示默认
            if (typeof cache !== 'string') {
                cache = utils.deepClone(this.defaultCols).join(',');
                cookie = null;
            }
            filterItems = cache
                .split(',')
                .map((item) => item.split('#')[1]);

            // 需要参与过滤的项
            const filterArr = [];
            // 必须要有的项
            const requiredArr = [];
            for(let v of rawHeaders){
                if(v.isColFilter){
                    filterArr.push(v);
                }else{
                    requiredArr.push(v);
                }
            }
            // 过滤设置列
            filterItems.forEach((headField) => {
                for(let target of filterArr){
                    let field = target.field;
                    if(typeof target.isColFilter === 'string'){
                        field = target.isColFilter;
                    }
                    if(field === headField){
                        headers.push(target);
                    }
                }
            });
            headers.push(...requiredArr);
            headers.sort((a, b)=>{
                const aZindex = typeof a.zindex === 'number' ? a.zindex : 0;
                const bZindex = typeof b.zindex === 'number' ? b.zindex : 0;
                return bZindex - aZindex;
            });
            return {
                headers,
                filterItems,
                cookie,
                rawHeaders
            };
        },
        // 排序变化时触发事件
        handleChange(val) {
            this.sortedVal = val;
        },
        // 排序重置时触发事件
        handleResetSortedVal() {
            let sortedVal = this.getCookies();
            this.sortedVal = sortedVal
                ? sortedVal.split(',')
                : utils.deepClone(this.rawCols);
            this.rawCols = sortedVal
                ? sortedVal.split(',')
                : utils.deepClone(this.rawCols);
        },
        handleReset() {
            this.rawCols = utils.deepClone(this.defaultCols);
            this.sortedVal = utils.deepClone(this.defaultCols);
        },
        handleClose() {
            this.handleResetSortedVal();
            this.$emit('close');
        },
        handleConfirm() {
            this.setCookies(this.sortedVal.join(','));
            this.$emit('change', this.getSetCol());
            this.$emit('close');
        },
        setCookies(val) {
            Cookie.set(this.obj.cacheName, val, { expires: 10000 });
        },
        getCookies() {
            return Cookie.get(this.obj.cacheName);
        }
    },
    components: {
        'msn-cols': PlatformCols,
        'msn-dialog': MsnDialog
    },
    created() {
        // headers 只用做记录原始headers 不希望他发生改变
        // 所以传入父组件传入headers时需要使用工厂模式构造一个对象
        Object.freeze(this.headers);
        // 校验
        const temp = [];
        for(const [k, v]of Object.entries(this.obj.cols)){
            for(const {label, value} of v){
                const t = {
                    k, label, value
                };
                for(let cur of temp){
                    if(cur.value === t.value){
                        console.log(`cols.${t.k}中的{label: '${t.label}',value: '${t.value}'}\
与cols.${cur.k}中的{label: '${cur.label}',value: '${cur.value}} 具有相同value`);
                        throw new Error('不能有相同value');
                    }
                }
                temp.push(t);
            }
        }
    }
};
</script>
<style scoped lang="scss">
.msn-column-config {
    text-align: left;
}
.footer-container {
    text-align: right;
    padding: 0 14px 20px;
}
</style>
