<template>
    <div class="message">
        <div
            class="message-box"
        >
            <div
                class="message-box-row"
                @click="handleDetail(item.userMessageId)"
            >
                <span class="message-box-row-tip">
                    <i :class="item.status === 0 ? 'message-box-row-tip-red' : 'message-box-row-tip-gray'"></i>
                </span>
                <div class="message-box-row-title">
                    <span class="message-box-row-title-span">{{ item.messageTitle }}</span>
                    <span class="message-box-row-title-span">{{ item.messageBody }}</span>
                </div>
                <div class="message-box-row-right">
                    <span>{{ handleData(item.createTime) }}</span>
                    <br>
                    <slot
                        class="message-box-row-right-slot"
                        name="type"
                    >
                        <span>
                            {{ " " }}
                        </span>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'MessageItem',
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        pointStyle: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {};
    },
    computed: {
    },
    components: {
    },
    methods: {
        handleDetail(id) {
            console.log(id, 'idczy');
            this.$emit('handleDetail', id);
        },
        handleData(val) {
            return moment(val).format('L');
        }
    },
    mounted() {
    },
    watch: {
    }

};
</script>

<style lang="scss" scoped>
    .message-box{
        width:100%;
        box-sizing: border-box;
        height: auto;
        &:hover{
            background: rgba(244, 248, 252, 1);
        }
        &-row{
            display: flex;
            height: 57px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &-tip{
                display: flex;
            }
            &-title{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                margin: 12px 0px;
                width: 88%;
                text-align: left;
                &-span{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            &-title span:first-child{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #53575B;
                width: 100%;
            }
            &-title span:last-child{
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 26px;
                color: rgba(83, 87, 91, 0.8);;
            }
            &-right{
                height: 100%;
                box-sizing: border-box;
                line-height: 30px;
            }
            &-right span:first-child{
                color: rgba(83, 87, 91, 0.5);
            }
        }
    }
</style>
<style>
    .message-popover{
        padding: 0px !important;
    }
</style>