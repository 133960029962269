import { copyTextToClipboard } from '@/common/clipboard';

const VueClipboardConfig = {
    autoSetContainer: false,
    appendToBody: true // This fixes IE, see #50
};
export const VueClipboard = {
    install: function (Vue) {
        let globalPrototype = Vue.version.slice(0, 2) === '3.' ? Vue.config.globalProperties : Vue.prototype;
        globalPrototype.$clipboardConfig = VueClipboardConfig;
        globalPrototype.$copyText = function (text, container) {
            return new Promise(function (resolve, reject) {
                copyTextToClipboard(text);
                resolve();
            });
        };
        Vue.directive('clipboard', {
            bind: function (el, binding, vnode) {
                el.addEventListener('click', function () {
                    if (binding.arg === 'message') {
                        globalPrototype.$message({
                            message: binding.value,
                            type: 'success'
                        });
                    } else {
                        copyTextToClipboard(binding.value);
                    }
                });
            }
        });
    },
    config: VueClipboardConfig
};