<template>
    <div
        v-loading="loading" class="page page-open-account"
        element-loading-spinner="el-icon-loading"
    >
        <msn-header/>
        <div style="height: 50px"></div>
        <div v-show="!showRes" class="page--content">
            <msn-title
                :title="$t('msn.openAccount.userTitle1')"
            />
            <el-form
                ref="user"
                class="form-container"
                label-position="top"
                :model="user"
                :hide-required-asterisk="true"
                :rules="rules"
            >
                <!-- 邮箱加发送验证码 -->
                <el-form-item prop="email">
                    <label slot="label" class="required-after">{{ $t('msn.permission.formEmail') }}<span style="color: #B5B8C1;">{{ $t('msn.permission.formEmailTip') }}</span></label>
                    <el-input v-model="user.email"/>
                    <count-down-btn
                        v-if="createType !== 'create'"
                        ref="countDown"
                        class="count-down"
                        @click="handleSendVCode"
                    >
                        {{ $t('msn.permission.vCode') }}
                    </count-down-btn>
                </el-form-item>
                <template v-if="createType !== 'create'">
                    <!-- 输入验证码 -->
                    <el-form-item prop="vCode">
                        <label slot="label" class="required-after">{{ $t('msn.permission.inputVCode') }}</label>
                        <el-input v-model="user.vCode"/>
                    </el-form-item>
                </template>
                <!-- 国家 -->
                <el-form-item prop="country">
                    <label slot="label" class="required-after">{{ $t('msn.openAccount.country') }}</label>
                    <msn-select
                        v-model="user.country"
                        class="account-select"
                        popper-class="account-select-dropdown"
                        style="width: 100%"
                        filterable
                        :option-list="countryList"
                    />
                </el-form-item>
                <!-- 姓 -->
                <el-form-item prop="firstName">
                    <label slot="label" class="required-after">{{ $t('msn.openAccount.firstName') }}</label>
                    <el-input v-model="user.firstName" :placeholder="$t('msn.openAccount.firstName')"/>
                </el-form-item>
                <!-- 名 -->
                <el-form-item prop="lastName">
                    <label slot="label" class="required-after">{{ $t('msn.openAccount.lastName') }}</label>
                    <el-input v-model="user.lastName" :placeholder="$t('msn.openAccount.lastName')"/>
                </el-form-item>
                <!-- 公司名称 -->
                <el-form-item prop="companyName">
                    <label slot="label" class="required-after">{{ $t('msn.permission.organizationName') }}</label>
                    <el-input v-model="user.companyName"/>
                </el-form-item>
                <!-- 公司网址 -->
                <el-form-item prop="companyWebsite">
                    <label slot="label" class="required-after">{{ $t('msn.permission.companySite') }}</label>
                    <el-input v-model="user.companyWebsite"/>
                </el-form-item>
                <!-- 工作 -->
                <el-form-item prop="jobTitle">
                    <label slot="label" class="required-after">{{ $t('msn.openAccount.jobTitle') }}</label>
                    <el-input v-model="user.jobTitle" :placeholder="$t('msn.openAccount.jobTitle')"/>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                    <label slot="label" class="required-after">{{ $t('msn.permission.password') }}</label>
                    <el-input
                        v-model="user.password"
                        v-popover:popover1
                        class="msn-password"
                        type="password"
                        show-password
                    />
                    <el-popover
                        ref="popover1" placement="right"
                        width="200" trigger="focus"
                    >
                        <password-tip :value="user.password"/>
                    </el-popover>
                </el-form-item>
            </el-form>
            <div class="page--content--footer">
                <msn-protocol v-model="protocolForm" class="protocol"/>
                <el-button
                    class="page--content--footer--btn"
                    type="submit-btn"
                    @click="handleApply"
                >
                    {{ $t('msn.openAccount.apply') }}
                </el-button>
            </div>
        </div>
        <!-- 提示 -->
        <section v-show="showRes" class="page--feedback">
            <section class="content">
                <img src="../../../assets/images/icon/success.png">
                <div class="card-text">
                    <div class="text">
                        {{ $t('msn.openAccount.info') }}{{ $t('msn.openAccount.info1') }}
                    </div>
                </div>
            </section>
            <section class="card-footer">
                <div class="text">
                    {{ $t('msn.openAccount.footer1') }}
                    <a
                        v-if="!isDiscovery"
                        class="link"
                        href="mailto:ext_mediago_am@baidu.com"
                    >ext_mediago_am@baidu.com</a>
                    <a
                        v-else
                        class="link"
                        href="info@popin.cc"
                    >info@popin.cc</a>
                </div>
                <div class="text">
                    {{ $t('msn.openAccount.footer2') }}
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import Header from './components/Header';
import { mapGetters } from 'vuex';
import Title from './components/Title';
import Protocol from '../components/Protocol';
import { rules } from '../constants';
import CountDownBtn from '@/components/CountDownBtn';
import MsnSelect from '@/components/MsnSelect/Select';
import api from '@/constants/api';
import { countryList } from '@/constants/countryList';
import PasswordTip from '../components/PasswordTip';
import md5 from 'md5';

export default {
    name: 'InviteOpenUser',
    data() {
        return {
            loading: false,
            rules: rules.call(this),
            user: {},
            protocolForm: [false, true],
            showRes: false,
            token: '',
            createType: ''
        };
    },
    components: {
        'msn-header': Header,
        'msn-title': Title,
        'msn-protocol': Protocol,
        CountDownBtn,
        MsnSelect,
        PasswordTip
    },
    methods: {
        // 处理提交申请
        handleApply() {
            this.$refs.user.validate((result, data) => {
                if (result) {
                    const params = {
                        email: this.user.email,
                        code: this.user.vCode,
                        country: this.user.country,
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        companyName: this.user.companyName,
                        companyWebsite: this.user.companyWebsite,
                        jobTitle: this.user.jobTitle,
                        passWord: md5(this.user.password).toUpperCase(),
                        acceptAdvice: this.protocolForm[1] ? '1' : '0',
                        token: this.token
                    };
                    // 手动创建账户时不传email、code
                    if (this.createType === 'create') {
                        delete params.code;
                    }
                    // 勾选框1是否勾选了
                    if(!this.isDiscovery){
                        console.log(this.isDiscovery, '++++++++++处理不是popin不需要校验隐私条款框');
                        if (!this.protocolForm[0]) {
                            this.$message.error(this.$t('msn.permission.p1'));
                            return;
                        }
                    }
                    this.$request(
                        {
                            method: 'post',
                            url: this.createType === 'create' ? api.fastRegister : api.register,
                            contentType: 'application/json; charset=UTF-8',
                            success(res) {
                                this.showRes = true;
                            }
                        },
                        params
                    );
                }
            });
        },
        // 发送验证码
        handleSendVCode() {
            this.$refs.user.validateField('email', errorMessage => {
                if (errorMessage === '') {
                    this.$request(
                        {
                            method: 'post',
                            url: api.sendEmailCheck,
                            contentType: 'application/json; charset=UTF-8',
                            success(res) {
                                this.$message({
                                    message: this.$t('msn.backendMsg.sendCodeSucs'),
                                    type: 'success'
                                });
                            },
                            error() {
                                this.$refs.countDown.reset(3);
                            }
                        },
                        { emailAddress: this.user.email }
                    );
                } else {
                    this.$refs.countDown.reset(3);
                }
            });
        }
    },
    computed: {
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        }),
        countryList() {
            return countryList.map(c => {
                return {
                    label: `msn.${c}`,
                    value: c
                };
            });
        }
    },
    created() {
        this.createType = this.$route.query ? this.$route.query.type : '';
    }
};
</script>

<style lang="scss" scoped>
@import "./common/common.scss";
</style>
<style lang="scss">
@import "./common/reset.scss";
</style>
