<template>
    <div
        class="message-box"
    >
        <el-popover
            ref="messagePopover"
            v-model="showMessagePopover"
            :width="getMsgLength ? 420 : 223"
            :popper-class="getMsgLength ? 'message-popover' : 'message-popover-no-msg'"
            :visible-arrow="false"
            trigger="hover"
            @show="getReadedMessage"
        >
            <div slot="reference" class="message-portrait">
                <i
                    class="msn-fa"
                    :class="getMsgLength ? 'msn-fa-message-unread' : ' msn-fa-message-read'"
                ></i>
            </div>
            <div
                v-if="getMsgLength"
                v-loading="loading"
                element-loading-spinner="el-icon-loading"
                class="message-list"
            >
                <messageItem
                    v-for="(item,index) in messageList" :key="index"
                    :item="item"
                    @handleDetail="handleDetailId"
                >
                    <span
                        slot="type"
                        class="message-dialog-list-slot" @click.stop="
                            handleRead(item.userMessageId)"
                    >
                        {{ $t('msn.amDashboard.delete') }}
                    </span>
                </messageItem>
            </div>
            <div v-else>
                <li class="no-message">
                    {{ $t('msn.amDashboard.noMessage') }}
                </li>
            </div>
            <div class="message-more">
                <span class="message-more-btn" @click="handleMore">
                    {{ $t('msn.amDashboard.moreMessage') }}
                </span>
            </div>
        </el-popover>
        <messageMoreDialog
            ref="messageMore"
            :visible="showMessageDialog"
            :more-message-loading="moreMessageLoading"
            :more-message-list="moreMessageList"
            @close="handleIsShowMoreList"
        />

        <message-detail
            :visible="showMessageDetail"
            :detail-loading="detailLoading"
            :detail="detail"
            :to-more-message="toMoreMessage"
            @close="handleShowDetail"
            @getMessageList="getMessageList"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageMoreDialog from '@/modules/msn/components/messageCenter/messageList.vue';
import messageItem from '@/modules/msn/components/messageCenter/messageItem.vue';
import MessageDetail from '@/modules/msn/components/messageCenter/message/messageDetail.vue';
import api from '@/constants/api';
import {uniqObjArr} from '@/common/utils.js';
export default {
    name: 'MessageCenter',
    props: {
        lang: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            messageList: [],
            showMessageDialog: false,
            showMessagePopover: false,
            showMessageDetail: false,
            loading: false,
            width: '',
            detailLoading: '',
            detail: {},
            toMoreMessage: false,
            moreMessageLoading: false,
            moreMessageList: [],
            idMax: 0,
            timeGap: 1000 * 60 * 30,
            msgTimer: null
        };
    },
    components: {
        messageMoreDialog,
        messageItem,
        MessageDetail
    },
    computed: {
        ...mapGetters('common', {
            curLang: 'getLang'
        }),
        ...mapGetters('msn', {
            getIsLoginState: 'getIsLoginState'
        }),
        handleCurLang() {
            return this.curLang === 'cn' ? 'zh' : this.curLang;
        },
        getMsgLength(){
            return this.messageList.length > 0 ? true : false;
        }
    },
    methods: {
        handleRead(id) {
            this.$request({
                method: 'post',
                url: api.messageStatusChange,
                success(res) {
                    this.moreMessageLoading = false;
                    this.moreMessageList = res.data;
                    this.handleFilter(id);
                },
                error(err) {
                    this.moreMessageLoading = false;
                    this.handleFilter(id);
                }
            }, {
                userMessageId: +id,
                status: +1
            });
        },
        handleFilter(id) {
            this.messageList = this.messageList.filter(item => item.userMessageId !== id);
            this.handleMaxId();
        },
        handleMaxId() {
            let messageIdMax = [];
            if(this.messageList.length > 0){
                this.messageList.map(item => {
                    messageIdMax.push(item.userMessageId);
                });
                this.idMax = Math.max(...messageIdMax);
            }
            if(this.messageList.length < 20 && this.showMessagePopover){
                this.getReadedMessage({idMax: +this.idMax});
            }
        },
        handleMore(){
            this.showMessageDialog = true;
            this.showMessagePopover = false;
            this.getMessageList();
        },
        handleDetailId(id) {
            this.showMessageDetail = true;
            this.toMoreMessage = true;
            this.showMessagePopover = false;
            this.$request({
                method: 'get',
                url: api.getMessageDetail,
                contentType: 'application/json; charset=UTF-8',
                success(res) {
                    this.detailLoading = false;
                    this.detail = res.data;
                    this.handleRead(id);
                },
                error(err) {
                    this.detailLoading = false;
                }
            }, { userMessageId: +id });
        },
        handleIsShowMoreList() {
            this.showMessageDialog = false;
            this.getReadedMessage({isAllGetMsg: true});
        },
        handleShowDetail() {
            this.showMessageDetail = false;
        },
        getReadedMessage(msgObj) {
            const params = {
                language: msgObj?.language || this.handleCurLang,
                readed: 0,
                idMax: msgObj?.idMax || 0
            };
            this.$request({
                method: 'get',
                url: api.getMessage,
                contentType: 'application/json; charset=UTF-8',
                success(res) {
                    this.loading = false;
                    // 兼容后端接口不对之前的消息做缓存
                    if(msgObj?.isAllGetMsg){
                        this.messageList = res.data;
                    } else {
                        this.messageList = uniqObjArr([...res.data, ...this.messageList], 'userMessageId');
                    }
                    // 有消息的时候主动弹出提示框，定时拉取回来以及切换语言不弹出消息框
                    if(this.getMsgLength && !msgObj?.isTimer && !msgObj?.isAllGetMsg) {
                        this.showMessagePopover = true;
                    }
                },
                error(err) {
                    this.loading = false;
                }
            }, params);
        },
        getMessageList(){
            this.showMessageDetail = false;
            this.$refs.messageMore.getData();
        },
        handleMsgTimerMsg() {
            if (this.msgTimer) {
                clearTimeout(this.msgTimer);
                this.msgTimer = null;
            }
            this.msgTimer = setInterval(() => {
                let msgObj = {
                    isTimer: true,
                    idMax: 0,
                    language: this.handleCurLang
                };
                this.getReadedMessage(msgObj);
            }, this.timeGap);
        },
        handleReloadMsg(){
            if(this.getIsLoginState){
                this.handleMsgTimerMsg();
                this.getReadedMessage();
            }
        }
    },
    mounted() {
        this.handleReloadMsg();
    },
    watch: {
        curLang: {
            handler(val, old) {
                if (old) {
                    this.getReadedMessage({isAllGetMsg: true});
                }
            },
            immediate: true
        }
    },
    destroyed() {
        clearInterval(this.msgTimer);
        this.msgTimer = null;
    }
};
</script>

<style lang="scss" scoped>
    .message-list{
        max-height:574px;
        box-sizing: border-box;
        overflow-y: hidden;
        &-bottom{
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        &::-webkit-scrollbar {
                width: 4px !important;
            }
        &::-webkit-scrollbar-thumb {
            background: rgba(196, 196, 196, 0.7);
            border-radius: 2px;
        }
    }
    .message-portrait{
        cursor: pointer;
        .msn-fa-message-read{
            width: 35px;
            height: 35px;
            display: block;
            margin-right: 10px;
        }
        .msn-fa-message-unread{
            width: 35px;
            height: 35px;
            margin-right: 10px;
            display: block;
        }
    }
.no-message{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 63px;
    list-style: none !important;
    height: 63px;
    width: 100%;
    color: rgba(83, 87, 91, 0.5);
}
.message-more{
    height: 38px;
    width: 100%;
    background: #F8F8F8;
    text-align: center;
    line-height: 38px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    &-btn{
        cursor: pointer;
    }
}
</style>
<style lang="scss" >
    .message-popover{
        left:calc(100% - 434px) !important;
        padding: 0px !important;
        width: 420px !important;
        transform-origin: center top !important;
        position: absolute !important;
        top: 44px !important;
        // right: 0 !important;
    }
    .message-popover-no-msg{
        left:calc(100% - 234px) !important;
        padding: 0px !important;
        width: 220px !important;
        transform-origin: center top !important;
        position: absolute !important;
        top: 44px !important;
        // right: 0 !important;
    }
.message-list{
    .message-box-row-title{
        width: 71% !important;
    }
     .message-dialog-list-slot{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #3F93F5;
        position: relative;
        top: -6px;
        left: 46px;
    }
}
</style>