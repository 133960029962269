<template>
    <div class="column-container">
        <div class="column-item">
            <div class="list">
                <div v-for="(value, key, index) in columnList" :key="index">
                    <msn-checkbox-group
                        v-model="selected[key]"
                        class="msn-checkbox-group"
                        :check-options="value"
                        :type="type"
                        :default-val="
                            checkboxDefaultCols[key] &&
                                checkboxDefaultCols[key].length > 0
                                ? checkboxDefaultCols[key]
                                : disableCheckboxKey[key] || []
                        "
                        :title="
                            $t(
                                `msn.dashboard.col${key[0].toUpperCase()}${key.slice(
                                    1
                                )}`
                            )
                        "
                        :title-suffix="
                            extraInfo[key] && extraInfo[key].hasTitleSuffix
                                ? $t(
                                    `msn.dashboard.suffix${key[0].toUpperCase()}${key.slice(
                                        1
                                    )}`
                                )
                                : ''
                        "
                        :min="(extraInfo[key] && extraInfo[key].min) || 0"
                        :max="(extraInfo[key] && extraInfo[key].max) || 100"
                        :no-select-all="
                            extraInfo[key] && extraInfo[key].noSelectAll
                        "
                    />
                </div>
            </div>
        </div>
        <div class="column-item">
            <div class="column-header">
                <div class="column-title">
                    {{ $t("msn.infos.selected") }}&nbsp;
                    <span class="info">{{ $t("msn.infos.dragTip") }}</span>
                </div>
                <div class="reset-btn" @click="reset()">
                    {{ $t("msn.button.reset") }}
                </div>
            </div>
            <div class="list right">
                <msn-draggable
                    v-model="dragData"
                    :type="type"
                    :default-cols="
                        defaultColumn.length > 0 ? defaultColumn : disableKey
                    "
                    :label-and-value-map="labelAndValueMap"
                    :before-delete="beforeDelete"
                    :extra-info="extraInfo"
                    @del="handleDel"
                    @error="handleDraggableError"
                />
            </div>
        </div>
    </div>
</template>
<script>
import MsnCheckbox from './CheckboxGroup';
import MsnDraggable from './Draggable';
import { mapGetters } from 'vuex';
import PATH from '@/constants/path';
import {
    dashboardDefaultCols,
    amDashboardDefaultCols,
    valueLabelMap,
    amValueLabelMap,
    dataReportDefaultCols,
    dataReportValLabelMap,
    pixelCols,
    pixelValLabelMap,
    diagnosisCols,
    diagnosisValLabelMap,
    adDefaultCols,
    domainDefaultCols,
    domainValueLabelMap,
    adValueLabelMap,
    trendDefaultCols,
    trendValueLabelMap,
    mediaTrendDefaultCols,
    mediaTrendValueLabelMap,
    mediaDashboardDefaultCols,
    mediaDashboardValueLabelMap,
    bidAnalysisDefaultCols,
    bidAnalysisValueLabelMap,
    multiTrendValueLabelMap,
    multiTrendDefaultCols,
    agencyDashboardValueLabelMap
} from './constants';
import utils from '@/common/utils';
import Cookie from 'js-cookie';
export default {
    name: 'PlatformColumn',
    props: {
        type: {
            type: String,
            default: 'dashboard'
        },
        lastColumns: {
            default: () => ({})
        },
        lastDragData: {
            default: () => []
        },
        beforeChange: {
            default: () => {}
        },
        beforeDelete: {
            default: () => {}
        },
        obj: {
            default: null
        }
    },
    components: {
        'msn-checkbox-group': MsnCheckbox,
        'msn-draggable': MsnDraggable
    },
    computed: {
        ...mapGetters('msn', {
            role: 'getRole',
            menu: 'getMenu'
        }),
        ...mapGetters('media', {
            getMediaAccountList: 'getAccountList'
        }),
        defaultColumn() {
            let arr = [];
            if (this.obj) {
                arr = this.obj.default;
                return arr;
            }
            switch (this.type) {
                case 'dashboard':
                    arr = dashboardDefaultCols.default;
                    break;
                case 'amDashboard':
                    arr = amDashboardDefaultCols.default;
                    break;
                case 'dataReport':
                    arr = dataReportDefaultCols.default;
                    break;
                case 'pixel':
                    arr = pixelCols.default;
                    break;
                case 'diagnosis':
                    arr = diagnosisCols.default;
                    break;
                case 'dashboardAd':
                    arr = adDefaultCols.default;
                    break;
                case 'dashboardDomain':
                    arr = domainDefaultCols.default;
                    break;
                case 'bidAnalysis':
                    arr = bidAnalysisDefaultCols.default;
                    break;
                case 'dashboardTrend':
                    arr = trendDefaultCols.default;
                    break;
                case 'multiDashboardTrend':
                    arr = multiTrendDefaultCols.default;
                    break;
                case 'mediaDashboardTrend':
                    arr = mediaTrendDefaultCols.default;
                    break;
                case 'mediaDashboard':
                    arr = mediaDashboardDefaultCols.default;
                    break;
                case 'agency':
                    arr = agencyDashboardValueLabelMap.default;
                default:
                    break;
            }
            return arr;
        },
        labelAndValueMap() {
            let arr = {};
            if (this.obj) {
                for (let [key, v] of Object.entries(this.obj.cols)) {
                    for (let o of v) {
                        if (o.noUseTranslateRight === true) {
                            arr[key + '#' + o.value] =
                                'noUseTranslateRight' +
                                (o.rightLabel || o.label);
                        } else {
                            arr[key + '#' + o.value] = o.rightLabel || o.label;
                        }
                    }
                }
                return arr;
            }
            switch (this.type) {
                case 'dashboard':
                    arr = valueLabelMap;
                    break;
                case 'amDashboard':
                    arr = amValueLabelMap;
                    break;
                case 'dataReport':
                    arr = dataReportValLabelMap;
                    break;
                case 'pixel':
                    arr = pixelValLabelMap;
                    break;
                case 'diagnosis':
                    arr = diagnosisValLabelMap;
                    break;
                case 'dashboardAd':
                    arr = adValueLabelMap;
                    break;
                case 'dashboardDomain':
                    arr = domainValueLabelMap;
                    break;
                case 'bidAnalysis':
                    arr = bidAnalysisValueLabelMap;
                    break;
                case 'dashboardTrend':
                    arr = trendValueLabelMap;
                    break;
                case 'multiDashboardTrend':
                    arr = multiTrendValueLabelMap;
                    break;
                case 'mediaDashboardTrend':
                    arr = mediaTrendValueLabelMap;
                    break;
                case 'mediaDashboard':
                    arr = mediaDashboardValueLabelMap;
                    break;
                case 'agency':
                    arr = agencyDashboardValueLabelMap;
                    break;
                default:
                    break;
            }
            return arr;
        },
        columnList() {
            let arr = [];
            if (this.obj) {
                arr = this.obj.cols;
                return arr;
            }
            switch (this.type) {
                case 'agency':
                    arr = agencyDashboardValueLabelMap.cols;
                    break;
                case 'dashboard':
                    arr = dashboardDefaultCols.cols;
                    break;
                case 'amDashboard':
                    arr = amDashboardDefaultCols.cols;
                    break;
                case 'dataReport':
                    arr = dataReportDefaultCols.cols;
                    // 角色权限限制
                    if (this.role.indexOf('3') < 0) {
                        arr.breakDownBy.pop();
                    }
                    break;
                case 'pixel':
                    arr = pixelCols.cols;
                    break;
                case 'diagnosis':
                    arr = diagnosisCols.cols;
                    break;
                case 'dashboardAd':
                    arr = adDefaultCols.cols;
                    break;
                case 'dashboardDomain': {
                    // * 判断是否有权限访问： ssp
                    if (this.menu[PATH['domainSspColumn']] !== 'rw') {
                        let index = domainDefaultCols.cols.detail.findIndex(
                            (item) => {
                                return item.value === 'ssp';
                            }
                        );
                        domainDefaultCols.cols.detail.splice(index, 1);
                    }
                    arr = domainDefaultCols.cols;
                    break;
                }
                case 'bidAnalysis':
                    arr = bidAnalysisDefaultCols.cols;
                    break;
                case 'dashboardTrend':
                    arr = trendDefaultCols.cols;
                    break;
                case 'multiDashboardTrend':
                    arr = multiTrendDefaultCols.cols;
                    break;
                case 'mediaDashboardTrend':
                    arr = mediaTrendDefaultCols.cols;
                    break;
                case 'mediaDashboard': {
                    // Media:通过判断用户帐户影藏掉一些设置列
                    const accountList = this.getMediaAccountList;
                    const col = mediaDashboardDefaultCols.cols;
                    const removeColVal = [];
                    // 逻辑1: 是否展示 Account 设置列
                    if (accountList.length <= 1) {
                        // 移除 Account
                        removeColVal.push('accountName');
                    }
                    // 逻辑1: 是否展示 Account 设置列
                    let sspRemoveFlag = true;
                    if (accountList.length > 0) {
                        for (let item of accountList) {
                            if (item.sspList.length > 1) {
                                // 不移除 ssp
                                sspRemoveFlag = false;
                                break;
                            }
                        }
                        if (sspRemoveFlag) {
                            removeColVal.push('ssp');
                        }
                    } else {
                        removeColVal.push('ssp');
                    }
                    if (removeColVal.length > 0) {
                        col.dimension = col.dimension.filter(
                            (e) => !removeColVal.includes(e.value)
                        );
                    }
                    arr = col;
                    break;
                }
            }
            return arr;
        },
        checkboxDefaultCols() {
            let cols = {};
            this.defaultColumn.forEach((dc) => {
                let keys = dc.split('#');
                if (keys.length === 2) {
                    if (cols[keys[0]]) {
                        cols[keys[0]].push(keys[1]);
                    } else {
                        cols[keys[0]] = [keys[1]];
                    }
                }
            });
            return cols;
        },
        extraInfo() {
            let arr = {};

            switch (this.type) {
                case 'dataReport':
                    arr = dataReportDefaultCols.groups;
                    break;
                case 'diagnosis':
                    arr = diagnosisCols.groups;
                    break;
            }
            return arr;
        },
        // 只要需要选中多少
        totalMin() {
            let min = 0;
            switch (this.type) {
                case 'dashboard':
                    min = dashboardDefaultCols.min;
                    break;
                case 'amDashboard':
                    min = amDashboardDefaultCols.min;
                    break;
                case 'dataReport':
                    min = dataReportDefaultCols.min;
                    break;
                case 'pixel':
                    min = pixelCols.min;
                    break;
                case 'diagnosis':
                    min = diagnosisCols.min;
                    break;
                case 'dashboardAd':
                    min = adDefaultCols.min;
                    break;
                case 'dashboardDomain':
                    min = domainDefaultCols.min;
                    break;
                case 'bidAnalysis':
                    min = bidAnalysisDefaultCols.min;
                    break;
                case 'dashboardTrend':
                    min = trendDefaultCols.min;
                    break;
                case 'multiDashboardTrend':
                    min = multiTrendDefaultCols.min;
                    break;
                case 'mediaDashboardTrend':
                    min = mediaTrendDefaultCols.min;
                    break;
                case 'mediaDashboard':
                    min = mediaDashboardDefaultCols.min;
                    break;
                case 'db_multidimension':
                    min = 1;
                    break;
                default:
                    break;
            }
            return min;
        }
    },
    data() {
        return {
            allSelect: {
                i: false,
                v: false
            },
            selected: {},
            dragData: [],
            // 记录上次选中的值
            lastSelected: {},
            // 处理多个区域只存一个item的功能
            disableKey: [],
            disableCheckboxKey: {}
        };
    },
    methods: {
        handleDraggableError(){
            // 把所有设置列的 cookie 都清除 标识为 db 开头的key。保证刷新后 设置列恢复正常
            Object.keys(Cookie.get()).filter(e=> e.startsWith('db')).forEach(e =>{
                Cookie.remove(e);
            });
        },
        translateCateName(key) {
            let title = '';
            switch (this.type) {
                case 'dashboard':
                    title = this.$t(`msn.dashboard.${key}`);
                    break;
                default:
                    break;
            }
            return title;
        },
        reset() {
            this.$emit('reset');
        },
        handleDel(val) {
            if (!val) {
                return;
            }

            let keyArr = val.split('#');
            let selected = this.selected[keyArr[0]];
            let index = selected.findIndex((s) => s === keyArr[1]);
            this.selected[keyArr[0]].splice(index, 1);
        },
        sortVal(checked) {
            // 排下序
            if (this.type === 'amDashboard') {
                // 将campaign/account 排前面
                checked.sort((c) => {
                    if (this.defaultColumn.includes(c)) {
                        return -1;
                    }
                    return 0;
                });
            }
            console.log(this.type);
            if (this.type === 'db_multidimension') {
                checked.sort((c) => {
                    if (this.defaultColumn.includes(c)) {
                        return -1;
                    }
                    return 0;
                });
            }
            if (this.type === 'dashboardAd') {
                // 将adSize排序到creative后面
                checked.sort((c) => {
                    if (this.defaultColumn.includes(c)) {
                        return -1;
                    }
                    return 0;
                });
            }

            return checked;
        },
        // 检测是否是最后一个
        checkEmpty(val) {
            // 是否只有一个元素
            let isOnlyOne = 0;
            // 非0个元素的组个数
            let isNotEmpty = 0;
            Object.keys(val || {}).forEach((key) => {
                if (val[key].length > 0) {
                    isNotEmpty++;
                    if (val[key].length === 1) {
                        isOnlyOne++;
                    }
                }
            });
            // 若当前未选中，则修改默认值
            if (this.totalMin > 0) {
                if (isOnlyOne === isNotEmpty && isOnlyOne === 1) {
                    this.disableKey = [];
                    this.disableCheckboxKey = {};
                    Object.keys(val || {}).forEach((key) => {
                        if (val[key].length > 0) {
                            this.disableKey.push(`${key}#${val[key][0]}`);
                            this.disableCheckboxKey[key] = [val[key][0]];
                        }
                    });
                } else if (
                    isNotEmpty === 1 &&
                    JSON.stringify(val) !== JSON.stringify(this.lastSelected)
                ) {
                    // 当只有一组被选中时，需要缓存上次选中的值，防止被全部删除
                    this.lastSelected = utils.deepClone(val);
                    this.disableKey = [];
                    this.disableCheckboxKey = {};
                } else if (isNotEmpty === 0) {
                    let selected = {};
                    Object.keys(this.lastSelected || {}).forEach((key) => {
                        if (this.lastSelected[key].length > 0) {
                            selected[key] = [this.lastSelected[key][0]];
                            this.disableKey.push(
                                `${key}#${this.lastSelected[key][0]}`
                            );
                            this.disableCheckboxKey[key] = [
                                this.lastSelected[key][0]
                            ];
                        } else {
                            selected[key] = [];
                        }
                    });
                    return selected;
                } else {
                    this.disableKey = [];
                    this.disableCheckboxKey = {};
                }
            }
            return val;
        }
    },
    watch: {
        lastColumns: {
            immediate: true,
            deep: true,
            handler(val) {
                let selected = utils.deepClone(this.lastColumns);
                this.selected = {};
                selected.forEach((s) => {
                    let sArr = s.split('#');
                    if (this.selected[sArr[0]]) {
                        this.selected[sArr[0]] = [
                            ...new Set(this.selected[sArr[0]].concat(sArr[1]))
                        ];
                    } else {
                        this.$set(this.selected, sArr[0], [sArr[1]]);
                    }
                });
            }
        },
        selected: {
            immediate: true,
            deep: true,
            handler(val) {
                this.selected = this.checkEmpty(val);
                let selected = [];
                Object.keys(val).forEach((item) => {
                    let arr = val[item].map((v) => `${item}#${v}`);
                    selected = selected.concat(arr);
                });
                let dragData = [];
                if (this.lastDragData.length > 0) {
                    dragData = selected.sort((a, b) => {
                        let existA = this.lastDragData.indexOf(a);
                        let existB = this.lastDragData.indexOf(b);
                        if (existA > -1 && existB > -1) {
                            return existA - existB < 0 ? -1 : 1;
                        } else {
                            return existA > -1 ? -1 : existB > -1 ? 1 : 0;
                        }
                    });
                }
                dragData = this.sortVal(dragData);
                this.$set(this, 'dragData', dragData);
            }
        },
        dragData: {
            immediate: true,
            deep: true,
            handler(val) {
                this.$emit('change', val);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.column-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 14px;
    .column-item {
        width: 240px;
        box-sizing: border-box;
        .column-header {
            background-color: #fff;
            height: 35px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #53575b;
            .column-title {
                flex: 1;
                font-weight: 700;
                .info {
                    font-size: 12px;
                    opacity: 0.5;
                }
            }
            .reset-btn {
                color: #3f93f5;
                cursor: pointer;
                font-weight: 700;
            }
        }
        .list {
            border-radius: 6px;
            background: #f6f8f9;
            overflow: auto;
            height: 540px;
            &.right {
                height: 505px;
            }
        }
    }
}
</style>
