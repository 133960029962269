<template>
    <section class="page-modify">
        <el-card
            v-loading="loading" class="box-card"
            element-loading-spinner="el-icon-loading"
        >
            <div class="box-header">
                <div class="modify-logo modify-pwd-logo" :class="{'login-header-logo-discovery': isDiscovery}"></div>
            </div>
            <div class="box-card--title">
                Forgot Password
            </div>
            <section class="card-login">
                <el-form
                    ref="user" :model="user"
                    :rules="rules" label-width="165px"
                    class="form-modify"
                >
                    <!-- 邮箱加发送验证码 -->
                    <el-form-item prop="email">
                        <span slot="label">
                            {{ $t('msn.permission.formEmail') }}
                            <msn-tip
                                popper-class="form-email-style"
                                style="vertical-align: middle;"
                                placement="top"
                                :content="$t('msn.permission.formEmailTip')"
                            />
                        </span>
                        <el-input v-model="user.email"/>
                        <count-down-btn
                            ref="countDown"
                            :class="lang === 'ja' ? 'count-down_jp' : 'count-down'"
                            @click="handleSendVCode"
                        >
                            {{ $t('msn.permission.forgotPasswordVCode') }}
                        </count-down-btn>
                    </el-form-item>
                    <!-- 输入验证码 -->
                    <el-form-item prop="vCode" :label="$t('msn.permission.forgotPasswordInputVCode')">
                        <el-input v-model="user.vCode"/>
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item prop="password" :label="$t('msn.permission.newPassword')">
                        <el-input
                            v-model="user.password"
                            v-popover:popover1
                            class="msn-password"
                            type="password"
                            show-password
                        />
                        <el-popover
                            ref="popover1" placement="right"
                            width="200" trigger="focus"
                        >
                            <password-tip :value="user.password"/>
                        </el-popover>
                    </el-form-item>
                </el-form>
            </section>
            <div class="card-footer">
                <el-button @click="handleCancel">
                    {{ $t('msn.button.cancel') }}
                </el-button>
                <el-button type="primary" @click="handlModify">
                    {{ $t('msn.button.confirm') }}
                </el-button>
            </div>
        </el-card>
    </section>
</template>

<script>
import md5 from 'md5';
import api from '@/constants/api';
import PATH from '@/constants/path';
import { rules } from '../constants';
import CountDownBtn from '@/components/CountDownBtn';
import PasswordTip from '../components/PasswordTip';
import MsnTip from '@/components/MsnTip/tip';
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('common', {
            lang: 'getLang',
            isDiscovery: 'getIsDiscovery'
        })
    },
    data() {
        return {
            loading: false,
            user: {},
            rules: rules.call(this)
        };
    },
    components: {
        CountDownBtn,
        MsnTip,
        PasswordTip
    },
    methods: {
        // 取消修改密码
        handleCancel() {
            this.$router.push({ path: PATH['login'] });
        },
        // 发送验证码
        handleSendVCode() {
            console.log(this.lang);
            this.$refs.user.validateField('email', errorMessage => {
                if (errorMessage === '') {
                    this.$request(
                        {
                            method: 'post',
                            url: api.sendEmailCheck,
                            contentType: 'application/json; charset=UTF-8',
                            success(res) {
                                this.$message({
                                    message: this.$t('msn.backendMsg.sendCodeSucs'),
                                    type: 'success'
                                });
                            },
                            error() {
                                this.$refs.countDown.reset(3);
                            }
                        },
                        { emailAddress: this.user.email, type: 'FORGETPWD', language: this.lang }
                    );
                } else {
                    this.$refs.countDown.reset(3);
                }
            });
        },
        // 确认修改
        handlModify() {
            this.$refs.user.validate(valid => {
                if (!valid) return;
                this.$request(
                    {
                        url: api.forgetPassword,
                        method: 'post',
                        contentType: 'application/json; charset=UTF-8',
                        success(res) {
                            this.$message.success(this.$t('msn.requestMsg.modified'));
                            this.$router.push({ path: PATH['login'] });
                        }
                    },
                    {
                        email: this.user.email,
                        code: this.user.vCode,
                        newPwd: md5(this.user.password).toUpperCase()
                    }
                );
            });
        }
    }
};
</script>

<style lang="scss">
.max-height-500.form-email-style {
    overflow: hidden;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
textarea,
input,
button,
select {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.page-modify {
  height: 100vh;
  position: relative;
  font-weight: 700;
  text-align: left;
  box-shadow: -2px 6px 30px 0 rgba(0, 0, 0, 0.4);
  .box-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 440px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 2px 20px 0 rgba(20, 70, 199, 0.1) !important;
    border: none !important;
    &--title {
      font-size: 18px;
      color: #000000;
      line-height: 18px;
      margin-top: 35px;
      text-align: center;
    }
    .box-header {
      height: 80px;
      background-image: linear-gradient(234deg, #1446c7 14%, #1967f2 100%);
      box-shadow: 0 3px 5px 0 rgba(20, 70, 199, 0.2);
      border-radius: 6px 6px 0 0;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .count-down {
      position: absolute;
      right: 0;
      top: 50%;
      width: 100px;
      transform: translateY(-50%);
      margin-top: 0;
      font-weight: 800;
    }
    .count-down_jp {
      position: absolute;
      right: 0;
      top: 28%;
      width: 150px;
      transform: translateY(-50%);
      margin-top: 0;
      font-weight: 800;
    }
    .el-card__body {
      padding: 0 !important;
    }
    .form-modify {
      width: 560px;
      margin: 35px 200px 0 auto;
    }
    .card-footer {
      text-align: center;
      padding: 0px 30px 30px;
    }
    .card-footer {
      @keyframes scaling {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.85);
        }
        100% {
          transform: scale(1);
        }
      }
      .el-button {
        height: 35px;
        box-sizing: border-box;
        border-radius: 17.5px;
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        &--default {
          border: 1px solid #53575b;
          background-color: #fff;
          color: #53575b !important;
          &:hover {
            border-color: transparent;
            background: rgba(83, 87, 91, 0.2);
          }
          &:active {
            border-color: transparent;
            background: rgba(83, 87, 91, 0.2);
            animation: 0.5s ease scaling;
          }
          &:focus {
            border-color: transparent;
            background: rgba(83, 87, 91, 0.2);
          }
        }
        &--primary {
          background: #0067ff;
          color: #fff !important;
          margin-left: 30px;
          &:active,
          &:hover,
          &:focus {
            border-color: #0067ff;
            background: #0067ff;
          }
          &:active {
            animation: 0.5s ease scaling;
          }
        }
      }
    }
    // element set
    .el-form-item {
      margin-bottom: 25px !important;
      .el-form-item__content {
        line-height: 1 !important;
      }
      label {
        color: #000;
        font-size: 14px;
      }
      .el-input__inner {
        height: 40px;
        background: #fff;
        border: 1px solid #c4c9d6;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 3px;
        color: #53575b;
        font-weight: 500;
        &:active,
        &:hover,
        &:focus {
          // background: rgba(236, 236, 236, .5);
          border: 1px solid #0067ff;
        }
        &::placeholder {
          color: rgba(83, 87, 91, 0.3);
          font-weight: 500;
        }
      }
    }
  }
}
</style>
