<template>
    <el-radio-group
        v-model="radio"
        class="msn-radio-group"
        :disabled="disabled"
        @change="() => {
            $emit('change')
        }"
    >
        <el-radio
            v-for="item in radioOpts"
            :key="item.value"
            :class="disabled ? `msn-radio disabled ${radioClass}` : `msn-radio ${radioClass}`"
            :disabled="item.disabled"
            :label="item.value"
        >
            <div
                v-if="showInputStyle"
                :class="['label-container', labelClass, radio === item.value ? 'active' : '']"
            >
                <div class="label-title">
                    {{
                        item.label.includes('.') && !item.label.includes('-') ? $t(item.label) : item.label
                    }}
                </div>
                <i v-if="!!item.img" class="msn-icon msn-fa msn-fa-notice"></i>
                <div
                    v-if="!!item.img" class="preview"
                    :style="item.style"
                >
                    <img :src="item.img" width="100%">
                </div>
                <div v-if="item.info" class="radio-extra-info">
                    {{ item.info }}
                </div>
            </div>
            <template v-else>
                {{
                    item.label.includes('.') && !item.label.includes('-') ? $t(item.label) : item.label
                }}
            </template>
        </el-radio>
    </el-radio-group>
</template>

<script>
export default {
    name: 'MsnRadioGroup',
    props: {
        value: {
            default: null
        },
        disabled: {
            default: false
        },
        radioOpts: {
            default: () => []
        },
        labelClass: {
            default: ''
        },
        showInputStyle: {
            default: false
        },
        radioClass: {
            default: ''
        }
    },
    computed: {
        radio: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>

<style lang="scss">
.msn-radio-group.el-radio-group {
    .label-container {
        display: inline-block;
        background: #EEF1F4;
        height: 35px;
        box-sizing: border-box;
        border-radius: 17.5px;
        font-size: 13px;
        line-height: 35px;
        color: #53575B;
        position: relative;
        padding: 0 20px;
        font-weight: 500;
        // border: 1px solid transparent;
        &.active {
            color: #2DD1AC;
            // border-color: #2DD1AC;
        }
        .msn-icon {
            font-size: 15px;
            opacity: .5;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            &:hover + .preview {
                display: block;
            }
        }

        .radio-extra-info {
            position: absolute;
            bottom: -15px;
            left: 0;
            font-size: 12px;
            color: #53575B;
            letter-spacing: 0;
            line-height: 12px;
            opacity: .5;
            font-weight: 300;
        }
    }
    .preview {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        padding: 0;
        background: transparent;
        border-radius: 6px;
        display: none;
        transform: translateY(calc(100% + 5px));

        img {
            display: block;
            margin: 0 auto;
        }
    }
    .el-radio {
        margin-right: 20px!important;
    }
    .el-radio__label {
        font-weight: 500;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #2DD1AC;
        background: #2DD1AC;
        &:after {
            width: 6px;
            height: 6px;
        }
    }
    .el-radio__inner {
        box-shadow: none !important;
    }
    .el-radio__input .el-radio__inner {
        &:hover {
            border: 1px solid #2DD1AC;
        }
    }
    .is-disabled.msn-radio {
        cursor: not-allowed;
        opacity: 0.4;
    }
    .is-disabled.msn-radio .el-radio__input .el-radio__inner {
        &:hover {
            border: none;
        }
    }
    .el-radio__input.is-checked+.el-radio__label {
        color: #2DD1AC;
    }
}
</style>
