<template>
    <div
        class="btn" :class="{'btn__active': active}"
        @click="start"
    >
        <span v-if="active">{{ count }}s</span>
        <span v-else>
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'CountDownBtn',
    data() {
        return {
            count: 60,
            active: false
        };
    },
    methods: {
        start() {
            if (!this.active) {
                this.active = true;
                this.count = 60;
                this.$emit('click');
                this.countDown();
            }
        },
        countDown() {
            this.$nextTick(() => {
                const i = setInterval(() => {
                    this.count = this.count - 1;
                    if (this.count < 0) {
                        clearInterval(i);
                        this.active = false;
                    }
                }, 1000);
            });
        },
        // 重置
        reset(num) {
            this.count = isNaN(parseInt(num, 10)) ? 0 : parseInt(num, 10);
        }
    }
};
</script>

<style lang="scss" scoped>
.btn {
    font-size: 13px;
    color: #0067ff;
    line-height: 1em;
    text-align: center;
    position: relative;
    cursor: pointer;
    &__active {
        color: #8ebafc;
    }
    &::after{
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 30px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #0067FF;
    }
}
</style>
