<template>
    <div class="msn-popover-container">
        <el-popover
            v-if="contentArr.length > sliceLength"
            placement="top-start"
            trigger="hover"
        >
            <slot name="content">
                <div v-if="isWrap" class="popover-content">
                    <div v-for="(item, index) in contentArr" :key="index">
                        {{ item }}
                    </div>
                </div>
                <div v-else class="popover-content">
                    {{ contentArr.join(',') }}
                </div>
            </slot>
            <div slot="reference">
                <div v-if="isWrap" class="popover-content">
                    <div v-for="(item, index) in contentSliceArr" :key="index">
                        {{ item }}
                    </div>
                    <div>...</div>
                </div>
                <div v-else>
                    {{ contentSliceArr.join(',') }},<span class="search-more">...</span>
                </div>
            </div>
        </el-popover>
        <div v-else>
            <div v-if="isWrap">
                <div v-for="(item, index) in contentArr" :key="index">
                    {{ item }}
                </div>
            </div>
            <div v-else>
                {{ contentArr.join(',') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        row: {
            type: Object,
            default: () => {}
        },
        info: {
            type: Object,
            default: () => {}
        },
        handler: {
            type: Function
        }
    },
    data() {
        return {};
    },
    computed: {
        contentArr() {
            const field = this.info?.field || '';
            return field && this.row && this.row[field] || [];
        },
        isWrap() {
            return this.info?.isWrap || false;
        },
        sliceLength() {
            return this.info?.sliceLength || 5;
        },
        contentSliceArr() {
            return this.contentArr.slice(0, this.sliceLength) || [];
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.msn-popover-container {
    width: 100%;
    cursor: pointer;
}

.popover-content {
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
}
</style>

