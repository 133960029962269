/**
 * @file 复制
 * @author  fangsimin
 * @date    2020-02-17 17:46:13
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-17 17:52:10
 */

/**
 * 复制到剪贴板
 *
 * @param  {string} text 要被复制的文字
 */
export async function copyTextToClipboard(text) {
    try {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            console.log('async copy successful');
        }, () => {
            fallbackCopyTextToClipboard(text);
        });
    } catch (e) {
        console.log('clipboard err', e);
    }
}

/**
 * 复制到剪贴板，异步copy失效时执行
 *
 * @param  {string} text 要被复制的文字
 * @return {boolean}
 */
function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement('textarea');

    // 隐藏textarea
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)
        && typeof document.createRange === 'function') {
        textArea.contentEditable = true;
        textArea.readOnly = true;
        let range = document.createRange();
        range.selectNodeContents(textArea);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        textArea.setSelectionRange(0, 999999);
    } else {
        textArea.focus();
        textArea.select();
    }

    try {
        let successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        return successful;
    } catch (err) {
        document.body.removeChild(textArea);
        return false;
    }
}
