<template>
    <div class="msn-tab-header">
        <div
            v-for="tab in headers"
            :key="tab.label"
            :class="[`msn-tab${ tab.value === lastVal ? '_active' : ''}`,
                     tab.disabled ? 'disabled' : '',
            ]"
            @click="handleClick(tab,$event)"
        >
            <i v-if="tab.label === 'msn.dashboard.domainTitle'" class="msn-fa-beta-1 beta-icon"></i>

            {{ $t(tab.label) }}
        </div>

        <el-dropdown
            v-if="headers[1] && tabOptions.length"
            trigger="click"
            @command="handleCommand"
        >
            <span>

                <i

                    class="el-icon-arrow-down el-icon--right tab-arrow"
                ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="item in tabOptions"
                    :key="item.label"
                    :disabled="item.disabled"
                    :style="`display: ${headers[1].disabled ? 'none' : 'block'}`"
                    :command="item"
                >
                    {{ $t(item.label) }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
export default {
    name: 'MsnTab',
    props: {
        disabled: {
            type: Boolean
        },
        headers: {
            default: () => [],
            type: Array
        },
        tabOptions: {
            default: () => [],
            type: Array
        },
        lastTabVal: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            activeName: 'first',
            lastVal: this.lastTabVal,
            tabTxt: 'domain',
            tabData: ''
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab);
            if (tab.disabled) {
                return;
            }else {
                this.lastVal = tab.value;
                this.tabData = tab;
            }
            // this.$emit('change', tab);
        },
        handleCommand(command) {
            console.log(this.headers);
            console.log(command, 'command');
            let index = this.headers.length;
            this.tabTxt =command.value;
            // eslint-disable-next-line vue/no-mutating-props
            this.headers[index-1] = command;

            this.$emit('select', command);
        }
    },
    watch: {
        tabData(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('change', newVal, oldVal);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .msn-tab-header {
        height: 50px;
        display: flex;
        border-radius: 6px 6px 0px 0px;
        overflow: hidden;
        cursor: pointer;
        .msn-tab{
            min-width: 55px;
            height: 50px;
            padding-left: 20px;
            padding-right:20px ;
            background: #F9F9F9;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 50px;
            color: #53575B;
            opacity: 0.5;
            &_active {
                position: relative;
                min-width: 55px;
                height: 50px;
                padding-left: 20px;
                padding-right: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 50px;
                border-top: 2px solid #2DD1AC;
                background: #fff;
                color: #2DD1AC;

            }
        }
        .beta-icon{
            position: absolute;
            top: 0;
            left: 0;
        }
       .msn-fa-beta-1 {
           background-size: 100%;
           width: 40px;
           height: 40px;
       }
        .tab-arrow {
            position: absolute;
            top: 0;
            left: -20px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 50px;
            color: #53575B;
            opacity: 0.5;
            margin: 0;
            &_active {

                color: #2DD1AC;
                border-top: 2px solid #2DD1AC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 50px;
                margin: 0;
            }
        }
        .disabled {
            cursor: not-allowed;
            &:hover {
                background-color: #f9f9f9 !important;
            }
        }
    }
</style>