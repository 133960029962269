<template>
    <msn-dialog
        class="fixed-dialog" :visible="visible"
        :title="$t('msn.amDashboard.noticeDetail')" width="950px"
        custom-class="msn-dialog"
        :append-to-body="true"
        :modal-append-to-body="false"
        :before-close="handleClose"
    >
        <div
            v-loading="detailLoading"
            class="dialog-container"
            element-loading-spinner="el-icon-loading"
        >
            <div class="detail-container">
                <div class="detail-container-title">
                    {{ detail.messageTitle }}
                </div>
                <div class="detail-container-date">
                    {{ handleData(detail.createTime) }}
                </div>
                <!--bca-disable-->
                <div
                    class="detail-container-content" style="white-space:pre-wrap"
                    v-html="detail.messageBody"
                >
                </div>
                <!--bca-enable-->
            </div>
        </div>
        <div
            slot="footer"
            class="footer-container"
        >
            <div>
                <span
                    v-if="toMoreMessage" class="footer-container-more"
                    @click="handleToMoreMessage"
                >
                    {{ $t('msn.amDashboard.toNoticeList') }}
                </span>
            </div>
            <div>
                <el-button v-if="isListMessage" @click="handleMessageList">
                    {{ $t('msn.amDashboard.closeDetail') }}
                </el-button>
            </div>
        </div>
    </msn-dialog>
</template>
<script>
import moment from 'moment';
export default {
    name: 'MessageDetail',
    props: {
        visible: {
            default: false
        },
        detailLoading: {
            default: false
        },
        detail: {
            default: () => {}
        },
        toMoreMessage: {
            default: false
        },
        isListMessage: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {};
    },
    computed: {},
    components: {
    },
    methods: {
        handleClose() {
            this.$emit('close');
            this.$emit('getData');
        },
        handleToMoreMessage(){
            this.$parent.showMessageDialog = true;
            this.$emit('getMessageList');
        },
        handleMessageList() {
            this.$emit('close');
            this.$emit('getData');
        },
        handleData(val) {
            return moment(val).format('L');
        }
    },
    mounted() {
    },
    watch: {
    }
};
</script>
<style lang="scss" scoped>
.fixed-dialog /deep/ .dialog-body {
    height: 593px;
    overflow-y: auto;
    ::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.7);
        border-radius: 3px;
        width: 3px;
    }
}
.fixed-dialog /deep/ .footer-container {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
        &-more{
            cursor: pointer;
        }
}
.dialog-container {
    display: flex;
    padding: 20px;
    .detail-container{
        text-align: left;
        min-height: 500px;
        &-title{
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #53575B;
        }
        &-date{
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: rgba(83, 87, 91, 0.5);
        }
        &-content{
            font-weight: 400;
            font-size: 13px;
            line-height: 26px;
            color: rgba(83, 87, 91, 0.8);

        }
    }
}
</style>
<style lang="scss">
    .fixed-dialog{
        .msn-dialog .el-dialog__header .dialog-title .title{
            text-align: left;
        }
    }

.dialog-container {
    &::-webkit-scrollbar{
        width: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.7)!important;
        border-radius: 3px!important;
        width: 3px!important;
    }
}
</style>