<template>
    <el-dialog
        :visible.sync="visible"
        :show-close="false"
        :width="width"
        :top="top"
        :append-to-body="appendToBody"
        :custom-class="classes"
        :before-close="beforeClose"
        :modal-append-to-body="modalAppendToBody"
    >
        <div slot="title" :class="`dialog-title ${titleClass}`">
            <div class="title">
                <slot name="title">
                    {{ title }}
                </slot>
                <i
                    v-if="showClose" class="msn-fa msn-fa-close close"
                    @click="beforeClose"
                ></i>
            </div>
        </div>
        <div class="dialog-body">
            <slot></slot>
        </div>
        <slot name="footer"></slot>
    </el-dialog>
</template>

<script>
export default {
    name: 'MsnDialog',
    props: {
        visible: {
            default: false
        },
        showClose: {
            default: true
        },
        title: {
            default: ''
        },
        beforeClose: {
            default: () => {
                return () => {};
            }
        },
        width: {
            default: '700px'
        },
        customClasses: {
            default: ''
        },
        titleClass: {
            default: ''
        },
        appendToBody: {
            default: false
        },
        top: {
            default: '15vh'
        },
        modalAppendToBody: {
            default: true
        }
    },
    computed: {
        classes() {
            return this.customClasses + ' msn-dialog';
        }
    }
};
</script>

<style lang="scss">
.msn-dialog {
    box-shadow: -3px 10px 30px 0 rgba(0, 0, 0, .25);
    border-radius: 6px!important;
    .el-dialog__header {
        padding: 0;
        padding-left: 30px;
        font-size: 12px;
        background: #F8F8F8;
        border-radius: 6px;
        .dialog-title {
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            color: #000;
            font-weight: 700;

            .title {
                position: relative;
                .close {
                    font-size: 15px;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }
        }

        .el-dialog__headerbtn {
            top: 16px;
        }
    }
    .el-dialog__body {
        padding: 0 16px;
    }
    @keyframes scaling {
        0% {
            transform: scale(1)
        }
        50% {
            transform: scale(.85);
        }
        100% {
            transform: scale(1);
        }
    }
    .el-button {
        height: 35px;
        padding: 10px 18px;
        box-sizing: border-box;
        border-radius: 17.5px;
        font-size: 13px;
        &--default {
            border: 1px solid #53575B;
            background-color: #fff;
            color: #53575B!important;
            &:hover {
                border-color: transparent;
                background: rgba(83, 87, 91, .20);
            }
            &:active {
                border-color: transparent;
                background: rgba(83, 87, 91, .20);
                animation: .5s ease scaling;
            }
            &:focus {
                border-color: transparent;
                background: rgba(83, 87, 91, .20);
            }
        }
        &--primary {
            color: #fff;
            // border-color: #2DD1AC;
            border: none;
            background-color: #2DD1AC;
            box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, .16);
            &:hover {
                background-color: #24937A;
            }
            &:active {
                background-color: #24937A;
                animation: .5s ease scaling;
            }
        }

        &+.el-button {
            margin-left: 20px;
        }
    }
}
.el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);

}
.el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar{
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.7);
        border-radius: 4px !important;
    }
}
</style>
