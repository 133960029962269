// 根据域名动态添加gtag
(function () {
    const idF = 'AR3uJRD5PaHk-MiGoNTIKNAn87S4TGdsg8_5gc-4em7X1DpzzTl85ryiTTbS'; // 正式
    const idT = 'ASUfGofiABEXBGwVppocmKrUKYGECFNFZ5l5m00C4ReX_ailzc67pcg23JVYsST_08YFLXb1ibER5EXW'; // 测试
    const id = location.hostname.indexOf('mediago.io') > -1 ? idF : idT;
    let a = document.createElement('script');
    let m = document.getElementsByTagName('script')[0];
    a.async = 1;
    a.src = `https://www.paypal.com/sdk/js?client-id=${id}&disable-funding=blik,bancontact,giropay,ideal,mybank,p24,sepa,sofort,venmo,credit`;
    m.parentNode.insertBefore(a, m);
})();
