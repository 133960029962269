/**
* @file 确定删除按钮
* @author fangsimin
* @date 2019-01-18 09:48:54
* @last Modified by fangsimin
* @last Modified time 2019-01-18 09:49:08
*/
<template>
    <el-popover v-model="visible">
        <p>{{ $t('message.isConfirmDelete') }}</p>
        <div>
            <el-button
                size="mini" type="text"
                @click="visible = false"
            >
                {{ $t('message.button.cancel') }}
            </el-button>
            <el-button
                type="primary" size="mini"
                @click="handleConfirm()"
            >
                {{ $t('message.button.confirm') }}
            </el-button>
        </div>
        <el-button
            slot="reference" size="mini"
            type="danger"
        >
            {{ $t('message.button.delete') }}
        </el-button>
    </el-popover>
</template>

<script>
export default {
    props: {
        confirm: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false
        };
    },
    methods: {
        handleConfirm() {
            typeof this.confirm === 'function'
                && this.confirm();
            this.visible = false;
        }
    }
};
</script>
