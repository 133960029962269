/*
 * @file state-cn.js
 * @author liushengxgi
 * @date 2020-02-10 11:07:54
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-10 11:08:41
 */

export default {
    AL: 'アラバマ',
    AK: 'アラスカ',
    AZ: 'アリゾナ',
    AR: 'アーカンサス',
    CA: 'カリフォルニア',
    CO: 'コロラド',
    CT: 'コネチカット',
    DE: 'デラウェアリー',
    FL: 'フロリダs',
    GA: 'ジョージア',
    HI: 'ハワイ',
    ID: 'イダホ',
    IL: 'イリノイ',
    IN: 'インディアナ',
    IA: 'アイオワ',
    KS: 'カンザス',
    KY: 'ケンタッキー',
    LA: 'ルイジアナ',
    ME: 'メインリー',
    MD: 'メリーランド',
    MA: 'マサチューセッツェ',
    MI: 'ミシガン',
    MN: 'ミネソタ',
    MS: 'ミシシッピ',
    MO: 'ミズーリ',
    MT: 'モンタナ',
    NE: 'ネブラスカ',
    NV: 'ネバダ',
    NH: 'ニューハンプシャー',
    NJ: 'ニュージャージー',
    NM: 'ニューメキシコ',
    NY: 'ニューヨーク',
    NC: 'ノースカロライナ州',
    ND: 'ノースダコタ',
    OH: 'オハイオーズ',
    OK: 'オクラホマ',
    OR: 'オレゴン',
    PA: 'ペンシルバニア',
    RI: 'ロードアイランド',
    SC: 'サウスカロライナ',
    SD: 'サウス・ダコタ',
    TN: 'テネシー',
    TX: 'テキサス',
    UT: 'ユタ',
    VT: 'バーマント',
    VA: 'バージニア',
    WAI: 'ワシントン',
    WV: 'ウェストバージニア',
    WI: 'ウィスコンシン',
    WY: 'ワイオミング',
    OT: '他の',
    DC: '他の',
    // 加拿大
    AB: 'アルバータ',
    BC: 'ブリティッシュコロンビア',
    MB: 'マニトバ',
    NB: 'ニューブランズウィック',
    NL: 'ニューファンドランドとラブラドール',
    NS: 'ノヴァスコシア',
    NT: 'ノースウエスト地域',
    NU: 'ヌナブト',
    ON: 'オンタリオ',
    PE: 'プリンス・エドワード島',
    QC: 'ケベック',
    SK: 'サスカチュワン',
    YT: 'ユコン',
    // 澳大利亚
    NSW: 'ニューサウスウェールズ州',
    QLD: 'Queensland.',
    SA: '南オーストラリア',
    TAS: 'タスマニア',
    VIC: 'ビクトリア',
    WA: 'オーストラリア西部',
    ACT: 'オーストラリアの首都特別地域',
    NT01: 'ノーザン・テリトリー',
    // 英国
    ENG: 'イングランド',
    NIR: '北アイルランド',
    SCT: 'スコットランド',
    WLS: 'ウェールズ',
    // 日本
    JP13: '東京',
    JP01: '北海道',
    JP26: '京都',
    JP27: '大阪',
    JP02: '青森',
    JP03: '岩手',
    JP04: '宮城',
    JP05: '秋田',
    JP06: '山形',
    JP07: '福島',
    JP08: '茨城',
    JP09: '栃木',
    JP10: '群馬',
    JP11: '埼玉',
    JP12: '千葉',
    JP14: '神奈川',
    JP15: '新潟',
    JP16: '富山',
    JP17: '石川',
    JP18: '福井',
    JP19: '山梨',
    JP20: '長野',
    JP21: '岐阜',
    JP22: '静岡',
    JP23: '愛知',
    JP24: '三重',
    JP25: '滋賀',
    JP28: '兵庫',
    JP29: '奈良',
    JP30: '和歌山',
    JP31: '鳥取',
    JP32: '島根',
    JP33: '岡山',
    JP34: '広島',
    JP35: '山口',
    JP36: '徳島',
    JP37: '香川',
    JP38: '愛媛',
    JP39: '高知',
    JP40: '福岡',
    JP41: '佐賀',
    JP42: '長崎',
    JP43: '熊本',
    JP44: '大分',
    JP45: '宮崎',
    JP46: '鹿児島',
    JP47: '沖縄',
    // 韩国
    KR11: 'ソウル',
    KR26: '釜山',
    KR27: '大邱',
    KR28: '仁川',
    KR29: '光州',
    KR30: '大田',
    KR31: '蔚山',
    KR50: '世宗',
    KR41: '京畿道',
    KR42: '江原道',
    KR43: '忠清北道',
    KR44: '忠清南道',
    KR45: '全羅北道',
    KR46: '全羅南道',
    KR47: '慶尚北道',
    KR48: '慶尚南道',
    KR49: '済州',
    // 台湾
    NWT: '臺北',
    TPE: '新北',
    TAO: '桃園',
    TXG: '臺中',
    TNN: '臺南',
    KHH: '高雄',
    ILA: '宜蘭',
    HSQ: '新竹',
    MIA: '苗栗',
    CHA: '彰化',
    NAN: '南投',
    YUN: '雲林',
    CYQ: '嘉義',
    PIF: '屏東',
    TTT: '花蓮',
    HUA: '臺東',
    PEN: '澎湖',
    KEE: '基隆',
    // 印尼
    IDAC: 'アチェ',
    IDBA: 'バリ州',
    IDBB: 'バンカビリトゥング州',
    IDBT: 'バンテン州',
    IDBE: 'ベンクル',
    IDGO: '中部ジャワ',
    IDJA: '中カリマンタン',
    IDJB: '中スラウェシ',
    IDJT: '東ジャワ',
    IDJI: '東カリマンタン',
    IDKB: '東ヌサトゥンガラ',
    IDKS: 'ゴロンタロ',
    IDKT: 'ジャカルタ首都圏',
    IDKI: 'ジャンビ',
    IDKU: 'ランプン県',
    IDKR: 'マルク',
    IDLA: '北カリマンタン',
    IDMA: '北マルク',
    IDMU: '北スラウェシ',
    IDNB: '北スマトラ',
    IDNT: 'パプア',
    IDPA: 'リアウ',
    IDPB: 'リアウ諸島',
    IDRI: '南東スラウェシ州',
    IDSR: '南カリマンタン',
    IDSN: '南スラウェシ',
    IDST: '南スマトラ',
    IDSG: '西ジャワ',
    IDSA: '西カリマンタン',
    IDSB: 'ヌサトゥンガラ西部',
    IDSS: '西パプア',
    IDSU: '西スラウェシ',
    IDJK: '西スマトラ',
    IDYO: 'ジョグジャカルタ特別地域'
};
