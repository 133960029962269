import { staticRole } from '@/modules/msn/components/PlatformColumn/adListColSize.js';
export const dashboardDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: ['detail#campaignName'],
    cols: {
        detail: [
            {
                label: 'msn.dashboard.colStatus',
                value: 'status'
            },
            {
                label: 'msn.dashboard.campaignName',
                value: 'campaignName'
            },
            {
                label: 'msn.dashboard.campaignId',
                value: 'campaignId'
            },
            {
                label: 'msn.dashboard.accountId',
                value: 'accountId'
            },
            {
                label: 'msn.dashboard.accountName',
                value: 'accountName'
            },
            {
                label: 'msn.dashboard.colStart',
                value: 'startDate'
            },
            {
                label: 'msn.dashboard.colEnd',
                value: 'endDate'
            },
            {
                label: 'msn.dashboard.colSubmissionTime',
                value: 'submissionTime'
            },
            {
                label: 'msn.dashboard.colSpend',
                value: 'spend'
            },
            {
                label: 'msn.dashboard.colBalance',
                value: 'remainingBudget'
            },
            {
                label: 'msn.dashboard.colTotalBudget',
                value: 'totalBudget'
            },
            {
                label: 'msn.dashboard.colDailyBudget',
                value: 'dailyBudget'
            },
            {
                label: 'msn.dashboard.uniformlyFlag',
                value: 'uniformlyFlag'
            }
        ],
        delivery: [

            {
                label: 'msn.dashboard.colCpc',
                value: 'cpc'
            },
            {
                label: 'msn.dashboard.colAvgCpc',
                value: 'avgCpc'
            },
            {
                label: 'msn.dashboard.colVCpm',
                value: 'vcpm'
            },
            {
                label: 'msn.dashboard.colImp',
                value: 'impression'
            },
            {
                label: 'msn.dashboard.colClick',
                value: 'click'
            },
            {
                label: 'msn.dashboard.colCtr',
                value: 'ctr'
            }
        ],
        conversion: [
            {
                label: 'MCV',
                value: 'mcv'
            },
            {
                label: 'CV',
                value: 'cv'
            },
            {
                label: 'MCVR',
                value: 'mcvr'
            },
            {
                label: 'CVR',
                value: 'cvr'
            },
            {
                label: 'msn.dashboard.roas',
                value: 'roas'
            },
            {
                label: 'msn.dashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.dashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dashboard.colConversionPrice',
                value: 'conversionPrice'
            },
            {
                label: 'msn.dashboard.cvViewContent',
                value: 'cvViewContent'
            },
            {
                label: 'msn.dashboard.cvAppInstall',
                value: 'cvAppInstall'
            },
            {
                label: 'msn.dashboard.cvCompleteRegistration',
                value: 'cvCompleteRegistration'
            },
            {
                label: 'msn.dashboard.cvAddToCar',
                value: 'cvAddToCar'
            }, {
                label: 'msn.dashboard.cvAddPaymentInfo',
                value: 'cvAddPaymentInfo'
            }, {
                label: 'msn.dashboard.cvSearch',
                value: 'cvSearch'
            }, {
                label: 'msn.dashboard.cvStartCheckout',
                value: 'cvStartCheckout'
            }, {
                label: 'msn.dashboard.cvPurchase',
                value: 'cvPurchase'
            }, {
                label: 'msn.dashboard.cvAddToWishlist',
                value: 'cvAddToWishlist'
            }, {
                label: 'msn.dashboard.cvLead',
                value: 'cvLead'
            }, {
                label: 'msn.dashboard.cvOther',
                value: 'cvOther'
            }
        ]
    }
};
export const valueLabelMap = {
    'conversion#roas': 'msn.dashboard.roas',
    'detail#status': 'msn.dashboard.colStatus',
    'detail#campaignName': 'msn.dashboard.campaignName',
    'detail#campaignId': 'msn.dashboard.campaignId',
    'detail#accountId': 'msn.dashboard.accountId',
    'detail#accountName': 'msn.dashboard.accountName',
    'detail#startDate': 'msn.dashboard.colStart',
    'detail#endDate': 'msn.dashboard.colEnd',
    // 这周暂时不做
    'detail#submissionTime': 'msn.dashboard.colSubmissionTime',
    'detail#spend': 'msn.dashboard.colSpend',
    'detail#remainingBudget': 'msn.dashboard.colBalance',
    'detail#totalBudget': 'msn.dashboard.colTotalBudget',
    'detail#dailyBudget': 'msn.dashboard.colDailyBudget',
    'detail#uniformlyFlag': 'msn.dashboard.uniformlyFlag',
    'delivery#cpc': 'msn.dashboard.colCpc',
    'conversion#mcv': 'MCV',
    'conversion#cv': 'CV',
    'conversion#mcvr': 'MCVR',
    'conversion#cvr': 'CVR',
    'delivery#avgCpc': 'msn.dashboard.colAvgCpc',
    'delivery#vcpm': 'msn.dashboard.colVCpm',
    'delivery#impression': 'msn.dashboard.colImp',
    'delivery#click': 'msn.dashboard.colClick',
    'delivery#ctr': 'msn.dashboard.colCtr',
    'conversion#conversion': 'msn.dashboard.colConversion',
    'conversion#conversionRate': 'msn.dashboard.colConversionRate',
    'conversion#conversionPrice': 'msn.dashboard.colConversionPrice',
    'conversion#cvViewContent': 'msn.dashboard.cvViewContent',
    'conversion#cvAppInstall': 'msn.dashboard.cvAppInstall',
    'conversion#cvCompleteRegistration': 'msn.dashboard.cvCompleteRegistration',
    'conversion#cvAddToCar': 'msn.dashboard.cvAddToCar',
    'conversion#cvAddPaymentInfo': 'msn.dashboard.cvAddPaymentInfo',
    'conversion#cvSearch': 'msn.dashboard.cvSearch',
    'conversion#cvStartCheckout': 'msn.dashboard.cvStartCheckout',
    'conversion#cvPurchase': 'msn.dashboard.cvPurchase',
    'conversion#cvAddToWishlist': 'msn.dashboard.cvAddToWishlist',
    'conversion#cvLead': 'msn.dashboard.cvLead',
    'conversion#cvOther': 'msn.dashboard.cvOther'
};
// adList column
export const adDefaultCols = {
    // 当用户刚进来的时候，默认展示
    // default: ['detail#imgUrl'], 在没权限的时候一定要显示 size
    default: staticRole ? ['detail#imgUrl'] : ['detail#imgUrl'],
    cols: {
        detail: [
            {
                label: 'msn.dashboard.adList.image',
                value: 'imgUrl'
            },
            {
                label: 'msn.dashboard.adList.size',
                value: 'size'
            },
            {
                label: 'msn.dashboard.adList.campaignName',
                value: 'campaignName'
            },
            {
                label: 'msn.dashboard.adList.campaignId',
                value: 'campaignId'
            },
            {
                label: 'msn.dashboard.adList.cpc',
                value: 'cpc'
            },
            {
                label: 'msn.dashboard.adList.id',
                value: 'adId'
            },
            {
                label: 'msn.dashboard.adList.status',
                value: 'status'
            },
            {
                label: 'msn.dashboard.adList.name',
                value: 'assetName'
            },
            {
                label: 'msn.dashboard.adList.title',
                value: 'headline'
            },
            {
                label: 'msn.dashboard.adList.brandName',
                value: 'brandName'
            },
            {
                label: 'msn.dashboard.adList.url',
                value: 'landingPage'
            },
            {
                label: 'msn.dashboard.adList.assetId',
                value: 'assetId'
            }


        ],
        delivery: [
            {
                label: 'msn.dashboard.adList.spend',
                value: 'spend'
            },
            {
                label: 'msn.dashboard.adList.impression',
                value: 'impression'
            },
            {
                label: 'msn.dashboard.adList.click',
                value: 'click'
            },
            {
                label: 'msn.dashboard.colCtr',
                value: 'ctr'
            }

        ],
        conversion: [
            {
                label: 'MCV',
                value: 'mcv'
            },
            {
                label: 'CV',
                value: 'cv'
            },
            {
                label: 'MCVR',
                value: 'mcvr'
            },
            {
                label: 'CVR',
                value: 'cvr'
            },
            {
                label: 'msn.dashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.dashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dashboard.colConversionPrice',
                value: 'conversionPrice'
            },
            {
                label: 'msn.dashboard.cvViewContent',
                value: 'cvViewContent'
            },
            {
                label: 'msn.dashboard.cvAppInstall',
                value: 'cvAppInstall'
            },
            {
                label: 'msn.dashboard.cvCompleteRegistration',
                value: 'cvCompleteRegistration'
            },
            {
                label: 'msn.dashboard.cvAddToCar',
                value: 'cvAddToCar'
            }, {
                label: 'msn.dashboard.cvAddPaymentInfo',
                value: 'cvAddPaymentInfo'
            }, {
                label: 'msn.dashboard.cvSearch',
                value: 'cvSearch'
            }, {
                label: 'msn.dashboard.cvStartCheckout',
                value: 'cvStartCheckout'
            }, {
                label: 'msn.dashboard.cvPurchase',
                value: 'cvPurchase'
            }, {
                label: 'msn.dashboard.cvAddToWishlist',
                value: 'cvAddToWishlist'
            }, {
                label: 'msn.dashboard.cvLead',
                value: 'cvLead'
            }, {
                label: 'msn.dashboard.cvOther',
                value: 'cvOther'
            }
        ]
    }
};

export const adValueLabelMap = {
    'detail#imgUrl': 'msn.dashboard.adList.image',
    'detail#adId': 'msn.dashboard.adList.id',
    'detail#status': 'msn.dashboard.adList.status',
    'detail#assetName': 'msn.dashboard.adList.name',
    'detail#headline': 'msn.dashboard.adList.title',
    'detail#brandName': 'msn.dashboard.adList.brandName',
    'delivery#spend': 'msn.dashboard.adList.spend',
    'detail#cpc': 'msn.dashboard.adList.cpc',
    'detail#campaignId': 'msn.dashboard.adList.campaignId',
    'detail#campaignName': 'msn.dashboard.adList.campaignName',
    'detail#size': 'msn.dashboard.adList.size',
    'detail#landingPage': 'msn.dashboard.adList.url',
    'detail#assetId': 'msn.dashboard.adList.assetId',
    // delivery
    'conversion#mcv': 'MCV',
    'conversion#cv': 'CV',
    'conversion#mcvr': 'MCVR',
    'conversion#cvr': 'CVR',
    'delivery#impression': 'msn.dashboard.adList.impression',
    'delivery#click': 'msn.dashboard.adList.click',
    'delivery#ctr': 'msn.dashboard.colCtr',
    // conversion
    'conversion#conversion': 'msn.dashboard.colConversion',
    'conversion#conversionRate': 'msn.dashboard.colConversionRate',
    'conversion#conversionPrice': 'msn.dashboard.colConversionPrice',
    'conversion#cvViewContent': 'msn.dashboard.cvViewContent',
    'conversion#cvAppInstall': 'msn.dashboard.cvAppInstall',
    'conversion#cvCompleteRegistration': 'msn.dashboard.cvCompleteRegistration',
    'conversion#cvAddToCar': 'msn.dashboard.cvAddToCar',
    'conversion#cvAddPaymentInfo': 'msn.dashboard.cvAddPaymentInfo',
    'conversion#cvSearch': 'msn.dashboard.cvSearch',
    'conversion#cvStartCheckout': 'msn.dashboard.cvStartCheckout',
    'conversion#cvPurchase': 'msn.dashboard.cvPurchase',
    'conversion#cvAddToWishlist': 'msn.dashboard.cvAddToWishlist',
    'conversion#cvLead': 'msn.dashboard.cvLead',
    'conversion#cvOther': 'msn.dashboard.cvOther'
};

// domain column

export const domainDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: ['detail#domainName'],
    cols: {
        detail: [
            {
                label: 'msn.dashboard.domain.domainName',
                value: 'domainName'
            },
            {
                label: 'msn.dashboard.domain.ssp',
                value: 'ssp'
            }
        ],
        delivery: [

            {
                label: 'msn.dashboard.domain.spend',
                value: 'spend'
            },
            {
                label: 'msn.dashboard.domain.impression',
                value: 'impression'
            },
            {
                label: 'msn.dashboard.domain.click',
                value: 'click'
            },
            {
                label: 'msn.dashboard.domain.ctr',
                value: 'ctr'
            },
            {
                label: 'msn.dashboard.domain.acpc',
                value: 'avgCpc'
            },
            {
                label: 'msn.dashboard.domain.cpc',
                value: 'cpc'
            },
            {
                label: 'msn.dashboard.domain.vcpm',
                value: 'vcpm'
            }
        ],
        conversion: [
            {
                label: 'MCV',
                value: 'mcv'
            },
            {
                label: 'CV',
                value: 'cv'
            },
            {
                label: 'MCVR',
                value: 'mcvr'
            },
            {
                label: 'CVR',
                value: 'cvr'
            },
            {
                label: 'msn.dashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.dashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dashboard.colConversionPrice',
                value: 'conversionPrice'
            },
            {
                label: 'msn.dashboard.cvViewContent',
                value: 'cvViewContent'
            },
            {
                label: 'msn.dashboard.cvAppInstall',
                value: 'cvAppInstall'
            },
            {
                label: 'msn.dashboard.cvCompleteRegistration',
                value: 'cvCompleteRegistration'
            },
            {
                label: 'msn.dashboard.cvAddToCar',
                value: 'cvAddToCar'
            }, {
                label: 'msn.dashboard.cvAddPaymentInfo',
                value: 'cvAddPaymentInfo'
            }, {
                label: 'msn.dashboard.cvSearch',
                value: 'cvSearch'
            }, {
                label: 'msn.dashboard.cvStartCheckout',
                value: 'cvStartCheckout'
            }, {
                label: 'msn.dashboard.cvPurchase',
                value: 'cvPurchase'
            }, {
                label: 'msn.dashboard.cvAddToWishlist',
                value: 'cvAddToWishlist'
            }, {
                label: 'msn.dashboard.cvLead',
                value: 'cvLead'
            }, {
                label: 'msn.dashboard.cvOther',
                value: 'cvOther'
            }
        ]
    }
};

export const domainValueLabelMap = {
    'detail#domainName': 'msn.dashboard.domain.domainName',
    'detail#ssp': 'msn.dashboard.domain.ssp',
    // delivery
    'delivery#spend': 'msn.dashboard.domain.spend',
    'delivery#impression': 'msn.dashboard.domain.impression',
    'delivery#click': 'msn.dashboard.domain.click',
    'delivery#ctr': 'msn.dashboard.domain.ctr',
    'delivery#vcpm': 'msn.dashboard.domain.vcpm',
    'delivery#avgCpc': 'msn.dashboard.domain.acpc',
    'delivery#cpc': 'msn.dashboard.domain.cpc',
    'conversion#mcv': 'MCV',
    'conversion#cv': 'CV',
    'conversion#mcvr': 'MCVR',
    'conversion#cvr': 'CVR',
    // conversion
    'conversion#conversion': 'msn.dashboard.colConversion',
    'conversion#conversionRate': 'msn.dashboard.colConversionRate',
    'conversion#conversionPrice': 'msn.dashboard.colConversionPrice',
    'conversion#cvViewContent': 'msn.dashboard.cvViewContent',
    'conversion#cvAppInstall': 'msn.dashboard.cvAppInstall',
    'conversion#cvCompleteRegistration': 'msn.dashboard.cvCompleteRegistration',
    'conversion#cvAddToCar': 'msn.dashboard.cvAddToCar',
    'conversion#cvAddPaymentInfo': 'msn.dashboard.cvAddPaymentInfo',
    'conversion#cvSearch': 'msn.dashboard.cvSearch',
    'conversion#cvStartCheckout': 'msn.dashboard.cvStartCheckout',
    'conversion#cvPurchase': 'msn.dashboard.cvPurchase',
    'conversion#cvAddToWishlist': 'msn.dashboard.cvAddToWishlist',
    'conversion#cvLead': 'msn.dashboard.cvLead',
    'conversion#cvOther': 'msn.dashboard.cvOther'
};
// trend column
export const trendDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: [],
    min: 1,
    cols: {
        detail: [
            {
                label: 'msn.dashboard.adList.spend',
                value: 'spend'
            }
        ],
        delivery: [

            {
                label: 'msn.dashboard.adList.impression',
                value: 'impression'
            },
            {
                label: 'msn.dashboard.adList.click',
                value: 'click'
            },
            {
                label: 'msn.dashboard.colCtr',
                value: 'clickRate'
            }
        ],
        conversion: [
            {
                label: 'MCV',
                value: 'mcv'
            },
            {
                label: 'CV',
                value: 'cv'
            },
            {
                label: 'msn.dashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.dashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dashboard.costPerConversion',
                value: 'costPerConversion'
            },
            // {
            //     label: 'msn.dashboard.colConversionPrice',
            //     value: 'conversionPrice'
            // },
            {
                label: 'msn.dashboard.cvViewContent',
                value: 'cvViewContent'
            },
            {
                label: 'msn.dashboard.cvAppInstall',
                value: 'cvAppInstall'
            },
            {
                label: 'msn.dashboard.cvCompleteRegistration',
                value: 'cvCompleteRegistration'
            },
            {
                label: 'msn.dashboard.cvAddToCar',
                value: 'cvAddToCar'
            }, {
                label: 'msn.dashboard.cvAddPaymentInfo',
                value: 'cvAddPaymentInfo'
            }, {
                label: 'msn.dashboard.cvSearch',
                value: 'cvSearch'
            }, {
                label: 'msn.dashboard.cvStartCheckout',
                value: 'cvStartCheckout'
            }, {
                label: 'msn.dashboard.cvPurchase',
                value: 'cvPurchase'
            }, {
                label: 'msn.dashboard.cvAddToWishlist',
                value: 'cvAddToWishlist'
            }, {
                label: 'msn.dashboard.cvLead',
                value: 'cvLead'
            }, {
                label: 'msn.dashboard.cvOther',
                value: 'cvOther'
            }
        ]
    }
};

export const trendValueLabelMap = {
    'detail#spend': 'msn.dashboard.adList.spend',
    // delivery
    'delivery#impression': 'msn.dashboard.adList.impression',
    'delivery#click': 'msn.dashboard.adList.click',
    'delivery#clickRate': 'msn.dashboard.colCtr',
    // conversion
    'conversion#mcv': 'mcv',
    'conversion#cv': 'cv',
    'conversion#conversion': 'msn.dashboard.colConversion',
    'conversion#conversionRate': 'msn.dashboard.colConversionRate',
    'conversion#costPerConversion': 'msn.dashboard.costPerConversion',
    // 'conversion#conversionPrice': 'msn.dashboard.colConversionPrice',
    'conversion#cvViewContent': 'msn.dashboard.cvViewContent',
    'conversion#cvAppInstall': 'msn.dashboard.cvAppInstall',
    'conversion#cvCompleteRegistration': 'msn.dashboard.cvCompleteRegistration',
    'conversion#cvAddToCar': 'msn.dashboard.cvAddToCar',
    'conversion#cvAddPaymentInfo': 'msn.dashboard.cvAddPaymentInfo',
    'conversion#cvSearch': 'msn.dashboard.cvSearch',
    'conversion#cvStartCheckout': 'msn.dashboard.cvStartCheckout',
    'conversion#cvPurchase': 'msn.dashboard.cvPurchase',
    'conversion#cvAddToWishlist': 'msn.dashboard.cvAddToWishlist',
    'conversion#cvLead': 'msn.dashboard.cvLead',
    'conversion#cvOther': 'msn.dashboard.cvOther'
};

// 多维度报表
export const multiTrendDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: [],
    min: 1,
    cols: {
        detail: [
            {
                label: 'msn.dashboard.adList.spend',
                value: 'spend'
            }
        ],
        delivery: [
            {
                label: 'msn.dashboard.adList.impression',
                value: 'impression'
            },
            {
                label: 'msn.dashboard.adList.click',
                value: 'click'
            },
            {
                label: 'msn.dashboard.colCtr',
                value: 'clickRate'
            }
        ],
        conversion: [
            {
                label: 'msn.dashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.dashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dashboard.costPerConversion',
                value: 'costPerConversion'
            },
            // {
            //     label: 'msn.dashboard.colConversionPrice',
            //     value: 'conversionPrice'
            // },
            {
                label: 'msn.dashboard.cvViewContent',
                value: 'cvViewContent'
            },
            {
                label: 'msn.dashboard.cvAppInstall',
                value: 'cvAppInstall'
            },
            {
                label: 'msn.dashboard.cvCompleteRegistration',
                value: 'cvCompleteRegistration'
            },
            {
                label: 'msn.dashboard.cvAddToCar',
                value: 'cvAddToCar'
            }, {
                label: 'msn.dashboard.cvAddPaymentInfo',
                value: 'cvAddPaymentInfo'
            }, {
                label: 'msn.dashboard.cvSearch',
                value: 'cvSearch'
            }, {
                label: 'msn.dashboard.cvStartCheckout',
                value: 'cvStartCheckout'
            }, {
                label: 'msn.dashboard.cvPurchase',
                value: 'cvPurchase'
            }, {
                label: 'msn.dashboard.cvAddToWishlist',
                value: 'cvAddToWishlist'
            }, {
                label: 'msn.dashboard.cvLead',
                value: 'cvLead'
            }, {
                label: 'msn.dashboard.cvOther',
                value: 'cvOther'
            }
        ]
    }
};

export const multiTrendValueLabelMap = {
    'detail#spend': 'msn.dashboard.adList.spend',
    // delivery
    'delivery#impression': 'msn.dashboard.adList.impression',
    'delivery#click': 'msn.dashboard.adList.click',
    'delivery#clickRate': 'msn.dashboard.colCtr',
    // conversion
    'conversion#conversion': 'msn.dashboard.colConversion',
    'conversion#conversionRate': 'msn.dashboard.colConversionRate',
    'conversion#costPerConversion': 'msn.dashboard.costPerConversion',
    // 'conversion#conversionPrice': 'msn.dashboard.colConversionPrice',
    'conversion#cvViewContent': 'msn.dashboard.cvViewContent',
    'conversion#cvAppInstall': 'msn.dashboard.cvAppInstall',
    'conversion#cvCompleteRegistration': 'msn.dashboard.cvCompleteRegistration',
    'conversion#cvAddToCar': 'msn.dashboard.cvAddToCar',
    'conversion#cvAddPaymentInfo': 'msn.dashboard.cvAddPaymentInfo',
    'conversion#cvSearch': 'msn.dashboard.cvSearch',
    'conversion#cvStartCheckout': 'msn.dashboard.cvStartCheckout',
    'conversion#cvPurchase': 'msn.dashboard.cvPurchase',
    'conversion#cvAddToWishlist': 'msn.dashboard.cvAddToWishlist',
    'conversion#cvLead': 'msn.dashboard.cvLead',
    'conversion#cvOther': 'msn.dashboard.cvOther'
};
// 运营平台
export const amDashboardDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: ['detail#companyName',
    'detail#campaignName', 'detail#accountName', 'detail#date', 'detail#language', 'detail#country'],
    cols: {
        detail: [
            {
                label: 'msn.amDashboard.companyName',
                value: 'companyName'
            },
            {
                label: 'msn.amDashboard.campaignName',
                value: 'campaignName'
            },
            {
                label: 'msn.amDashboard.accountName',
                value: 'accountName'
            },
            {
                label: 'msn.amDashboard.date',
                value: 'date'
            },
            // 【运营分析】新增Language/Country维度指标
            {
                label: 'msn.amDashboard.language',
                value: 'language'
            },
            {
                label: 'msn.amDashboard.country',
                value: 'country'
            },
            {
                label: 'msn.amDashboard.colStatus',
                value: 'status'
            },
            {
                label: 'msn.amDashboard.campaignId',
                value: 'campaignId'
            },
            {
                label: 'msn.amDashboard.accountId',
                value: 'accountId'
            },
            {
                label: 'msn.amDashboard.colStart',
                value: 'startDate'
            },
            {
                label: 'msn.amDashboard.colEnd',
                value: 'endDate'
            },
            {
                label: 'msn.amDashboard.colTotalBudget',
                value: 'totalBudget'
            },
            {
                label: 'msn.amDashboard.colDailyBudget',
                value: 'dailyBudget'
            },
            {
                label: 'msn.dataReport.publisher',
                value: 'publisher'
            }
        ],
        delivery: [
            {
                label: 'msn.amDashboard.colCpc',
                value: 'cpc'
            },
            {
                label: 'msn.amDashboard.colAvgCpc',
                value: 'avgCpc'
            },
            {
                label: 'msn.amDashboard.colVCpm',
                value: 'vcpm'
            },
            {
                label: 'msn.amDashboard.colImp',
                value: 'impression'
            },
            {
                label: 'msn.amDashboard.colClick',
                value: 'click'
            },
            {
                label: 'msn.amDashboard.colCtr',
                value: 'ctr'
            },
            {
                label: 'msn.amDashboard.colIctr',
                value: 'ictr'
            },
            {
                label: 'msn.amDashboard.colIcpm',
                value: 'icpm'
            },
            {
                label: 'msn.amDashboard.colViewableRate',
                value: 'viewableRate'
            },
            {
                label: 'msn.amDashboard.colIimpression',
                value: 'iimpression'
            },
            {
                label: 'msn.amDashboard.colCost',
                value: 'cost'
            },
            {
                label: 'msn.amDashboard.colProfitRate',
                value: 'profitRate'
            },
            {
                label: 'msn.amDashboard.colSpend',
                value: 'spend'
            },
            {
                label: 'msn.amDashboard.colSpendRate',
                value: 'spendRate'
            },
            {
                label: 'msn.amDashboard.colLastSpend',
                value: 'lastSpend'
            },
            {
                label: 'msn.amDashboard.colCompareRate',
                value: 'compareRate'
            },
            {
                label: 'msn.amDashboard.remainingBudget',
                value: 'remainingBudget'
            }
        ],
        conversion: [
            {
                label: 'msn.amDashboard.colConversion',
                value: 'conversion'
            },
            {
                label: 'msn.amDashboard.colConversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.amDashboard.colConversionPrice',
                value: 'conversionPrice'
            },
            {
                label: 'msn.amDashboard.roas',
                value: 'roas'
            },
            {
                label: 'msn.create.targetCpa',
                value: 'targetCpa'
            }
        ]
    }
};

export const amValueLabelMap = {
    'detail#companyName': 'companyName',
    'detail#date': 'msn.amDashboard.date',
    'detail#language': 'msn.amDashboard.language',
    'detail#country': 'msn.amDashboard.country',
    'conversion#roas': 'msn.amDashboard.roas',
    'detail#status': 'msn.amDashboard.colStatus',
    'detail#campaignName': 'msn.amDashboard.campaignName',
    'detail#campaignId': 'msn.amDashboard.campaignId',
    'detail#accountId': 'msn.amDashboard.accountId',
    'detail#accountName': 'msn.amDashboard.accountName',
    'detail#startDate': 'msn.amDashboard.colStart',
    'detail#endDate': 'msn.amDashboard.colEnd',
    'detail#publisher': 'msn.dataReport.publisher',
    'detail#totalBudget': 'msn.amDashboard.colTotalBudget',
    'detail#dailyBudget': 'msn.amDashboard.colDailyBudget',
    'detail#uniformlyFlag': 'msn.dashboard.uniformlyFlag',
    'delivery#cpc': 'msn.amDashboard.colCpc',
    'delivery#avgCpc': 'msn.amDashboard.colAvgCpc',
    'delivery#vcpm': 'msn.amDashboard.colVCpm',
    'delivery#remainingBudget': 'msn.amDashboard.remainingBudget',
    'delivery#impression': 'msn.amDashboard.colImp',
    'delivery#click': 'msn.amDashboard.colClick',
    'delivery#ctr': 'msn.amDashboard.colCtr',
    'delivery#iimpression': 'msn.amDashboard.colIimpression',
    'delivery#cost': 'msn.amDashboard.colCost',
    'delivery#ictr': 'msn.amDashboard.colIctr',
    'delivery#icpm': 'msn.amDashboard.colIcpm',
    'delivery#viewableRate': 'msn.amDashboard.colViewableRate',
    'delivery#profitRate': 'msn.amDashboard.colProfitRate',
    'delivery#spend': 'msn.amDashboard.colSpend',
    'delivery#spendRate': 'msn.amDashboard.colSpendRate',
    'delivery#lastSpend': 'msn.amDashboard.colLastSpend',
    'delivery#compareRate': 'msn.amDashboard.colCompareRate',
    'conversion#conversion': 'msn.amDashboard.colConversion',
    'conversion#conversionRate': 'msn.amDashboard.colConversionRate',
    'conversion#conversionPrice': 'msn.amDashboard.colConversionPrice',
    'conversion#targetCpa': 'msn.create.targetCpa'

};

export const dataReportDefaultCols = {
    default: [],
    groups: {
        campaignAds: {
            items: [
                'campaignAds#campaignName',
                'campaignAds#assetName'
            ],
            min: 0
        },
        breakDownBy: {
            items: [
                'breakDownBy#audience',
                'breakDownBy#deviceBrowser',
                'breakDownBy#location',
                // 设置列1
                'breakDownBy#language',
                'breakDownBy#devicePlatform',
                'breakDownBy#deviceOs',
                'breakDownBy#adsize',
                'breakDownBy#ssp'
            ],
            min: 1,
            max: 2,
            hasTitleSuffix: true,
            noSelectAll: true
        },
        data: {
            items: [
                'data#spend',
                'data#impression',
                'data#click',
                'data#ctr',
                'data#avgCpc',
                'data#vcpm',
                'data#conversion',
                'data#conversionRate',
                'data#conversionPrice'
            ],
            min: 1
        }
    },
    cols: {
        campaignAds: [
            {
                label: 'msn.dataReport.campaignName',
                value: 'campaignName'
            },
            {
                label: 'msn.dataReport.assetName',
                value: 'assetName'
            }
        ],
        breakDownBy: [
            {
                label: 'msn.dataReport.audience',
                value: 'audience'
            },
            {
                label: 'msn.dataReport.browser',
                value: 'deviceBrowser'
            },
            {
                label: 'msn.dataReport.location',
                value: 'location'
            },
            {
                // 设置列2
                label: 'msn.dataReport.language',
                value: 'language'
            },
            {
                label: 'msn.dataReport.platform',
                value: 'devicePlatform'
            },
            {
                label: 'msn.dataReport.os',
                value: 'deviceOs'
            },
            {
                label: 'msn.dataReport.publisher', // 这个做了特殊处理
                value: 'ssp'
            }
            // {
            //     label: 'msn.dataReport.adsize',
            //     value: 'adsize'
            // }
        ],
        data: [
            {
                label: 'msn.dataReport.spend',
                value: 'spend'
            },
            {
                label: 'msn.dataReport.viewedImp',
                value: 'impression'
            },
            {
                label: 'msn.dataReport.click',
                value: 'click'
            },
            {
                label: 'msn.dataReport.colAvgCpc',
                value: 'avgCpc'
            },
            {
                label: 'msn.dataReport.colVcpm',
                value: 'vcpm'
            },
            {
                label: 'msn.dataReport.colDate',
                value: 'date'
            },
            {
                label: 'msn.dataReport.ctr',
                value: 'ctr'
            },
            {
                label: 'msn.dataReport.conversions',
                value: 'conversion'
            },
            {
                label: 'msn.dataReport.conversionRate',
                value: 'conversionRate'
            },
            {
                label: 'msn.dataReport.conversionPrice',
                value: 'conversionPrice'
            }
        ]
    }
};
// 用于映射右侧拖拽展示信息
export const dataReportValLabelMap = {
    'campaignAds#campaignName': 'msn.dataReport.campaignName',
    'campaignAds#assetName': 'msn.dataReport.assetName',
    'breakDownBy#audience': 'msn.dataReport.audience',
    'breakDownBy#location': 'msn.dataReport.location',
    // 设置列3
    'breakDownBy#language': 'msn.dataReport.language',
    'breakDownBy#devicePlatform': 'msn.dataReport.platform',
    'breakDownBy#deviceOs': 'msn.dataReport.os',
    'breakDownBy#deviceBrowser': 'msn.dataReport.browser',
    'breakDownBy#adsize': 'msn.dataReport.adsize',
    'breakDownBy#ssp': 'msn.dataReport.publisher',
    'data#spend': 'msn.dataReport.spend',
    'data#impression': 'msn.dataReport.viewedImp',
    'data#click': 'msn.dataReport.click',
    'data#avgCpc': 'msn.dataReport.colAvgCpc',
    'data#vcpm': 'msn.dataReport.colVcpm',
    'data#date': 'msn.dataReport.colDate',
    'data#ctr': 'msn.dataReport.ctr',
    'data#conversion': 'msn.dataReport.conversions',
    'data#conversionRate': 'msn.dataReport.conversionRate',
    'data#conversionPrice': 'msn.dataReport.conversionPrice'
};

export const pixelCols = {
    default: [],
    groups: {
        breakDownBy: {
            items: [
                'breakDownBy#accountName',
                'breakDownBy#conversionName',
                'breakDownBy#conversionType',
                'breakDownBy#status',
                'breakDownBy#category',
                'breakDownBy#includeInTotalConversion',
                'breakDownBy#lastReceived',
                'breakDownBy#lastModified'
            ],
            min: 1
        }
    },
    cols: {
        breakDownBy: [
            {
                label: 'msn.pixel.accountName',
                value: 'accountName'
            },
            {
                label: 'msn.pixel.conversionName',
                value: 'conversionName'
            },
            {
                label: 'msn.pixel.conversionType',
                value: 'conversionType'
            },
            {
                label: 'msn.pixel.status',
                value: 'status'
            },
            {
                label: 'msn.pixel.category',
                value: 'category'
            },
            {
                label: 'msn.pixel.includeInTotalConversion',
                value: 'includeInTotalConversion'
            },
            {
                label: 'msn.pixel.lastReceived',
                value: 'lastReceived'
            },
            {
                label: 'msn.pixel.lastModified',
                value: 'lastModified'
            }
        ]
    }
};

export const pixelValLabelMap = {
    'breakDownBy#accountName': 'msn.pixel.accountName',
    'breakDownBy#conversionName': 'msn.pixel.conversionName',
    'breakDownBy#conversionType': 'msn.pixel.conversionType',
    'breakDownBy#status': 'msn.pixel.status',
    'breakDownBy#category': 'msn.pixel.category',
    'breakDownBy#includeInTotalConversion': 'msn.pixel.includeInTotalConversion',
    'breakDownBy#lastReceived': 'msn.pixel.lastReceived',
    'breakDownBy#lastModified': 'msn.pixel.lastModified'
};

// 数据诊断相关
export const diagnosisCols = {
    // 用户第一次使用时的默认值
    default: [],
    // 左边选项列表
    cols: {
        dimension: [
            {
                label: 'msn.tracker.publisherName',
                value: 'publisherName',
                noUseTranslate: false
                // noUseTranslateRight: false
            },
            {
                label: 'msn.tracker.page',
                value: 'page',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.format',
                value: 'format',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.accountName',
                value: 'accountName',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.campaignName',
                value: 'campaignName',
                noUseTranslate: false
            }
        ],
        data: [{
            value: 'request',
            label: 'msn.tracker.request',
            noUseTranslate: false
        }, {
            value: 'callback',
            label: 'msn.tracker.callback',
            noUseTranslate: false
        }, {
            value: 'imp',
            label: 'msn.tracker.imp',
            noUseTranslate: false
        }, {
            value: 'vimp',
            label: 'msn.tracker.vimp',
            noUseTranslate: false
        }, {
            value: 'click',
            label: 'msn.tracker.click',
            noUseTranslate: false
        }, {
            value: 'callbackRate',
            label: 'msn.tracker.callbackRate',
            noUseTranslate: false
        }, {
            value: 'winRate',
            label: 'msn.tracker.winRate',
            noUseTranslate: false
        }, {
            value: 'viewableRate',
            label: 'msn.tracker.viewableRate',
            noUseTranslate: false
        }, {
            value: 'iCtr',
            label: 'msn.tracker.iCTR',
            noUseTranslate: false
        }, {
            value: 'vCtr',
            label: 'msn.tracker.vCTR',
            noUseTranslate: false
        }, {
            value: 'callbackRateRoc',
            label: 'msn.tracker.callbackRateROC',
            noUseTranslate: false
        }, {
            value: 'winRateRoc',
            label: 'msn.tracker.winRateROC',
            noUseTranslate: false
        }, {
            value: 'viewableRateRoc',
            label: 'msn.tracker.viewableRateROC',
            noUseTranslate: false
        }, {
            value: 'vCtrRoc',
            label: 'msn.tracker.vCTRROC',
            noUseTranslate: false
        }, {
            value: 'iCtrRoc',
            label: 'msn.tracker.iCTRROC',
            noUseTranslate: false
        }, {
            value: 'revenue',
            label: 'msn.tracker.revenue',
            noUseTranslate: false
        }, {
            value: 'cost',
            label: 'msn.tracker.cost',
            noUseTranslate: false
        }, {
            value: 'roi',
            label: 'msn.tracker.rOI',
            noUseTranslate: false
        }, {
            value: 'iCpm',
            label: 'msn.tracker.icpm',
            noUseTranslate: false
        }, {
            value: 'iRpm',
            label: 'msn.tracker.iRpm',
            noUseTranslate: false
        }, {
            value: 'revenueRoc',
            label: 'msn.tracker.revenueROC',
            noUseTranslate: false
        }, {
            value: 'revenueDiff',
            label: 'msn.tracker.revenueDiff',
            noUseTranslate: false
        },
        {
            value: 'requestDiff',
            label: 'msn.tracker.requestDiff',
            noUseTranslate: false
        },
        {
            value: 'callbackDiff',
            label: 'msn.tracker.callbackDiff',
            noUseTranslate: false
        },
        {
            value: 'impDiff',
            label: 'msn.tracker.impDiff',
            noUseTranslate: false
        },
        {
            value: 'vimpDiff',
            label: 'msn.tracker.vimpDiff',
            noUseTranslate: false
        },
        {
            value: 'clickDiff',
            label: 'msn.tracker.clickDiff',
            noUseTranslate: false
        },
        {
            value: 'profit',
            label: 'msn.tracker.profit',
            noUseTranslate: false
        },
        {
            value: 'profitDiff',
            label: 'msn.tracker.profitDiff',
            noUseTranslate: false
        },
        {
            value: 'accountId',
            label: 'msn.tracker.accountID',
            noUseTranslate: false
        },
        {
            value: 'campaignId',
            label: 'msn.tracker.campaignID',
            noUseTranslate: false
        },
        {
            value: 'accountName',
            label: 'msn.tracker.accountName',
            noUseTranslate: false
        }],
        conversion: [
            {
                label: 'msn.tracker.conversion',
                value: 'conversion',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.conversionDiff',
                value: 'conversionDiff',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.conversionRate',
                value: 'conversionRate',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.conversionRateROC',
                value: 'conversionRateRoc',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.costPerConversion',
                value: 'costPerConversion',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.costPerConversionDiff',
                value: 'costPerConversionDiff',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.viewContent',
                value: 'cvViewContent',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.appInstall',
                value: 'cvAppInstall',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.completeRegistration',
                value: 'cvCompleteRegistration',
                noUseTranslate: false
            },
            {
                label: 'msn.tracker.addToCar',
                value: 'cvAddToCar',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.addPaymentInfo',
                value: 'cvAddPaymentInfo',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.search',
                value: 'cvSearch',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.startCheckout',
                value: 'cvStartCheckout',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.purchase',
                value: 'cvPurchase',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.addToWishlist',
                value: 'cvAddToWishlist',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.lead',
                value: 'cvLead',
                noUseTranslate: false
            }, {
                label: 'msn.tracker.other',
                value: 'cvOther',
                noUseTranslate: false
            }
        ]
    },
    groups: {
        dimension: {
            min: 1
        },
        data: {
            min: 1
        },
        conversion: {
            min: 0
        }
    }
};
export const diagnosisValLabelMap = {
    'dimension#publisherName': 'msn.tracker.publisherName',
    'dimension#page': 'msn.tracker.page',
    'dimension#format': 'msn.tracker.format',
    'dimension#accountName': 'msn.tracker.accountName',
    'dimension#campaignName': 'msn.tracker.campaignName',
    'data#request': 'msn.tracker.request',
    'data#callback': 'msn.tracker.callback',
    'data#imp': 'msn.tracker.imp',
    'data#vimp': 'msn.tracker.vimp',
    'data#click': 'msn.tracker.click',
    'data#callbackRate': 'msn.tracker.callbackRate',
    'data#winRate': 'msn.tracker.winRate',
    'data#viewableRate': 'msn.tracker.viewableRate',
    'data#iCtr': 'msn.tracker.iCTR',
    'data#vCtr': 'msn.tracker.vCTR',
    'data#callbackRateRoc': 'msn.tracker.callbackRateROC',
    'data#winRateRoc': 'msn.tracker.winRateROC',
    'data#viewableRateRoc': 'msn.tracker.viewableRateROC',
    'data#vCtrRoc': 'msn.tracker.vCTRROC',
    'data#iCtrRoc': 'msn.tracker.iCTRROC',
    'data#revenue': 'msn.tracker.revenue',
    'data#cost': 'msn.tracker.cost',
    'data#roi': 'msn.tracker.rOI',
    'data#iCpm': 'msn.tracker.icpm',
    'data#iRpm': 'msn.tracker.iRpm',
    'data#revenueRoc': 'msn.tracker.revenueROC',
    'data#revenueDiff': 'msn.tracker.revenueDiff',
    'data#requestDiff': 'msn.tracker.requestDiff',
    'data#callbackDiff': 'msn.tracker.callbackDiff',
    'data#impDiff': 'msn.tracker.impDiff',
    'data#vimpDiff': 'msn.tracker.vimpDiff',
    'data#clickDiff': 'msn.tracker.clickDiff',
    'data#profit': 'msn.tracker.profit',
    'data#profitDiff': 'msn.tracker.profitDiff',
    'data#accountId': 'msn.tracker.accountID',
    'data#campaignId': 'msn.tracker.campaignID',
    'data#accountName': 'msn.tracker.accountNameData',
    // conversion
    'conversion#conversion': 'msn.tracker.conversion',
    'conversion#conversionDiff': 'msn.tracker.conversionDiff',
    'conversion#conversionRate': 'msn.tracker.conversionRate',
    'conversion#conversionRateRoc': 'msn.tracker.conversionRateROC',
    'conversion#costPerConversion': 'msn.tracker.costPerConversion',
    'conversion#costPerConversionDiff': 'msn.tracker.costPerConversionDiff',
    'conversion#cvViewContent': 'msn.tracker.viewContent',
    'conversion#cvAppInstall': 'msn.tracker.appInstall',
    'conversion#cvCompleteRegistration': 'msn.tracker.completeRegistration',
    'conversion#cvAddToCar': 'msn.tracker.addToCar',
    'conversion#cvAddPaymentInfo': 'msn.tracker.addPaymentInfo',
    'conversion#cvSearch': 'msn.tracker.search',
    'conversion#cvStartCheckout': 'msn.tracker.startCheckout',
    'conversion#cvPurchase': 'msn.tracker.purchase',
    'conversion#cvAddToWishlist': 'msn.tracker.addToWishlist',
    'conversion#cvLead': 'msn.tracker.lead',
    'conversion#cvOther': 'msn.tracker.other'
};
// bidAnalysis
export const bidAnalysisDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: ['detail#adId', 'detail#placementId'],
    cols: {
        detail: [
            {
                label: 'msn.bidAnalysis.adId',
                value: 'adId'
            },
            {
                label: 'msn.bidAnalysis.placementId',
                value: 'placementId'
            },
            {
                label: 'msn.bidAnalysis.avgRank',
                value: 'avgRank'
            },
            {
                label: 'msn.bidAnalysis.bidMode',
                value: 'bidMode'
            },
            {
                label: 'msn.bidAnalysis.picpm',
                value: 'picpm'
            },
            {
                label: 'msn.bidAnalysis.pvcpm',
                value: 'pvcpm'
            },
            {
                label: 'msn.bidAnalysis.pictr',
                value: 'pictr'
            },
            {
                label: 'msn.bidAnalysis.pvctr',
                value: 'pvctr'
            },
            {
                label: 'msn.bidAnalysis.pvwr',
                value: 'pvwr'
            },
            {
                label: 'msn.bidAnalysis.pvctrLevel',
                value: 'pvctrLevel'
            },
            {
                label: 'msn.bidAnalysis.pvwrLevel',
                value: 'pvwrLevel'
            },
            {
                label: 'msn.bidAnalysis.cpc',
                value: 'cpc'
            },
            {
                label: 'msn.bidAnalysis.title',
                value: 'title'
            },
            {
                label: 'msn.bidAnalysis.campaignId',
                value: 'campaignId'
            }
        ]
    }
};

export const bidAnalysisValueLabelMap = {
    'detail#adId': 'msn.bidAnalysis.adId',
    'detail#placementId': 'msn.bidAnalysis.placementId',
    'detail#avgRank': 'msn.bidAnalysis.avgRank',
    'detail#bidMode': 'msn.bidAnalysis.bidMode',
    'detail#picpm': 'msn.bidAnalysis.picpm',
    'detail#pvcpm': 'msn.bidAnalysis.pvcpm',
    'detail#pictr': 'msn.bidAnalysis.pictr',
    'detail#pvctr': 'msn.bidAnalysis.pvctr',
    'detail#pvwr': 'msn.bidAnalysis.pvwr',
    'detail#pvctrLevel': 'msn.bidAnalysis.pvctrLevel',
    'detail#pvwrLevel': 'msn.bidAnalysis.pvwrLevel',
    'detail#cpc': 'msn.bidAnalysis.cpc',
    'detail#title': 'msn.bidAnalysis.title',
    'detail#campaignId': 'msn.bidAnalysis.campaignId'
};
// media dashboard trend column


export const mediaTrendDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: [],
    min: 1,
    cols: {
        earning: [
            {
                label: 'media.dashboard.totalRevenue',
                value: 'totalRevenue'
            },
            // {
            //     label: 'media.dashboard.payin',
            //     value: 'payin'
            // },
            {
                label: 'media.dashboard.rpm',
                value: 'rpm'
            },
            {
                label: 'media.dashboard.vrpm',
                value: 'vrpm'
            }
        ],
        delivery: [
            {
                label: 'media.dashboard.matchedRequest',
                value: 'matchedRequest'
            },
            {
                label: 'media.dashboard.returnAds',
                value: 'returnAds'
            },
            {
                label: 'media.dashboard.rendersWithAds',
                value: 'rendersWithAds'
            },
            {
                label: 'media.dashboard.viewsWithAds',
                value: 'viewsWithAds'
            },
            {
                label: 'media.dashboard.clicks',
                value: 'clicks'
            },
            {
                label: 'media.dashboard.withAds',
                value: 'withAds'
            },
            {
                label: 'media.dashboard.winRate',
                value: 'winRate'
            },
            {
                label: 'media.dashboard.withVisibility',
                value: 'withVisibility'
            },
            {
                label: 'media.dashboard.vctr',
                value: 'vctr'
            },
            {
                label: 'media.dashboard.avgCPC',
                value: 'avgCPC'
            }
        ]
    }
};
// 修改定位字符串:bWVkaWFEYXNoYm9hcmREaW1lbnNpb24=
export const mediaTrendValueLabelMap = {
    // Dimension
    'dimension#date': 'media.dashboard.dimensionDate',
    'dimension#accountName': 'media.dashboard.dimensionAccount',
    'dimension#ssp': 'media.dashboard.dimensionSSP',
    'dimension#site': 'media.dashboard.dimensionSite',
    // earning
    'earning#rpm': 'media.dashboard.rpm',
    'earning#vrpm': 'media.dashboard.vrpm',
    'earning#totalRevenue': 'media.dashboard.totalRevenue',
    // 'earning#payin': 'media.dashboard.payin',
    // delivery
    'delivery#matchedRequest': 'media.dashboard.matchedRequest',
    'delivery#returnAds': 'media.dashboard.returnAds',
    'delivery#rendersWithAds': 'media.dashboard.rendersWithAds',
    'delivery#winRate': 'media.dashboard.winRate',
    'delivery#viewsWithAds': 'media.dashboard.viewsWithAds',
    'delivery#clicks': 'media.dashboard.clicks',
    'delivery#withAds': 'media.dashboard.withAds',
    'delivery#withVisibility': 'media.dashboard.withVisibility',
    'delivery#vctr': 'media.dashboard.vctr',
    'delivery#avgCPC': 'media.dashboard.avgCPC'
};

export const mediaDashboardDefaultCols = {
    // 当用户刚进来的时候，默认展示
    default: [],
    min: 1,
    cols: {
        // 修改定位字符串:bWVkaWFEYXNoYm9hcmREaW1lbnNpb24=
        dimension: [
            {
                label: 'media.dashboard.dimensionDate',
                value: 'date'
            },
            {
                label: 'media.dashboard.dimensionAccount',
                value: 'accountName'
            },
            {
                label: 'media.dashboard.dimensionSSP',
                value: 'ssp'
            },
            {
                label: 'media.dashboard.dimensionSite',
                value: 'site'
            }
        ],
        earning: [
            // {
            //     label: 'media.dashboard.accountName',
            //     value: 'accountName'
            // },
            {
                label: 'media.dashboard.totalRevenue',
                value: 'totalRevenue'
            },
            // {
            //     label: 'media.dashboard.payin',
            //     value: 'payin'
            // },
            {
                label: 'media.dashboard.rpm',
                value: 'rpm'
            },
            {
                label: 'media.dashboard.vrpm',
                value: 'vrpm'
            }
        ],
        delivery: [
            {
                label: 'media.dashboard.matchedRequest',
                value: 'matchedRequest'
            },
            {
                label: 'media.dashboard.returnAds',
                value: 'returnAds'
            },
            {
                label: 'media.dashboard.rendersWithAds',
                value: 'rendersWithAds'
            },
            {
                label: 'media.dashboard.viewsWithAds',
                value: 'viewsWithAds'
            },
            {
                label: 'media.dashboard.clicks',
                value: 'clicks'
            },
            {
                label: 'media.dashboard.withAds',
                value: 'withAds'
            },
            {
                label: 'media.dashboard.winRate',
                value: 'winRate'
            },
            {
                label: 'media.dashboard.withVisibility',
                value: 'withVisibility'
            },
            {
                label: 'media.dashboard.vctr',
                value: 'vctr'
            },
            {
                label: 'media.dashboard.avgCPC',
                value: 'avgCPC'
            }
        ]
    }
};

export const mediaDashboardValueLabelMap = {
    // 修改定位字符串:bWVkaWFEYXNoYm9hcmREaW1lbnNpb24=
    // Dimension
    'dimension#date': 'media.dashboard.dimensionDate',
    'dimension#accountName': 'media.dashboard.dimensionAccount',
    'dimension#ssp': 'media.dashboard.dimensionSSP',
    'dimension#site': 'media.dashboard.dimensionSite',
    // 'earning#accountName': 'media.dashboard.accountName',
    'earning#rpm': 'media.dashboard.rpm',
    'earning#vrpm': 'media.dashboard.vrpm',
    'earning#totalRevenue': 'media.dashboard.totalRevenue',
    // 'earning#payin': 'media.dashboard.payin',
    // delivery
    'delivery#matchedRequest': 'media.dashboard.matchedRequest',
    'delivery#returnAds': 'media.dashboard.returnAds',
    'delivery#rendersWithAds': 'media.dashboard.rendersWithAds',
    'delivery#winRate': 'media.dashboard.winRate',
    'delivery#viewsWithAds': 'media.dashboard.viewsWithAds',
    'delivery#clicks': 'media.dashboard.clicks',
    'delivery#withAds': 'media.dashboard.withAds',
    'delivery#withVisibility': 'media.dashboard.withVisibility',
    'delivery#vctr': 'media.dashboard.vctr',
    'delivery#avgCPC': 'media.dashboard.avgCPC'
};
