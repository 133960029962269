/*
 * @file media.js
 * @author liushengxgi
 * @date 2020-09-01 17:40:53
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-01 17:41:08
 */

import request from '@/common/request';
import api from '@/constants/api';

const getMenuPermissions = function () {
    return new Promise(resolve => {
        request({
            method: 'get',
            // timeout: 10000000,
            url: api.mediaGetMenu
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

// 获取ssp列表
const getSspList = function () {
    return new Promise(resolve => {
        request({
            method: 'get',
            url: api.mediaGetSspList
        }).then(res => {
            resolve(res);
        }, (err) => {
            console.log('err', err);
            resolve({data: []});
        }).catch((err) => {
            console.log(err);
            resolve({data: []});
        });
    });
};

const state = {
    menu: null,
    loginState: false,
    userName: '',
    email: '',
    userId: '',
    accountType: '',
    role: '',
    // 账户列表
    accountList: null,
    // no-permission 记录用户是否已经填写表单
    showFeedback: false,
    // 需要缓存的组件
    keepAliveComp: [],
    // 处理dashboard的缓存
    dashboardFilterData: null,
    useDashboardLastState: false,
    // 获取媒体数据
    mediaType: null,
    // 版本信息
    version: '',
    // ssp List
    sspList: []
};

const mutations = {
    SET_MENU(state, menu) {
        state.menu = menu;
    },
    SET_EMAIL(state, email) {
        state.email = email;
    },
    SET_USER_NAME(state, name) {
        state.userName = name;
    },
    SET_USER_ID(state, id) {
        state.userId = id;
    },
    SET_ACCOUNT_TYPE(state, accountType) {
        state.accountType = accountType;
    },
    SET_ROLE(state, role) {
        state.role = role;
    },
    SET_ACCOUNT_LIST(state, accountList) {
        state.accountList = accountList;
    },
    SET_SHOW_FEED_BACK(state, showFeedback) {
        state.showFeedback = showFeedback;
    },
    SET_KEEP_ALIVE(state, component) {
        let keepAliveComp = state.keepAliveComp.concat(component);
        state.keepAliveComp = [...new Set(keepAliveComp)];
    },
    SET_NO_KEEP_ALIVE(state, notKeepAliveComp) {
        let keepAliveComp = state.keepAliveComp;
        let index = keepAliveComp.indexOf(notKeepAliveComp);
        if (index > -1) {
            state.keepAliveComp.splice(index, 1);
        }
    },
    /**
     * 设置dashboard 过滤条件信息
     * @param {*} state
     * @param {Object} dashboardFilterData 由于数据比较分散，需要分开进行更新
     */
    SET_DASHBOARD_FILTER_DATA(state, dashboardFilterData) {
        if (!state.dashboardFilterData) {
            state.dashboardFilterData = {};
        }
        Object.keys(dashboardFilterData || {}).forEach(key => {
            let value = dashboardFilterData[key];
            if (typeof value === 'object') {
                value = JSON.parse(JSON.stringify(dashboardFilterData[key]));
            }
            state.dashboardFilterData[key] = value;
        });
    },
    // 是否使用上次dashboard状态
    SET_USE_DASHBOARD_LAST_STATE(state, useDashboardLastState) {
        state.useDashboardLastState = useDashboardLastState;
    },
    // 获取媒体类型
    SET_MEDIA_TYPE(state, mediaType) {
        state.mediaType = mediaType;
    },
    SET_VERSION(state, version) {
        state.version = version;
    },
    SET_LOGIN_STATE(state, loginState) {
        state.loginState = loginState;
    },
    SET_SSP_LIST(state, sspList) {
        state.sspList = sspList;
    }
};

const getters = {
    getMenu: (state, getters, rootState) => {
        return state.menu;
    },
    getEmail: (state, getters, rootState) => {
        return state.email;
    },
    getUserName: (state, getters, rootState) => {
        return state.userName;
    },
    getUserId: (state, getters, rootState) => {
        return state.userId;
    },
    getAccountType: (state, getters, rootState) => {
        return state.accountType;
    },
    getRole: (state, getters, rootState) => {
        return state.role;
    },
    getAccountList: (state, getters, rootState) => {
        return state.accountList;
    },
    getAccountTotalBalance: (state, getters, rootState) => {
        let totalMoney = 0;
        state.accountList.forEach(item => {
            totalMoney += isNaN(parseFloat(item.balance)) ? 0 : parseFloat(item.balance);
        });
        return totalMoney;
    },
    getShowFeedback: (state, getters, rootState) => {
        return state.showFeedback;
    },
    getKeepAliveComp(state) {
        return state.keepAliveComp;
    },
    getDashboardFilterData(state) {
        return state.dashboardFilterData;
    },
    getUseDashboardLastState(state) {
        return state.useDashboardLastState;
    },
    getMediaType(state) {
        return state.mediaType;
    },
    getVersion(state) {
        return state.version;
    },
    getLoginState(state) {
        return state.login;
    },
    getSspList(state) {
        return state.sspList;
    }
};

const actions = {
    setMenu: async ({state, commit, dispatch}, payload) => {
        if (state.menu && !payload) {
            return;
        }
        const {data} = await getMenuPermissions();
        // help center 默认只读权限
        // let menu = {
        //     ...data.menus
        // };
        let menu = data.menus || {};
        // 开发使用
        // menu = {
        //     ...menu,
        //     '/media/dashboard': 'rw',
        //     '/media/editorial/domain': 'rw',
        //     '/media/editorial/domain:add': 'rw',
        //     '/media/editorial/domain:del': 'rw',
        //     '/media/editorial': 'rw',
        //     '/media/authorize': 'rw',
        //     '/media/authorize/account': 'rw',
        //     '/media/authorize/account:add': 'rw',
        //     '/media/authorize/account:status': 'rw',
        //     '/media/authorize/account:edit': 'rw',
        //     '/media/authorize/user': 'rw',
        //     '/media/authorize/user:status': 'rw',
        //     '/media/authorize/user:add': 'rw',
        //     '/media/authorize/user:edit': 'rw',
        //     '/media/authorize/role': 'rw',
        //     '/media/authorize/role:add': 'rw',
        //     '/media/authorize/role:edit': 'rw',
        //     '/media/management': 'rw',
        //     '/media/management/audience': 'rw',
        //     '/media/management/audience:add': 'rw',
        //     '/media/management/audience:edit': 'rw',
        //     '/media/management/sensitiveTag': 'rw',
        //     '/media/management/sensitiveTag:add': 'rw',
        //     '/media/management/sensitiveTag:edit': 'rw',
        //     '/media/management/sensitiveCfg': 'rw',
        //     '/media/management/sensitiveCfg:add': 'rw',
        //     '/media/management/sensitiveCfg:edit': 'rw'
        // };
        commit('SET_MENU', menu);
        dispatch('setEmail', data.email || '');
        dispatch('setUserName', data.userName || '');
        dispatch('setAccountType', data.accountType || '');
        dispatch('setRole', data.role || '');
        dispatch('setUserId', data.userId || '');
        dispatch('setAccountList', data.accountList || []);
        dispatch('setVersion', data.version || '');
        const loginState = Boolean(data.accountType);
        dispatch('setLoginState', loginState || false);
    },
    setEmail: ({commit}, email) => {
        commit('SET_EMAIL', email);
    },
    setUserName: ({commit}, name) => {
        commit('SET_USER_NAME', name);
    },
    setAccountType: ({commit}, accountType) => {
        commit('SET_ACCOUNT_TYPE', accountType);
    },
    setRole: ({commit}, role) => {
        commit('SET_ROLE', role);
    },
    setUserId: ({commit}, userId) => {
        commit('SET_USER_ID', userId);
    },
    setAccountList: ({commit}, accountList) => {
        commit('SET_ACCOUNT_LIST', accountList);
    },
    setShowFeedback: ({commit}, showFeedback) => {
        commit('SET_SHOW_FEED_BACK', showFeedback);
    },
    setKeepAlive({commit}, keepAliveComp) {
        commit('SET_KEEP_ALIVE', keepAliveComp);
    },
    setNoKeepAlive({commit}, notKeepAliveComp) {
        commit('SET_NO_KEEP_ALIVE', notKeepAliveComp);
    },
    setDashboardFilterData({commit}, dashboardFilterData) {
        commit('SET_DASHBOARD_FILTER_DATA', dashboardFilterData);
    },
    setUseDashboardLastState({commit}, useDashboardLastState) {
        commit('SET_USE_DASHBOARD_LAST_STATE', useDashboardLastState);
    },
    setMediaType({commit}, mediaType) {
        commit('SET_MEDIA_TYPE', mediaType);
    },
    setVersion({commit}, version) {
        commit('SET_VERSION', version);
    },
    setBmList({commit}, bmList) {
        commit('SET_BM_LIST', bmList);
    },
    setLoginState({commit}, loginState) {
        commit('SET_LOGIN_STATE', loginState);
    },
    setSspList: async ({commit}) => {
        const res = await getSspList();
        commit('SET_SSP_LIST', res.data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
