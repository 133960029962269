<template>
    <el-date-picker
        v-model="date"
        v-bind="$attrs"
        :align="align"
        class="msn-date"
        :type="type"
        :placeholder="placeholder ? placeholder : $t('msn.dashboard.selectDate')"
        :format="format"
        :picker-options="pickList"
        :use-translate="useTranslate"
        :disabled="disabled"
        :clearable="clearable"
        :value-format="valueFormat"
        :popper-class="popperClass"
        :start-placeholder="$t('msn.tracker.startDate')"
        :end-placeholder="$t('msn.tracker.endDate')"
        @change="() => {
            $emit('change')
        }"
    />
</template>

<script>
export default {
    name: 'MsnDatePicker',
    props: {
        valueFormat: {
            default: '',
            type: String
        },
        align: {
            default: 'left'
        },
        type: {
            type: String,
            default: 'date'
        },
        value: {
            default: null
        },
        format: {
            default: 'dd/MM/yyyy HH:mm:ss'
        },
        pickOptions: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            default: false
        },
        clearable: {
            default: true
        },
        popperClass: {
            default: 'msn-date-popper'
        },
        // startPlaceholder: {
        //     default: 'Start Date'
        // },
        // endPlaceholder: {
        //     default: 'End Date'
        // },
        placeholder: {
            default: ''
        },
        useTranslate: {
            type: Boolean,
            dafault: false
        }
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        pickList() {
            const pickOptions = this.pickOptions;
            const shortcuts = pickOptions.shortcuts || [];
            if (pickOptions.shortcuts){
                const res =  {
                ...pickOptions,
                shortcuts: shortcuts.map(item => {
                    return {
                        text: this.useTranslate ? this.$t(item.text) : item.text,
                        onClick: item.onClick
                    };
                })
                };
                return res;
            }
            return pickOptions;
        }
    }
};
</script>

<style lang="scss">
.el-picker-panel__shortcut:hover{
    color:#2ddEAC;
}
.msn-date.el-date-editor {
    border-radius: 17.5px;
    height: 35px;
    line-height: 35px;
    .el-input__inner {
        height: 35px;
        border-radius: 17.5px;
        background: #EEF1F4;
        font-size: 12px;
        color: #53575B;
        font-weight: 500;
        border: 1px solid transparent;
        &::placeholder {
            font-weight: 400;
            color: #53575B;
            opacity: 0.3;
        }
        &:hover {
            border-color: #2DD1AC;
        }
        &:focus {
            border-color: #2DD1AC;
        }
    }
    .el-range-separator {
        line-height: 27px;
    }

    .el-range-input {
        height: 27px;
        font-size: 12px;
        color: #53575B;
        letter-spacing: 0;
        font-weight: 500;
    }
    .el-input__icon {
        line-height: 27px;
    }
    .el-icon-time {
        line-height: 37px;
    }
    .el-icon-date {
        display: none;
    }
    &.msn-is-gray {
        background: #EEF1F4;
        .el-range-input {
            background-color: #EEF1F4;
        }
        box-shadow: none!important;
    }
    &.msn-is-light {
        .el-range-input,
        .el-input__inner {
            background-color: #fff!important;
        }
    }
}
// 处理日期范围的样式
.msn-date.el-date-editor--daterange {
    .el-range-input {
        width: 45%!important;
    }
    .el-input__icon {
        width: 10px!important;
    }
}
.msn-date.el-range-editor {
    border-color: transparent;
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, .16);

    &:hover {
        border-color: #2DD1AC!important;
    }
}
.msn-date.el-range-editor.is-active {
    border-color: #2DD1AC!important;
    color: #fff!important;
}
.el-form-item.is-error .msn-date.el-date-editor .el-input__inner {
    background-color: rgba(238, 241, 244, .5);
    border: 1px solid #E92754;
}
.msn-date-popper .el-date-table td.start-date span, .el-date-table td.end-date span {
    background-color: #2DD1AC!important;
    color: #fff!important;
}
.msn-date-popper .el-date-table td.today span {
    color: #2DD1AC;
}
.msn-date-popper .el-date-table td.current:not(.disabled) span {
    background-color: #2DD1AC!important;
    color: #fff!important;
}
.msn-date-popper .el-button--text {
    color: #2DD1AC!important;
}
.msn-date-popper.btn-class .el-button.el-button--default{
    // width: 80px;
    // height: 35px;
    width: 45px;
    height: 25px;
    text-align: center;
    border-radius: 25px;
    font-size: 12px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    margin-right: 12px;
    font-weight: 600;
    color: #fff!important;
    background: #2DD1AC!important;
    // box-shadow: -3px 2px 2px 0 rgba(119,151,178,.16)!important;
    box-shadow: none!important;
}
.msn-date-popper .el-button.el-button--default {
    background-color: #fff!important;
    color: #53575B!important;
    border: none!important;
    box-shadow: none !important;
}
.msn-date-popper .el-date-table td.available:hover {
    color: #2DD1AC!important;
}
.msn-date-popper {
    .el-input__inner {
        height: 35px;
        border-radius: 17.5px;
        background: #EEF1F4;
        font-size: 12px;
        color: #53575B;
        font-weight: 500;
        border: 1px solid transparent;
        &::placeholder {
            font-weight: 400;
            color: #53575B;
            opacity: 0.3;
        }
        &:hover {
            border-color: #2DD1AC;
        }
        &:focus {
            border-color: #2DD1AC;
        }
    }
    &.has-time {
        .el-button--text {
            display: none!important;
        }

    }
}
</style>
