<template>
    <div class="bg" :class="{bg__hide: !visible}">
        <div class="box">
            <div class="box--left">
                <div class="box--left--item" :style="!isDiscovery ? '' : 'display: none'">
                    <img class="box--left--item--image" :src="checkStatusList[0].status ? ydPng : wdPng">
                    <span
                        class="box--left--item--text"
                        :class="{'box--left--item--text__check': checkStatusList[0].status}"
                    >
                        Online Promotion Service Agreement
                    </span>
                </div>
                <div class="box--left--item">
                    <img class="box--left--item--image" :src="checkStatusList[1].status ? ydPng : wdPng">
                    <span
                        class="box--left--item--text"
                        :class="{'box--left--item--text__check': checkStatusList[1].status}"
                    >
                        Privacy Policy
                    </span>
                </div>
            </div>
            <div class="box--right">
                <div ref="content" class="box--right--content">
                    <div>
                        <iframe
                            width="100%" :height="iframeH2 + 'px'"
                            scrolling="no" :src="iframeSrc2"
                            frameborder="0" :style="!isDiscovery ? '' : 'display: none'"
                        ></iframe>
                        <div style="height: 20px"></div>
                        <iframe
                            v-if="!isDiscovery"
                            width="100%" :height="iframeH1 + 'px'"
                            scrolling="no" :src="iframeSrc1"
                            frameborder="0"
                        ></iframe>
                        <iframe
                            v-else
                            width="100%" :height="iframeH3 + 'px'"
                            scrolling="no" :src="iframeSrc3"
                            frameborder="0"
                        ></iframe>
                    </div>
                </div>
                <div style="height: 65px"></div>
                <div class="box--right--btn-box">
                    <msn-button
                        type="transparent" style="margin-right: 20px"
                        @click="handleCanle"
                    >
                        {{ $t('message.button.cancel') }}
                    </msn-button>
                    <msn-button
                        type="blue" :disabled="disabled"
                        @click="handlConfirm"
                    >
                        Agree and Submit
                    </msn-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MsnButton from '@/components/MsnButton';
import ydPng from './image/yd.png';
import wdPng from './image/wd.png';
import { mapGetters } from 'vuex';
import { throttle } from '@/common/util.js';
export default {
    name: 'ProtocolPreview',
    components: {
        MsnButton
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checkStatusList: [{ status: false }, { status: false }],
            ydPng,
            wdPng,
            iframeSrc1: 'https://www.mediago.io/privacy',
            iframeSrc2: 'https://www.mediago.io/terms',
            iframeSrc3: 'https://www.mediago.io/discovery',
            /* iframeSrc1: 'http://localhost:8888/privacy.html',
            iframeSrc2: 'http://localhost:8888/terms.html', */
            iframeH1: 0,
            iframeH2: 0,
            iframeH3: 0
            // showDiscoveryTerms: true
        };
    },
    computed: {
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        }),
        disabled() {
            return !this.checkStatusList[0].status || !this.checkStatusList[1].status;
        }
    },
    methods: {
        handleCanle() {
            this.$emit('close');
        },
        handlConfirm() {
            this.$emit('submit');
            this.$emit('close');
        }
        // handleDiscoveryTerms() {
        //     // console.log(location.href);
        //     // 测试
        //     if(location.href.search('localhost') || location.href.search('amazonaws') || location.href.search('popin')){
        //         this.showDiscoveryTerms = false;
        //     } else {
        //         this.showDiscoveryTerms = true;

        //     }
        //     // console.log(this.showDiscoveryTerms);
        //     console.log(this.isDiscovery);
        // }
    },
    mounted() {
        // 监听滚动
        const dd = document.querySelector('.box--right--content');
        const ls = throttle(() => {
            let height = dd.scrollHeight;
            let top = dd.scrollTop;
            if (top + 535 + 10 >= this.iframeH2 && !this.checkStatusList[0].status) {
                this.checkStatusList[0].status = true;
            }
            if (top + 535 + 10 >= height && !this.checkStatusList[1].status) {
                this.checkStatusList[1].status = true;
                dd.removeEventListener('scroll', ls);
            }
        }, 200);
        window.addEventListener('message', (e) => {
            console.log(e.data.name, '123123');
            if (e.data.name === 'privacy') {
                this.iframeH1 = e.data.height;
                // console.log(e.data.height, this.iframeH1, '1111');
            } else {
                this.iframeH3 = 10830;
                dd.addEventListener('scroll', ls);
            }
            if (e.data.name === 'terms') {
                this.iframeH2 = e.data.height;
                dd.addEventListener('scroll', ls);
            }
        });
        // this.handleDiscoveryTerms();
    }
};
</script>

<style lang="scss" scoped>
.bg {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    &__hide {
        left: -10000px;
        top: -10000px;
    }
}
.box {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &--left {
        background: #ffffff;
        margin-right: 10px;
        width: 190px;
        border-radius: 6px;
        padding: 30px 10px;
        box-sizing: border-box;
        &--item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &--image {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            &--text {
                font-size: 14px;
                color: #a9abad;
                &__check {
                    color: #000000;
                }
            }
        }
    }
    &--right {
        background: #ffffff;
        border-radius: 6px;
        width: 665px;
        position: relative;
        &--content {
            height: 535px;
            overflow-y: scroll;
        }
        &--btn-box {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            height: 65px;
            background: #ffffff;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 30px;
            box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.16);
        }
    }
}
</style>
