import filter from '@/common/filters';
const {numberFormat} = filter;

export const balanceMap = {
    1: {
        icon: '',
        tip: ''
    },
    2: {
        icon: 'msn-fa-danger',
        tip: 'msn.dashboard.totalBalanceTip1'
    },
    3: {
        icon: 'msn-fa-danger',
        tip: 'msn.dashboard.totalBalanceTip2'
    },
    4: {
        icon: 'msn-fa-limit',
        tip: 'msn.dashboard.totalBalanceTip1'
    }
};

export const headers = function() {
    // const self = this;
    return [
        {
            field: 'accountName',
            title: 'msn.dashboard.accountName',
            minWidth: '180px'
        },
        {
            field: 'spend',
            title: 'msn.dashboard.adList.spend',
            sortable: 'custom',
            number: true,
            format: (row) => {
                return filter.getCurrencyDataFormat(row.currency, filter.numberFormat(+row.spend));
            },
            minWidth: '81px'
        },
        {
            field: 'avgCpc',
            title: 'msn.dashboard.colAvgCpc',
            sortable: 'custom',
            minWidth: '96px',
            format: (row) => {
                return filter.getCurrencyDataFormat(row.currency, filter.numberFormat(+row.avgCpc, 3));
            }
        },
        {
            field: 'impression',
            title: 'msn.dashboard.adList.impression',
            sortable: 'custom',
            number: true,
            minWidth: '112px',
            format: (row) => {
                return filter.numberFormat(+row.impression);
            }
        },
        {
            field: 'click',
            title: 'msn.dashboard.click',
            sortable: 'custom',
            minWidth: '77px',
            format: (row) => {
                return filter.numberFormat(+row.click);
            }
        },
        {
            field: 'ctr',
            title: 'msn.dashboard.adList.ctr',
            format: (row) => {
                return numberFormat((row.ctr && (+row.ctr * 100)) || 0) + '%';
            },
            sortable: 'custom',
            number: true,
            minWidth: '80px'
        },
        {
            field: 'accountId',
            title: 'msn.dashboard.accountId',
            sortable: 'custom',
            disabled: () => this.visible,
            minWidth: '105px'
        },
        {
            field: 'conversion',
            title: 'msn.dashboard.newColConversion',
            sortable: 'custom',
            number: true,
            minWidth: '70px',
            format: (row) => {
                return filter.numberFormat(+row.conversion);
            }
        }
    ];
};