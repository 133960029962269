/*
 * @file state-en.js
 * @author liushengxgi
 * @date 2020-02-10 11:08:34
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-10 11:38:27
 */

export default {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New hampshire',
    NJ: 'New jersey',
    NM: 'New mexico',
    NY: 'New york',
    NC: 'North carolina',
    ND: 'North dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode island',
    SC: 'South carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WAI: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    OT: 'Other',
    DC: 'Other',
    // 加拿大
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    YT: 'Yukon',
    NSW: 'New South Wales',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia',
    ACT: 'Australian Capital Territory',
    NT01: 'Northern Territory',
    // 英国
    ENG: 'England',
    NIR: 'Northern Ireland',
    SCT: 'Scotland',
    WLS: 'Wales',
    // 日本
    JP13: 'Tokyo',
    JP01: 'Hokkaido',
    JP26: 'Kyoto',
    JP27: 'Osaka',
    JP02: 'Aomori',
    JP03: 'Iwate',
    JP04: 'Miyagi',
    JP05: 'Akita',
    JP06: 'Yamagata',
    JP07: 'Fukushima',
    JP08: 'Ibaraki',
    JP09: 'Tochigi',
    JP10: 'Gunma',
    JP11: 'Saitama',
    JP12: 'Chiba',
    JP14: 'Kanagawa',
    JP15: 'Niigata',
    JP16: 'Toyama',
    JP17: 'Ishikawa',
    JP18: 'Fukui',
    JP19: 'Yamanashi',
    JP20: 'Nagano',
    JP21: 'Gifu',
    JP22: 'Shizuoka',
    JP23: 'Aichi',
    JP24: 'Mie',
    JP25: 'Shiga',
    JP28: 'Hyōgo',
    JP29: 'Nara',
    JP30: 'Wakayama',
    JP31: 'Tottori',
    JP32: 'Shimane',
    JP33: 'Okayama',
    JP34: 'Hiroshima',
    JP35: 'Yamaguchi',
    JP36: 'Tokushima',
    JP37: 'Kagawa',
    JP38: 'Ehime',
    JP39: 'Kōchi',
    JP40: 'Fukuoka',
    JP41: 'Saga',
    JP42: 'Nagasaki',
    JP43: 'Kumamoto',
    JP44: 'Ōita',
    JP45: 'Miyazaki',
    JP46: 'Kagoshima',
    JP47: 'Okinawa',
    // 韩国
    KR11: 'Seoul',
    KR26: 'Busan',
    KR27: 'Daegu',
    KR28: 'Incheon',
    KR29: 'Gwangju',
    KR30: 'Daejeon',
    KR31: 'Ulsan',
    KR50: 'Sejong Special Self-Governing City',
    KR41: 'Gyeonggi-do',
    KR42: 'Gangwon-do',
    KR43: 'Chungcheongbuk-do',
    KR44: 'Chungcheongnam-do',
    KR45: 'Jeollabuk-do',
    KR46: 'Jeollanam-do',
    KR47: 'Gyeongsangbuk-do',
    KR48: 'Gyeongsangnam-do',
    KR49: 'Jeju Special Self-Governing Province',
    // 台湾
    NWT: 'Taipei',
    TPE: 'New Taipei',
    TAO: 'Taoyuan',
    TXG: 'Taichung',
    TNN: 'Tainan',
    KHH: 'Kaohsiung',
    ILA: 'Yilan',
    HSQ: 'Hsinchu',
    MIA: 'Miaoli',
    CHA: 'Changhua',
    NAN: 'Nantou',
    YUN: 'Yunlin',
    CYQ: 'Chiayi',
    PIF: 'Pingtung',
    TTT: 'Taitung',
    HUA: 'Hualien',
    PEN: 'Penghu',
    KEE: 'Keelung',
    // 印尼
    IDAC: 'Aceh',
    IDBA: 'Bali',
    IDBB: 'Bangka Belitung Islands',
    IDBT: 'Banten',
    IDBE: 'Bengkulu',
    IDGO: 'Central Java',
    IDJA: 'Central Kalimantan',
    IDJB: 'Central Sulawesi',
    IDJT: 'East Kalimantan',
    IDJI: 'East Kalimantan',
    IDKB: 'East Nusa Tenggara',
    IDKS: 'Gorontalo',
    IDKT: 'Special Capital Region of Jakarta',
    IDKI: 'Jambi',
    IDKU: 'Lampung',
    IDKR: 'North Kalimantan',
    IDLA: 'North Kalimantan',
    IDMA: '北North Kalimantan',
    IDMU: 'North Sulawesi',
    IDNB: 'North Sumatra',
    IDNT: 'Papua',
    IDPA: 'Riau',
    IDPB: 'Riau Islands',
    IDRI: 'Southeast Sulawesi',
    IDSR: 'South Kalimantan',
    IDSN: 'South Sulawesi',
    IDST: 'South Sumatra',
    IDSG: 'West Java',
    IDSA: 'West Kalimantan',
    IDSB: 'West Nusa Tenggara',
    IDSS: 'West Papua',
    IDSU: 'West Sulawesi',
    IDJK: 'West Sumatra',
    IDYO: 'Special Region of Yogyakarta'
};