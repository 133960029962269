/*
 * @file beforeRouter.js 用于拦截路由
 * @author liushengxgi
 * @date 2020-09-02 11:16:35
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-02 11:16:38
 */

import store from '@/store/';
import tool from '../modules/msn/common/tool';
import utils from '../common/utils';
import PATH from '@/constants/path';
import {HELP_CENTER} from '@/constants/helpCenterPath';
let ONLY_HELP_CENTER_PATHS = {
    [PATH['helpCenter']]: 'r',
    ...HELP_CENTER
};
// 需要将根路径删除进行比较
if (ONLY_HELP_CENTER_PATHS['/']) {
    delete ONLY_HELP_CENTER_PATHS['/'];
}

// 配置缓存dashboard列表
const cacheDashboardList = [PATH['dashboard'], PATH['adList']];
const setKeepAliveList = curPath => {
    if (cacheDashboardList.includes(curPath)) {
        /**
         * 'Dashboard': Component Name
         */
        store.dispatch('msn/setKeepAlive', 'Dashboard');
    } else {
        store.dispatch('msn/setNoKeepAlive', 'Dashboard');
    }
};

// 处理platform的路由跳转
export const platformBeforeRouter = async (rawPath, next) => {
    // 去除路径最后的'/'
    let toPath = rawPath ? (rawPath.endsWith('/') ? rawPath.slice(0, -1) : rawPath) : '';
    let curPath = toPath;
    const noValidatePath = {
        [PATH['login']]: 'rw',
        [PATH['modify']]: 'rw',
        [PATH['forgetPassword']]: 'rw',
        [PATH['register']]: 'rw',
        [PATH['openAccount']]: 'rw',
        [PATH['inviteOpenUser']]: 'rw',
        [PATH['inviteOpenUserAndAccount']]: 'rw'
    };
    if (!noValidatePath[curPath]) {
        await store.dispatch('msn/setMenu');
        await store.dispatch('msn/getUserLastOperationalInfo');
        let permission = store.state.msn.menu;
        let permissionKey = Object.keys(permission || {});
        // 需要将根路径删除进行比较
        let spliceRootKey = JSON.parse(JSON.stringify(permission));
        if (spliceRootKey['/']) {
            delete spliceRootKey['/'];
        }
        let isLogin = store.state.msn.loginState;
        // 判断权限问题
        if (permissionKey.length > 0) {
            if (!tool.includePermission(curPath, 'msn')) {
                // 服务端不好排序，前端进行处理，默认跳转到dashboard
                // if (permission[PATH['dashboard']]) {
                //     setKeepAliveList(curPath);
                //     next({path: PATH['dashboard']});
                // } else
                if (permission[PATH['new_dashboard']] || permission[PATH['dashboard']]) {
                    setKeepAliveList(curPath);
                    next({path: PATH['new_dashboard']});
                } else if (isLogin && JSON.stringify(spliceRootKey) === JSON.stringify(ONLY_HELP_CENTER_PATHS)) {
                    // 当用户登录后，并且权限也只有help center 相关的页面时，跳转到申请页面
                    next({path: PATH['register']});
                } else {
                    // 默认展示到第一个有权限的页面，不包括help center
                    let pathKeys = permissionKey.filter(p => {
                        return !p.includes(':');
                    });
                    let firstPath = pathKeys.length > 0 ? pathKeys[0] : '';
                    curPath = utils.getSubPath(permission, firstPath);
                    if (curPath && curPath !== toPath) {
                        setKeepAliveList(curPath);
                        next({path: curPath});
                    }
                }
            } else {
                const isDashboardUri =  toPath === '/platform/dashboard';
                // 若输入的是菜单层级，默认跳转到第一个找到的子菜单
                // discovery平台使用new dashboard,需要将old dashboard 重定向至 new dashboard
                curPath = !isDashboardUri ? utils.getSubPath(permission, toPath) : '/platform/new_dashboard';
                if (curPath !== toPath) {
                    setKeepAliveList(curPath);
                    next({path: curPath});
                }
            }
        } else if (curPath !== PATH['apply']) {
            next({path: PATH['apply']});
        }
    }
    setKeepAliveList(curPath);
    next();
};

// 处理media的路由跳转
export const mediaBeforeRouter = async (rawPath, next) => {
    // 去除路径最后的'/'
    let toPath = rawPath ? (rawPath.endsWith('/') ? rawPath.slice(0, -1) : rawPath) : '';
    let curPath = toPath;
    const noValidatePath = {
        [PATH['mediaLogin']]: 'rw'
    };
    if (!noValidatePath[curPath]) {
        await store.dispatch('media/setMenu');
        let permission = store.state.media.menu;
        let permissionKey = Object.keys(permission || {});
        // 判断权限问题
        if (permissionKey.length > 0) {
            if (!tool.includePermission(curPath, 'media')) {
                // 服务端不好排序，前端进行处理，默认跳转到dashboard
                if (permission[PATH['mediaDashboard']]) {
                    next({path: PATH['mediaDashboard']});
                } else {
                    // 默认展示到第一个有权限的页面，不包括help center
                    let pathKeys = permissionKey.filter(p => {
                        return !p.includes(':');
                    });
                    let firstPath = pathKeys.length > 0 ? pathKeys[0] : '';
                    curPath = utils.getSubPath(permission, firstPath);
                    if (curPath && curPath !== toPath) {
                        next({path: curPath});
                    }
                }
            } else {
                // 若输入的是菜单层级，默认跳转到第一个找到的子菜单
                curPath = utils.getSubPath(permission, toPath);
                if (curPath !== toPath) {
                    next({path: curPath});
                }
            }
        }
    }
    setKeepAliveList(curPath);
    next();
};
