<template>
    <el-input
        ref="elInput"
        v-model="inputVal"
        :type="type"
        :rows="rows"
        :autosize="autosize"
        :show-word-limit="showWordLimit"
        :maxlength="maxlength"
        :minlength="minlength"
        :placeholder="placeholder"
        :class="inputClass"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="clearable"
        :wrap="wrap"
        @change="($event) => $emit('change', $event)"
        @focus="($event) => $emit('focus', $event)"
        @blur="($event) => $emit('blur', $event)"
    >
        <span slot="prefix" class="fix prefix">
            <slot name="prefix"></slot>
        </span>
        <span slot="suffix" class="fix suffix">
            <slot name="suffix"></slot>
        </span>
    </el-input>
</template>

<script>
export default {
    name: 'MsnNoramlInput',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            default: 'text'
        },
        wrap: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        autosize: {
            default: null
        },
        disabled: {
            default: false
        },
        readonly: {
            default: false
        },
        rows: {
            type: Number,
            default: 2
        },
        showWordLimit: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: undefined
        },
        minlength: {
            type: Number,
            default: undefined
        },
        clearable: {
            type: Boolean,
            default: false
        },
        prefixLength: {
            default: 2,
            type: Number
        }
    },
    data() {
        return {};
    },
    computed: {
        inputClass() {
            const classList = ['', '', 'middle', 'max'];
            const className = this.disabled ? 'msn-input disabled' : 'msn-input';
            return className + ' ' + (classList[this.prefixLength - 1] || '');
        },
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>
<style lang="scss">
.msn-input.el-input {
    .prefix {
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        left: 20px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
    }
    .suffix {
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        left: 20px;
        position: absolute;
        top: 50%;
        right: 15px;
        left: auto;
        transform: translateY(-50%);
    }
    .el-input__inner {
        height: 35px;
        background-color: #EEF1F4;
        border-radius: 17.5px;
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid transparent;
        padding-left: 15px;

        &:hover {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
        }
        &:focus {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
            font-weight: 400;
        }
        &:focus + .el-input__prefix .prefix {
            font-weight: 400;
        }
    }
    &.msn-input-normal {
        .el-input__inner {
            border-radius: 6px;
        }
    }
}
.msn-input.msn-input--prefix {
    .el-input__inner {
        padding-left: 33px;
    }
    &.middle .el-input__inner {
        padding-left: 40px; // 考虑货币符号有两个字符
    }
    &.middle .fix.prefix {
        min-width: 22px;
    }
    &.max .el-input__inner {
        padding-left: 50px; // 考虑货币符号有三个字符
    }
    &.max .fix.prefix {
        min-width: 30px;
    }
}
.msn-input.el-textarea {
    .el-textarea__inner {
        min-height: 35px;
        background-color: #EEF1F4;
        border-radius: 25px;
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid transparent;
        resize: none;

        &:hover {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
        }
        &:focus {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
            font-weight: 400;
        }
        &:focus + .el-input__prefix .prefix {
            font-weight: 400;
        }
    }
    &.msn-input-normal {
        .el-textarea__inner {
            border-radius: 6px;
        }
    }
}
.msn-input.disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.msn-is-light {
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, .16);
    border-radius: 17.5px;
    .el-input__inner {
        background-color: #fff!important;
    }
}

.el-form-item.is-error .msn-input.el-input .el-input__inner {
    border: 1px solid #E92754;
}
</style>
