<template>
    <draggable
        v-model="dragData" class="drag-box"
        :move="onMoveCallback"
    >
        <transition-group>
            <template v-if="type !== 'dashboardAd'">
                <div
                    v-for="item in dragData"
                    :key="item"
                    class="drag-item"
                    :class="tempCols && tempCols.indexOf(item) > -1 ? 'disabled' : ''"
                    :label="item"
                >
                    <span class="item-title">{{ getLabelName(item) }}</span>
                    <i
                        v-if="tempCols && tempCols.indexOf(item) === -1"
                        class="el-icon-close close-icon"
                        @click="clear(item)"
                    ></i>
                </div>
            </template>
            <template v-else>
                <div
                    v-for="item in dragData"
                    :key="item"
                    class="drag-item"
                    :class="tempCols && tempCols.indexOf(item) > -1 ? 'disabled' : ''"
                    :label="item"
                    :style="item === 'detail#size' && adListColSizeStyle"
                >
                    <span class="item-title">{{ getLabelName(item) }}</span>
                    <i
                        v-if="tempCols && tempCols.indexOf(item) === -1"
                        class="el-icon-close close-icon"
                        @click="clear(item)"
                    ></i>
                </div>
            </template>
        </transition-group>
    </draggable>
</template>
<script>
import draggable from 'vuedraggable';
// import utils from '@/common/utils';
// import {valueLabelMap} from './constants';
import PATH from '@/constants/path';
import mixin from '@/modules/msn/components/PlatformColumn/adListColSize.js';
import { mapGetters } from 'vuex';
export default {
    name: 'Draggable',
    mixins: [mixin],
    props: {
        value: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Object | Array,
            default: null
        },
        defaultCols: {
            default: () => []
        },
        type: {
            default: 'dashboard'
        },
        labelAndValueMap: {
            default: () => ({})
        },
        beforeDelete: {
            default: () => {}
        },
        extraInfo: {
            default: () => ({})
        }
    },
    computed: {
        ...mapGetters('msn', {
            menu: 'getMenu',
            dashboardDomainFlag: 'getDashboardDomainFlag'
        }),
        dragData: {
            get() {
                switch (this.type) {
                    case 'mediaDashboard': {
                        // 根据tempCols的顺序排序 把tempCols全部提前到最前面
                        const temp = [...this.value];
                        for (let i = (this.tempCols.length - 1); i >= 0; i--) {
                            let item = this.tempCols[i];
                            if (temp.includes(item)) {
                                const index = temp.findIndex(e => e === item);
                                temp.splice(index, 1);
                                temp.unshift(item);
                            }
                        }
                        return temp;
                    }
                    case 'db_multidimension': {
                        // 根据tempCols的顺序排序 把tempCols全部提前到最前面
                        const temp = [...this.value];
                        for (let i = (this.tempColsDimension.length - 1); i >= 0; i--) {
                            let item = this.tempColsDimension[i];
                            if (temp.includes(item)) {
                                const index = temp.findIndex(e => e === item);
                                temp.splice(index, 1);
                                temp.unshift(item);
                            }
                        }
                        return temp;
                    }
                    case 'dashboardAd' : {
                        // 根据tempCols的顺序排序 把tempCols全部提前到最前面
                        const temp = [...this.value];
                        for (let i = (this.tempColsDashboardAd.length - 1); i >= 0; i--) {
                            let item = this.tempColsDashboardAd[i];
                            if (temp.includes(item)) {
                                const index = temp.findIndex(e => e === item);
                                temp.splice(index, 1);
                                temp.unshift(item);
                            }
                        }
                        return temp;
                    }
                    case 'dashboardDomain': {
                        if (!(this.dashboardDomainFlag && this.menu[PATH['dashboardDomainCpc']] === 'rw')) {
                            return this.value.filter(item => item !== 'delivery#cpc');
                        }
                        return this.value;
                    }
                    default: {
                        return this.value;
                    }
                }

            },
            set(val) {
                this.$emit('input', val);
            }
        },
        tempCols() {
            switch (this.type) {
                case 'mediaDashboard': {
                    return [
                        'dimension#date',
                        'dimension#accountName',
                        'dimension#ssp',
                        'dimension#site'
                    ];
                }
                default: {
                    return this.defaultCols;
                }
            }
        },
        tempColsDimension() {
            switch (this.type) {
                case 'db_multidimension': {
                    return [
                        'dimension#accountId',
                        'dimension#campaignName',
                        'dimension#sspId'
                    ];
                }
                default: {
                    return this.defaultCols;
                }
            }
        },
        tempColsDashboardAd() {
            switch (this.type) {
                case 'dashboardAd': {
                    return [
                        'detail#imgUrl',
                        'detail#size'
                    ];
                }
                default: {
                    return this.defaultCols;
                }
            }
        }
    },
    components: {
        draggable
    },
    methods: {
        clear(item) {
            // 添加，一个校验钩子，钩子返回Boolean
            let beforeCreate = typeof this.beforeDelete === 'function' ? this.beforeDelete(item, this.dragData) : true;
            if (beforeCreate && this.checkExtraInfo(item)) {
                this.$emit('del', item);
            }
        },
        getLabelName(key) {
            if (!key) return;
            // 已翻译，注释掉
            // if (this.type === 'diagnosis') {
            //     return this.labelAndValueMap[key];
            // }
            const noFlag = 'noUseTranslateRight';
            try{
                this.labelAndValueMap[key].includes(noFlag);
            }catch(e){
                // 可能出现的情况
                // 1.cookie 中有 xx#value 但是并没有和它对应的项
                // 2.配置错误
                console.error(`\n 层级${key.split('#')[0]} 中不存在 value 等于 ${key.split('#')[1]} 的元素`);
                // 把所有设置列的 cookie 都清除 标识为 db 开头的key。保证刷新后 设置列恢复正常
                this.$emit('error');
            }
            if(this.labelAndValueMap[key].includes(noFlag)){
                return this.labelAndValueMap[key].slice(noFlag.length);
            }else{
                return this.$t(this.labelAndValueMap[key]);
            }

        },
        onMoveCallback(evt, originalEvent) {
            // console.log(evt, originalEvent);
            let relatedContext = evt.relatedContext;
            let draggedContext = evt.draggedContext;
            let defaultCols = this.tempCols;
            if (defaultCols.includes(relatedContext.element) || defaultCols.includes(draggedContext.element)) {
                return false;
            }
            return true;
        },
        // 检测当前配置是否有最少选项限制
        checkExtraInfo(curItem) {
            if (Object.keys(this.extraInfo).length === 0) {
                return true;
            }
            let vc = curItem.split('#')[0];
            let curNum = 0;
            this.dragData.forEach(item => {
                if (item.indexOf(vc) > -1) {
                    curNum++;
                }
            });
            return curNum > (this.extraInfo[vc].min !== undefined ? this.extraInfo[vc].min : 0);
            /* let arrays = [];
            Object.keys(this.extraInfo || {}).forEach(key => {
                arrays.push(this.extraInfo[key]['items']);
            });
            return arrays.every(arr => {
                return arr.some(item => {
                    return this.dragData.includes(item) && item !== curItem;
                });
            }); */
        }
    }
};
</script>

<style lang="scss" scoped>
.drag-box {
    padding: 10px 15px 0;
    .drag-item {
        font-size: 12px;
        color: #53575b;
        margin-bottom: 10px;
        cursor: move;
        .item-title {
            margin-right: 15px;
        }
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}
.close-icon {
    cursor: pointer;
}
</style>
