
import utils from '@/common/utils';

const VALIDATORS = function () {
    const numReg = /^(([0-9]\d{0,})|(([0]\.\d{1,2}|[1-9]\d{0,}\.\d{1,2})))$/;
    const checkNum = (rule, value, callback) => {
        if (!numReg.test(value) || +value < 0) {
            return callback(new Error(this.$t('msn.validations.money')));
        }
        //  else if (+value > 1000000000) {
        //     return callback(new Error(this.$t('msn.validations.numberBeyond')));
        // }
        callback();
    };
    const checkEmail = (rule, value, callback) => {
        // eslint-disable-next-line
        const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
        if (value && !reg.test(value)) {
            callback(new Error(this.$t('msn.validations.email')));
        }
        callback();
    };
    // 检查http链接
    const checkHttp = (rule, value, callback) => {
        // eslint-disable-next-line
        const httpReg = utils.httpReg;
        // 判断域名不能包含连续的点
        let domainStr = value.slice(0, value.indexOf('?'));
        const cDots = domainStr.split('.');
        if (
            value
            && (!httpReg.test(value) || cDots.includes(''))
        ) {
            callback(new Error(this.$t('msn.validations.http')));
        }
        callback();
    };
    // 检查字符长度
    const getCheckStringLength = (sL) => {
        return (rule, str, callback) => {
            let slength = 0;
            for (let i = 0; i < str.length; i++) {
                if ((str.charCodeAt(i) >= 0) && (str.charCodeAt(i) <= 255)) {
                    slength = slength + 1;
                } else {
                    slength = slength + 2;
                }
            }
            if (slength < sL) {
                callback(new Error(this.$t(`msn.validations.${rule.field}`)));
            }
            callback();
        };
    };
    // 检查密码
    const checkPassword = (rule, value, callback) => {
        const reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*])[a-z\d#@!~%^&*]{8,}/i;
        if (value && !reg.test(value)) {
            callback(new Error(this.$t('msn.validations.pwdRequired')));
        }
        callback();
    };
    return {
        checkNum,
        checkEmail,
        checkHttp,
        getCheckStringLength,
        checkPassword
    };
};
// 快速开户 rules
export const rules = function () {
    const v1 = VALIDATORS.call(this);
    const checkStrLength1 = v1.getCheckStringLength(2);
    const checkEmail = v1.checkEmail;
    const checkHttp = v1.checkHttp;
    const checkPassword = v1.checkPassword;
    const checkPhone = (rule, value, callback) => {
        if (value && !/^\d+$/.test(value)) {
            callback(new Error(this.$t('msn.validations.number')));
        }
        callback();
    };
    return {
        vCode: [{required: true, message: this.$t('msn.validations.vCodeNeed'), trigger: 'blur'}],
        firstName: [{required: true, message: this.$t('msn.validations.firstName'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        lastName: [{required: true, message: this.$t('msn.validations.lastName'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        companyName: [{required: true, message: this.$t('msn.validations.organizationName'), trigger: 'blur'}],
        companyWebsite: [{required: true, message: this.$t('msn.validations.companySite'), trigger: 'blur'}, {
            validator: checkHttp, trigger: 'blur'
        }],
        jobTitle: [{required: true, message: this.$t('msn.validations.jobTitle'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        phoneNumber: [
            {validator: checkPhone, trigger: 'blur'}
        ],
        email: [
            {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
        ],
        password: [
            {required: true, message: this.$t('msn.validations.pwdRequired'), trigger: 'blur'},
            {validator: checkPassword, trigger: 'blur'}
        ]
    };
};

// open account rules
export const accountRules = function () {
    const validator = VALIDATORS.call(this);
    const checkStr = validator.getCheckStringLength(2);
    const billingEmail = require(
        '@/modules/msn/pages/permission/accountMgr/constants.js')
        .VALIDATORS
        .call(this).billingEmail;
    return {
        companyName: [{required: true, message: this.$t('msn.validations.company'), trigger: 'blur'},
            {validator: checkStr, trigger: 'blur'}],
        accountName: [{required: true, message: this.$t('msn.validations.account'), trigger: 'blur'}],
        website: [{required: true, message: this.$t('msn.validations.companySite'), trigger: 'blur'},
            {validator: validator.checkHttp, trigger: 'blur'}],
        email: [
            {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
            {validator: validator.checkEmail, trigger: 'blur'}
        ],
        budget: [
            {required: true, message: this.$t('msn.validations.budget'), trigger: 'blur'},
            {validator: validator.checkNum, trigger: 'blur'}
        ],
        payMethod: [{required: true, message: this.$t('msn.validations.payMethod'), trigger: 'change'}],
        adMethod: [{required: true, message: this.$t('msn.validations.adMethod'), trigger: 'change'}],
        registerAddress: [{required: true, message: this.$t('msn.validations.registerAdd'), trigger: 'blur'},
            {validator: checkStr, trigger: 'blur'}],
        businessAddress: [{required: true, message: this.$t('msn.validations.businessAddress'), trigger: 'blur'},
            {validator: checkStr, trigger: 'blur'}],
        timezone: [{required: true, message: this.$t('msn.validations.timezone'), trigger: 'change'}],
        country: [{required: true, message: this.$t('msn.validations.country'), trigger: 'change'}],
        billingEmail: [{ validator: billingEmail, trigger: 'blur' }],
        settlementCurrency: [{ required: true, message: this.$t('msn.validations.currencyCheck'), trigger: 'blur' }],
        contractualSubject: [{ required: true, message: this.$t('msn.validations.contractCheck'), trigger: 'blur' }]
    };
};

export const userRules = function () {
    const v1 = VALIDATORS.call(this);
    const checkStrLength1 = v1.getCheckStringLength(2);
    const checkEmail = v1.checkEmail;
    // 检查http链接
    const checkHttp = v1.checkHttp;
    // 检查密码
    const checkPassword = v1.checkPassword;
    const checkPhone = (rule, value, callback) => {
        if (value && !/^\d+$/.test(value)) {
            callback(new Error(this.$t('msn.validations.number')));
        }
        callback();
    };
    return {
        vCode: [{required: true, message: this.$t('msn.validations.vCodeNeed'), trigger: 'blur'}],
        country: [{required: true, message: this.$t('msn.validations.country'), trigger: 'blur'}],
        firstName: [{required: true, message: this.$t('msn.validations.firstName'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        lastName: [{required: true, message: this.$t('msn.validations.lastName'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        companyName: [{required: true, message: this.$t('msn.validations.organizationName'), trigger: 'blur'}],
        companyWebsite: [{required: true, message: this.$t('msn.validations.companySite'), trigger: 'blur'}, {
            validator: checkHttp, trigger: 'blur'
        }],
        jobTitle: [{required: true, message: this.$t('msn.validations.jobTitle'), trigger: 'blur'}, {
            validator: checkStrLength1, trigger: 'blur'
        }],
        phoneNumber: [
            {validator: checkPhone, trigger: 'blur'}
        ],
        email: [
            {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
        ],
        password: [
            {required: true, message: this.$t('msn.validations.pwdRequired'), trigger: 'blur'},
            {validator: checkPassword, trigger: 'blur'}
        ]
    };
};

export const oldUserRules = function () {
    const v1 = VALIDATORS.call(this);
    const checkEmail = v1.checkEmail;
    return {
        email: [
            {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
        ]
    };
};
export const passwordReg = {
    oneNumber: /\d+/, // 至少一个数字
    oneLC: /[a-zA-Z]+/, // 至少一个字母
    oneSC: /[#@!~%^&*]+/, // 至少包含一个特殊字符
    more: /.{8,}/i // 至少8位
};
