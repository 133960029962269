/**
 * @file 通用工具
 * @author  fangsimin
 * @date    2018-07-23 11:47:17
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-23 20:33:37
 */

/**
 * 根据url获取url参数
 * @param  {string} url url
 * @return {Object} 参数数组
 */
export function getUrlParams(url) {
    let ret = /^[^?]*\?(.*)$/.exec(url);
    if (ret && ret[1]) {
        let paramStr = ret[1];

        return paramStr.split('&').reduce((ret, item) => {
            let [key, value] = item.split('=');
            if (typeof ret[key] === 'undefined') {
                ret[key] = decodeURIComponent(value);
            } else if (key === 'from') { // 为了解决安卓重复参数from的问题
                let decodeValue = decodeURIComponent(value);
                if (decodeValue !== 'android') {
                    ret[key] = decodeValue;
                }
            }
            return ret;
        }, {});
    } else {
        return {};
    }
}

/**
 * 根据url获取url参数，重复的参数存为数组
 * 为了兼容坑爹的安卓
 *
 * @param  {string} url url
 * @return {Object} 参数数组
 */
export function getAllUrlParams(url) {
    let ret = /^[^?]*\?(.*)$/.exec(url);
    if (ret && ret[1]) {
        let paramStr = ret[1];

        return paramStr.split('&').reduce((ret, item) => {
            let [key, value] = item.split('=');
            value = decodeURIComponent(value);
            if (typeof ret[key] === 'undefined') {
                ret[key] = value;
            } else if (typeof ret[key] === 'string') {
                ret[key] = [ret[key], value];
            } else if (ret[key] instanceof Array
                && ret[key].indexOf(value) === -1) {
                ret[key].push(value);
            }
            return ret;
        }, {});
    } else {
        return {};
    }
}

/**
 * 根据url参数数组和url前缀获取url
 * @param  {Object} param 参数数组
 * @param  {string} url   url前缀
 * @return {string} url或者参数（带?）字符串
 */
export function getUrlStr(param, url = '') {
    if (typeof param !== 'object') {
        return url;
    } else {
        let paramArr = Object.entries(param).map(entry => {
            if (entry[1] instanceof Array) {
                entry[1] = '[' + entry[1].toString() + ']';
            } else if (typeof entry[1] === 'object') {
                entry[1] = JSON.stringify(entry[1]);
            }
            return entry.join('=');
        });
        let paramStr = paramArr.join('&');
        let hashIndex = url.indexOf('#');
        let urlPrefix = url;
        let urlHash = '';
        if (hashIndex !== -1) {
            urlPrefix = url.slice(0, hashIndex);
            urlHash = url.slice(hashIndex);
        }

        if (paramStr) {
            if (urlPrefix && urlPrefix.indexOf('?') !== -1) {
                urlPrefix += '&' + paramStr;
            } else {
                urlPrefix += '?' + paramStr;
            }
        }
        return urlPrefix + urlHash;
    }
}

/**
 * 对循环引用的object进行stringify
 * 防止【Uncaught TypeError: Converting circular structure to JSON】错误
 * @param  {Object} object 传入的对象
 * @return {string}
 */
export function simpleStringify(object) {
    let simpleObject = {};
    for (const prop in object) {
        if (!object.hasOwnProperty(prop)) {
            continue;
        }
        if (typeof object[prop] === 'object') {
            continue;
        }
        if (typeof object[prop] === 'function') {
            continue;
        }
        simpleObject[prop] = object[prop];
    }
    return JSON.stringify(simpleObject); // returns cleaned up JSON
}

/**
 * 深复制
 * @param  {Object} object 被复制的对象
 * @return {Object}
 */
export function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * post请求
 * @param  {string} url  post请求地址
 * @param  {string} body 请求body
 * @param {Function} callback 回调函数
 */
export function post(url, body = '', callback = () => {}) {
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = xmlhttp => {
        callback(xmlhttp);
    };
    xmlhttp.open('POST', url, true);
    xmlhttp.send(body);
}

/**
 * 添加隐藏iframe
 * @param  {string} url iframe的src
 * @param  {Object} param iframe的src的参数
 */
export const addIframe = (() => {
    let body = document.getElementsByTagName('body')[0] || document.documentElement;
    return (url, param = {}) => {
        let ifr = document.createElement('iframe');
        ifr.style.display = 'none';
        ifr.src = getUrlStr(param, url);
        body.appendChild(ifr);
    };
})();

/**
 * 防抖 - 最后一次调用时触发
 * @param  {Function} fn   原始函数
 * @param  {number}   wait 等待时间(ms)，在等待时间内再次被调用会再次延迟执行
 * @param  {number}   time 超时(ms)，在超时后直接执行，不再等待
 * @return {Function}
 */
export function debounce(fn, wait = 200, time = 1000) {
    let previous = null; // 上次调用时间戳
    let timer = null; // 延迟执行的timer

    return (...args) => {
        let now = +new Date(); // 当前执行时间戳

        if (!previous) {
            previous = now; // 第一次调用时初始化previous
        }

        if (now - previous > time) {
            // 当间隔时间大于time，则直接调用
            // 避免如果一直调用此函数，则此函数一直不被执行
            timer && clearTimeout(timer);
            previous = now;
            return new Promise(resolve => {
                resolve(fn(...args));
            });
        } else { // 当间隔时间小于time，则重新延迟wait(ms)后执行
            timer && clearTimeout(timer);
            return new Promise(resolve => {
                timer = setTimeout(() => {
                    resolve(fn(...args));
                }, wait);
            });
        }
    };
}

/**
 * 节流 - 每隔一段等待时间，就会执行一次
 * @param  {Function} fn   原始函数
 * @param  {number}   wait 等待时间(ms)，在等待时间内再次被调用会再次延迟执行
 * @return {Function}
 */
export function throttle(fn, wait = 200) {
    let timer; // 延迟执行的timer
    let firstTime = true; // 是否是第一次调用
    let previous = null; // 上次调用时间戳

    return (...args) => {
        let now = +new Date(); // 当前执行时间戳

        if (firstTime) { // 如果是第一次调用，直接执行
            // previous = now; // 初始化previous
            firstTime = false;
            return new Promise(resolve => {
                resolve(fn(...args));
            });
        }

        // 计算剩余时间
        let leftTime = wait + previous - now;
        previous = now; // 重新赋值上次执行时间戳
        if (leftTime > 0) { // 当剩余时间大于0，再延迟剩余时间执行
            timer && clearTimeout(timer);
            return new Promise(resolve => {
                timer = setTimeout(() => {
                    clearTimeout(timer);
                    timer = null;
                    resolve(fn(...args));
                }, leftTime);
            });
        }

        // 如果没有剩余时间了，就直接执行
        timer && clearTimeout(timer);
        timer = null;
        return new Promise(resolve => {
            resolve(fn(...args));
        });
    };
}

/**
 * 预加载图片
 * @param  {string} url 图片url
 * @return {Promise}
 */
export function loadImage(url) {
    let img = new Image();
    return new Promise((resolve, reject) => {
        img.onload = () => {
            resolve(img);
        };
        img.onerror = e => {
            reject(e);
        };
        img.src = url;
    });
}

/**
 * 获取日期格式
 * @param  {Date} date 日期对象
 * @return {string}
 */
export function getDateFormat(date) {
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    let hh = date.getHours();
    let MM = date.getMinutes();
    let ss = date.getSeconds();

    return [
        date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join('') + ' ' + [
        (hh > 9 ? '' : '0') + hh,
        (MM > 9 ? '' : '0') + MM,
        (ss > 9 ? '' : '0') + ss
    ].join(':');
}
