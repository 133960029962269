<template>
    <div class="search-bar--options--item">
        <!-- 选中样式 -->
        <div
            v-show="temp"
            class="search-bar--options--item--box search-bar--options--item--box__active"
        >
            <span class="el-checkbox__input is-checked">
                <span class="el-checkbox__inner" :style="{background: '#2dd1ac',borderColor: '#2dd1ac'}"></span>
            </span>
            <span v-text="label"></span>
        </div>
        <!-- 非选中样式 -->
        <div
            v-show="!temp"
            class="search-bar--options--item--box search-bar--options--item--box__normal"
        >
            <!-- all 按钮非选中要特殊样式 1全空 2选了一些 -->
            <span v-show="allBtn === 2" class="el-checkbox__input is-indeterminate">
                <span class="el-checkbox__inner" :style="{background: '#2dd1ac',borderColor: '#2dd1ac'}"></span>
            </span>
            <!-- 普通 -->
            <span v-show="!allBtn || allBtn === 1" class="el-checkbox__input is-disabled">
                <span class="el-checkbox__inner" :style="{background: 'white'}"></span>
            </span>
            <span v-text="label"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OptionItem',
    props: ['temp', 'label', 'allBtn'],
    data() {
        return {};
    }
};
</script>
<style lang="scss" scoped>
.search-bar--options--item {
    width: 100%;
    height: 35px;
    overflow: hidden;
    cursor: pointer;
    &--box {
        width: 100%;
        font-size: 13px;
        padding-left: 20px;
        position: relative;
        font-weight: normal;
        &:hover {
            background-color: #eef4f9;
        }
        &__active {
            color: #2ddeac;
            /* &::after {
                font-family: "element-icons";
                position: absolute;
                right: 35px;
                font-family: "element-icons";
                content: "\E6DA";
                font-size: 12px;
                font-weight: bold;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            } */
        }
        &__normal {
            color: #53575b;
        }
    }
}
</style>
