/**
 * @file 韩语文案
 * @author  fangsimin
 * @date    2019-01-02 09:54:13
 * @last Modified by    zp
 * @last Modified time  2021-12-21
 */

import koLocale from 'element-ui/lib/locale/lang/ko';
import msnlang from './msn/ko'; // msn模块的语言
import medialang from './media/ko'; // msn模块的语言

const ko = {
    message: {
        submit: '제출',
        reset: '리셋',
        login: '로그인',
        password: '패스워드',
        username: '유저명',
        hintRequireUsername: '유저명을 입력해주세요',
        hintRequirePassword: '패스워드를 입력해주세요',
        networkError: '네트워크 에러',
        somethingWrong: '알 수 없는 에러',
        loginError: '로그인 에러',
        notFound: '페이지가 존재하지 않습니다.',
        triggerType: 'Trigger type',
        triggerWord: 'Trigger word',
        languag: 'Trigger word',
        permission: '접속불가',
        triggerTypes: {
            keyword: '키워드',
            category: '분류',
            source: '뉴스소스',
            author: '저자',
            picSex: '성적인이미지'
        },
        button: {
            add: '추가',
            edit: '편집',
            delete: '삭제',
            cancel: '취소',
            confirm: '확정',
            submit: '제출',
            reset: '리셋',
            previous: '돌아가기',
            next: '다음으로',
            logout: '로그아웃',
            login: '로그인',
            simulatedLogin: '계정을 입력해주세요.',
            returnAccount: '계정으로 돌아가기',
            addAccount: '계정을 추가하기',
            detailConfig: '운용구성',
            export: '제출',
            search: '제출',
            create: '작성'
        },
        isConfirmDelete: '삭제하시겠습니까?'
    },
    ...koLocale,
    msn: msnlang,
    media: medialang
};

export default ko;
