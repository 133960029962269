/**
 * @file 中文文案
 * @author  fangsimin
 * @date    2019-01-02 09:54:13
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:33:58
 */

import zhHantLocale from 'element-ui/lib/locale/lang/zh-TW';
import msnlang from './msn/zhHant'; // msn模塊的語言
import medialang from './media/zhHant'; // msn模塊的語言

const zhHant = {
    message: {
        submit: '提交',
        reset: '重置',
        login: '登錄',
        password: '密碼',
        username: '用户名',
        hintRequireUsername: '請輸入用户名',
        hintRequirePassword: '請輸入密碼',
        networkError: '網絡錯誤',
        somethingWrong: '未知錯誤',
        loginError: '登錄錯誤',
        notFound: '無相應頁面',
        triggerType: '觸發類型',
        triggerWord: '觸發詞',
        language: '觸發詞',
        permission: '暫無權限訪問',
        triggerTypes: {
            keyword: '關鍵詞',
            category: '分類',
            source: '新聞源',
            author: '作者',
            picSex: '色情圖片'
        },
        button: {
            add: '增加',
            edit: '編輯',
            delete: '刪除',
            cancel: '取消',
            confirm: '確定',
            submit: '提交',
            reset: '重置',
            previous: '上一頁',
            next: '下一頁',
            logout: '登出',
            login: '登錄',
            simulatedLogin: '進入賬户',
            returnAccount: '返回管理賬户',
            addAccount: '添加賬户',
            detailConfig: '運營配置',
            export: '導出',
            search: '查詢',
            create: '創建'
        },
        isConfirmDelete: '確定刪除嗎？'
    },
    ...zhHantLocale,
    msn: msnlang,
    media: medialang
};

export default zhHant;
