/**
 * @file 常量维护
 * @author  fangsimin
 * @date    2019-01-02 11:11:20
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-17 20:42:07
 */

import api from './api';
import path from './path';
import { breadcrumbLinks } from './breadcrumbPath';

export default {
    api,
    path,
    breadcrumbLinks,
    // 审核类型：0-先发后审，1-先审后发
    reviewTypeMap: [
        'pushFirst',
        'reviewFirst'
    ],
    // 审核状态
    reviewStatusMap: [

    ],
    // 触发类型
    triggerTypeMap: [
        'keyword', // 关键词
        'category', // 分类
        'source', // 新闻源
        'author', // 作者
        'picSex' // 色情图片
    ],
    // 内容类型
    contentTypeMap: [
        'news', // 新闻
        'picture', // 图片
        'video' // 视频
    ]
};
