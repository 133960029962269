<template>
    <el-time-picker
        v-model="time"
        class="msn-time"
        :picker-options="pickOptions"
        :placeholder="$t('msn.dashboard.selectDate')"
        popper-class="msn-date-popper"
        :value-format="valueFormat"
        :disabled="disabled"
    />
</template>

<script>
export default {
    name: 'MsnTimePicker',
    props: {
        valueFormat: {
            default: '',
            type: String
        },
        type: {
            type: String,
            default: 'date'
        },
        value: {
            default: null
        },
        pickOptions: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            default: false
        }
    },
    computed: {
        time: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>

<style lang="scss">
.msn-time.el-date-editor {
    border-radius: 17.5px;
    height: 35px;
    .el-input__inner {
        height: 35px;
        border-radius: 17.5px;
        background: #EEF1F4;
        font-size: 12px;
        color: #53575B;
        font-weight: 500;
        border: none;
        &::placeholder {
            font-weight: 400;
            color: #53575B;
            opacity: 0.3;
        }
    }
    .el-input__icon {
        line-height: 40px;
    }
}
.el-form-item.is-error .msn-time.el-date-editor .el-input__inner {
    background-color: rgba(238, 241, 244, .5);
    border: 1px solid #E92754;
}
.msn-date-popper .el-time-panel__btn.confirm {
    color: #2DD1AC;
}
</style>
