/**
 * @file 状态管理
 * @author  fangsimin
 * @date    2019-01-02 10:07:17
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:26:08
 */

import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import msn from './modules/msn';
import media from './modules/media';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        common,
        media,
        msn
    }
});
