<template>
    <div class="user-box">
        <el-popover
            popper-class="user-popover-box"
            placement="top-start"
            width="294"
            trigger="hover"
        >
            <div slot="reference" class="user-portrait">
                <i class="msn-fa-user-smallportrait"></i>
                <el-badge
                    v-if="messageLen > 0" class="message-mark"
                    :class="{max: messageLen > 99}"
                    :value="messageLen" :max="99"
                />
            </div>
            <div class="user-portrait-top">
                <div class="user-portrait-top-img">
                    <i class="msn-fa-user-portrait"></i>
                </div>
                <div>
                    <span class="user-name">{{ userName }}</span>
                    <br>
                    <span class="user-email">{{ email }}</span>
                </div>
            </div>
            <div class="user-portrait-bottom">
                <div v-if="!isDiscovery">
                    <span>
                        <a href="https://www.mediago.io/privacy" target="_blank">{{ $t('msn.openAccount.privacyLink') }}</a></span>
                    &nbsp; | &nbsp;
                    <span>
                        <a href="https://www.mediago.io/terms" target="_blank">{{ $t('msn.openAccount.terms') }}</a>
                    </span>
                </div>
                <!-- discovery隐去条款 -->
                <div v-else>
                    <!-- <span>
                        <a href="https://www.mediago.io/discovery" target="_blank">{{ $t('msn.openAccount.terms') }}</a>
                    </span> -->
                </div>
                <div class="user-exit">
                    <msn-button
                        class="box--row--cell" type="transparent"
                        @click.native="loginOut"
                    >
                        {{ $t('msn.openAccount.logout') }}
                    </msn-button>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MsnButton from '@/components/MsnButton';
import constants from '@/constants';
import api from '@/constants/api';

const PATHS = constants.path;
export default {
    name: 'App',
    data() {
        return {
            messageLen: 0
        };
    },
    components: {
        MsnButton
    },
    computed: {
        ...mapGetters('msn', {
            userName: 'getUserName',
            email: 'getEmail'
        }),
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        })
    },
    methods: {
        loginOut() {
            this.loading = true;
            this.$request({
                method: 'get',
                url: api.logout,
                success(res) {
                    this.loading = false;
                    this.$message.success(this.$t('msn.requestMsg.logoutSuccess'));
                    location.href = PATHS['login'];
                },
                error() {
                    this.loading = false;
                    this.$message.error(this.$t('msn.requestMsg.logoutFailed'));
                }
            });
            // 登出清空aiCreate模块持久化数据
            localStorage.removeItem('aiCreateData');
        }
    }
};
</script>

<style lang="scss" >
.message-mark {
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
    display: flex;
    align-items: center;
    &.max {
        right: -5px;
    }
    .el-badge__content {
        background-color: #E92754;
        border: none;
        transform: scale(0.7);
        transform-origin: top right;
        font-weight: 600;
    }
}
.user-box{
    .user-portrait{
        position: relative;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;
        background: #2DD1AC;
        display: flex;
        align-items: center;
        justify-content: center;
        .msn-fa-user-smallportrait{
            width: 12px;
            height: 13.85px;
            display: block;
        }
    }
}

</style>
<style lang="scss">
.user-portrait-top{
    display: flex;
    align-items: center;
}
.user-portrait-top-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
    background: #2DD1AC;
    display: flex;
    align-items: center;
    justify-content: center;
    .msn-fa-user-portrait{
        display: block;
        width: 20px;
        height: 23.64px;
        z-index: 10000;
    }
}
.user-popover-box{
    padding: 15px 0 !important;
    overflow: hidden;
}
.user-portrait-top{
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 12px;
    .user-name{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }
    .user-email{
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #53575B;
        opacity: 0.5;
    }
}
.user-portrait-bottom{
    margin-left: 25px;
    margin-right: 25px;
    height: 45px;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed #D7D8DD;
    padding-top: 10px;
    a{
        text-decoration: none;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #53575B;
    }
}
</style>