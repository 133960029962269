/*
 * @file country-zh.js
 * @author liushengxgi
 * @date 2019-12-31 14:47:20
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2019-12-31 14:47:30
 */

export default {
    ALB: 'アルバニア',
    DZA: 'アルジェリア',
    AFG: 'アフガニスタン',
    ARG: 'アルゼンチン',
    ARE: 'アラブ首長国連邦',
    ABW: 'アルバ',
    OMN: 'オマーン',
    AZE: 'アゼルバイジャン',
    EGY: 'エジプト',
    ETH: 'エチオピア',
    IRL: 'アイルランド',
    EST: 'エストニア',
    AND: 'アンドラ',
    AGO: 'アンゴラ',
    AIA: 'アンギラ',
    ATG: 'アンティグア・バーブーダ',
    AUT: 'オーストリア',
    ALA: 'オーランド諸島',
    AUS: 'オーストラリア',
    MAC: 'マカオ',
    BRB: 'バルバドス',
    PNG: 'パプア・ニューギニア',
    BHS: 'バハマ',
    PAK: 'パキスタン',
    PRY: 'パラグアイ',
    PSE: 'パレスチナ',
    BHR: 'バーレーン',
    PAN: 'パナマ',
    BRA: 'ブラジル',
    BLR: 'ベラルーシ',
    BMU: 'バミューダ',
    BGR: 'ブルガリア',
    MNP: '北マリアナ諸島',
    BEN: 'ベナン',
    BEL: 'ベルギー',
    ISL: 'アイスランド',
    PRI: 'プエルトリコ',
    BIH: 'ボスニア・ヘルツェゴビナ',
    POL: 'ポーランド',
    BOL: 'ボリビア',
    BLZ: 'ベリーズ',
    BWA: 'ボツワナ',
    BTN: 'ブータン',
    BFA: 'ブルキナファソ',
    BDI: 'ブルンジ',
    BVT: 'ブーベ島',
    PRK: '北朝鮮',
    GNQ: '赤道ギニア',
    DNK: 'デンマーク',
    DEU: 'ドイツ',
    TLS: '東ティモール',
    TGO: 'トーゴ',
    DOM: 'ドミニカ共和国',
    DMA: 'ドミニカ',
    RUS: 'ベラルーシ',
    ECU: 'エクアドル',
    ERI: 'エリトリア',
    FRA: 'フランス',
    FRO: 'フェロー諸島',
    PYF: 'フランス領ポリネシア',
    GUF: 'フレンチギアナ',
    ATF: 'フランス領南方・南極地域',
    MAF: 'サン・マルタン',
    VAT: 'バチカン',
    PHL: 'フィリピン',
    FJI: 'フィジー',
    FIN: 'フィンランド',
    CPV: 'カーボベルデ共和国',
    GMB: 'ガンビア',
    COG: 'コンゴ',
    COD: 'コンゴ民主共和国',
    COL: 'コロンビア',
    CRI: 'コスタリカ',
    GRD: 'グレナダ',
    GRL: 'グリーンランド',
    GEO: 'ジョージア',
    GGY: 'ガーンジー',
    CUB: 'キューバ',
    GLP: 'グアドループ',
    GUM: 'グアム',
    GUY: 'ガイアナ',
    KAZ: 'カザフスタン',
    HTI: 'ハイチ',
    KOR: '韓国',
    NLD: 'オランダ',
    BES: 'ボネール、シント・ユースタティウスおよびサバ',
    HMD: 'ハード島とマクドナルド諸島 ',
    MNE: 'モンテネグロ',
    HND: 'ホンジュラス',
    KIR: 'キリバス',
    DJI: 'ジブチ',
    KGZ: 'キルギスタン',
    GIN: 'パプア・ニューギニア',
    GNB: 'ギニアビサウ',
    CAN: 'カナダ',
    GHA: 'ガーナ',
    GAB: 'ガボン',
    KHM: 'カンボジア',
    CZE: 'チェコ',
    ZWE: 'ジンバブエ',
    CMR: 'カメルーン',
    QAT: 'カタール',
    CYM: 'ケイマン諸島',
    CCK: 'ココス（キーリング）諸島',
    COM: 'コモロ',
    CIV: 'コートジボアール',
    KWT: 'クウェート',
    HRV: 'クロアチア',
    KEN: 'ケニア',
    COK: 'クック諸島',
    LVA: 'ラトビア',
    LSO: 'レソト',
    LAO: 'ラオス人民民主共和国',
    LBN: 'レバノン',
    LTU: 'リトアニア',
    LBR: 'リベリア',
    LBY: 'リビア',
    LIE: 'リヒテンシュタイン',
    REU: 'レユニオン',
    LUX: 'ルクセンブルク',
    RWA: 'ルワンダ',
    ROU: 'ルーマニア',
    MDG: 'マダガスカル',
    IMN: 'マン島',
    MDV: 'モルディブ',
    FLK: 'フォークランド（マルビナス）諸島',
    MLT: 'マルタ',
    MWI: 'マラウイ',
    MYS: 'マレーシア',
    MLI: 'マリ',
    MKD: 'マケドニア',
    MHL: 'マーシャル諸島',
    MTQ: 'マルティニーク',
    MYT: 'マヨット',
    MUS: 'モーリシャス',
    MRT: 'モーリタニア',
    USA: 'アメリカ合衆国',
    UMI: '合衆国領有小離島',
    ASM: 'アメリカンサモア',
    VIR: 'アメリカ領バージン諸島',
    MNG: 'モンゴル',
    MSR: 'モントセラト',
    BGD: 'バングラデシュ',
    PER: 'ペルー',
    FSM: 'ミクロネシア連邦',
    MMR: 'ミャンマー',
    MDA: 'モルドバ',
    MAR: 'モロッコ',
    MCO: 'モナコ',
    MOZ: 'モザンビーク',
    MEX: 'メキシコ',
    NAM: 'ナミビア',
    ZAF: '南アフリカ',
    ATA: '南極大陸',
    SGS: 'サウスジョージア・サウスサンドウィッチ諸島',
    SSD: '南スーダン',
    NRU: 'ナウル',
    NPL: 'ネパール',
    NIC: 'ニカラグア',
    NER: 'ニジェール',
    NGA: 'ナイジェリア',
    NIU: 'ニウエ',
    NOR: 'ノルウェー',
    NFK: 'ノーフォーク島',
    PLW: 'パラオ',
    PCN: 'ピトケアン',
    PRT: 'ポルトガル',
    JPN: '日本',
    SWE: 'スウェーデン',
    CHE: 'スイス',
    SLV: 'エルサルバドル',
    WSM: 'サモア',
    SRB: 'セルビア',
    SLE: 'シエラレオネ',
    SEN: 'セネガル',
    CYP: 'キプロス',
    SYC: 'セイシェル',
    SAU: 'サウジアラビア',
    BLM: 'サン・バルテルミー島',
    CXR: 'クリスマス島',
    STP: 'サントメ・プリンシペ',
    SHN: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    KNA: 'セントクリストファー・ネイビス',
    LCA: 'セントルシア',
    SMR: 'サンマリノ',
    SPM: 'サンピエール島・ミクロン島',
    VCT: 'セントビンセント＆グレナディーン諸島',
    LKA: 'スリランカ',
    SVK: 'スロバキア',
    SVN: 'スロベニア',
    SJM: 'スヴァールバル諸島およびヤンマイエン島',
    SWZ: 'スワジランド',
    SDN: 'スーダン',
    SUR: 'スリナム',
    SLB: 'ソロモン諸島',
    SOM: 'ソマリア',
    TJK: 'タジキスタン',
    THA: 'タイ',
    TZA: 'タンザニア',
    TON: 'トンガ',
    TCA: 'タークス・カイコス諸島',
    TTO: 'トリニダード＆トバゴ',
    TUN: 'チュニジア',
    TUV: 'タウバル',
    TUR: 'トルコ',
    TKM: 'トルクメニスタン',
    TKL: 'トケラウ',
    WLF: 'ウォリス・フツナ',
    VUT: 'バヌアツ',
    GTM: 'グアテマラ',
    VEN: 'ベネズエラ',
    BRN: 'ブルネイ',
    UGA: 'ウガンダ',
    UKR: 'ウクライナ',
    URY: 'ウルグアイ',
    UZB: 'ウズベキスタン',
    ESP: 'スペイン',
    ESH: '西サハラ',
    GRC: 'ギリシャ',
    HKG: '香港',
    SGP: 'シンガポール',
    NCL: 'ニューカレドニア',
    NZL: 'ニュージーランド',
    HUN: 'ハンガリー',
    SYR: 'シリア',
    JAM: 'ジャマイカ',
    ARM: 'アルメニア',
    YEM: 'イエメン',
    IRQ: 'イラク',
    IRN: 'イラン',
    ISR: 'イスラエル',
    ITA: 'イタリア',
    IND: 'インド',
    IDN: 'インドネシア',
    GBR: 'イギリス',
    VGB: 'イギリス領バージン諸島',
    IOT: 'イギリス領インド洋地域',
    JOR: 'ヨルダン',
    VNM: 'ベトナム',
    ZMB: 'ザンビア',
    JEY: 'ジャージー',
    TCD: 'チャド',
    GIB: 'ジブラルタル',
    CHL: 'チリ',
    CAF: '中央アフリカ共和国',
    CHN: '中国',
    TWN: '台湾'
};
