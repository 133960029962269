/**
 * @file 入口函数
 * @author  fangsimin
 * @date    2019-01-02 13:25:50
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-14 19:54:51
 */
// import '@/plugins/public-path.js';
import Vue from 'vue';
import '@/plugins/element.js';
import router from './router';
// import '@/plugins/bad.js';
import '@/plugins/ajax.js';
import '@/plugins/gtag.js';
import '@/plugins/paypal.js';
import '@/plugins/weirwood.js';
// import '@/plugins/fontawesome.js'; // icon使用fontawesome
import i18n from '@/i18n/i18n';
import store from '@/store/index';
import '@/modules/msn/assets/styles/element-style.scss';
import '@/modules/msn/assets/styles/style.css';
import filter from '@/common/filters';
import { VueClipboard } from '@/common/directives';
import VueHighlightJS from 'vue-highlightjs';
import { isTargetDomain } from '@/common/utils';
import '@/components/requireComponents';
import GaReport from '@/plugins/gaReport';
import './flexible.js';
const Base64 = require('js-base64').Base64;
Vue.config.productionTip = false;
Vue.use(VueHighlightJS);
import Cookie from 'js-cookie';
// 注册filters
Object.keys(filter).forEach(key => {
    Vue.filter(key, filter[key]);
});
Vue.use(VueClipboard);
// 修改默认值 使得 不可以点击遮罩关闭 MessageBox
const _$confirm = Vue.prototype.$confirm;
Vue.prototype.$confirm = function () {
    if (arguments) {
        if (arguments[2]) {
            // $confirm(message, title, options)
            if (arguments[2].closeOnClickModal === null || arguments[2].closeOnClickModal === undefined) {
                arguments[2].closeOnClickModal = false;
            }
        } else {
            // 或 $confirm(message, options)
            if (arguments[1].closeOnClickModal === null || arguments[1].closeOnClickModal === undefined) {
                arguments[1].closeOnClickModal = false;
            }
        }
    }

    return _$confirm.apply(this, arguments);
};

// ga report
const $GaReport = new GaReport().init();
Vue.prototype.$GaReport = $GaReport;
Vue.prototype.handleGa = function ({action, label}, {res, cookieKey}) {
    const cols = Cookie.get(cookieKey);
    if(res) {
        const {wait_seconds, code, msg} = res;
        $GaReport.report({action, label}, {
            wait_seconds,
            errors: code ? `${code};${msg ? msg.slice(0, 100) : ''}` : '',
            cols: cookieKey ? cols : ''
        });
    } else {
        $GaReport.report({action, label}, {cols});
    }
};
const isDiscovery = isTargetDomain('popin');
if (isDiscovery) {
    store.commit('common/setPlatformInfo', { isDiscovery });
    document.title = 'Discovery Ad Platform';
    const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
    link.href = `${require('./assets/favicon.png')}`;
}
new Vue({
    store,
    i18n,
    router,
    Base64
}).$mount('#app');