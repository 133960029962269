/**
 * @file 路由
 * @author  fangsimin
 * @date    2019-01-02 14:04:10
 * @last Modified by    fangsimin
 * @last Modified time  2020-03-01 13:22:20
 */

import Vue from 'vue';
import Router from 'vue-router';
// import App from '@/App';
import MsnApp from '@/modules/msn/App';
import Login from '@/modules/msn/Login';
import Register from '@/modules/msn/pages/system/noPermission';
import ModifyPwd from '@/modules/msn/pages/system/modifyPwd';
import ForgetPassword from '@/modules/msn/pages/system/forgetPassword';
import OpenAccount from '@/modules/msn/pages/system/openAccountAndUser';
import inviteOpenUser from '@/modules/msn/pages/system/invite/inviteOpenUser';
import inviteOpenUserAndAccount from '@/modules/msn/pages/system/invite/inviteOpenUserAndAccount';
import constants from '@/constants';
import msnPathConfig from '@/modules/msn/constants/pathConfig';
import MediaApp from '@/modules/media/App';
import MediaLogin from '@/modules/media/Login';
import mediaPathConfig from '@/modules/media/constants/pathConfig';
import {platformBeforeRouter, mediaBeforeRouter} from './beforeRouter';

const PATH = constants.path;

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [{
        path: PATH['login'],
        name: 'login',
        component: Login
    }, {
        path: PATH['modify'],
        name: 'modify',
        component: ModifyPwd
    },
    {
        path: PATH['forgetPassword'],
        name: 'forgetPassword',
        component: ForgetPassword
    }, {
        path: PATH['register'],
        name: 'register',
        component: Register
    }, {
        path: PATH['mediaLogin'],
        name: 'mediaLogin',
        component: MediaLogin
    }, {
        path: PATH['openAccount'],
        name: 'openAccount',
        component: OpenAccount
    },
    {
        path: PATH['inviteOpenUser'],
        name: 'inviteOpenUser',
        component: inviteOpenUser
    },
    {
        path: PATH['inviteOpenUserAndAccount'],
        name: 'inviteOpenUserAndAccount',
        component: inviteOpenUserAndAccount
    },
    {
        path: '/platform',
        name: 'platform',
        component: MsnApp,
        // 所有路由配置处，做成子路由是因为导航处相同
        children: msnPathConfig['routes']
    },
    {
        path: '/media',
        name: 'media',
        component: MediaApp,
        // 所有路由配置处，做成子路由是因为导航处相同
        children: mediaPathConfig['routes']
    },
    {
        path: '/',
        beforeEnter: async (to, fromPath, next) => {
            try {
                // const userinfo = await getUserInfo();
                next();
            } catch (e) {
                next({
                    path: PATH['login']
                });
            }
        }
    }, {
        path: '/noPermission',
        component: resolve => require(['@/pages/401'], resolve)
    }, {
        path: '**',
        component: resolve => require(['@/pages/404'], resolve)
    }]
});

router.beforeEach((to, from, next) => {
    let rawPath = to.path.trim();
    let curSys = rawPath.startsWith('/platform')
        ? 'platform'
        : rawPath.startsWith('/media')
            ? 'media'
            : '';
    // 先判断是否为登录，登录了才能获取到权限，怎么判断登录就不写了
    try {
        if (curSys === 'platform') {
            platformBeforeRouter(rawPath, next);
        } else if (curSys === 'media') {
            mediaBeforeRouter(rawPath, next);
        }
    } catch (e) {
        next({
            path: curSys === 'platform' ? PATH['login'] : PATH['mediaLogin']
        });
    }
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

export default router;
