<template>
    <span class="msn-tip-container">
        <el-popover
            ref="msn-tip"
            :placement="placement"
            title
            :visible-arrow="visibleArrow"
            :width="width"
            :trigger="trigger"
            :popper-class="`max-height-500 ${popperClass}`"
        >
            <div>
                <!--bca-disable-->
                <div
                    class="content-wrapper" :class="{chinese: lang !== 'en'}"
                    v-html="content"
                ></div>
                <!--bca-enable-->
                <slot name="content"></slot>
            </div>
        </el-popover>
        <i v-popover:msn-tip class="msn-fa msn-fa-notice cursor"></i>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'MsnTip',
    props: {
        content: {
            type: String
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        width: {
            type: String,
            default: '200'
        },
        trigger: {
            type: String,
            default: 'hover'
        },
        visibleArrow: {
            type: Boolean,
            default: false
        },
        popperClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('common', {
            lang: 'getLang'
        })
    }
};
</script>

<style scoped>
.msn-tip-container {
    display: inline-block;
    height: 1.2em;
    line-height: 1.2em;
}
.cursor {
    cursor: pointer;
}
.content-wrapper {
    font-size: 13px;
    white-space: pre-line;
    text-align: left;
    word-break: keep-all;
    word-wrap: break-word;
}
/* 当是中文 */
.chinese {
    word-break: normal;
}

</style>
