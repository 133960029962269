<template>
    <div class="preview-container">
        <div
            :class="['upload-preview', previewContainerClass]"
            :style="style"
            @mouseover="handlePreview"
        >
        </div>
        <!-- <div v-if="showPreview" class="opt-container" @click="handlePreview"></div> -->
        <div v-show="preview" class="preview">
            <img :src="image" height="100%">
        </div>
    </div>
</template>

<script>
// import api from '@/constants/api';
const visualSizeMap = {
    '102x96': ['221', '208'],
    '300x175': ['357', '208'],
    '300x397': ['157', '208'],
    '622x368': ['352', '208'],
    '624x350': ['370', '208']
};
export default {
    name: 'MsnPreviewImage',
    props: {
        image: String,
        showPreview: {
            default: true
        },
        previewContainerClass: {
            default: ''
        },
        useImgStyle: {
            default: true
        },
        // 缩小
        half: {
            default: 1
        }
    },
    data() {
        return {
            preview: false
        };
    },
    computed: {
        imgStyle() {
            let style = {};
            let url = this.image && this.image.split('/').slice(-1) && this.image.split('/').slice(-1)[0];
            let scale = url && url.split('.') && url.split('.')[0];
            let type = '';
            if (scale) {
                type = scale.slice(-7).split('x')
                    .map(item => item.length > 3 ? item.slice(1) : item).join('x');
                style = {
                    // 624x350 旧尺寸
                    // 后续调整尺寸记得修改 type === '622x368'
                    'border-radius': type === '624x350' ? '6px' : '0',
                    width: `${visualSizeMap[type][0] * this.half}px`,
                    height: `${visualSizeMap[type][1] * this.half}px`
                };
            }
            return style;
        },
        style() {
            return Object.assign({}, {backgroundImage: `url(${this.image})`});
        },
        containerStyle() {
            return {
                width: `${370 * this.half}px`,
                height: `${208 * this.half}px`
            };
        }
    },
    methods: {
        clickOutSide(e) {
            if (!this.$el.contains(e.target)) {
                this.preview = false;
            }
        },
        handlePreview() {
            // 若不需要展示预览，则不展示
            if (this.showPreview) {
                this.preview = true;
            }
        }
    },
    watch: {
        preview(newVal, oldVal) {
            if (newVal === true) {
                // 若显示，则监听失去焦点事件。
                document.addEventListener('mouseover', this.clickOutSide, true);
            } else {
                // 不显示，销毁监听事件。
                document.removeEventListener('mouseover', this.clickOutSide, true);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container {
    height: 100%;
    padding: 0;
    margin: 0;
    background-image: url(../../modules/msn/assets/images/icon/image-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    overflow: visible;
}
.upload-preview {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
    .opt-container {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 48px;
        height: 48px;
        background-image: url(../../modules/msn/assets/images/icon/enlarge.png);
        background-position: right -4px bottom -2px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        cursor: pointer;
    }
    .preview {
        position: absolute;
        right: -2px;
        bottom: 0;
        z-index: 100;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.20);
        border-radius: 6px;
    }
</style>
