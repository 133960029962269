<template>
    <div class="data-select-container">
        <div
            v-if="label"
            :class="required ? 'select-label required' : 'select-label'"
        >
            {{ useTranslate ? $t(label) : label }}
        </div>
        <!-- sort 必须为 false 因为本例已自己实现 sort 函数 -->
        <!-- clearable属性仅适用于单选 -->
        <msn-select
            v-model="select"
            class="msn-multi-select msn-is-transparent"
            :option-list="optionList"
            :disabled="disabled"
            :clearable="!multiple"
            :multiple="multiple"
            :filterable="filterable"
            :collapse-tags="collapseTags"
            :use-translate="useTranslate"
            :sort="false"
            :filter-method-fn="filterable ? tempFilterMethod : null"
            :loadmore="loadmore"
            :no-more="noMore"
            @change="$emit('change')"
        />
    </div>
</template>

<script>
import MsnSelect from '@/components/MsnSelect/Select';
export default {
    name: 'DataSelect',
    props: {
        value: {
            type: [String, Number, Array],
            required: true
        },
        // 全部的数据
        allList: {
            type: Array,
            required: true
        },
        // 开启搜索模式
        filterable: {
            type: Boolean,
            default: false
        },
        // 默认判断 value 如果要和名字一起 filterMethod = ['label', 'value']
        filterMethod: {
            type: [Function, Array],
            default: null
        },
        // 每一页的展示数量 这个不能太短 太短触发不了滚动至少可能也要10吧
        limit: {
            type: Number,
            default: 30
        },
        collapseTags: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            required: true
        },
        useTranslate: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        // 初始化 optionList
        const optionList = this.allList.slice(0, this.limit);
        return {
            optionList,
            // 展示数据 optionList 的在 allList 里的 index ,因为存在重复index不一定等于optionList.length
            currentIndex: this.limit - 1,
            // 下拉加载更多的间隔ms 和 搜索的时间间隔
            loadingTime: 300,
            // 缓存
            cache: {
                // 下拉加载更多的防抖
                loadingMore: false,
                // 下拉加载更多的定时器
                timer: null,
                // true=>正在搜索
                isSearch: false,
                // 搜索出来的全部数据 不会全部加载出来所以要用一个变量保存
                searchAllList: null,
                // 类似 currentIndex
                searchCurrentIndex: null,
                // 保存搜索前的列表结束搜索后要重新展示
                beforeOptionList: null
            }
        };
    },
    components: {
        'msn-select': MsnSelect
    },
    computed: {
        noMore() {
            if(this.cache.isSearch){
                // 搜索时
                return this.currentIndex >= this.cache.searchAllList.length - 1;
            }else{
                // 非搜索时
                return this.currentIndex >= this.allList.length - 1;
            }
        },
        select: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
        valueList() {
            const res = [];
            if (this.multiple) {
                // 多选时 this.select 是Array[value]
                res.push(...this.select);
            } else {
                // 单选 此时 select 为选中的 value 值
                res.push(this.select);
            }
            return res;
        }
    },
    methods: {
        loadmore() {
            const currentSelectList = this.optionList;
            const limit = this.limit;
            let currentIndex = null;
            let allList = null;

            if (this.cache.isSearch) {
                // 搜索时下拉加载更多
                currentIndex = this.cache.searchCurrentIndex;
                allList = this.cache.searchAllList;
            } else {
                // 正常滚动下拉加载更多
                currentIndex = this.currentIndex;
                allList = this.allList;
            }
            // 变量初始化完成----^----
            if (this.cache.loadingMore === true) {
                return;
            }
            if (currentIndex >= allList.length - 1) {
                return;
            }
            // 执行----^----
            // 开启防抖
            this.cache.loadingMore = true;
            // index 指向不同
            if (this.cache.isSearch) {
                this.cache.searchCurrentIndex += limit;
            } else {
                this.currentIndex += limit;
            }
            // 根据 对象的 value 去重
            const pushList = allList
                .slice(currentIndex + 1, currentIndex + 1 + limit)
                .filter((e) => {
                    const eValue = e.value;
                    const removeFlag = currentSelectList.some((i) => {
                        const iValue = i.value;
                        return eValue === iValue;
                    });
                    return removeFlag === false;
                });
            this.updateOptionList(currentSelectList.concat(pushList), {
                addLose: false,
                sort: false
            });
            setTimeout(() => {
                this.cache.loadingMore = false;
            }, this.loadingTime);
        },
        tempFilterMethod(option) {
            const search = option.search;
            // 大概率用不到自定义方法
            if (typeof this.filterMethod === 'function') {
                return;
            }
            // 默认筛选方法
            if (this.cache.timer !== null) {
                clearTimeout(this.cache.timer);
            }
            this.cache.timer = setTimeout(() => {
                if (search.length > 0) {
                    this.cache.timer = null;
                    if (this.cache.isSearch === false) {
                        // 开始搜索
                        this.cache.beforeOptionList = this.optionList;
                    }
                    this.cache.isSearch = true;
                    const allList = this.allList;
                    // 自定义校验字段
                    const defaultKeys = ['label'];
                    const keys = Array.isArray(this.filterMethod)
                        ? this.filterMethod
                        : defaultKeys;
                    // 搜索出的全部结果
                    const res = allList.filter((e) => {
                        for (let key of keys) {
                            if ((e[key] + '').includes(search)) {
                                return true;
                            }
                        }
                        return false;
                    });
                    // 每一次 search 发生变化都会初始化
                    this.cache.searchAllList = res;
                    this.cache.searchCurrentIndex = this.limit - 1;
                    const searchOptionList = this.cache.searchAllList.slice(
                        0,
                        this.limit
                    );
                    // 只把符合搜索的选项 且 被选中的提前
                    this.updateOptionList(searchOptionList, {
                        addLose: false
                    });
                } else {
                    if (this.cache.isSearch === true) {
                        // 重置回搜索前的状态
                        this.cache.isSearch = false;
                        this.updateOptionList(this.cache.beforeOptionList);
                        this.cache.beforeOptionList = null;
                    }
                }
            }, this.loadingTime);
        },
        updateOptionList(optionList, option) {
            // 用法:传入新的 optionList 和 原optionList(this.optionList) 指针必须不一样才能刷新页面
            // 注意:组件实例 只在此处做 更新页面 操作
            option = option || {};
            // 1. 添加被选中但是没有在 optionList 中的值
            option.addLose !== false && this.privateAddLoseItem(optionList);
            // 2. 排序
            option.sort !== false && this.privateSort(optionList);
            // 3. 更新页面
            this.optionList = optionList;
        },
        privateAddLoseItem(optionList) {
            // 修改原数组
            // 添加被选中但是没有在 optionList 中的值 且 添加在最前面
            const valueList = this.valueList;
            const allList = this.allList;
            const currentOptionList = optionList;
            for (let value of valueList) {
                if (value === '') {
                    break;
                }
                const exist = currentOptionList.some((e) => e.value === value);
                if (!exist) {
                    const target = allList.find((e) => e.value === value);
                    // 如果 allList 里没有就不渲染 不过这种情况肯定是有问题的
                    if (target) {
                        currentOptionList.unshift(target);
                    }
                }
            }
            return currentOptionList;
        },
        privateSort(optionList) {
            // 修改原数组
            // 排序把选中的放到最前面
            const valueList = this.valueList;
            optionList.sort((a, b) => {
                if (valueList.includes(a.value)) {
                    return -1;
                } else if (valueList.includes(b.value)) {
                    return 1;
                } else {
                    return 0;
                }
            });
            return optionList;
        }
    },
    watch: {
        select() {
            this.updateOptionList(this.optionList.concat());
        }
    }
};
</script>
<style lang="scss" scoped>
/* 继承src/modules/msn/pages/dataReport/data-select.vue的全局样式 */
.data-select-container {
    /deep/ .el-select-dropdown__wrap {
        max-width: 600px;
    }
}
</style>
