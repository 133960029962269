/*
 * @file 通用数据
 * @author liushengxgi
 * @date 2020-02-25 10:08:58
 * ----
 * @last Modified by    fangsimin
 * @last Modified time  2020-04-07 15:40:32
 */
import { getDefaultLang } from '@/constants/language';
import { newCurrencyList } from '@/modules/msn/constants';
import Cookie from 'js-cookie';
const getDefaultCountry = () => {
    const localCountry = localStorage.getItem('new_country');
    let country = [];
    if(localCountry) {
        const localList = localCountry.split(',');
        country = (localList.length === 1) ? ['ALL', localList[0].toUpperCase()] : localList;
    } else {
        country = ['ALL', 'ALL'];
    }
    localStorage.setItem('new_country', country.join(','));
    return country;
};

const state = {
    lang: getDefaultLang(),
    country: getDefaultCountry(),
    timezone: localStorage.getItem('timezone') || 'EST',
    platformInfo: {
        isDiscovery: false,
        platform: 'MediaGo'
    },
    helpCenterLang: '',
    currency: '',
    assetImgFlag: false,
    imageFit: false
};
const defaultCurrency = 'USD';
const getters = {
    getCountry: (state, getters, rootState) => {
        console.log(rootState.msn.menu);
        if (rootState.msn.menu['/platform/new_dashboard:country'] === 'rw') {
            return state.country;
        }else {
            return ['ALL', 'ALL'];
        }
    },
    getLang: (state, getters, rootState) => {
        return state.lang;
    },
    getCurrency: (state, getters, rootState) => {
        return state.currency;
    },
    getTimezone: (state, getters, rootState) => {
        return state.timezone;
    },
    getIsDiscovery: (state) => {
        return state.platformInfo.isDiscovery;
    },
    getPlatform: (state) => {
        return state.platformInfo.platform;
    },
    getHelpCenterLang: (state) => {
        return state.helpCenterLang;
    },
    getAssetImgFlag: (state) => {
        return state.assetImgFlag;
    },
    getImageFit: (state) => {
        return state.imageFit;
    },
    // 获取平台币种和符号
    getCurrencyInfo: (state, getters, rootState) => {
        let currencyType = defaultCurrency;
        // console.log(rootState.msn.accountList);
        // 修改平台的币种符号只算可写的账户，不管可读的账户
        const writeableAccount = [];
        rootState.msn.accountList.map(item => {
            if (item.writeable == '1') {
                writeableAccount.push(item);
            }
        });
        // console.log(writeableAccount);
        const currencyList = writeableAccount.map(item => item.currency);
        console.log(currencyList);
        const uniqCurrencyList = Array.from(new Set(currencyList));
        if (uniqCurrencyList.length === 1) {
            currencyType = currencyList[0];
        }
        return {
            currencySymbol: newCurrencyList.find(item => item.value === currencyType).symbol || '',
            currency: currencyType
        };
    },
    // 获取平台币种&符号
    getCurrencySymbol: (state, getters, rootState) => {
        let currencyTypeArr = [];
        let currencyCatch = [];
        let currencyType = 'USD';
        rootState.msn.accountList.map(item => {
            currencyTypeArr.push(item.currency);
        });
        currencyCatch = Array.from(new Set(currencyTypeArr));
        if(currencyCatch.length > 0){
            currencyType = currencyCatch[0];
        }
        return {
            currencySymbol: newCurrencyList.find(item => item.value === currencyType ).symbol || '',
            currency: currencyCatch[0]
        };
    }
};


const mutations = {
    setAssetImgFlag(state, flag) {
        console.log(state);
        state.assetImgFlag = flag;
    },
    setImageFit(state, flag) {
        state.imageFit = flag;
    },
    setCountry(state, country) {
        state.country = country;
        localStorage.setItem('new_country', country);
    },
    switchLang(state, lang) {
        // state.lang = lang;
        // window.app.$i18n.locale = lang;
        // localStorage.setItem('lang', lang);
        state.lang = lang;
        localStorage.setItem('lang', lang);
    },
    switchCurrency(state, currency) {
        state.currency = currency;
        Cookie.set('currency', currency);
        localStorage.setItem('currency', currency);
    },
    setHelpCenterLang(state, lang) {
        state.helpCenterLang = lang;
    },
    setTimezone(state, timezone) {
        state.timezone = timezone;
        localStorage.setItem('timezone', timezone);
    },
    setPlatformInfo(state, platformInfo) {
        const { isDiscovery } = platformInfo;
        const platform = isDiscovery ? 'Discovery' : 'MediaGo';
        state.platformInfo = {
            isDiscovery,
            platform
        };
    }
};

const actions = {
    handleSetTimezone({ commit }, timezone) {
        commit('setTimezone', timezone);
    }
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
};
