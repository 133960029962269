/*
 * @file index.js
 * @author liushengxgi
 * @date 2020-09-01 17:27:42
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-01 17:28:44
 */

export const MODULE_PLATFORM = 'media';

export function elementScrollToTop(elementId, top = 0) {
    const parent = document.getElementById(elementId);
    parent && parent.scrollIntoView({ top, behavior: 'auto' });
}
