/*
 * @file country-zh.js
 * @author liushengxgi
 * @date 2019-12-31 14:47:20
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2019-12-31 14:47:30
 */

export default {
    ALB: '阿尔巴尼亚',
    DZA: '阿尔及利亚',
    AFG: '阿富汗',
    ARG: '阿根廷',
    ARE: '阿联酋',
    ABW: '阿鲁巴',
    OMN: '阿曼',
    AZE: '阿塞拜疆',
    EGY: '埃及',
    ETH: '埃塞俄比亚',
    IRL: '爱尔兰',
    EST: '爱沙尼亚',
    AND: '安道尔',
    AGO: '安哥拉',
    AIA: '安圭拉',
    ATG: '安提瓜和巴布达',
    AUT: '奥地利',
    ALA: '奥兰群岛',
    AUS: '澳大利亚',
    MAC: '澳门',
    BRB: '巴巴多斯',
    PNG: '巴布亚新几内亚',
    BHS: '巴哈马',
    PAK: '巴基斯坦',
    PRY: '巴拉圭',
    PSE: '巴勒斯坦',
    BHR: '巴林',
    PAN: '巴拿马',
    BRA: '巴西',
    BLR: '白俄罗斯',
    BMU: '百慕大',
    BGR: '保加利亚',
    MNP: '北马里亚纳群岛',
    BEN: '贝宁',
    BEL: '比利时',
    ISL: '冰岛',
    PRI: '波多黎各',
    BIH: '波黑',
    POL: '波兰',
    BOL: '玻利维亚',
    BLZ: '伯利兹',
    BWA: '博茨瓦纳',
    BTN: '不丹',
    BFA: '布基纳法索',
    BDI: '布隆迪',
    BVT: '布韦岛',
    PRK: '朝鲜 北朝鲜',
    GNQ: '赤道几内亚',
    DNK: '丹麦',
    DEU: '德国',
    TLS: '东帝汶',
    TGO: '多哥',
    DOM: '多米尼加',
    DMA: '多米尼克',
    RUS: '俄罗斯',
    ECU: '厄瓜多尔',
    ERI: '厄立特里亚',
    FRA: '法国',
    FRO: '法罗群岛',
    PYF: '法属波利尼西亚',
    GUF: '法属圭亚那',
    ATF: '法属南部领地',
    MAF: '法属圣马丁',
    VAT: '梵蒂冈',
    PHL: '菲律宾',
    FJI: '斐济群岛',
    FIN: '芬兰',
    CPV: '佛得角',
    GMB: '冈比亚',
    COG: '刚果（布）',
    COD: '刚果（金）',
    COL: '哥伦比亚',
    CRI: '哥斯达黎加',
    GRD: '格林纳达',
    GRL: '格陵兰',
    GEO: '格鲁吉亚',
    GGY: '根西岛',
    CUB: '古巴',
    GLP: '瓜德罗普',
    GUM: '关岛',
    GUY: '圭亚那',
    KAZ: '哈萨克斯坦',
    HTI: '海地',
    KOR: '韩国 南朝鲜',
    NLD: '荷兰',
    BES: '荷兰加勒比区',
    HMD: '赫德岛和麦克唐纳群岛',
    MNE: '黑山',
    HND: '洪都拉斯',
    KIR: '基里巴斯',
    DJI: '吉布提',
    KGZ: '吉尔吉斯斯坦',
    GIN: '几内亚',
    GNB: '几内亚比绍',
    CAN: '加拿大',
    GHA: '加纳',
    GAB: '加蓬',
    KHM: '柬埔寨',
    CZE: '捷克',
    ZWE: '津巴布韦',
    CMR: '喀麦隆',
    QAT: '卡塔尔',
    CYM: '开曼群岛',
    CCK: '科科斯群岛',
    COM: '科摩罗',
    CIV: '科特迪瓦',
    KWT: '科威特',
    HRV: '克罗地亚',
    KEN: '肯尼亚',
    COK: '库克群岛',
    LVA: '拉脱维亚',
    LSO: '莱索托',
    LAO: '老挝',
    LBN: '黎巴嫩',
    LTU: '立陶宛',
    LBR: '利比里亚',
    LBY: '利比亚',
    LIE: '列支敦士登',
    REU: '留尼汪',
    LUX: '卢森堡',
    RWA: '卢旺达',
    ROU: '罗马尼亚',
    MDG: '马达加斯加',
    IMN: '马恩岛',
    MDV: '马尔代夫',
    FLK: '马尔维纳斯群岛（ 福克兰）',
    MLT: '马耳他',
    MWI: '马拉维',
    MYS: '马来西亚',
    MLI: '马里',
    MKD: '马其顿',
    MHL: '马绍尔群岛',
    MTQ: '马提尼克',
    MYT: '马约特',
    MUS: '毛里求斯',
    MRT: '毛里塔尼亚',
    USA: '美国',
    UMI: '美国本土外小岛屿',
    ASM: '美属萨摩亚',
    VIR: '美属维尔京群岛',
    MNG: '蒙古国 蒙古',
    MSR: '蒙塞拉特岛',
    BGD: '孟加拉',
    PER: '秘鲁',
    FSM: '密克罗尼西亚联邦',
    MMR: '缅甸',
    MDA: '摩尔多瓦',
    MAR: '摩洛哥',
    MCO: '摩纳哥',
    MOZ: '莫桑比克',
    MEX: '墨西哥',
    NAM: '纳米比亚',
    ZAF: '南非',
    ATA: '南极洲',
    SGS: '南乔治亚岛和南桑威奇群岛',
    SSD: '南苏丹',
    NRU: '瑙鲁',
    NPL: '尼泊尔',
    NIC: '尼加拉瓜',
    NER: '尼日尔',
    NGA: '尼日利亚',
    NIU: '纽埃',
    NOR: '挪威',
    NFK: '诺福克岛',
    PLW: '帕劳',
    PCN: '皮特凯恩群岛',
    PRT: '葡萄牙',
    JPN: '日本',
    SWE: '瑞典',
    CHE: '瑞士',
    SLV: '萨尔瓦多',
    WSM: '萨摩亚',
    SRB: '塞尔维亚',
    SLE: '塞拉利昂',
    SEN: '塞内加尔',
    CYP: '塞浦路斯',
    SYC: '塞舌尔',
    SAU: '沙特阿拉伯',
    BLM: '圣巴泰勒米岛',
    CXR: '圣诞岛',
    STP: '圣多美和普林西比',
    SHN: '圣赫勒拿',
    KNA: '圣基茨和尼维斯',
    LCA: '圣卢西亚',
    SMR: '圣马力诺',
    SPM: '圣皮埃尔和密克隆',
    VCT: '圣文森特和格林纳丁斯',
    LKA: '斯里兰卡',
    SVK: '斯洛伐克',
    SVN: '斯洛文尼亚',
    SJM: '斯瓦尔巴群岛和 扬马延岛',
    SWZ: '斯威士兰',
    SDN: '苏丹',
    SUR: '苏里南',
    SLB: '所罗门群岛',
    SOM: '索马里',
    TJK: '塔吉克斯坦',
    THA: '泰国',
    TZA: '坦桑尼亚',
    TON: '汤加',
    TCA: '特克斯和凯科斯群岛',
    TTO: '特立尼达和多巴哥',
    TUN: '突尼斯',
    TUV: '图瓦卢',
    TUR: '土耳其',
    TKM: '土库曼斯坦',
    TKL: '托克劳',
    WLF: '瓦利斯和富图纳',
    VUT: '瓦努阿图',
    GTM: '危地马拉',
    VEN: '委内瑞拉',
    BRN: '文莱',
    UGA: '乌干达',
    UKR: '乌克兰',
    URY: '乌拉圭',
    UZB: '乌兹别克斯坦',
    ESP: '西班牙',
    ESH: '西撒哈拉',
    GRC: '希腊',
    HKG: '香港',
    SGP: '新加坡',
    NCL: '新喀里多尼亚',
    NZL: '新西兰',
    HUN: '匈牙利',
    SYR: '叙利亚',
    JAM: '牙买加',
    ARM: '亚美尼亚',
    YEM: '也门',
    IRQ: '伊拉克',
    IRN: '伊朗',
    ISR: '以色列',
    ITA: '意大利',
    IND: '印度',
    IDN: '印尼',
    GBR: '英国',
    VGB: '英属维尔京群岛',
    IOT: '英属印度洋领地',
    JOR: '约旦',
    VNM: '越南',
    ZMB: '赞比亚',
    JEY: '泽西岛',
    TCD: '乍得',
    GIB: '直布罗陀',
    CHL: '智利',
    CAF: '中非',
    CHN: '中国内地',
    TWN: '台湾'
};
