<template>
    <div
        v-loading="importLoading"
        element-loading-spinner="el-icon-importLoading"
        class="import-container"
    >
        <div class="import-title">
            {{ $t("msn.create.importZipCode") }}
        </div>
        <div
            class="import-body"
            :style="{'padding-bottom': fileList.length > 0 ? '20px' : '30px'}"
        >
            <el-upload
                ref="upload"
                class="msn-import"
                :action="urlList.uploadZipcode"
                accept=".csv"
                name="zipcode"
                :show-file-list="true"
                :before-upload="handleBeforeUpload"
                :on-success="handleSuccess"
                :on-error="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-change="handleFileChange"
                :auto-upload="false"
            >
                <div class="option-container-wrap">
                    <div
                        slot="trigger" class="option-container"
                        style="margin-right:5px;"
                    >
                        <el-button size="small" type="green">
                            {{
                                $t("msn.create.selectCsv")
                            }}
                        </el-button>
                    </div>
                    <div class="option-container">
                        <el-button
                            class="light"
                            size="small"
                            type="bordered"
                            @click.stop="handleDownload"
                        >
                            {{ $t("msn.create.downloadTemplate") }}
                        </el-button>
                    </div>
                </div>
            </el-upload>
        </div>
        <div slot="footer" class="footer-container">
            <span class="btn cancel" @click="handleClose">{{
                $t("msn.button.cancel")
            }}</span>
            <span
                class="btn confirm" type="primary"
                @click="submitUpload"
            >{{
                $t("msn.button.confirm")
            }}</span>
        </div>
    </div>
</template>

<script>
import api from '@/constants/api';
const urlList = {
    zipcodeTemplateDownload: api.zipcodeTemplateDownload,
    uploadZipcode: api.uploadZipcode
};
export default {
    data() {
        return {
            importLoading: false,
            fileList: [],
            urlList,
            // 处理 on-change事件
            isSuccess: false
        };
    },
    methods: {
        handleClose() {
            this.isSuccess = false;
            this.handleRemove();
            this.$emit('close');
        },
        // 上传文件
        submitUpload() {
            this.isSuccess = false;
            if (this.fileList.length > 0) {
                this.importLoading = true;
                this.$refs.upload.submit();
            }
        },
        // 前置校验
        handleBeforeUpload() {
            // console.log(file);
            return true;
        },
        // 上传成功
        handleSuccess(response, file, fileList) {
            this.importLoading = false;
            this.isSuccess = true;
            const data = response.data;
            this.$emit('import', data);
            this.handleClose();
        },
        handleError() {
            this.importLoading = false;
            this.$message.error(this.$t('msn.requestMsg.uploadFailed'));
        },
        // 下载模版
        handleDownload() {
            let a = document.createElement('a');
            a.style.display = 'none';
            a.download = 'zipcode csv template.csv';
            a.href = urlList.zipcodeTemplateDownload;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
            }, 1000);
        },
        handleRemove() {
            this.fileList = [];
            this.$refs.upload.clearFiles();
        },
        handleFileChange(file, fileList) {
            if (!this.isSuccess) {
                this.$refs.upload.clearFiles();
                this.fileList = fileList.length > 0 ? fileList.slice(-1) : [];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.import-container {
    background-color: #f8f9fb;
    border-radius: 6px;
    .import-title {
        font-size: 12px;
        color: #53575b;
        line-height: 1;
        padding: 15px 20px;
    }
    .import-body {
        padding: 0 20px 30px;
        border-bottom: 1px solid #d7d8dd;
    }
    .option-container-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        .option-container {
            height: 30px;
            flex: none;
            .el-button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                width: 140px;
                height: 30px;
                box-shadow: none;
                line-height: 20px;
                padding: 0;
                span {
                    font-size: 12px;
                }
                &.light {
                    background-color: transparent;
                    &:hover,
                    &:focus,
                    &:active {
                        color: #53575b;
                        border-color: #53575b;
                        background: transparent;
                    }
                }
            }
        }
    }

    .btn {
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        & + .btn {
            margin-left: 22px;
        }
        &.cancel {
            color: #a5a8ab;
            &:hover {
                color: #53575b;
            }
        }
        &.confirm {
            color: #2dd1ac;
        }
    }
}
.msn-import {
    line-height: 30px;
    /deep/ .el-upload-list__item {
        color: #53575b;
        font-size: 12px;
        cursor: pointer;
        .el-upload-list__item-name {
            padding-left: 0;
        }
        .el-icon-close {
            display: inline-block;
            right: 0;
        }
        &:hover {
            background: transparent;
            .el-upload-list__item-name {
                .el-icon-document {
                    color: #2dd1ac;
                }
                color: #2dd1ac;
            }
            .el-icon-close {
                color: #2dd1ac;
            }
        }
    }
}
.footer-container {
    text-align: right;
    padding: 0;
    padding-right: 20px;
    height: 30px;
    line-height: 30px;
}
</style>
