export const langList = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: '中简',
        value: 'cn'
    },
    {
        label: '中繁',
        value: 'zhHant'
    },
    {
        label: '日本語',
        value: 'ja'
    },
    {
        label: '한국어 ',
        value: 'ko'
    },
    {
        label: 'ภาษาไทย',
        value: 'th'
    },
    {
        label: 'Indonesia',
        value: 'id'
    }
];


export const languageList = [
    {
        label: '中文（简体）',
        storeLabel: 'ZH',
        value: 'zh'
    },
    {
        label: '中文（繁體）',
        storeLabel: 'zhHant',
        value: 'zhHant'
    },
    {
        label: '日本語',
        storeLabel: 'JA',
        value: 'ja'
    },
    {
        label: 'English',
        storeLabel: 'EN',
        value: 'en'
    },
    {
        label: '한국어 ',
        storeLabel: 'KO',
        value: 'ko'
    }
];

export const getDefaultLang = () => {
    const standardLang = (navigator.userLanguage || navigator.language);
    const isZh = standardLang === 'zh' || standardLang === 'zh-CN';
    const userLang = isZh ? 'cn' : standardLang.startsWith('zh-') ? 'zhHant' : standardLang.slice(0, 2);
    const lang = localStorage.getItem('lang') || userLang;
    const hasDefined = languageList.some(lg => lg.value === lang);
    const curLang = (lang === 'zh' || lang === 'cn') ? 'cn' : !hasDefined ? 'en' : lang;
    localStorage.setItem('lang', curLang);
    return curLang;
};