<template>
    <div class="box">
        <!-- step1 -->
        <div class="box--item box--item__active">
            <div class="box--item--img box--item--img__step1"></div>
            <div class="box--item--desc">
                {{ $t('msn.openAccount.stepOne') }}
            </div>
        </div>
        <!-- step2 -->
        <div class="box--line-box" :class="{'box--line-box__active': activeArr[0]}">
            <div class="box--line-box--line"></div>
        </div>
        <div class="box--item" :class="{'box--item__active': activeArr[0]}">
            <div class="box--item--img box--item--img__step2"></div>
            <div class="box--item--desc">
                {{ $t('msn.openAccount.stepTwo') }}
            </div>
        </div>
        <!-- step3 -->
        <div class="box--line-box" :class="{'box--line-box__active': activeArr[1]}">
            <div class="box--line-box--line"></div>
        </div>
        <div class="box--item" :class="{'box--item__active': activeArr[1]}">
            <div class="box--item--img box--item--img__step3"></div>
            <div class="box--item--desc">
                {{ $t('msn.openAccount.stepThree') }}
            </div>
        </div>
    </div>
</template>

<script>
// step : 1 2 3
export default {
    name: 'Step',
    props: {
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        step: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        activeArr() {
            return [parseInt(this.value, 10) > 1, parseInt(this.value, 10) > 2];
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    height: 90px;
    width: 960px;
    margin: 0 auto;
    &--item {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &--img {
            width: 35px;
            height: 35px;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 12px;
            &__step2 {
                background-image: url(../asset/step2.png);
            }
            &__step3 {
                background-image: url(../asset/step3.png);
            }
        }
        &--desc {
            font-size: 12px;
            font-weight: 600;
            color: #b5b8c1;
            line-height: 1em;
        }
        // active
        &__active {
            .box--item--img__step1 {
                background-image: url(../asset/step1-active.png);
            }
            .box--item--img__step2 {
                background-image: url(../asset/step2-active.png);
            }
            .box--item--img__step3 {
                background-image: url(../asset/step3-active.png);
            }
            .box--item--desc {
                color: #000000;
            }
        }
    }
    &--line-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &--line {
            border-color: #b5b8c1;
            width: 80%;
            height: 0;
            border-top-width: 2px;
            border-top-style: dashed;
        }
        &__active {
            .box--line-box--line {
                border-color: #0067ff;
            }
        }
    }
}
</style>
