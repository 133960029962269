<template>
    <div class="checkbox-group-container">
        <div class="checkbox-header" :class="{'checkbox-header1': (titleSuffix !== '')}">
            <div class="checkbox-title">
                {{ title }}
                <div v-if="titleSuffix !== ''" class="checkbox-header1-suffix">
                    {{ titleSuffix }}
                </div>
            </div>
            <div v-if="!noSelectAll" class="checkbox-all">
                <el-checkbox
                    v-model="allSelect.selected"
                    :indeterminate="allSelect.indeterminate"
                    @change="checkAll"
                >
                    {{ $t('msn.infos.allSelect') }}
                </el-checkbox>
            </div>
        </div>
        <div class="checkbox-wrapper">
            <el-checkbox-group
                v-model="checked" :min="min"
                :max="max"
            >
                <template v-if="type !== 'dashboardAd'">
                    <el-checkbox
                        v-for="item in checkOptions"
                        :key="item.label"
                        class="checkbox-container"
                        :label="item.value"
                        :disabled="disableData.includes(item.value)"
                        :style=" ( item.value === 'mcv' && adListColCvStyle)
                            || ( item.value === 'cv' && adListColCvStyle)
                            || ( item.value === 'cvr' && adListColCvStyle)
                            || ( item.value === 'mcvr' && adListColCvStyle)"
                        :class="{'checkbox-item-hidden': type === 'dashboardDomain' && item.value === 'cpc' && !showCPC}"
                        @change="handleChange($event, item)"
                    >
                        {{ item.noUseTranslate ? item.label : $t(item.label) }}
                    </el-checkbox>
                </template>
                <template v-else>
                    <el-checkbox
                        v-for="item in checkOptions"
                        :key="item.label"
                        class="checkbox-container"
                        :label="item.value"
                        :disabled="disableData.includes(item.value) || (item.value === 'size' && !canAdListColSize)"
                        :style="getItemStyle(item.value) || (item.value === 'size' && adListColSizeStyle)
                            || ( item.value === 'mcv' && adListColCvStyle)
                            || ( item.value === 'cv' && adListColCvStyle)
                            || ( item.value === 'cvr' && adListColCvStyle)
                            || ( item.value === 'mcvr' && adListColCvStyle) "
                        @change="handleChange($event, item)"
                    >
                        {{ item.noUseTranslate ? item.label : $t(item.label) }}
                    </el-checkbox>
                </template>
            </el-checkbox-group>
        </div>
    </div>
</template>

<script>
import mixin from '@/modules/msn/components/PlatformColumn/adListColSize.js';
import PATH from '@/constants/path';
import { mapGetters } from 'vuex';
export default {
    name: 'MsnCheckBoxGroup',
    mixins: [mixin],
    // 取消 size 权限后下列代码删除 mounted 周期
    mounted() {
        if (this.type === 'dashboardAd' && this.title === 'Details') {
            // 如果没有全选 则 js 帮助用户勾上 size
            if (!this.canAdListColSize) {
                if (!this.checked.includes('size')) {
                    this.checked = [...this.checked, 'size'];
                }
            }
        }
    },
    props: {
        title: {
            default: ''
        },
        value: {
            default: () => []
        },
        defaultVal: {
            default: () => []
        },
        checkOptions: {
            default: () => []
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        type: {
            type: String
        },
        beforeChange: {
            default: () => {}
        },
        noSelectAll: {
            type: Boolean,
            default: false
        },
        titleSuffix: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            allSelect: {
                indeterminate: false,
                selected: false
            },
            disable: false
        };
    },
    computed: {
        ...mapGetters('msn', {
            menu: 'getMenu',
            dashboardDomainFlag: 'getDashboardDomainFlag'
        }),
        showCPC() {
            return this.dashboardDomainFlag && this.menu[PATH['dashboardDomainCpc']] === 'rw';
        },
        checked: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        disableData() {
            // 特殊处理：当type为数据报表，且是广告信息类别纬度时，两个都选中时，campainName为不可操作
            if (
                this.type === 'dataReport'
                && this.checkOptions[0].value === 'campaignName'
            ) {
                if (this.checked.length === 2) {
                    return ['campaignName'];
                }
            }
            if (this.type === 'amDashboard') {
                // 【运营分析】新增Language/Country维度指标
                // js 设置选中 this.checked = []
                // 获取默认值
                let defaultVal = [...this.defaultVal];
                // 获取被选中的默认值
                let selected = defaultVal.filter(v => this.checked.includes(v));
                // 变成必选状态的选项
                let res = [];
                // 逻辑1: 'language', 'country' 互斥
                // if (selected.includes('language')) {
                //     res.push('country');
                // }
                // if (selected.includes('country')) {
                //     res.push('language');
                // }
                // 逻辑2: 选择 campaignName accountName 任意一个后 'language', 'country' 不可选 反之也是 互斥关系
                // if (selected.includes('campaignName') || selected.includes('accountName')) {
                //     res.push('country');
                //     res.push('language');
                // }
                // if (selected.includes('country') || selected.includes('language')) {
                //     res.push('campaignName');
                //     res.push('accountName');
                // }
                // 逻辑3: 五选一
                if (selected.length === 0) {
                } else if (selected.length === 1) {
                    res.push(selected[0]);
                } else {
                }
                return res;
            }
            if (this.type === 'dashboardAd') {
                // 勾size 才能勾 adId
                if (!this.checked.includes('size')) {
                    return [...this.defaultVal, 'adId'];
                }
            }
            if (this.type === 'db_amConfig'){
                console.log(this.defaultVal);
                // 【运营分析】新增Language/Country维度指标
                // js 设置选中 this.checked = []
                // 获取默认值
                let defaultVal = [...this.defaultVal];
                // 获取被选中的默认值
                let selected = defaultVal.filter(v => this.checked.includes(v));
                // 变成必选状态的选项
                let res = [];
                if (selected.length === 0) {
                } else if (selected.length === 1) {
                    res.push(selected[0]);
                } else {
                }
                // 如果未勾选campaignName或者campaignId中任意一个时，则cpa为不可操作
                if (!(this.checked.includes('campaignName') || this.checked.includes('campaignId'))) {
                    res.push('cpa');
                }
                return res;

            }
            return this.defaultVal;
        }
    },
    methods: {
        f(value) {
            if (value) {
                return false;
            }else {
                return true;
            }
        },
        checkAll() {
            console.log(this.checked);
            let isAllSelect = this.allSelect.selected;
            this.checked = isAllSelect
                ? this.checkOptions.map(item => item.value)
                : this.defaultVal;
            if (this.type === 'amDashboard') {
                // 防止全选的时候 date language counrty 都被选
                let defaultVal = [...this.defaultVal];
                // 获取被选中的默认值
                let selected = defaultVal.filter(v => this.checked.includes(v));
                if (isAllSelect) {
                    if (selected.length === 1 && selected[0] === 'date') {
                        const disabled = ['language', 'country'];
                        this.checked
                            = this.checkOptions.filter(item => !(disabled.includes(item.value)))
                                .map(item => item.value);
                    } else {
                        const arr1 = ['campaignName', 'accountName', 'companyName'];
                        const arr2 = ['country', 'language'];
                        const disabled = [];
                        // 其实看第一个就够了
                        if (arr1.includes(selected[0])) {
                            disabled.push(...arr2);
                        } else {
                            disabled.push(...arr1);
                        }
                        // // 唯一选中时该选项也会加入到 this.disableData
                        // if (selected.length === 1) {
                        //     const index = disabled.findIndex(e => e === selected[0]);
                        //     disabled.splice(index, 1);
                        // }
                        this.checked
                            = this.checkOptions.filter(item => !(disabled.includes(item.value)))
                                .map(item => item.value);
                    }
                } else {
                    // 取消全选时
                    this.checked = [...selected];
                }
            }
            if (this.min > 0 && !isAllSelect) {
                this.checked = [this.checkOptions[0]['value']];
            }
            // this.beforeChange();
            this.$nextTick(() => {
                this.controlCheckBox();
            });
        },
        handleChange(e, item) {
            if (this.type === 'dataReport') {
                if (e && item.value === 'assetName') {
                    let checked = this.checked.concat([
                        'campaignName',
                        'assetName'
                    ]);
                    this.checked = [...new Set(checked)];
                } else if (
                    !e
                    && item.value === 'campaignName'
                    && this.checked.includes('assetName')
                ) {
                    this.checked.push('campaignName');
                    this.checked = [...new Set(this.checked)];
                }
            }
            if (this.type === 'dashboardAd') {
                // 点击取消 size设置列时 取消 adId的选中
                if (item.value === 'size' && e === false) {
                    if (this.checked.includes('adId')) {
                        this.checked = this.checked.filter((e) => e !== 'adId' && e !== 'size');
                    }
                };
            }
            if (this.type === 'amDashboard') {
                // 逻辑: 组一 campaignName accountName 组二 'language', 'country' 当其中一组的任意元素被选中的时候 另一组全部取消选中
                const _ = (...arr) => {
                    for (let i = 0; i < arr.length; i++) {
                        const target = arr[i];
                        if (target.includes(item.value) && e === true) {
                            arr.splice(i, 1);
                            // 数组扁平化
                            const lt = arr.flat();
                            const newChecked = [...this.checked.filter((e) => !lt.includes(e)), item.value];
                            this.checked = newChecked;
                            break;
                        };
                    }
                };
                const arr1 = ['campaignName', 'accountName', 'companyName'];
                const arr2 = ['country', 'language'];
                _(arr1, arr2);
                // 当选中companyName的时候取消
            }
            if (this.type === 'db_amConfig') {
                this.$nextTick(() => {
                    if (!this.checked.includes('campaignName') && !this.checked.includes('campaignId')) {
                        this.checked = this.checked.filter(e => e !== 'cpa');
                    }
                });
            }
            this.$nextTick(() => {
                this.controlCheckBox();
            });
            if (this.type === 'db_eco') {
                // console.log(this.$parent.selected['adPerformance'].pop());≈
                if (item.value === 'campaignName' && e === true) {
                    this.disable = false;
                }else{
                    this.disable = true;
                }
            }
        },
        controlCheckBox() {
            if (this.checkOptions.length === this.checked.length) {
                this.allSelect.indeterminate = false;
                this.allSelect.selected = true;
            } else if (this.checked.length === 0) {
                this.allSelect.indeterminate = false;
                this.allSelect.selected = false;
            } else {
                this.allSelect.indeterminate = true;
                this.allSelect.selected = false;
            }
        }
    },

    watch: {
        checked: {
            immediate: true,
            handler(newVal, oldVal) {
                this.controlCheckBox();
                if (this.type === 'db_multidimension') {
                    console.log(this.checkOptions);
                    console.log(this.checked);
                    // 列出dimension维度数组
                    let multiArr
                        = ['sspId', 'domain', 'crid', 'campaignId', 'accountId', 'audience',
                           'deviceBrowser', 'devicePlatform', 'deviceOs', 'language', 'country', 'location'];
                    if (this.checkOptions.length === multiArr.length) {
                        let submit = [];
                        multiArr.map(item => {
                            // 判断是否全部为空 将结果放入新数组
                            submit.push(this.checked.includes(item));
                        });
                        // 每次点击更新数组后判断数组内是否全部为false
                        if (submit.every(this.f)) {
                            this.checked.push('accountId');
                            console.log(this.checked);
                        }
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss">
$green: #2DD1AC;
$blue: #0067FF;
$gray: #8CA0B3;
$white: #fff;
$defaultBorderColor: #53575B;
$transparent: transparent;
.checkbox-group-container {
    color: #53575b;
    font-size: 12px;
    font-weight: 500;
    .checkbox-header {
        padding: 0 15px;
        display: flex;
        align-items: center;
        background: #eef1f4;
        height: 35px;
        .checkbox-title {
            flex: 1;
            font-size: 13px;
            font-weight: 700;
        }
        .el-checkbox__inner {
            border-color: $defaultBorderColor;
            background: $transparent;
            line-height: 14px;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: $defaultBorderColor;
        }
        .el-checkbox__input.is-indeterminate .el-checkbox__inner,
        .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #2dd1ac;
            border-color: #2dd1ac;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #53575b;
        }
    }
    .checkbox-header1 {
        height: 45px;
        &-suffix {
            font-size: 12px;
            font-weight: 400;
            color: #a0a4a7;
        }
    }
    .checkbox-wrapper {
        padding: 10px 15px 0;
    }
    .checkbox-container {
        display: block;
        margin-bottom: 8px;
        .el-checkbox__label {
            font-size: 12px !important;
            line-height: 12px !important;
        }
        &:last-child {
            margin-bottom: 5px;
        }
        .el-checkbox__inner {
            background: $transparent;
            border-color: $defaultBorderColor;
            line-height: 14px;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: $defaultBorderColor;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #2dd1ac;
            border-color: #2dd1ac;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #53575b;
        }
    }
    .el-checkbox__input.is-disabled.is-checked {
        .el-checkbox__inner {
            border-color: $gray;
            background: $gray;
            &:after {
                border-color: $white;
            }
        }
    }
}
.checkbox-item-hidden {
    display: none!important;
}
</style>
