<template>
    <el-dropdown
        class="export-dropdown"
        :trigger="triggerType"
        :size="size"
        @command="handleExport"
    >
        <el-button
            class="export-btn"
            type="blue"
            round
            :loading="loading"
        >
            {{ $t('msn.button.export') }}
        </el-button>
        <el-dropdown-menu
            slot="dropdown"
        >
            <div v-for="item in downloadList" :key="item.value">
                <el-dropdown-item
                    icon="msn-fa-export-blue"
                    class="dropdown-item" :command="item.value"
                >
                    {{ $t(item.label) }}
                </el-dropdown-item>
            </div>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
export default {
    name: 'NewExport',
    props: {
        loading: Boolean,
        downloadList: {
            default: () => [],
            type: Array
        },
        triggerType: {
            type: String,
            default: 'click'
        },
        size: {
            type: String,
            default: 'small'
        }
    },
    data() {
        return {};
    },
    methods: {
        handleExport(command){
            this.$emit('change', command);
        }
    }
};
</script>
<style scoped lang="scss">
.export-btn {
    font-weight: 700;
    font-size: 12px;
    width: 80px;
    padding: 0 15px !important;
    line-height: 35px !important;
    display: flex;
    color: #ffff;
    align-items: center;
    justify-content: center;
}
.export-dropdown{
    padding-left: 20px !important;
    /deep/.el-dropdown-menu__item{
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #53575B;
    }
}
</style>