<template>
    <div
        class="box" :class="type"
        @click="$emit('click')"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    /* eslint-disable-next-line */
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'solid' // solid hollow
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    transition: transform 0.5s;
    border-radius: 17.5px;
    font-weight: 600;
    cursor: pointer;
    &:active {
        transform: scale(0.85);
    }
}
.hollow {
    border-color: #53575b;
    background-color: transparent;
    color: #53575b;
    &:hover {
        border-color: #d8d8d9;
        background-color: #d8d8d9;
        color: #53575b;
    }
}
.solid {
    border-color: #0067ff;
    background-color: #0067ff;
    color: #ffffff;
    &:hover {
        border-color: #003d99;
        background-color: #003d99;
    }
}
</style>
