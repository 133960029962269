<template>
    <div
        v-loading="loading" class="page page-open-account"
        element-loading-spinner="el-icon-loading"
    >
        <msn-header>
            <step v-show="!showInvalidToken" v-model="step"/>
        </msn-header>
        <div style="height: 140px"></div>
        <div v-show="!showInvalidToken" class="page--content">
            <!-- 邀请人信息 -->
            <div v-if="!!agentInfo.companyName" class="agent-header">
                <img src="../../../assets/images/icon/file.png">
                <div class="agent-title">
                    {{ agentInfo.companyName }}{{ $t('msn.openAccount.inviteMsg') }}
                </div>
            </div>
            <!-- step1 -->
            <div v-show="step === '1'">
                <el-form
                    ref="account"
                    class="form-container"
                    label-position="top"
                    :hide-required-asterisk="true"
                    :model="account"
                    :rules="accountRules"
                >
                    <div class="form-info">
                        {{ $t('msn.openAccount.accountTitleDesc1') }}
                    </div>
                    <div class="form-info" style="margin-bottom: 10px;">
                        {{ $t('msn.openAccount.accountTitleDesc2') }}
                    </div>
                    <el-form-item prop="accountName">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.accountName') }}</label>
                        <el-input
                            v-model="account.accountName"
                            :placeholder="$t('msn.openAccount.accountName')"
                        />
                    </el-form-item>
                    <el-form-item prop="email">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.accountEmail') }}</label>
                        <el-input v-model="account.email" :placeholder="$t('msn.openAccount.accountEmail')"/>
                    </el-form-item>
                    <el-form-item prop="billingEmail">
                        <div slot="label" class="add-label">
                            <span v-text="$t('msn.openAccount.billingEmail')"></span>
                            <msn-tip :content="$t('msn.openAccount.billingEmailTip')"/>
                        </div>
                        <!-- <label slot="label" class="required-after">{{$t('msn.openAccount.billingEmail')}}</label> -->
                        <msn-normal-input
                            v-model="account.billingEmail"
                            type="textarea"
                            class="billing-email-textarea"
                            :placeholder="$t('msn.openAccount.billingEmail')"
                            :autosize="{minRows: 1, maxRows: 5}"
                        />
                    </el-form-item>
                    <!-- 币种选择 邀请链接新增结算币种 -->
                    <el-form-item prop="settlementCurrency">
                        <div slot="label" class="add-label">
                            <span v-text="$t('msn.openAccount.currency')"></span>
                            <msn-tip :content="$t('msn.openAccount.currencyTitle')"/>
                        </div>
                        <msn-select
                            v-model="account.settlementCurrency"
                            class="account-select"
                            popper-class="account-select-dropdown"
                            style="width: 100%"
                            :option-list="settlementCurrencyList"
                        />
                    </el-form-item>
                    <el-form-item prop="budget">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.totalBudget') }}</label>
                        <el-input v-model="account.budget" :placeholder="$t('msn.openAccount.totalBudget')"/>
                    </el-form-item>
                    <el-form-item prop="payMethod">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.payMethod') }}</label>
                        <msn-radio
                            v-model="account.payMethod"
                            class="account-radio"
                            :radio-opts="[
                                {value: '预付', label: 'msn.openAccount.prepayment'},
                                {value: '到付', label: 'msn.openAccount.delivery'},
                            ]"
                        />
                    </el-form-item>
                    <el-form-item prop="adMethod">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.adMethod') }}</label>
                        <msn-radio
                            v-model="account.adMethod"
                            class="account-radio"
                            :radio-opts="[
                                {value: '自投', label: 'msn.openAccount.selfService'},
                                {value: '代投', label: 'msn.supplement.managed'},
                            ]"
                        />
                    </el-form-item>
                    <!-- 邀请开户新增合同主体 -->
                    <el-form-item
                        prop="contractualSubject"
                        :label="$t('msn.creditLine.contractualTitle')"
                    >
                        <span slot="label" class="required-after">
                            {{ $t('msn.creditLine.contractualTitle') }}
                            <msn-tip
                                placement="right"
                                :content="$t('msn.openAccount.contractualTip')"
                                class="handleTip"
                            />
                        </span>
                        <msn-select
                            v-model="account.contractualSubject"
                            style="width: 100%"
                            :option-list="contractualList"
                            class="account-select"
                            popper-class="account-select-dropdown"
                        />
                    </el-form-item>
                    <el-form-item prop="timezone" class="timezone">
                        <label slot="label" class="required-after">
                            {{ $t('msn.openAccount.timezone') }}
                            <el-popover
                                ref="popover" placement="right"
                                width="200" trigger="hover"
                            >
                                <div class="msn-pw-tip">
                                    <div class="msn-pw-tip--item">
                                        {{ $t('msn.supplement.selectthetimezone') }}
                                    </div>
                                </div>
                            </el-popover>
                            <i v-popover:popover class="msn-tip msn-fa msn-fa-notice timezone--tip"></i>
                        </label>
                        <msn-select
                            v-model="account.timezone"
                            class="account-select"
                            popper-class="account-select-dropdown"
                            style="width: 100%"
                            :option-list="timezoneList"
                        />
                    </el-form-item>
                    <el-form-item prop="country">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.country') }}</label>
                        <msn-select
                            v-model="account.country"
                            class="account-select"
                            popper-class="account-select-dropdown"
                            style="width: 100%"
                            filterable
                            :option-list="countryList"
                        />
                    </el-form-item>
                    <el-form-item prop="companyName">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.companyName') }}</label>
                        <el-input
                            v-model="account.companyName"
                            :placeholder="$t('msn.openAccount.companyName')"
                        />
                    </el-form-item>
                    <el-form-item prop="registerAddress">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.registerAddr') }}</label>
                        <el-input
                            v-model="account.registerAddress"
                            :placeholder="$t('msn.openAccount.registerAddr')"
                        />
                    </el-form-item>
                    <el-form-item prop="businessAddress">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.operateAddr') }}</label>
                        <el-input
                            v-model="account.businessAddress"
                            :placeholder="$t('msn.openAccount.operateAddr')"
                        />
                    </el-form-item>
                    <el-form-item prop="website">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.website') }}</label>
                        <el-input v-model="account.website" :placeholder="$t('msn.openAccount.website')"/>
                    </el-form-item>
                </el-form>
                <div class="btn-box">
                    <msn-button @click="handleBtnClick('next')">
                        {{ $t('msn.supplement.next') }}
                    </msn-button>
                </div>
            </div>
            <!-- step2 -->
            <div v-show="step === '2'">
                <msn-title :title="$t('msn.openAccount.registAccount')"/>
                <el-form
                    ref="user"
                    class="form-container"
                    label-position="top"
                    :model="user"
                    :hide-required-asterisk="true"
                    :rules="userRules"
                >
                    <!-- 邮箱加发送验证码 -->
                    <el-form-item prop="email">
                        <label slot="label" class="required-after">{{ $t('msn.permission.formEmail') }}
                            <span style="color: #B5B8C1;">{{ $t('msn.openAccount.workEmailTip') }}</span>
                        </label>
                        <el-input v-model="user.email"/>
                        <count-down-btn
                            ref="countDown"
                            class="count-down"
                            @click="handleSendVCode"
                        >
                            {{ $t('msn.permission.vCode') }}
                        </count-down-btn>
                    </el-form-item>
                    <!-- 输入验证码 -->
                    <el-form-item prop="vCode">
                        <label slot="label" class="required-after">{{ $t('msn.permission.inputVCode') }}</label>
                        <el-input v-model="user.vCode"/>
                    </el-form-item>
                    <!-- 国家 -->
                    <el-form-item prop="country">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.country') }}</label>
                        <msn-select
                            v-model="user.country"
                            class="account-select"
                            popper-class="account-select-dropdown"
                            style="width: 100%"
                            filterable
                            :option-list="countryList"
                        />
                    </el-form-item>
                    <!-- 姓 -->
                    <el-form-item prop="firstName">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.firstName') }}</label>
                        <el-input v-model="user.firstName" :placeholder="$t('msn.openAccount.firstName')"/>
                    </el-form-item>
                    <!-- 名 -->
                    <el-form-item prop="lastName">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.lastName') }}</label>
                        <el-input v-model="user.lastName" :placeholder="$t('msn.openAccount.lastName')"/>
                    </el-form-item>
                    <!-- 工作 -->
                    <el-form-item prop="jobTitle">
                        <label slot="label" class="required-after">{{ $t('msn.openAccount.jobTitle') }}</label>
                        <el-input v-model="user.jobTitle" :placeholder="$t('msn.openAccount.jobTitle')"/>
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item prop="password">
                        <label slot="label" class="required-after">{{ $t('msn.permission.password') }}</label>
                        <el-input
                            v-model="user.password"
                            v-popover:popover1
                            class="msn-password"
                            type="password"
                            show-password
                        />
                        <el-popover
                            ref="popover1" placement="right"
                            width="200" trigger="focus"
                        >
                            <password-tip :value="user.password"/>
                        </el-popover>
                    </el-form-item>
                </el-form>
                <!-- 存在的账户 -->
                <msn-title :title="$t('msn.openAccount.addExistAccount')"/>
                <div style="position: relative;top: -45px;" class="user-opt-container">
                    <span
                        class="icon-btn icon-btn-add"
                        @click="handleAdd()"
                    >{{ $t('msn.openAccount.add') }}</span>
                </div>
                <div v-for="(oldUser, index) in oldUserList" :key="'email_' + index">
                    <el-form
                        ref="oldUser"
                        class="form-container"
                        label-position="top"
                        :model="oldUser"
                        :hide-required-asterisk="true"
                        :rules="oldUserRules"
                    >
                        <el-form-item prop="email" :error="bindUserError[index]">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.email') }}</label>
                            <el-input v-model="oldUser.email" :placeholder="$t('msn.openAccount.email')"/>
                        </el-form-item>
                    </el-form>
                    <div
                        style="position: relative;top: -107px;left: -26px;"
                        class="user-opt-container"
                    >
                        <span
                            v-show="oldUserList.length > 0"
                            class="icon-btn icon-btn-del"
                            @click="handleDelete(index)"
                        >{{ $t('msn.openAccount.del') }}</span>
                    </div>
                </div>
                <msn-protocol v-model="protocolForm" class="protocol"/>
                <div class="btn-box">
                    <msn-button
                        style="margin-right: 30px"
                        type="hollow"
                        @click="handleBtnClick('previous')"
                    >
                        {{ $t('msn.supplement.previous') }}
                    </msn-button>
                    <msn-button @click="handleBtnClick('submit')">
                        {{ $t('message.button.submit') }}
                    </msn-button>
                </div>
            </div>
            <!-- step3 -->
            <div v-show="step === '3'">
                <div class="form-container">
                    <div class="messageTip">
                        <span>
                            {{ $t('msn.supplement.previous1') }}
                        </span>
                        <br>
                        <span>{{ $t('msn.supplement.previous2') }}</span>
                        <br>
                        <span>{{ $t('msn.supplement.previous3') }}</span>
                        <a class="link" href="mailto:ext_mediago_am@baidu.com">ext_mediago_am@baidu.com</a>
                        <br>
                        <span>{{ $t('msn.supplement.previous4') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示 -->
        <section v-show="showInvalidToken" class="page--feedback">
            <section class="content">
                <img src="../../../assets/images/icon/alert.png">
                <div class="card-text">
                    <div class="text">
                        {{ $t('msn.openAccount.invalidToken') }}
                    </div>
                </div>
            </section>
            <section class="card-footer">
                <div class="text">
                    {{ $t('msn.openAccount.footer1') }}
                    <a
                        class="link"
                        href="mailto:ext_mediago_am@baidu.com"
                    >ext_mediago_am@baidu.com</a>
                </div>
                <div class="text">
                    {{ $t('msn.openAccount.footer2') }}
                </div>
            </section>
        </section>
    </div>
    <!-- <msn-header></msn-header> -->
</template>

<script>
import Header from './components/Header';
import Step from './components/Step';
import Title from './components/Title';
import {userRules, accountRules, oldUserRules} from '../constants';
import {timezoneList} from '@/modules/msn/constants/';
import {countryList} from '@/constants/countryList';
import {settlementCurrencyList, contractualList} from '../../../constants';
import MsnSelect from '@/components/MsnSelect/Select';
import MsnRadio from '@/components/MsnRadio/';
// import MsnCheckbox from '@/components/MsnCheckbox/Checkbox';
import api from '@/constants/api';
import Button from '../components/Button';
import CountDownBtn from '@/components/CountDownBtn';
import PasswordTip from '../components/PasswordTip';
import Protocol from '../components/Protocol';
import md5 from 'md5';
import MsnNormalInput from '@/components/MsnInput/normalInput';
import MsnTip from '@/components/MsnTip/tip';

const OLD_USER = {email: ''};
export default {
    name: 'InviteOpenUserAndAccount',
    components: {
        Step,
        'msn-header': Header,
        'msn-title': Title,
        MsnSelect,
        MsnRadio,
        // MsnCheckbox,
        CountDownBtn,
        PasswordTip,
        'msn-button': Button,
        'msn-protocol': Protocol,
        MsnNormalInput,
        MsnTip
    },
    data() {
        return {
            loading: false,
            step: '1',
            accountRules: accountRules.call(this),
            userRules: userRules.call(this),
            oldUserRules: oldUserRules.call(this),
            timezoneList,
            account: {
                payMethod: '预付',
                adMethod: '自投',
                timezone: 'EST',
                billingEmail: '',
                currency: '',
                settlementCurrency: 'USD',
                contractualSubject: 'mediago'
            },
            showInvalidToken: false,
            agentInfo: {},
            user: {},
            oldUserList: [],
            protocolForm: [false, true],
            bindUserError: [],
            token: '',
            settlementCurrencyList,
            contractualList
        };
    },
    methods: {
        handleDelete(index) {
            this.oldUserList.splice(index, 1);
        },
        handleAdd() {
            this.oldUserList.push(Object.assign({}, OLD_USER));
        },
        // 发送验证码
        handleSendVCode() {
            this.$refs.user.validateField('email', errorMessage => {
                if (errorMessage === '') {
                    this.$request(
                        {
                            method: 'post',
                            url: api.sendEmailCheck,
                            contentType: 'application/json; charset=UTF-8',
                            success(res) {
                                this.$message({
                                    message: this.$t('msn.backendMsg.sendCodeSucs'),
                                    type: 'success'
                                });
                            },
                            error() {
                                this.$refs.countDown.reset(3);
                            }
                        },
                        {emailAddress: this.user.email}
                    );
                }
                else {
                    this.$refs.countDown.reset(3);
                }
            });
        },
        getAgentInfo() {
            this.loading = true;
            this.$request(
                {
                    method: 'get',
                    url: api.getTokenInfo,
                    success(res) {
                        this.loading = false;
                        this.agentInfo = res.data || {};
                        this.showInvalidToken = !res.data;
                    },
                    error() {
                        this.loading = false;
                    }
                },
                {
                    token: this.token
                }
            );
        },
        handleBtnClick(type) {
            switch (type) {
                case 'next': {
                    this.$refs.account.validate((result, data) => {
                        if (result) {
                            this.step = '2';
                            document.documentElement.scrollTop = 0;
                        }
                    });
                    break;
                }
                case 'previous': {
                    this.step = '1';
                    document.documentElement.scrollTop = 0;
                    break;
                }
                case 'submit': {
                    this.handleSubmit();
                    break;
                }
            }
        },
        handleSubmit() {
            // 存在的账户邮箱校验
            let oldUserValidate = true;
            let temp = this.$refs.oldUser;
            if (temp) {
                temp.forEach(item => {
                    item.validate(result => {
                        if (!result) {
                            oldUserValidate = false;
                        }
                    });
                });
            }
            // 绑定用户表单校验
            let userValidate;
            this.$refs.user.validate(result => {
                userValidate = result;
            });
            // 勾选框1是否勾选了
            if(!this.isDiscovery){
                console.log(this.isDiscovery, '++++++++++处理不是popin不需要校验隐私条款框');
                if (!this.protocolForm[0]) {
                    this.$message.error(this.$t('msn.permission.p1'));
                    return;
                }
            }
            // 提交表单
            if (userValidate && oldUserValidate) {
                // 处理参数差异
                const user = {...this.user};
                user.code = user.vCode;
                delete user.vCode;
                user.passWord = md5(user.password).toUpperCase();
                delete user.password;
                user.acceptAdvice = this.protocolForm[1] ? '1' : '0';
                //
                const {settlementCurrency} = this.account;
                const {contractualSubject} = this.account;
                let params = {
                    token: this.token,
                    accountName: this.account.accountName,
                    email: this.account.email,
                    budget: +this.account.budget,
                    payMethod: this.account.payMethod,
                    adMethod: this.account.adMethod,
                    timezone: this.account.timezone,
                    companyName: this.account.companyName,
                    registerAddress: this.account.registerAddress,
                    businessAddress: this.account.businessAddress,
                    website: this.account.website,
                    applyUsers: [user],
                    bindUsers: (this.oldUserList || []).map(odl => odl.email),
                    country: this.account.country,
                    // 邀请链接开户新增法人所在地
                    locationOfCorporation: this.account.country,
                    // 交易币种
                    currency: this.account.settlementCurrency,
                    // 新增结算币种字段
                    settlementCurrency,
                    contractualSubject
                };
                console.log(params.locationOfCorporation, params.settlementCurrency);
                // 财务邮箱去除空格和换行
                params.billingEmail
                    = require('@/modules/msn/pages/permission/accountMgr/constants.js')
                        .billingEmailFormat(this.account.billingEmail);
                this.bindUserError = [];
                this.$request(
                    {
                        url: api.openAccount,
                        method: 'post',
                        contentType: 'application/json; charset=UTF-8',
                        success(res) {
                            if (res.error) {
                                res.error.email && this.$message.error(this.$t('msn.validations.repeatEmail'));
                                let bindError = res.error.bindEmail || [];
                                bindError.forEach(e => {
                                    this.bindUserError[e] = this.$t('msn.validations.emailNotExist');
                                });
                            }
                            else {
                                this.step = '3';
                                document.documentElement.scrollTop = 0;
                            }
                        }
                    },
                    params
                );
            }
        }
    },
    computed: {
        countryList() {
            return countryList.map(c => {
                return {
                    label: `msn.${c}`,
                    value: c
                };
            });
        }
    },
    mounted() {
        this.token = this.$route.query && this.$route.query.token;
        if (this.token) {
            this.getAgentInfo();
        }
        else {
            this.showInvalidToken = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./common/common.scss";
.billing-email-textarea{
  /deep/ textarea{
    font-size:14px!important;
    border: 1px solid #c4c9d6!important;
    background-color: #fff!important;
    border-radius: 3px!important;
    padding-top:10px!important;
    min-height:40px!important;
    &:focus,&:hover{
      border: 1px solid #0067ff!important;
    }
  }

}
.page {
    .user-opt-container {
        font-size: 14px;
        line-height: 20px;
        padding: 5px 0;
        text-align: right;
        font-weight: 700;
        .icon-btn {
            position: relative;
            cursor: pointer;
            &:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                background-position: center center;
                background-size: 100% auto;
                background-repeat: no-repeat;
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .icon-btn-add {
            color: #0067ff;
            margin-left: 30px;
            &:before {
                background-image: url("../../../assets/images/icon/add-oa.png");
            }
            &:hover {
                color: #003d99;
                &:before {
                    background-image: url("../../../assets/images/icon/add-oa-h.png");
                }
            }
        }
        .icon-btn-del {
            color: #e92754;
            &:before {
                background-image: url(../../../assets/images/icon/del-oa.png);
            }
            &:hover {
                color: #b8002c;
                &:before {
                    background-image: url(../../../assets/images/icon/del-oa-h.png);
                }
            }
        }
    }
}
.messageTip {
    font-size: 18px;
    color: #000000;
    line-height: 2.5em;
    font-weight: 800;
    text-align: center;
    padding: 125px 0 160px;
}
.timezone {
    &--tip {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.handleTip{
    position: absolute;
    right: -20px;
}
</style>
<style lang="scss">
@import "./common/reset.scss";
</style>
