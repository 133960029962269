<template>
    <div class="tree-container">
        <el-tree v-bind="$props"/>
    </div>
</template>

<script>
import {Tree} from 'element-ui';
export default {
    name: 'MsnTree',
    props: {
        ...Tree.props
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
