/* eslint-disable guard-for-in */
/*
 * @file filters.js 用于存放全局过滤器
 * @author liushengxgi
 * @date 2020-03-05 17:23:07
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-03-05 17:23:13
 */
import { currencySymbolFormat, getCurrencyDataFormat, getCurrencyFinanceFormat } from './utils';
/**
 * 数字转换
 * @param {string | number} value
 * @param {number} decimals 小数位数
 */
const numberFormat = (value, decimals = 2) => {
    if (isNaN(value)) {
        return value;
    }
    // 如果返回的数是 int类型的最小值，返回 -
    if (value + '' === '-2147483648') {
        return '-';
    }
    // 将value转成字符串，然后才能使用字符串方法
    value = '' + value;
    let sign = '';
    if (['-', '+'].includes(value[0])) {
        sign = value[0];
        value = value.slice(1);
    }

    let intPart = Number(value) - (Number(value) % 1); // 获取整数部分
    let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断

    let value2Array = value.toString().split('.');

    // 表示数据有小数位
    if (value2Array.length === 2) {
        let floatPart = value2Array[1].toString();
        floatPart = Number('0.' + floatPart).toFixed(decimals).slice(2);
        return `${sign}${intPartFormat}.${floatPart}`;
    } else {
        return `${sign}${intPartFormat}`;
    }
};

const weekFormat = (str, format) => {
    if (format === undefined) {
        throw new Error('');
    }
    const map1 = {
        Mon: 'Monday',
        Tues: 'Tuesday',
        Wed: 'Wednesday',
        Thur: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday',
        Sun: 'Sunday'
    };
    const map2 = {
        Monday: 'Mon',
        Tuesday: 'Tues',
        Wednesday: 'Wed',
        Thursday: 'Thur',
        Friday: 'Fri',
        Saturday: 'Sat',
        Sunday: 'Sun'
    };
    if (format === 1) {
        for(let key in map1){
            if(map1.hasOwnProperty(key)) {
                const r = new RegExp(key, 'ig');
                str = str.replace(r, map1[key]);
            }
        }
        return str;
    }
    if (format === 2) {
        for(let key in map2){
            if(map2.hasOwnProperty(key)) {
                const r = new RegExp(key, 'ig');
                str = str.replace(r, map2[key]);
            }
        }
        return str;
    }
};
// 财务合计加专用货币符号
const currencyFinanceFormat = (v) => {
    for(let m in v) {
        const currencySym = v[m].data.currency;
        for(let n in v[m].data) {
            v[m].data[n] = getCurrencyFinanceFormat(currencySym, numberFormat(v[m].data[n]));
        }
    }
};

const twoDecimalPlaces = (num) => {
    return Math.round(num * 100) / 100;
};
function formatCurrency (key){
    return (row) => getCurrencyDataFormat(row.currency, row[key]);
}
function formatSummary(summary, {currency}, decimals = 2) {
    return getCurrencyDataFormat(currency, '') + numberFormat((summary && +summary) || 0, decimals);
}
export default {
    numberFormat,
    weekFormat,
    currencySymbolFormat,
    getCurrencyDataFormat,
    getCurrencyFinanceFormat,
    currencyFinanceFormat,
    twoDecimalPlaces,
    formatCurrency,
    formatSummary
};
