/*
 * @file state-cn.js
 * @author liushengxgi
 * @date 2020-02-10 11:07:54
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-10 11:08:41
 */

export default {
  // 美国
    AL: '앨라배마 주',
    AK: '알래스카 주',
    AZ: '애리조나 주',
    AR: '아칸소 주',
    CA: '캘리포니아 주',
    CO: '콜로라도 주',
    CT: '코네티컷 주',
    DE: '델라웨어 주',
    FL: '피리다 주',
    GA: '조지아 주',
    HI: '하와이 주',
    ID: '아이다호 주',
    IL: '일리노이 주',
    IN: '인디애나 주',
    IA: '아이오와 주',
    KS: '캔자스 주',
    KY: '켄터키 주',
    LA: '루이지애나 주',
    ME: '메인 주',
    MD: '메릴랜드 주',
    MA: '매사추세츠 주',
    MI: '미시간 주',
    MN: '미네소타 주',
    MS: '미시시피 주',
    MO: '미시시피 주',
    MT: '몬태나 주',
    NE: '네브래스카 주',
    NV: '네바다 주',
    NH: '뉴햄프셔 주',
    NJ: '뉴저지 주',
    NM: '뉴멕시코 주',
    NY: '纽约州',
    NC: '노스캐롤라이나 주',
    ND: '노스다코타 주',
    OH: '오하이오 주',
    OK: '오클라호마 주',
    OR: '오리건 주',
    PA: '펜실베이니아 주',
    RI: '로드아일랜드 주',
    SC: '사우스캐롤라이나 주',
    SD: '사우스다코타 주',
    TN: '테네시 주',
    TX: '텍사스 주',
    UT: '유타 주',
    VT: '버몬트 주',
    VA: '버지니아 주',
    WAI: '워싱턴 주',
    WV: '웨스트버지니아 주',
    WI: '위스콘신 주',
    WY: '와이오밍 주',
    OT: '기타',
    DC: '기타',
    // 加拿大
    AB: '앨버타 주',
    BC: '브리티시컬럼비아 주',
    MB: '매니토바',
    NB: '뉴브런즈윅 주',
    NL: '뉴펀들랜드 래브라도 주',
    NS: '노바스코샤',
    NT: '노스웨스트 준주',
    NU: '누나부트',
    ON: '온타리오',
    PE: '프린스에드워드 섬',
    QC: '퀘벡 주',
    SK: '서스캐처원 주',
    YT: '유콘',
    // 澳大利亚
    NSW: '뉴사우스웨일스 주',
    QLD: '퀸즐랜드 주',
    SA: '사우스오스트레일리아 주',
    TAS: '태즈메이니아 주',
    VIC: '빅토리아',
    WA: '웨스턴오스트레일리아 주',
    ACT: '오스트레일리아 수도 준주',
    NT01: '노던 준주',
    // 英国
    ENG: '잉글랜드',
    NIR: '북아일랜드',
    SCT: '스코틀랜드',
    WLS: '웨일즈',
    // 日本
    JP13: '도쿄',
    JP01: '홋카이도',
    JP26: '교토',
    JP27: '오사카',
    JP02: '아오모리',
    JP03: '이와테',
    JP04: '미야기',
    JP05: '아키타',
    JP06: '야마가타',
    JP07: '후쿠시마',
    JP08: '이바라키',
    JP09: '토치기',
    JP10: '군마',
    JP11: '사이타마',
    JP12: '치바',
    JP14: '카나가와',
    JP15: '니가타',
    JP16: '토야마',
    JP17: '이시가와',
    JP18: '후쿠이',
    JP19: '야마시나',
    JP20: '나가노',
    JP21: '기후',
    JP22: '시즈오카',
    JP23: '아이치',
    JP24: '미에',
    JP25: '시가',
    JP28: '효고',
    JP29: '나라',
    JP30: '와카야마',
    JP31: '톳토리',
    JP32: '시마네',
    JP33: '오카야마',
    JP34: '히로시마',
    JP35: '야마구치',
    JP36: '토쿠시마',
    JP37: '카가와',
    JP38: '에히메',
    JP39: '코치',
    JP40: '후쿠오카',
    JP41: '사가',
    JP42: '나가사키',
    JP43: '쿠마모토',
    JP44: '오이타',
    JP45: '미아자키',
    JP46: '카고시마',
    JP47: '오키나와',
    // 韩国
    KR11:	'서울특별시',
    KR26:	'부산광역시',
    KR27:	'대구광역시',
    KR28:	'인천광역시',
    KR29:	'광주광역시',
    KR30:	'대전광역시',
    KR31:	'울산광역시',
    KR50:	'세종특별자치시',
    KR41:	'경기도',
    KR42:	'강원도',
    KR43:	'충청북도',
    KR44:	'충청남도',
    KR45:	'전라북도',
    KR46:	'전라남도',
    KR47:	'경상북도',
    KR48:	'경상남도',
    KR49:	'제주특별자치도',
    // 台湾
    NWT: '타이베이',
    TPE: '신베이',
    TAO: '타오위안',
    TXG: '타이중',
    TNN: '타이난',
    KHH: '가오슝',
    ILA: '이란',
    HSQ: '신죽',
    MIA: '먀오리',
    CHA: '창화',
    NAN: '남투',
    YUN: '운림',
    CYQ: '자이',
    PIF: '핑둥',
    TTT: '화련',
    HUA: '타이둥',
    PEN: '펑후',
    KEE: '지룽',
    // 印尼
    IDAC: '아체특구',
    IDBA: '발리 주',
    IDBB: '방카 벨리퉁 섬',
    IDBT: '완단 주',
    IDBE: '명구루 주',
    IDGO: '중자바 주',
    IDJA: '칼리만탄 주',
    IDJB: '술라웨시 주',
    IDJT: '동자바 주',
    IDJI: '칼리만탄 주',
    IDKB: '이스트누샤덴가라 주',
    IDKS: '콜럼탈로 주',
    IDKT: '자카르타 수도 특구',
    IDKI: '점비성',
    IDKU: '남방성',
    IDKR: '마루구 주',
    IDLA: '북칼리만탄 주',
    IDMA: '북마루코 주',
    IDMU: '북술라웨시 주',
    IDNB: '북수마트라 주',
    IDNT: '파푸아 주',
    IDPA: '요내성',
    IDPB: '리우네 제도 주',
    IDRI: '동남술라웨시 주',
    IDSR: '남칼리만탄 주',
    IDSN: '남술라웨시 주',
    IDST: '남수마트라 주',
    IDSG: '시자바 주',
    IDSA: '서칼리만탄 주',
    IDSB: '니시누샤덴가라 주',
    IDSS: '서파푸아 주',
    IDSU: '서술라웨시 주',
    IDJK: '서수마트라 주',
    IDYO: '욕야카르타 특별 주'
};

