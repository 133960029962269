/*
 * @file breadcrumbPath.js
 * @author liushengxgi
 * @date 2020-04-01 00:08:06
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-04-01 00:08:17
 */

// 头部跳转
export const breadcrumbLinks = [];
