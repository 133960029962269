<template>
    <section class="page-no-permission">
        <header class="login-header">
            <div class="login-logo" :class="{'login-logo-discovery': isDiscovery}"></div>
        </header>
        <el-card
            v-loading="loading"
            class="box-card card-container"
            element-loading-spinner="el-icon-loading"
        >
            <header class="card-header">
                {{ $t("msn.permission.title") }}
                <span style="font-size: 12px; float: right">
                    {{ $t("msn.permission.tologinText", {platform: platform}) }}
                    <router-link
                        class="link"
                        :to="{path: loginPath}"
                        :style="lang === 'en' ? {'margin-left': '5px'} : {}"
                    >
                        {{ $t("msn.login.loginText") }}
                    </router-link>
                </span>
            </header>
            <section class="card-body">
                <el-form
                    ref="form"
                    class="form-container"
                    :model="form"
                    :rules="rules"
                >
                    <!-- 邮箱加发送验证码 -->
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.email,
                            blurStyle.email,
                        ]"
                        prop="email"
                    >
                        <div
                            class="msn-label required"
                            v-text="$t('msn.permission.formEmail')"
                        >
                        </div>
                        <el-input
                            v-model="form.email"
                            @focus="handleInputFocus('email')"
                            @change="handleGa('in')"
                            @blur="handleInputBlur('email')"
                        />
                        <count-down-btn
                            ref="countDown"
                            class="count-down"
                            @click="handleSendVCode"
                        >
                            {{ $t("msn.permission.vCode") }}
                        </count-down-btn>
                    </el-form-item>
                    <!-- 输入验证码 -->
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.vCode,
                            blurStyle.vCode,
                        ]"
                        prop="vCode"
                    >
                        <div class="msn-label required">
                            {{ $t("msn.permission.inputVCode") }}
                        </div>
                        <el-input
                            v-model="form.vCode"
                            @focus="handleInputFocus('vCode')"
                            @blur="handleInputBlur('vCode')"
                            @change="handleGa('in')"
                        />
                    </el-form-item>
                    <!-- 国家加时区 -->
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.country,
                            blurStyle.country,
                        ]"
                        prop="country"
                    >
                        <div class="msn-label required">
                            {{ $t("msn.permission.country") }}
                        </div>
                        <el-select
                            v-model="form.country"
                            filterable
                            class="p-select"
                            popper-class="p-select-popper"
                            placeholder
                            @focus="handleInputFocus('country')"
                            @blur="handleInputBlur('country')"
                            @change="handleGa('in')"
                        >
                            <el-option
                                v-for="item in countryList"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.timeZone,
                            blurStyle.timeZone,
                        ]"
                        prop="timeZone"
                    >
                        <div class="msn-label required">
                            {{ $t("msn.permission.timeZone") }}
                        </div>
                        <el-select
                            v-model="form.timeZone"
                            class="p-select"
                            popper-class="p-select-popper"
                            placeholder
                            @focus="handleInputFocus('timeZone')"
                            @blur="handleInputBlur('timeZone')"
                            @change="handleGa('in')"
                        >
                            <el-option
                                v-for="item in timeZoneList"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                            />
                        </el-select>
                        <el-popover
                            ref="popover"
                            placement="right"
                            width="200"
                            trigger="hover"
                        >
                            <div class="msn-pw-tip">
                                <div class="msn-pw-tip--item">
                                    {{ $t('msn.permission.timeZoneTip') }}
                                </div>
                            </div>
                        </el-popover>
                        <i
                            v-popover:popover
                            class="msn-tip msn-fa msn-fa-notice"
                        ></i>
                    </el-form-item>
                    <!-- 货币选择 -->
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.currency,
                            blurStyle.currency,
                        ]"
                        prop="settlementCurrency"
                    >
                        <div class="msn-label required">
                            {{ $t("msn.permission.currency") }}
                        </div>
                        <el-select
                            v-model="form.settlementCurrency"
                            filterable
                            class="p-select"
                            popper-class="p-select-popper"
                            placeholder
                            @focus="handleInputFocus('currency')"
                            @blur="handleInputBlur('currency')"
                            @change="handleGa('in')"
                        >
                            <el-option
                                v-for="item in settlementCurrencyList"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                            />
                        </el-select>
                        <el-popover
                            ref="popoverCurrency"
                            placement="right"
                            width="200"
                            trigger="hover"
                        >
                            <div class="msn-pw-tip">
                                <div class="msn-pw-tip--item">
                                    {{ $t('msn.permission.currencyTip') }}
                                </div>
                            </div>
                        </el-popover>
                        <i
                            v-popover:popoverCurrency
                            class="msn-tip msn-fa msn-fa-notice"
                        ></i>
                    </el-form-item>
                    <!-- 姓氏加名称 -->
                    <el-row :gutter="20" justify="space-between">
                        <el-col :span="12">
                            <el-form-item
                                :class="[
                                    'msn-form-item',
                                    focusStyle.firstName,
                                    blurStyle.firstName,
                                ]"
                                prop="firstName"
                            >
                                <div class="msn-label required">
                                    {{ $t("msn.permission.firstName") }}
                                </div>
                                <el-input
                                    v-model="form.firstName"
                                    @focus="handleInputFocus('firstName')"
                                    @blur="handleInputBlur('firstName')"
                                    @change="handleGa('in')"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :class="[
                                    'msn-form-item',
                                    focusStyle.lastName,
                                    blurStyle.lastName,
                                ]"
                                prop="lastName"
                            >
                                <div class="msn-label required">
                                    {{ $t("msn.permission.lastName") }}
                                </div>
                                <el-input
                                    v-model="form.lastName"
                                    @focus="handleInputFocus('lastName')"
                                    @blur="handleInputBlur('lastName')"
                                    @change="handleGa('in')"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 公司网址加工作岗位 -->
                    <el-row :gutter="20" justify="space-between">
                        <el-col :span="12">
                            <el-form-item
                                :class="[
                                    'msn-form-item',
                                    focusStyle['companyWebsite'],
                                    blurStyle.companyWebsite,
                                ]"
                                prop="companyWebsite"
                            >
                                <div class="msn-label required">
                                    {{ $t("msn.permission.companySite") }}
                                </div>
                                <el-input
                                    v-model="form.companyWebsite"
                                    @focus="handleInputFocus('companyWebsite')"
                                    @blur="handleInputBlur('companyWebsite')"
                                    @change="handleGa('in')"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :class="[
                                    'msn-form-item',
                                    focusStyle['jobTitle'],
                                    blurStyle.jobTitle,
                                ]"
                                prop="jobTitle"
                            >
                                <div class="msn-label required">
                                    {{ $t("msn.permission.jobTitle") }}
                                </div>
                                <el-input
                                    v-model="form.jobTitle"
                                    @focus="handleInputFocus('jobTitle')"
                                    @blur="handleInputBlur('jobTitle')"
                                    @change="handleGa('in')"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <input type="text" class="is-hidden">
                    <input type="password" class="is-hidden">
                    <!-- 密码 -->
                    <el-form-item
                        :class="[
                            'msn-form-item',
                            focusStyle.password,
                            blurStyle.password,
                        ]"
                        prop="password"
                    >
                        <div class="msn-label required">
                            {{ $t("msn.permission.password") }}
                        </div>
                        <el-input
                            v-model="form.password"
                            v-popover:popover1
                            class="msn-password"
                            type="password"
                            show-password
                            @focus="handleInputFocus('password')"
                            @blur="handleInputBlur('password')"
                            @change="handleGa('in')"
                        />
                        <el-popover
                            ref="popover1"
                            placement="right"
                            width="200"
                            trigger="focus"
                        >
                            <password-tip :value="form.password"/>
                        </el-popover>
                    </el-form-item>
                </el-form>
            </section>
            <footer class="page-footer">
                <protocol
                    v-model="protocolForm"
                    :hide-privacy="true"
                />
                <el-button type="primary" @click="handleApply">
                    {{ $t("msn.permission.apply") }}
                </el-button>
            </footer>
        </el-card>
        <div class="bg-text">
            <h1>
                Tap into a global, premium<br>media ecosystem with {{ platform }}.
            </h1>
            <ul class="h2-wrap">
                <li class="h2-item">
                    <h2 class="normal-text">
                        Premium native inventory with top sites
                    </h2>
                </li>
                <li class="h2-item">
                    <h3 class="normal-text">
                        Precise audience targeting, transparency and trust
                    </h3>
                </li>
            </ul>
        </div>
        <protocol-preview
            ref="pp"
            :visible="ppShow"
            @close="ppShow = false"
            @submit="sendApply"
        />
    </section>
</template>

<script>
import md5 from 'md5';
import { mapGetters } from 'vuex';
import { rules } from './constants';
import api from '@/constants/api';
import PATH from '@/constants/path';
import CountDownBtn from '@/components/CountDownBtn';
import { countryList } from '@/constants/countryList';
import { timezoneList, settlementCurrencyList, DOMAIN_MAP } from '@/modules/msn/constants/';
import Protocol from './components/Protocol';
import ProtocolPreview from './components/ProtocolPreview';
import PasswordTip from './components/PasswordTip';
import tool from '@/modules/msn/common/tool';
export default {
    name: 'MsnApply',
    data() {
        return {
            // 第一次输入的时候发送一条 GA 只发一次
            inputGaFlow: true,
            ppShow: false,
            timeZoneList: timezoneList,
            form: {
                country: 'USA',
                timeZone: 'EST',
                currency: '',
                settlementCurrency: 'USD'
            },
            protocolForm: [false, true],
            rules: rules.call(this),
            loading: false,
            focusStyle: {
                country: '',
                timeZone: '',
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                companyWebsite: '',
                jobTitle: '',
                phoneNumber: '',
                vCode: '',
                password: '',
                currency: ''
            },
            blurStyle: {
                country: 'blur',
                timeZone: 'blur',
                currency: 'blur',
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                companyWebsite: '',
                jobTitle: '',
                phoneNumber: '',
                vCode: '',
                password: ''
            },
            settlementCurrencyList,
            loginPath: PATH['login']
        };
    },
    components: {
        CountDownBtn,
        Protocol,
        PasswordTip,
        ProtocolPreview
    },
    computed: {
        ...mapGetters('common', {
            lang: 'getLang',
            isDiscovery: 'getIsDiscovery',
            platform: 'getPlatform'
        }),
        isDev() {
            const host = window.location.host;
            const isDev = /localhost/.test(host) || /ec2-44-233-123-136/.test(host);
            return isDev;
        },
        countryList() {
            return countryList.map(c => {
                return {
                    label: `msn.${c}`,
                    value: c
                };
            });
        }
    },
    methods: {
        executeScript(str){
            const script = document.createElement('script');
            script.innerHTML = str;
            document.getElementsByTagName('head')[0].appendChild(script);
        },
        linkedinGaInit(){
            const script1 = `
                _linkedin_partner_id = "2901228";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            `;
            const script2 = `
                (function(){
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);
                })();
            `;
            this.executeScript(script1);
            this.executeScript(script2);
        },
        linkedinGa(){
            // request img
            const o = new Image();
            o.src = 'https://px.ads.linkedin.com/collect/?pid=2901228&conversionId=3597620&fmt=gif';
        },
        twitterGaInit(){
            const script1 = `
                !function(e,t,n,s,u,a){
                    e.twq||
                    (
                        s=e.twq=function(){
                            s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                        },
                        s.version='1.1',
                        s.queue=[],
                        u=t.createElement(n),
                        u.async=!0,
                        u.src='//static.ads-twitter.com/uwt.js',
                        a=t.getElementsByTagName(n)[0],
                        a.parentNode.insertBefore(u,a)
                    )
                }(window,document,'script');
                twq('init','o5cly');
                twq('track','PageView');
            `;
            this.executeScript(script1);
        },
        twitterGa(){
            const script1 = `
                twq('init','o5g28');
                twq('track','PageView');
            `;
            this.executeScript(script1);
        },
        // 处理提交申请
        handleApply() {
            this.$refs.form.validate((result, data) => {
                if (result) {
                    // 勾选框1是否勾选了
                    /* if (!this.protocolForm[0]) {
                        this.$message.error(this.$t('msn.permission.p1'));
                        return;
                    } */
                    this.ppShow = true;
                }
            });
        },
        // 发送请求
        sendApply() {
            const params = {
                email: this.form.email,
                code: this.form.vCode,
                country: this.form.country,
                timeZone: this.form.timeZone,
                // 交易币种
                currency: this.form.settlementCurrency,
                // 新增结算币种
                settlementCurrency: this.form.settlementCurrency,
                // 新增公司法人所在地
                locationOfCorporation: this.form.country,
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                companyWebsite: this.form.companyWebsite,
                jobTitle: this.form.jobTitle,
                passWord: md5(this.form.password).toUpperCase(),
                acceptAdvice: this.protocolForm[1] ? '1' : '0'
            };
            this.$request(
                {
                    method: 'post',
                    url: api.quickRegister,
                    contentType: 'application/json; charset=UTF-8',
                    success(res) {
                        this.handleGa('success');
                        this.login();
                    }
                },
                params
            );
        },
        // 获取页面 query 参数方便 GA
        getQuery() {
            const search = {};
            if (window.location.search.length > 1) {
                for (let i of window.location.search.slice(1).split('&')) {
                    const keyvalue = i.split('=');
                    const key = decodeURIComponent(keyvalue[0]);
                    const value = decodeURIComponent(keyvalue[1]);
                    search[key] = value;
                }
            }
            return search;
        },
        registerGa() {
            function direct() {
                const option = {
                    action: 'register_direct_success',
                    category: 'register',
                    label: ''
                };
                tool.gaRegister(option);
            }
            // 注册 GA
            const query = this.getQuery();
            const gaLastpage = query['ga-lastpage'];
            const action = query['official-action'];
            const category = query['official-category'];
            const label = query['official-label'];
            let queryDimension = query['official-dimension'];
            if (true
                && gaLastpage === 'official'
                && typeof action === 'string'
                && typeof category === 'string'
                && typeof label === 'string') {
                const option = {
                    action: action,
                    category: category,
                    label: label
                };
                if (queryDimension !== undefined) {
                    queryDimension = queryDimension;
                    const dimensionOption = queryDimension.split('###');
                    const dimension = {};
                    for (let i = 0; i < dimensionOption.length; i++) {
                        let keyvalue = dimensionOption[i].split('$$$');
                        let key = keyvalue[0];
                        let value = keyvalue[1];
                        dimension[key] = value;
                    }
                    option.dimension = dimension;
                }
                tool.gaRegister(option);
            } else {
                direct();
            }
        },
        handleGa(mode) {
            const label = {
                referrer: document && document.referrer,
                href: window && window.location && window.location.href
            };
            switch (mode) {
                // in 为用户发生输入
                case 'in': {
                    if (this.inputGaFlow) {
                        this.inputGaFlow = false;
                        tool.gaLog({
                            action: 'self_open_input',
                            category: 'self_open',
                            label: JSON.stringify(label)
                        });
                    }
                    break;
                }
                // 用户注册成功
                case 'success': {
                    // 平台 GA
                    tool.gaLog({
                        action: 'self_open_success',
                        category: 'self_open',
                        label: JSON.stringify(label)
                    });
                    // 注册成功 GA
                    this.registerGa();
                    // 领英 推特 分析
                    this.linkedinGa();
                    this.twitterGa();
                    break;
                }
            }
        },
        // 样式切换相关
        handleInputFocus(type) {
            this.$nextTick(() => {
                this.focusStyle[type] = 'focus';
                this.blurStyle[type] = '';
            });
        },
        // 样式切换相关
        handleInputBlur(type) {
            this.focusStyle[type] = '';
            this.$nextTick(() => {
                if (!this.form[type]) {
                    this.blurStyle[type] = '';
                } else {
                    this.blurStyle[type] = 'blur';
                }
            });
        },
        // 发送验证码
        handleSendVCode() {
            this.$refs.form.validateField('email', errorMessage => {
                if (errorMessage === '') {
                    this.$request(
                        {
                            method: 'post',
                            url: api.sendEmailCheck,
                            contentType: 'application/json; charset=UTF-8',
                            success(res) {
                                this.$message({
                                    message: this.$t('msn.backendMsg.sendCodeSucs'),
                                    type: 'success'
                                });
                            },
                            error() {
                                this.$refs.countDown.reset(3);
                            }
                        },
                        { emailAddress: this.form.email, language: this.lang }
                    );
                } else {
                    this.$refs.countDown.reset(3);
                }
            });
        },
        // 调用登录接口
        login() {
            // 配置跳转信息
            // 根据域名添加cn / us 参数
            const origin = location.hostname;
            let domainKey = 'us';
            if (origin.indexOf(DOMAIN_MAP['us']) > -1) {
                domainKey = 'us';
            } else if (origin.indexOf(DOMAIN_MAP['cn']) > -1) {
                domainKey = 'cn';
            }
            let selfR = `/platform/main/login?type=m&source=${domainKey}`;
            const params = {
                email: this.form.email,
                password: md5(this.form.password).toUpperCase()
            };
            this.$request(
                {
                    method: 'post',
                    url: api.login,
                    contentType: 'application/json; charset=UTF-8',
                    success(res) {
                        console.log(res);
                        this.loading = false;
                        if (+res.code === 101) {
                            this.$message.warning(this.$t('msn.requestMsg.loginFailed'));
                            return;
                        }
                        let data = res.data || {};
                        if (data.redirect) {
                            location.href = data.redirect;
                        } else {
                            location.href = selfR;
                        }
                    },
                    error() {
                        this.loading = false;
                    }
                },
                params
            );
        }
    },
    mounted() {
        this.linkedinGaInit();
        this.twitterGaInit();
    }
};
</script>

<style lang="scss">
@function vw($px) {
    @return ($px) * 1px;
    /* @return ($px / 1440) * 100vw; */
    /* @return (($px)/1920) * 100rem; */
}
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
textarea,
input,
button,
select {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.page-no-permission {
    height: 100vh;
    min-height: 651px;
    background-image: url(../../assets/images/login-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-weight: 700;
    text-align: left;
    .box-card {
        z-index: 999;
        position: absolute;
        top: 50%;
        right: 150px;
        transform: translateY(calc(-50% + 28px));
        width: vw(540);
        height: vw(650);
        padding: vw(20);
        box-sizing: border-box;
        border-radius: vw(6);
        box-shadow: none !important;
        border: none !important;
    }
    .el-card__body {
        padding: 0;
    }
    .card-header {
        padding: 0 vw(10) vw(0);
        font-size: vw(20);
        color: #53575b;
        line-height: 25px;
    }
    .form-append {
        font-size: 12px;
        color: #afb6bf;
        margin-bottom: vw(10);
        line-height: 12px;
        font-weight: 400;
        margin-top: vw(10);
    }
    .link {
        text-decoration: none;
        color: #0067ff;
        // opacity: 0.5;
        display: inline-block;
        position: relative;
        &:visited {
            color: #0067ff;
        }
        &:hover {
            color: #0067ff;
        }
        &:active {
            color: #0067ff;
        }
        &:focus {
            color: #0067ff;
        }
    }
    .to-login-page {
        font-size: 12px;
        color: #53575b;
        text-align: left;
        line-height: 12px;
        text-align: center;
        font-weight: 700;
        margin: vw(12) 0;
        .link {
            opacity: 1;
        }
    }
    .page-footer {
        text-align: center;
        padding-top: 10px;
    }
    .content {
        width: 396px;
        margin: 120px auto 123px;
        text-align: center;
        font-size: 18px;
        color: #53575b;
        img {
            width: 89px;
            margin-bottom: 30px;
            margin-left: 1em;
        }
        .text {
            line-height: 27px;
        }
    }
    .card-footer {
        text-align: center;
        opacity: 0.5;
        font-size: 14px;
        color: #53575b;
        line-height: 24px;
        font-weight: 500;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        .text {
            width: 400px;
        }
    }
}
.page-no-permission {
    .form-container {
        padding: 0 vw(20);
    }
    .el-form-item {
        margin-bottom: 0;
        .el-form-item__content {
            line-height: vw(60);
        }
    }
    .el-input .el-input__inner {
        position: absolute;
        width: 100%;
        font-size: 15px;
        color: #53575b;
        font-weight: 600;
        background: none;
        border: none;
        padding-left: 0;
        height: vw(35);
        line-height: vw(35);
        padding-bottom: vw(15);
    }
    .msn-form-item {
        &.focus {
            &:before {
                opacity: 1;
                transform: scaleX(1);
            }
            .msn-label {
                position: absolute;
                top: vw(15);
                line-height: 1;
                font-size: 12px;
            }
        }
        &.blur {
            &:before {
                opacity: 0;
                transform: scaleX(0);
            }
            .msn-label {
                position: absolute;
                top: vw(15);
                line-height: 1;
                font-size: 12px;
            }
        }
        &.is-error {
            &:before {
                opacity: 1;
                transform: scaleX(1);
                background-color: #e92754;
            }
            .msn-label {
                display: none;
            }
            .el-form-item__error {
                position: absolute;
                top: vw(7.5);
            }
        }
    }
    .msn-form-item {
        position: relative;
        .msn-label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 13px;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            white-space: nowrap;
            color: #afb6bf;
            font-weight: 600;
        }
        .required {
            &:before {
                content: "*";
                color: #f56c6c;
                position: absolute;
                left: -10px;
            }
        }
        input {
            font-size: 16px;
            font-weight: 600;
            color: #53575b;
        }
        &:before,
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            background-color: #0067ff;
            z-index: 1;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            transform: scaleX(0);
            opacity: 0;
        }
        &:after {
            background-color: #ededed;
            z-index: 0;
        }
    }
}

.page-no-permission {
    @keyframes scaling {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.85);
        }
        100% {
            transform: scale(1);
        }
    }
    .el-button {
        margin: 10px auto 0;
        width: vw(330);
        height: vw(44);
        padding: vw(10) vw(27);
        box-sizing: border-box;
        border-radius: vw(22);
        &--primary {
            color: #fff;
            border-color: #0067ff;
            background-color: #0067ff;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
            font-size: vw(20);
            font-weight: 700;
            &:focus {
                border-color: #0067ff;
                background-color: #0067ff;
            }
            &:hover {
                border-color: #0067ff;
                background-color: #0067ff;
            }
            &:active {
                border-color: #0067ff;
                background-color: #0067ff;
                animation: 0.5s ease scaling;
            }
        }
    }
}
.p-select-popper {
    margin-top: 35px !important;
}
@media (max-width: 1200.2px){
    body{
        min-width:1200px;
    }
}
</style>
<style lang="scss" scoped>
.bg-text{
    position: fixed;
    top:305px;
    left:150px;
    button{
        cursor: pointer;
        margin-right: 5px;
        font-size: 16px;
    }
    &>h1{
        margin: 0;
        padding: 0;
        color:#fff;
        font-size: 50px;
        font-style:italic;
        line-height: 1.5;
    }
    .h2-wrap {
        .h2-item {
            margin: 0;
            padding: 0;
            margin-top: 24px;
            color:#bec5ce;
            font-size: (50px*3/5);
            .normal-text {
                font-size: (50px*3/5);
                display: inline;
                font-weight: 300;
                font-style: italic;
                margin: 0;
            }
        }
    }
}
@media (max-width: 1630.2px){
    .page-no-permission{
        .box-card {
            right: 50px;
        }
    }
    .bg-text{
        top: 280px;
        left: 80px;
        &>h1{
            font-size: 45px;
        }
        .h2-wrap {
            .h2-item {
                font-size: (45px*3/5);
                .normal-text {
                    font-size: (45px*3/5);
                }
            }
        }
    }
}
@media (max-width: 1400.2px){
    .page-no-permission{
        .box-card {
            right: 50px;
        }
    }
    .bg-text{
        top: 280px;
        left: 60px;
        &>h1{
            font-size: 35px;
        }
        .h2-wrap {
            .h2-item {
                font-size: (35px*3/5);
                .normal-text {
                    font-size: (35px*3/5);
                }
            }
        }
    }
}
@media (max-width: 1200.2px){
    .page-no-permission{
        .box-card {
            right: 50px;
        }
    }
    .bg-text{
        left: 40px;
        &>h1{
            font-size: 32px;
        }
        .h2-wrap {
            .h2-item {
                font-size: (32px*3/5);
                .normal-text {
                    font-size: (32px*3/5);
                }
            }
        }
    }
}

.login-header {
    height: 50px;
    display: flex;
    align-items: center;
}
.p-select {
    width: 100%;
    & /deep/ .el-input__suffix {
        top: 20px;
    }
}
.msn-password /deep/ .el-input__suffix-inner {
    position: relative;
    top: -20px;
}
.msn-tip {
    position: absolute;
    right: -15px;
    top: 72%;
    transform: translateY(-50%);
}
.form-container {
    .count-down {
        position: absolute;
        right: 0;
        top: 50%;
        width: 100px;
        transform: translateY(-50%);
        margin-top: 0;
        font-weight: 800;
    }
}

.el-select-dropdown__item {
    text-align: left;
    &.selected {
        color: #0067ff !important;
    }
}
.el-button /deep/ span {
    font-size: 20px !important;
}
</style>
