/**
 * @file 基于vue-i18n的多语言实现
 * @author  fangsimin
 * @date    2019-01-02 09:52:11
 * @last Modified by    fangsimin
 * @last Modified time  2020-04-07 15:49:20
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locale from 'element-ui/lib/locale';
import messages from './langs';
import { getDefaultLang } from '@/constants/language';

let defaultLang = getDefaultLang();
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: defaultLang,
    messages,
    silentTranslationWarn: true
});

// 实现element插件的多语言切换
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
