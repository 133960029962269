export const onEvents = function () {
    const self = this;
    return {
        mouseover: (e, instance) => {
            self.$emit('chartMouseover', {e, instance});
        },
        mouseout: (e, instance) => {
            self.$emit('chartMouseout', {e, instance});
        }
    };
};
