/**
 * @file 英文文案
 * @author  fangsimin
 * @date    2019-01-02 09:55:16
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:30:57
 */

import enLocale from 'element-ui/lib/locale/lang/en';
import msnlang from './msn/en'; // msn模块的语言
import medialang from './media/en'; // media模块的语言

const en = {
    message: {
        submit: 'submit',
        reset: 'Reset',
        login: 'Log in',
        password: 'password',
        username: 'username',
        hintRequireUsername: 'please enter user name',
        hintRequirePassword: 'Please enter the password',
        networkError: 'Network Error',
        somethingWrong: 'unknown mistake',
        loginError: 'Login error',
        triggerType: 'Trigger type',
        triggerWord: 'Trigger word',
        language: 'Trigger word',
        permission: 'Permission Denied',
        notFound: 'Page Not Found',
        triggerTypes: {
            keyword: 'Key words',
            category: 'Classification',
            source: 'News source',
            author: 'author',
            picSex: 'Erotic pictures'
        },
        button: {
            add: 'Increase',
            edit: 'edit',
            delete: 'delete',
            cancel: 'Cancel',
            confirm: 'Sure',
            submit: 'submit',
            reset: 'Reset',
            previous: 'Previous page',
            next: 'Next page',
            logout: 'Sign out',
            login: 'Log in',
            simulatedLogin: 'Enter account',
            returnAccount: 'Return to management account',
            addAccount: 'Add account',
            detailConfig: 'Operational configuration',
            export: 'Export',
            search: 'Export',
            create: 'Create'
        },
        isConfirmDelete: 'confirm to delete?'
    },
    ...enLocale,
    msn: msnlang,
    media: medialang
};

export default en;
