import PATH from './path';

export const HELP_CENTER = {
    [PATH['gettingStart']]: 'r',
    [PATH['bestPractice']]: 'r',
    [PATH['faq']]: 'r',
    [PATH['createAndManageCampaign']]: 'r',
    [PATH['adPolicy']]: 'r',
    [PATH['priceAndBill']]: 'r',
    [PATH['reportAndOptimizing']]: 'r'
};
