<template>
    <el-input
        v-model="searchword"
        class="search-item"
        :placeholder="placeholder"
        :disabled="disabled"
        :clearable="clearable"
        :class="[disabled ? 'search-item-disabled' : '']"
        size="small"
        @change="handleChange"
    >
        <span
            slot="suffix" class="suffix-icon"
            :class="[disabled ? 'suffix-icon-disabled' : 'suffix-icon-normal']"
        >
            <i class="msn-fa msn-fa-search"></i>
        </span>
    </el-input>
</template>

<script>
export default {
    name: 'MsnSearchInput',
    props: {
        value: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        disabled: {
            default: false
        },
        clearable: {
            default: Boolean
        }
    },
    data() {
        return {};
    },
    computed: {
        searchword: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        }
    },
    methods: {
        handleChange() {
            this.$emit('change');
        }
    }
};
</script>

<style scoped>
.suffix-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-150%, -40%);
    font-size: 1.5em;
}
.suffix-icon-normal {
    /* cursor: pointer; */
}
.suffix-icon-disabled {
    cursor:not-allowed;
}
.search-item {
    border: none;
}
</style>

<style lang="scss">
.search-item {
    .el-input__inner {
        height: 35px;
        background-color: #EEF1F4;
        border-radius: 17.5px;
        color: #53575B;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid transparent;

        &:hover {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
        }
        &:focus {
            background: rgba(238,241,244,0.50);
            border: 1px solid #2DD1AC;
            font-weight: 400;
        }
        &:focus + .el-input__prefix .prefix {
            font-weight: 400;
        }
    }
}
.search-item-disabled{
    .el-input__inner {
        border-color:white!important;
        &:hover {
            border-color:white;
        }
    }
}
</style>
