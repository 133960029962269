/*
 * @file tool.js
 * @author liushengxgi
 * @date 2020-02-25 14:33:30
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-25 14:33:35
 */
import store from '@/store';
import moment from 'moment-timezone';

const isDev = !(location.hostname.indexOf('mediago.io') > -1);
// 媒体平台的 GA
function gaMedia(params, fnOption) {
    // fnOption.role 是一个数组或者不传递，传入需要用户同时拥有的身份。例如['publisher', 'admin'] 代表需要同时具有'publisher', 'admin'两个身份才发送ga
    // fnOption.info 是一个数组 代表 label需要额外带上的参数  例如:['email', 'userName','accountListName']
    let {action, category, label} = params;
    if (label === undefined || label === null) {
        label = {};
    }
    fnOption = fnOption || {};
    if (typeof label !== 'object') {
        throw new Error('GAMedia 的 label 必须是一个 object 类型 或者为空');
    }

    const data = store.state.media;
    // 用户特征判断
    const infoMap = {
        email: data.email,
        userName: data.userName,
        accountListName: data.accountList.length === 0 ? 'undefined' : data.accountList[0].accountName
    };

    // 添加额外信息
    if (Array.isArray(fnOption.info) && fnOption.info.length > 0) {
        for (let i of fnOption.info) {
            label[i] = infoMap[i];
        }
    }
    // 用户身份
    const userRole = data.role.split(';');
    const roleMap = {
        publisher: '1000',
        admin: '1001',
        // 因为表意不明
        1002: '1002'
    };
    // 身份判断
    if (Array.isArray(fnOption.role) && fnOption.role.length > 0) {
        for (let i of fnOption.role) {
            if (roleMap[i]) {
                if (userRole.includes(roleMap[i]) === false) {
                    return;
                }
            } else {
                throw new Error('roleMap 没有映射');
            }
        }
    }

    const option = {
        event_category: category,
        event_label: JSON.stringify(label),
        send_to: 'media'
    };
    if (isDev) {
        // 控制台报告
        console.log('%cgaMedia', 'color:#ff6800');
        console.log(`window.gtag && window.gtag('event', ${action}, ${JSON.stringify(option)});`);
    }
    // 发送 GA
    window.gtag && window.gtag('event', action, option);
};
// 权限判定

// --------------
function includePermission(permission, module) {
    // 这里要判断的权限没有设置的话，就等于不需要权限，直接返回 true
    if (!permission) return true;
    const permissionList = Object.keys(store.state[module].menu) || [];
    // if(permission === '/platform/dashboard') {
    //     return false;
    // }
    return permissionList.includes(permission);
}

function gaLog(params, module = 'msn') {
    let {action, category, label, dimension} = params;
    // 角色限制,以Advertiser开头的，没有广告主权限的，不上报
    if (category.indexOf('Advertiser') > -1 && store.state[module].role.indexOf('2') < 0) {
        return;
    }
    // 角色限制,以MSN Reviewer开头的，没有MSN审核员权限的，不上报
    if (category.indexOf('MSN Reviewer') > -1 && store.state[module].role.indexOf('4') < 0) {
        return;
    }
    const option = {
        event_category: category,
        event_label: label,
        send_to: 'normal',
        ...dimension
    };
    if (isDev) {
        console.log('%cgaLog', 'color:#ff6800');
        console.log(`window.gtag('event', ${action}, ${JSON.stringify(option)});`);
    }
    window.gtag && window.gtag('event', action, option);
}

gaLog.msn = {};
Object.defineProperty(gaLog.msn, 'userName', {
    get: function () {
        const module = 'msn';
        // 不清楚 getter 方法怎么拿出来先用 方法 来代替执行
        function _() {
            return store.state[module].userName;
        }
        return _();
    }
});

Object.defineProperty(gaLog.msn, 'userAccount', {
    get: function () {
        const module = 'msn';
        const accountList = store.state[module].accountList;
        // 第一个可写帐户 找不到为 undefined
        const firstWritableAccount = accountList.find(e => (e.writeable * 1) === 1);
        // 帐户名
        const account = firstWritableAccount ? firstWritableAccount.label : 'notWritableAccount';
        return account;
    }
});
// 注册相关的 ga 账号
function gaRegister(params) {
    let {action, category, label, dimension} = params;
    if (isDev) {
        const option = {
            event_category: category,
            event_label: label,
            send_to: 'normal',
            ...dimension
        };
        // 控制台报告
        console.log('%cgaRegister', 'color:#ff6800');
        console.log(`window.gtag && window.gtag('event', ${action}, ${JSON.stringify(option)});`);
        // 发送 GA
        window.gtag && window.gtag('event', action, option);
    } else {
        const option = {
            event_category: category,
            event_label: label,
            send_to: 'register',
            ...dimension
        };
        window.gtag && window.gtag('event', action, option);
    }
}
// 创建倒计时上报
function getCountDownGaLog(params, time) {
    return setTimeout(() => {
        gaLog(params);
    }, time);
}
// http://newicafe.baidu.com/v5/issue/OnlineApi-2515/show?cid=5
// 【Dashboard】保留用户的上次设置
//  window.localStorage.getItem(key) 拿不到值返回 对象 null 不是空字符串''
const userContrl = {
    // localStore key
    key: 'mg-index-data',
    getCache() {
        const key = userContrl.key;
        return JSON.parse(window.localStorage.getItem(key) || '{}');
    },
    clearKey(key) {
        const cache = userContrl.getCache();
        if (key in cache) {
            delete cache[key];
        };
        window.localStorage.setItem(userContrl.key, JSON.stringify(cache));
    },
    storeUserContrl({datePattern = null, latitude = null, pageSize = null} = {}) {
        const key = userContrl.key;
        const temp = {datePattern, latitude, pageSize};
        const target = userContrl.getCache();
        for (let [key, value] of Object.entries(temp)) {
            if (value != null) {
                target[key] = value;
            }
        };
        window.localStorage.setItem(key, JSON.stringify(target));
    },
    getUserContrl() {
        const target = userContrl.getCache();
        // 当 latitude为天级（'1'）时检查是否有预定义时间模式
        if (target.latitude === '1' && target.datePattern) {
            // 设置一个EUI 可识别的 date 数组
            const [dateName, formatStr] = target.datePattern.split(';');
            const option = userContrl.datePatternOption.find(e => e.name === dateName);
            if (option) {
                const date = option.handler(formatStr);
                // 动态添加
                target['date'] = date;
            };
        }
        return target;
    },
    datePatternOption: [
        {
            name: 'msn.dashboard.yesterday',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-1, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .add(-1, 'days')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.today',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.last7days',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-6, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.last30days',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-29, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        }
    ],
    // 处理页面样式给 datePattern 的按钮加个样式
    // 1只在第一次点击根据 LocalStroe设置样式。
    // 2在每次@change的时候清空样式和缓存
    // 3在点击快捷键的触发@change 时设定500ms后设置LocalStroe和样式,这样就能在 @change 之后出发.
    // 处理第一次点击
    initFirstShow(vm) {
        const nodeList = userContrl.getDom();
        if (nodeList && nodeList[0] && nodeList[0].dataset && !nodeList[0].dataset.init) {
            nodeList[0].dataset.init = 'true';
            userContrl.setStyle(vm);
        }
    },
    // 初始化DOM元素
    dom: null,
    getDom() {
        const nodeList = userContrl.dom || (userContrl.dom = document.querySelectorAll('.el-picker-panel__shortcut'));
        if (nodeList && nodeList[0]) {
            if (!nodeList[0].dataset.text) {
                for (let i of nodeList) {
                    i.dataset.text = i.innerText;
                };
            }
        }
        return nodeList;
    },
    setStyle(vm) {
        const nodeList = userContrl.getDom();
        const {datePattern, latitude} = userContrl.getCache();
        // 判断此时是否存模式并且添加样式
        if (latitude === '1' && datePattern) {
            const [key] = datePattern.split(';');
            const value = vm.$t(key);
            for (let i of nodeList) {
                if (i.dataset.text === value) {
                    i.innerHTML = `<span style="color: #2DD1AC;font-weight:bolder;">${i.dataset.text}</span>`;
                } else {
                    i.innerHTML = `${i.dataset.text}`;
                }
            }
        }
    },
    clearStyle() {
        const nodeList = userContrl.getDom();
        for (let i of nodeList) {
            i.innerHTML = `${i.dataset.text}`;
        }
    }
};
// 保存用户上次时间设定
const saveUserTimeConfig = function (key) {
    key = window.location.pathname + '-timestore';
    let OPTION_MAP = [
        {
            name: 'msn.dashboard.yesterday',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-1, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .add(-1, 'days')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.today',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.last7days',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-6, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        },
        {
            name: 'msn.dashboard.last30days',
            handler(formatStr) {
                let start = moment()
                    .startOf('day')
                    .add(-29, 'days')
                    .format(formatStr);
                let end = moment()
                    .endOf('day')
                    .format(formatStr);
                return [start, end];
            }
        }
    ];
    return {
        getStore() {
            const store = JSON.parse(window.localStorage.getItem(key) || '{}');
            delete store.date;
            Object.defineProperty(store, 'date', {
                enumerable: false,
                configurable: true,
                get() {
                    if (this.latitude === '1' && this.datePattern) {
                        // 设置一个EUI 可识别的 date 数组
                        const [dateName, formatStr] = this.datePattern.split(';');
                        const option = OPTION_MAP.find(e => e.name === dateName);
                        const date = option.handler(formatStr);
                        return date;
                    } else {
                        return undefined;
                    }
                }
            });
            return store;
        },
        setStore({latitude = null, datePattern = null}) {
            // 时区本身就有实现缓存所以这里不做
            const store = this.getStore();
            const temp = {
                latitude,
                datePattern
            };
            for (let key in temp) {
                if (temp[key] !== null && temp[key] !== undefined) {
                    store[key] = temp[key];
                }
            }

            const storetemp = {
                latitude: store.latitude,
                datePattern: store.datePattern
            };
            window.localStorage.setItem(key, JSON.stringify(storetemp));
        },
        // 处理页面样式给 datePattern 的按钮加个样式
        // 1只在第一次点击根据 LocalStroe设置样式。
        // 2在每次@change的时候清空样式和缓存
        // 3在点击快捷键的触发@change 时设定500ms后设置LocalStroe和样式,这样就能在 @change 之后出发.
        // 处理第一次点击
        getDom() {
            const nodeList = document.querySelectorAll('.el-picker-panel__shortcut');
            if (!nodeList[0].dataset.text) {
                for (let i of nodeList) {
                    i.dataset.text = i.innerText;
                };
            }
            return nodeList;
        },
        initFirstShow(vm) {
            const nodeList = this.getDom();
            // 只需要判断第一个就可以
            if (!nodeList[0].dataset.init) {
                nodeList[0].dataset.init = 'true';
                this.setStyle(vm);
            }
        },
        // 根据 Store 设置样式
        setStyle(vm) {
            const nodeList = this.getDom();
            const {datePattern, date} = this.getStore();
            // 判断此时是否存模式并且添加样式 因为 date 时 getter 所以可以作为判断条件
            if (date) {
                const [key] = datePattern.split(';');
                const value = vm.$t(key);
                for (let i of nodeList) {
                    if (i.dataset.text === value) {
                        i.innerHTML = `<span style="color: #2DD1AC;font-weight:bolder;">${i.dataset.text}</span>`;
                    } else {
                        i.innerHTML = `${i.dataset.text}`;
                    }
                }
            }
        },
        clearStyle() {
            const nodeList = this.getDom();
            for (let i of nodeList) {
                i.innerHTML = `${i.dataset.text}`;
            }
        },
        clearKey(deleteKey) {
            const store = this.getStore();
            if (deleteKey in store) {
                delete store[deleteKey];
            };
            window.localStorage.setItem(key, JSON.stringify(store));
        },
        onChangeClear() {
            // 当天级别 picker 发成改变的时候
            this.clearStyle();
            this.clearKey('datePattern');
            // 如果是由四个按钮点的500m后添加缓存
        },
        onBtnChangeSet(datePattern) {
            // 如果是由四个按钮点的500m后添加缓存
            setTimeout(() => {
                this.setStore({datePattern});
            }, 500);
        }

    };
};

export default {
    includePermission,
    gaLog,
    gaMedia,
    gaRegister,
    getCountDownGaLog,
    clearKey: userContrl.clearKey,
    storeUserContrl: userContrl.storeUserContrl,
    setStyle: userContrl.setStyle,
    clearStyle: userContrl.clearStyle,
    getUserContrl: userContrl.getUserContrl,
    initFirstShow: userContrl.initFirstShow,
    saveUserTimeConfig
};
