<template>
    <div
        class="btn" :class="[`btn__${type} btn-active__${activeType}`,{'btn__disabled': disabled}]"
        @click="handleClick"
    >
        <slot>button</slot>
    </div>
</template>

<script>
export default {
    name: 'MsnButton',
    props: {
        type: {
            type: String,
            default: 'green' // green、blue、transparent
        },
        activeType: {
            type: String,
            default: 'scale' // scale、opacity
        },
        disabled: {
            type: Boolean,
            default: false // 按钮是否不可点
        }
    },
    methods: {
        handleClick() {
            if (!this.disabled) {
                this.$emit('click');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.btn {
    border-radius: 17.5px;
    padding: 0 20px;
    height: 35px;
    font-size: 13px;
    font-weight: 800;
    line-height: 35px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    &__green {
        background: #2dd1ac;
        color: #ffffff;
        &:hover {
            background: #24937a;
        }
    }
    &__transparent {
        border: 1px solid #53575b;
        color: #53575b;
        &:hover {
            border-color: transparent;
            background: rgba(83, 87, 91, 0.2);
        }
    }
    &__blue {
        color: #ffffff;
        background: #0067FF;
        box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, 0.16);
        &:hover {
            background: #3f93f5;
        }
    }
    @keyframes btn_scaling {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.85);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes btn_opacity {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
    &-active__opacity:active {
        animation: btn_opacity 0.4s ease;
    }
    &-active__scale:active {
        animation: btn_scaling 0.4s ease;
    }
    &__disabled {
        background: #B8BABB;
        border-color: #B8BABB;
        color: #ffffff;
        &:hover {
            background-color: #B8BABB;
        }
    }
}
</style>
