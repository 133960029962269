import PATH from '@/constants/path';
import {mapGetters} from 'vuex';
import store from '@/store';
// 【Dashboard】Ad层级添加Size纬度，拆封Creative数据
// WillTODO:会在将来取消设置 size 的权限 现在需要权限才能设置 size
// import mixin from '@/modules/msn/components/PlatformColumn/adListColSize.js';
let mixin = {
    computed: {
        ...mapGetters('msn', {
            menu: 'getMenu'
        }),
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        }),
        canAdListColSize() {
            return this.menu[PATH['dashboardAdListColSize']] === 'rw';
        },
        canAdlistColCv() {
            return this.menu[PATH['conversiontype']]=== 'rw';
        },
        adListColSizeStyle() {
            const flag = this.canAdListColSize;
            if (flag) {
                return {
                };
            } else {
                return {
                    display: 'none'
                };
            }
        },
        adListColCvStyle() {
            const flag = this.canAdlistColCv;
            if (flag) {
                return {
                };
            } else {
                return {
                    display: 'none'
                };
            }
        }
    },
    methods: {
        getItemStyle(value) {
            if (value === 'size' || value === 'adId') {
                console.log(this.isDiscovery);
                return this.isDiscovery ? { display: 'none' } : {};
            }
        }
    }

};
let _ = false;
// meida 平台也会执行 不存在 msn 的 menu 所以会报错
try {
    _ = store.state['msn'].menu['/platform/dashboard:adListColSize'] === 'rw';
} catch (e) {
    _ = false;
}
export const getIsDiscovery = () => {
    return store.state['common'].isDiscovery;
};
export const staticRole = _;
export default mixin;
