<template>
    <header class="header">
        <div class="header--logo" :class="{discovery: isDiscovery}"></div>
        <slot></slot>
    </header>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    computed: {
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        })
    }
};
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    width: 100%;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(107, 119, 153, 0.1);
    &--logo {
        height: 50px;
        background-image: url("../../../../assets/images/modify-pwd-logo.png");
        background-size: auto 18px;
        background-position: 30px center;
        background-repeat: no-repeat;
        &.discovery{
          background-size: auto 23px;
          background-image: url("../../../../assets/images/icon/account-logo.png");
        }
    }
}
</style>
