<template>
    <div
        :ref="refId" class="chart-content"
        :style="chartStyle"
    ></div>
</template>

<script>
// 引入主模块
import echarts from 'echarts/lib/echarts';

// 按需加入
// 引入线图
import 'echarts/lib/chart/line';
// 引入柱状图
import 'echarts/lib/chart/bar';
// 引入相关组件
import 'echarts/lib/component/tooltip';

import uuidv1 from 'uuid/v1';
import { onEvents } from './constants';

export default {
    name: 'CommonChart',
    props: {
        chartOptions: {
            required: true
        },
        chartStyle: {
            default() {
                return {};
            }
        },
        eventName: {
            type: [String, Array],
            default: null
        }
    },
    data() {
        return {
            chartInstance: null,
            refId: '',
            onEvents: onEvents.call(this)
        };
    },
    created() {
        this.refId = uuidv1();
    },
    methods: {
        monitorEvents() {
            if (typeof this.eventName === 'string') {
                this.chartInstance.on(this.eventName, 'series.line', e => {
                    this.onEvents[this.eventName](e, this.chartInstance);
                });
            } else if (this.eventName instanceof Array) {
                let self = this;
                this.eventName.forEach(ev => {
                    self.chartInstance.on(ev, 'series.line', e => {
                        self.onEvents[ev](e, self.chartInstance);
                    });
                });
            }
        },
        $_handleResizeChart() {
            this.chartInstance.resize();
        }
    },
    mounted() {
        this.chartInstance = echarts.init(this.$refs[this.refId], '', {
            devicePixelRatio: 3
        });
        // 监听窗体改变，并且在组件销毁的时候取消监听
        window.addEventListener('resize', this.$_handleResizeChart);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', this.$_handleResizeChart);
        });
        // 监听事件
        this.eventName && this.monitorEvents();
    },
    watch: {
        chartOptions: {
            deep: true,
            handler(val) {
                if (this.chartInstance) {
                    this.chartInstance.setOption(val, true);
                }
            }
        },
        chartInstance(val) {
            if (val && this.chartOptions) {
                this.chartInstance.setOption(this.chartOptions, true);
            }
        }
    }
};
</script>

<style scoped>
.chart-content {
    height: 400px;
}
</style>
