/**
 * @file 登录页面
 * @author  fangsimin
 * @date    2019-01-11 16:13:02
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-14 21:43:01
 */

<template>
    <section class="page-login">
        <header class="login-header">
            <div :class="{'login-logo': true, 'login-logo-discovery': isDiscovery}"></div>
        </header>
        <el-card
            v-loading="loading" class="box-card"
            element-loading-spinner="el-icon-loading"
        >
            <div :class="{'login-header-logo': true, 'login-header-logo-discovery': isDiscovery}"></div>
            <header class="card-header">
                <div class="header-gap"></div>
                <div class="login-title">
                    {{ $t('msn.login.emailLogin') }}
                </div>
                <div class="header-gap"></div>
            </header>
            <section class="card-login">
                <el-form
                    ref="login" :model="form"
                    :rules="rules"
                >
                    <el-form-item class="form-item-container" prop="email">
                        <el-input v-model="form.email" :placeholder="$t('msn.login.account')"/>
                    </el-form-item>
                    <input type="password" class="is-hidden">
                    <el-form-item class="form-item-container" prop="password">
                        <el-input
                            v-model="form.password"
                            type="password"
                            :placeholder="$t('msn.login.pwd')"
                            @keypress.enter.native="login"
                        />
                    </el-form-item>
                    <el-form-item class="form-item-container margin-bottom-0">
                        <el-button
                            style="width: 100%;"
                            class="login-button"
                            :disabled="disabled"
                            :type="disabled ? 'default' : 'primary'"
                            @click="login"
                        >
                            {{ $t('msn.login.loginText') }}
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="form-footer">
                    <div class="forget-pwd-container">
                        <el-button type="text" @click="toModifyPwd">
                            {{ $t('msn.login.forgetPwd') }}
                        </el-button>
                    </div>
                    <div v-if="!isDiscovery" class="to-register-container">
                        {{ $t('msn.login.haveNoAccount') }}<el-button type="text" @click="toRegister">
                            <span v-if="lang === 'en'">&nbsp;</span>
                            {{ $t('msn.login.register') }}
                        </el-button>
                    </div>
                </div>
            </section>
            <section v-if="!isDiscovery" class="card-footer">
                <div class="footer-title-container">
                    <div class="footer-gap"></div>
                    <div class="login-title">
                        {{ $t('msn.login.thirdPartyLogin') }}
                    </div>
                    <div class="footer-gap"></div>
                </div>
                <div class="btn-container">
                    <div class="msn-fa msn-fa-b login-icon" @click="handleClick('b')"></div>
                    <div class="msn-fa msn-fa-g login-icon" @click="handleClick('g')"></div>
                </div>
            </section>
        </el-card>
    </section>
</template>

<script>
import md5 from 'md5';
import api from '@/constants/api';
import PATH from '@/constants/path';
import {mapGetters} from 'vuex';
import { DOMAIN_MAP } from './constants/';
export default {
    data() {
        const checkEmail = (rule, value, callback) => {
            // eslint-disable-next-line
            const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
            if (value && !reg.test(value)) {
                callback(new Error(this.$t('msn.validations.email')));
            }
            callback();
        };
        // 根据域名添加cn / us 参数
        const origin = location.hostname;
        let domainKey = 'us';
        if (origin.indexOf(DOMAIN_MAP['us']) > -1) {
            domainKey = 'us';
        } else if (origin.indexOf(DOMAIN_MAP['cn']) > -1) {
            domainKey = 'cn';
        }
        return {
            URLS: {
                b: `/platform/main/login?type=b&source=${domainKey}`,
                g: `/platform/main/login?type=g&source=${domainKey}`,
                m: `/platform/main/login?type=m&source=${domainKey}`
            },
            form: {},
            rules: {
                email: [
                    {required: true, message: this.$t('msn.validations.email'), trigger: 'blur'},
                    {validator: checkEmail, tigger: 'blur'}
                ],
                password: [
                    {required: true, message: this.$t('msn.validations.pwdRequired'), trigger: 'blur'}
                ]
            },
            state: '',
            redirectUri: '',
            clientId: '',
            loading: false
        };
    },
    computed: {
        ...mapGetters('common', {
            lang: 'getLang',
            isDiscovery: 'getIsDiscovery',
            platform: 'getPlatform'
        }),
        disabled() {
            console.log(this.form);
            return !this.form.email || !this.form.password;
        }
    },
    methods: {
        handleClick(key) {
            location.href = this.URLS[key];
        },
        // 登录
        login() {
            this.$refs.login.validate(valid => {
                if (!valid) return;
                const params = Object.assign({}, this.form, {
                    state: this.state,
                    redirectUri: this.redirectUri,
                    clientId: this.clientId
                });
                params.password = md5(params.password).toUpperCase();
                this.loading = true;
                this.$request({
                    method: 'post',
                    url: api.login,
                    contentType: 'application/json; charset=UTF-8',
                    success(res) {
                        this.loading = false;
                        if (+res.code === 101) {
                            this.$message.warning(this.$t('msn.requestMsg.loginFailed'));
                            return;
                        }
                        let data = res.data || {};
                        if (data.redirect) {
                            location.href = data.redirect;
                        } else {
                            location.href = this.URLS.m;
                        }
                    },
                    error() {
                        this.loading = false;
                    }
                }, params);
            });
        },
        // 修改密码
        toModifyPwd() {
            this.$router.push({path: PATH['forgetPassword']});
        },
        // 注册
        toRegister() {
            this.$router.push({path: PATH['register']});
        }
    },
    mounted() {
        const query = this.$route.query || {
            state: '',
            client_id: '',
            redirect_uri: ''
        };
        this.state = query.state;
        this.clientId = query.client_id;
        this.redirectUri = query.redirect_uri;
    }
};
</script>
<style lang="scss" scoped>

.page-login{
    height: 100vh;
    background-image: url(./assets/images/login-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    font-weight: 700;
    text-align: left;
    box-shadow: -2px 6px 30px 0 rgba(0,0,0,0.40);

    .login-header {
        height: 50px;
        display: flex;
        align-items: center;
    }

    .box-card {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 28px));
        width: 402px;
        height: 550px;
        padding: 0 40px;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none!important;
        border: none!important;
        background-image: url(./assets/images/login-content-bg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        /deep/ .el-card__body {
            padding: 0!important;
        }
        .login-header-logo {
            height: 37px;
            width: 213px;
            margin: 72px auto;
            background-image: url(./assets/images/icon/login-logo.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &.login-header-logo-discovery{
                background-image: url(./assets/images/icon/login-logo-discovery.png);
            }
        }
        .card-header {
            font-size: 14px;
            color: #53575B;
            font-weight: 700;
            margin-top: 10px;
            display: flex;
            align-items: center;
            .header-gap {
                flex: 1;
                height: 1px;
                background-color: rgba(151, 151, 151, .2);
            }
            .login-title {
                display: inline-block;
                position: relative;
                padding: 20px 0 20px;
                margin: 0 10px;
            }
        }
        .card-footer {
            .footer-title-container {
                font-size: 14px;
                color: #53575B;
                font-weight: 700;
                margin-top: 10px;
                display: flex;
                align-items: center;
                .footer-gap {
                    flex: 1;
                    height: 1px;
                    background-color: rgba(151, 151, 151, .2);
                }
                .login-title {
                    display: inline-block;
                    position: relative;
                    padding: 20px 0 20px;
                    margin: 0 10px;
                }
            }
            .login-icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                box-shadow: 0 2px 5px 0 rgba(20, 70, 199, .1);
                &:first-child {
                    margin-right: 40px;
                }
            }
            .btn-container {
                display: flex;
                align-items: center;
                width: 120px;
                font-size: 13px;
                color: #53575B;
                font-weight: 900;
                text-align: center;
                flex: 1;
                margin: auto;
                cursor: pointer;
            }
            .gap {
                flex: 2;
            }
        }
        /deep/ .el-form-item {
            margin-bottom: 20px!important;
            .el-input__inner {
                height: 35px;
                background: #ECECEC;
                border: 1px solid transparent;
                box-sizing: border-box;
                font-size: 14px;
                border-radius: 17.5px;
                color:  #53575B;
                font-weight: 500;
                line-height: 1;
                &:active,
                &:hover,
                &:focus {
                    background: rgba(236, 236, 236, .5);
                    border: 1px solid #0067FF;
                }
                &::placeholder {
                    color: rgba(83, 87, 91, .3);
                    font-weight: 500;
                }
            }
            .el-button {
                height: 35px;
                padding: 10px 18px!important;
                box-sizing: border-box;
                border-radius: 17.5px;
                font-weight: 500;
                line-height: 1;
                font-size: 14px;
                border: none!important;
                span {
                    font-size: 14px;
                }
                &--default {
                    background: #ECECEC;
                    color: rgba(83, 87, 91, .3)!important;
                    &:active,
                    &:hover,
                    &:focus {
                        background: #ECECEC;
                    }
                }
                &--primary {
                    background: #0067FF;
                    color: #fff!important;
                    &:active,
                    &:hover,
                    &:focus {
                        background: #0067FF;
                    }
                }
            }
        }
        .margin-bottom-0 {
            margin-bottom: 0!important;
        }
        .form-footer {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #53575B;
            letter-spacing: 0;
            line-height: 1;
            font-weight: 400;
            .forget-pwd-container {
                flex: 1;
            }
            .to-register-container {
                display: flex;
                align-items: center;
            }
            .el-button--text {
                display: inline-block;
                height: 12px;
                padding: 0!important;
                font-size: 12px;
                line-height: 12px;
                font-weight: 300;
                color: #0067FF;
            }
        }
    }
}
</style>

<style lang="scss">
body,
html {
    margin: 0;
    padding: 0;
}
</style>
