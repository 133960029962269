/**
 * @file 请求包装
 * @author  fangsimin
 * @date    2019-01-14 15:14:23
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-14 20:02:45
 */

import axios from 'axios';
import qs from 'qs';
import {captrueApiException} from '@/plugins/weirwood';
import Cookies from 'js-cookie';
// 灰度发布标记
const handleIsgu = (options) => {
    const isgu = Cookies.get('isgu');
    if(isgu !== undefined && options['headers']) {
         options['headers'].isgu = isgu;
    }
};

axios.interceptors.request.use(config => {
    let url = config.url;
    // get参数编码
    if ((config.method === 'get' || config.method === 'delete') && config.params) {
        url += '?';
        let keys = Object.keys(config.params);
        for (let key of keys) {
            if (config.params[key] !== undefined) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`;
            }
        }
        url = url.substring(0, url.length - 1);
        config.params = {};
    }
    config.url = url;
    return config;
});

/**
 * 请求失败时的初步处理回调
 * @param  {Object} response 返回
 * @return  {Object} response 返回
 */
function errorState(response) {
    try {
        const code = response && response.data && response.data.code;
        const status = response && response.status;
        const codes = response && response.code && response.code;
        const isEffectiveResponse = +status === 200 && (+code === 0 || +code === 101);
        if (!isEffectiveResponse || codes === 'ECONNABORTED') {
            response && captrueApiException(response);
        }
    }
    catch (err) {
        console.log(err);
    }
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response;
    } else if (response && +response.status === 401) {
        const url = (response.headers && response.headers['location']) || '';
        if (url) {
            location.href = url;
        }
        return null;
    } else {
        return null;
    }
}

/**
 * 请求成功返回时的初步处理回调
 * @param  {Object} response 返回
 * @return {boolean} 返回服务端的code是否为0
 */
function successState(response) {
    // 101状态码用于处理登录失败的特殊提示
    if (response.data && (+response.data.code === 0 || +response.data.code === 101)) {
        return true;
    } else {
        return false;
    }
    // 一判断后端返回的错误码
    // if (res.data.errCode == '000002') {
    //   console.log(res.data.errDesc || '网络异常')
    // } else if (res.data.errCode != '000002' && res.data.errCode != '000000') {
    //   console.log(res.data.errDesc || '网络异常')
    // }
}

/**
 * 文件下载
 * @param {ArrayBuffer} response
 */
function downloadFile(data, fileName, type) {
    const url = window.URL.createObjectURL(new Blob([data], {type: type}));
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default (opts, data) => {
    if (!opts.method) {
        opts.method = 'get';
    }
    // 公共参数
    let defaultParam = {
        // 'testParam': ''
    };
    // http默认配置
    let httpDefaultOpts = {
        baseURL: opts.baseURL,
        method: opts.method, // 基础 url 前缀
        url: opts.url, // 请求的地址
        timeout: opts.timeout || 20000 // 超时时间, 单位毫秒
    };
    const randomString = [...Array(16)].map(() => Math.random().toString(36)[2]).join('');
    if (opts.method === 'get' || opts.method === 'delete') {
        httpDefaultOpts['params']
            = Object.assign(defaultParam, opts.params, data);
        httpDefaultOpts['headers'] = {
            // 'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
             Traceid: randomString
        };
    } else {
        // 处理formdata格式的上传
        if (opts['ajaxType'] && opts['ajaxType'] === 'upload') {
            httpDefaultOpts['data'] = data;
        } else {
            httpDefaultOpts['data']
                = opts.qs
                    ? qs.stringify(Object.assign(defaultParam, opts.data, data))
                    : Object.assign(defaultParam, opts.data, data);
        }
        httpDefaultOpts['headers'] = {
            // 'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': opts.contentType || 'application/x-www-form-urlencoded; charset=UTF-8',
             Traceid: randomString
        };
        httpDefaultOpts['transformRequest'] = opts.contentType ? opts.transformRequest || [] : [function (data) {
            let ret = '';
            /* eslint-disable-next-line */
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            return ret;
        }];
    }
    handleIsgu(httpDefaultOpts);
    if (opts.type === 'download') {
        httpDefaultOpts['responseType'] = 'arraybuffer';
        // httpDefaultOpts['headers'] = {'Content-Type': 'application/csv; charset=UTF-8'};

        return new Promise((resolve, reject) => {
            axios(httpDefaultOpts).then(response => {
                if (+response.status === 200) {
                    const header = response.headers;
                    const fileName = (
                        header['content-disposition']
                        && header['content-disposition'].split('filename=')[1]
                    ) || 'filename';
                    downloadFile(response.data, fileName, opts.fileType);
                    resolve(true);
                } else {
                    if (+response.status === 401) {
                        this.$message.error(this.$i18n.t('message.permissionDenied'));
                        setTimeout(() => {
                            window.location.replace('/adx-dc/logout.do');
                        }, 500);
                    }
                    reject(response);
                }
            }).catch(response => {
                errorState(response);
                reject(response);
            });
        });
    } else {
        httpDefaultOpts['responseType'] = 'json';
    }
    opts.startTime = new Date().getTime();
    return new Promise(function (resolve, reject) {
        axios(httpDefaultOpts).then(response => {
            response.data.wait_seconds = (new Date().getTime() - opts.startTime) / 1000;
            if (successState(response)) {
                resolve(response.data);
            } else {
                errorState(response);
                reject(response);
            }
        }).catch(response => {
            if(response.code && response.code === 'ECONNABORTED') {
                errorState(response);
                reject(response);
            } else {
                errorState(response.response);
                reject(response.response);
            }
        });
    });
};
