var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"el-table-footer-wrapper",class:[
        _vm.table && _vm.table.layout.scrollX ? ("is-scrolling-" + (_vm.table.scrollPosition)) : 'is-scrolling-none',
        _vm.table && _vm.table.tableSize ? ("el-table-footer--" + (_vm.table.tableSize)) : '',
        {
            'el-table-footer-wrapper--border': _vm.table && _vm.table.border,
        }]},[(_vm.fixedColumns.length > 0)?_c('div',{staticClass:"el-table-footer-wrapper__fixed-left",style:({
            width: _vm.fixedWidth,
        })},[_c('table-footer',{ref:"fixedLeftFooter",attrs:{"data":_vm.data,"fixed":"left"}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"footerWrapper",staticClass:"el-table-footer"},[_c('table-footer',{ref:"footer",attrs:{"data":_vm.data}})],1),_vm._v(" "),(_vm.rightFixedColumns.length > 0)?_c('div',{staticClass:"el-table-footer-wrapper__fixed-right",style:({
            width: _vm.rightFixedWidth,
            right: _vm.table && _vm.table.layout.scrollY ? (_vm.table.border ? _vm.table.layout.gutterWidth : (_vm.table.layout.gutterWidth || 0)) + 'px' : 0,
        })},[_c('table-footer',{ref:"fixedRightFooter",attrs:{"data":_vm.data,"fixed":"right"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }