<template>
    <div v-if="show && !accountSource" class="box">
        <template v-if="typeof rMsgList === 'object'">
            <span>{{ rMsgList[0] }}</span>
            <span class="goto" @click="onClick">{{ rMsgList[1] }}</span>
            <span>{{ rMsgList[2] }}</span>
        </template>
        <template v-else>
            <span>{{ rMsgList }}</span>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'MsnGlobalNotify',
    data() {
        return {
            show: false,
            msg: '',
            goto: ''
        };
    },
    computed: {
        ...mapGetters('msn', {
            accountSource: 'getAccountSource'
        }),
        rMsgList() {
            let result;
            const reg = /^(.*){(.*)}(.*)$/;
            const t = this.msg.match(reg);
            if (t) {
                result = [];
                result.push(t[1]);
                result.push(t[2]);
                result.push(t[3]);
            } else {
                result = this.msg;
            }
            return result;
        }
    },
    methods: {
        onClick() {
            this.$router.push({path: this.goto});
        },
        open(opt) {
            this.show = true;
            this.msg = opt.msg || '';
            this.goto = opt.goto || '';
        },
        close() {
            this.show = false;
        }
    }
};
</script>

<style scoped lang="scss">
.box {
    height: 40px;
    line-height: 40px;
    background: #feb01c;
    color: #ffffff;
    background-image: url("./globalNotify.png");
    background-repeat: no-repeat;
    background-size: 16.7px 14.4px;
    background-position: 20px 13px;
    font-size: 13px;
    text-align: left;
    text-indent: 4em;
    font-weight: 600;
    .goto {
        text-decoration: underline;
        font-weight: bolder;
        cursor: pointer;
    }
}
</style>
