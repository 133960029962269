/**
 * @file 维护前端路由列表
 * @author  fangsimin
 * @date    2019-01-02 11:12:42
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-17 19:46:05
 */

const env = process.env;
export default new Proxy(
    {
        login: 'platform/user/login',
        modify: 'platform/user/modify',
        forgetPassword: 'platform/user/forgetPassword',
        register: 'platform/user/register',
        dashboard: 'platform/dashboard',
        new_dashboard: 'platform/new_dashboard',
        dashboardDomainCpc: 'platform/dashboard:domainSetCampaignCpc',
        adList: 'platform/dashboard/adList',
        new_dashboardDomainCpc: 'platform/new_dashboard:domainSetCampaignCpc',
        new_adList: 'platform/new_dashboard/adList',
        create: 'platform/campaign',
        createOsv: 'platform/campaign:osv',
        display: 'platform/campaign:displaySize',
        userPackage: 'platform/campaign:userPackage',
        impURL: 'platform/campaign:impURL',
        domainwhitelist: 'platform/campaign:domainwhitelist',
        monthlyCapStatus: 'platform/campaign:monthlyCapStatus',
        objective: 'platform/campaign:objective',
        audience: 'platform/campaign:audience',
        locationIP: 'platform/campaign:locationIP',
        locationTW: 'platform/campaign:locationTW',
        postback: 'platform/campaign:postback',
        bulkUpload: 'platform/campaign:bulkUpload',
        zeroThreshold: 'platform/campaign:zeroThreshold',
        smartBidMaxCV: 'platform/campaign:smartBidMaxCV',
        dataInheritance: 'platform/campaign:dataInheritance',
        dataInheritanceInternal: 'platform/campaign:dataInheritanceInternal',
        imgUploadSizeUpperLimit: 'platform/campaign:imgUploadSizeUpperLimit',
        // dashboard actions
        dashboardEdit: 'platform/dashboard:edit',
        dashboardDomainBlock: 'platform/dashboard:domainBlock',
        dashboardStatus: 'platform/dashboard:status',
        dashboardReview: 'platform/dashboard:review',
        dashboardDetail: 'platform/dashboard:detail',
        dimensionFilter: 'platform/dashboard:dimensionFilter',
        dashboardCopy: 'platform/dashboard:copy',
        domainSspColumn: 'platform/dashboard:domainSspColumn',
        dashboardAdStatus: 'platform/dashboard:adStatus',
        dashboardAP: 'platform/dashboard:auditPublisher',
        dashboardAdListColSize: 'platform/dashboard:adListColSize',
        // new dashboard
        agencyList: 'platform/new_dashboard:agencyList',
        new_createOsv: 'platform/campaign:new_createOsv',
        new_objective: 'platform/campaign:new_objective',
        new_monthlyCapStatus: 'platform/campaign:new_monthlyCapStatus',
        new_audience: 'platform/campaign:new_audience',
        new_dashboardEdit: 'platform/new_dashboard:edit',
        new_dashboardDomainBlock: 'platform/new_dashboard:domainBlock',
        new_dashboardStatus: 'platform/new_dashboard:status',
        new_dashboardReview: 'platform/new_dashboard:review',
        new_dashboardDetail: 'platform/new_dashboard:detail',
        new_dimensionFilter: 'platform/new_dashboard:dimensionFilter',
        new_dashboardCopy: 'platform/new_dashboard:copy',
        new_domainSspColumn: 'platform/new_dashboard:domainSspColumn',
        new_dashboardAdStatus: 'platform/new_dashboard:adStatus',
        new_dashboardAP: 'platform/new_dashboard:auditPublisher',
        new_dashboardAdListColSize: 'platform/new_dashboard:adListColSize',
        new_dashboardDomainTab: 'platform/new_dashboard:domainTab',
        new_dashboardReadChart: 'platform/new_dashboard:read',
        new_dashboardCountry: 'platform/new_dashboard:country',
        new_dashboardTrendTypevCPM: 'platform/new_dashboard:vCPM',
        new_dashboardAgency: 'platform/new_dashboard:agency',
        // 新增多货币切换权限配置
        currencyType: 'platform/currencyType',
        financial: 'platform/financial',
        creditLine: 'platform/financial/creditLine',
        // 月度账单
        monthlyBillSave: 'platform/financial/mothlyBill:monthlyBillSave',
        monthlyBillRelease: 'platform/financial/mothlyBill:monthlyBillRelease',

        conversiontype: 'platform/dashboard:conversiontype',
        campaignSetOcpc: 'platform/dashboard:campaignSetOcpc',
        campaignSetEcpc: 'platform/dashboard:campaignSetEcpc',
        bidByDomainCPC: 'platform/dashboard:bidByDomainCPC',
        // new dashboard
        // 转化类型现在控制的是mcv，mcpa，mcvr三个，去除之前cv,以及cvr的判断
        new_conversiontype: 'platform/new_dashboard:conversiontype',
        new_mediagoCv: 'platform/new_dashboard:mediagoCv',
        new_campaignSetOcpc: 'platform/new_dashboard:campaignSetOcpc',
        new_campaignSetEcpc: 'platform/new_dashboard:campaignSetEcpc',
        new_bidByDomainCPC: 'platform/new_dashboard:bidByDomainCPC',
        new_suggestedCpc: 'platform/new_dashboard:suggestedCpc',
        // am actions
        // am analysis actions
        amConfigCountr: 'platform/am/amConfig:amConfigCountr',
        blackWhiteConfig: 'platform/am/amDashboard:blackWhiteConfig', // 添加黑白名单
        campaignOptCfg: 'platform/am/amDashboard:campaignOptCfg', // 根据page id 配置黑白名单
        pageIdBlockCfg: 'platform/am/amDashboard:pageIdBlockCfg', // 根据page id 配置黑白名单
        taboolaBind: 'platform/am/amDashboard:taboolaBind', // 绑定taboola账号功能点
        campaignOptLog: 'platform/am/amDashboard:campaignOptLog', // 查看操作日志权限
        campaignAlertCfg: 'platform/am/amDashboard:campaignAlertCfg', // 查看告警日志
        serviceRecord: 'platform/am/amDashboard:serviceRecord', // 查看告警日志
        yueBao: 'platform/am/monthlyReport', // 查看月报
        // am icafe actions
        icafeCreate: 'platform/am/icafe:create',
        icafeEdit: 'platform/am/icafe:edit',
        // 内部QA
        qaCenter: 'platform/am/qaCenter',
        qaCenterEdit: 'platform/am/qaCenter:edit',
        // creditLine page actions
        creditLineSetting: 'platform/financial/creditLine:setting',
        creditLineCharge: 'platform/financial/creditLine:charge',
        couponSetting: 'platform/financial/creditLine:coupon',
        creditLineStatus: 'platform/financial/creditLine:status',
        creditLineProfitRate: 'platform/financial/creditLine:profitRate',
        receivables: 'platform/financial/receivables',
        // account mgr actions
        accountList: 'platform/permission/account',
        accountStatus: 'platform/permission/account:status',
        accountEdit: 'platform/permission/account:edit',
        accountDetail: 'platform/permission/account:detail',
        accountCreate: 'platform/permission/account:create',
        accountBdInvite: 'platform/permission/account:bdInvite',
        accountInvite: 'platform/permission/account:invite',
        bdOpenAccount: 'platform/permission/bdOpenAccount',
        accountAuthorize: 'platform/permission/account:authorize',
        accountIgnore: 'platform/permission/account:ignore',
        dailyCmpCount: 'platform/permission/account:dailyCmpCount',
        // agent
        agentAccount: 'platform/agencyManagement/account',
        agentAccountCreate: 'platform/agencyManagement/account:create',
        agentUser: 'platform/agencyManagement/user',
        agentUserCreate: 'platform/agencyManagement/user:create',
        agentUserEdit: 'platform/agencyManagement/user:edit',
        // authorize page actions
        authorizedStatus: 'platform/permission/user:status',
        authorizedEdit: 'platform/permission/user:edit',
        authorizedDetail: 'platform/permission/user:detail',
        unauthorizedAudit: 'platform/permission/user:authorize',
        adminAuthorize: 'platform/permission/user:adminAuthorize',
        advertiseAuthorize: 'platform/permission/user:advertiserAuthorize',
        unauthorizedIgnore: 'platform/permission/user:ignore',
        // role actions
        roleCreate: 'platform/permission/role:create',
        roleDetail: 'platform/permission/role:detail',
        roleEdit: 'platform/permission/role:edit',
        roleDelete: 'platform/permission/role:delete',
        // audit actions
        auditBtn: 'platform/audit:auditBtn',
        auditDetail: 'platform/audit:detail',
        auditIndustry: 'platform/audit:industry',
        auditGeoEdge: 'platform/audit:geoEdge',
        auditSetSensitive: 'platform/audit:sensitiveTag',
        auditDisplayAllImg: 'platform/audit:displayAllImg',
        baiduBrain: 'platform/audit:baiduBrain',
        // 可视化测试
        visualTestAdminEmailCode: 'platform/test/generateLocalPageWrapper:adminEmailCode',
        // pixel
        pixelList: 'platform/pixel/conversionList',
        pixelListStatus: 'platform/pixel/conversionList:status',
        pixelListEdit: 'platform/pixel/conversionList:edit',
        pixelListCreate: 'platform/pixel/conversionList:create',
        pixelCreate: 'platform/pixel/conversionCreate',
        readCreate: 'platform/pixel/readCreate',
        pixelConversions: 'platform/pixel/conversions',
        readList: 'platform/pixel/readList',
        discoveryReadCreate: 'platform/pixel/discoveryReadCreate',
        // open account
        apply: 'platform/apply',
        openAccount: 'platform/openAccount',
        inviteOpenUser: 'platform/inviteOpenUser',
        inviteOpenUserAndAccount: 'platform/inviteOpenUserAndAccount',
        // help center
        helpCenter: 'platform/helpCenter',
        priceAndBill: 'platform/helpCenter/priceAndBill',
        faq: 'platform/helpCenter/faq',
        createAndManageCampaign: 'platform/helpCenter/createAndManageCampaign',
        gettingStart: 'platform/helpCenter/gettingStart',
        adPolicy: 'platform/helpCenter/adPolicy',
        reportAndOptimizing: 'platform/helpCenter/reportAndOptimizing',
        priceAndBillEdit: 'platform/helpCenter/priceAndBill:edit',
        faqEdit: 'platform/helpCenter/faq:edit',
        createAndManageCampaignEdit: 'platform/helpCenter/createAndManageCampaign:edit',
        gettingStartEdit: 'platform/helpCenter/gettingStart:edit',
        adPolicyEdit: 'platform/helpCenter/adPolicy:edit',
        reportAndOptimizingEdit: 'platform/helpCenter/reportAndOptimizing:edit',
        recharge: 'platform/recharge',
        rechargePayAction: 'platform/recharge:payAction',
        autoBilling: 'platform/recharge:autoBilling',
        payAction: 'platform/payAction',
        stripeAction: 'platform/recharge:stripe',
        stripe: 'platform/stripeAction',
        pageBidEdit: 'platform/test/pageBid:edit',
        pageBidAudit: 'platform/test/pageBid:audit',
        pageBidHistory: 'platform/test/pageBid:history',
        innerStatus: 'platform/test/checkStatus:innerStatus',
        // media paths
        mediaLogin: 'media/user/login',
        mediaDashboard: 'media/dashboard',
        mediaDomain: 'media/editorial/domain',
        mediaDomainAdd: 'media/editorial/domain:add',
        mediaDomainRemove: 'media/editorial/domain:del',
        mediaAuthorize: 'media/authorize',
        mediaAccountStatus: 'media/authorize/account:status',
        mediaAccountEdit: 'media/authorize/account:edit',
        mediaAccountAdd: 'media/authorize/account:add',
        mediaUserStatus: 'media/authorize/user:status',
        mediaUserEdit: 'media/authorize/user:edit',
        mediaUserAdd: 'media/authorize/user:add',
        mediaRoleEdit: 'media/authorize/role:edit',
        mediaRoleAdd: 'media/authorize/role:add',
        mediaManagement: 'media/management',
        mediaAudience: 'media/management/audience',
        mediaAudienceAdd: 'media/management/audience:add',
        mediaAudienceEdit: 'media/management/audience:edit',
        mediaSensitiveTag: 'media/management/sensitiveTag',
        mediaSensitiveTagAdd: 'media/management/sensitiveTag:add',
        mediaSensitiveTagEdit: 'media/management/sensitiveTag:edit',
        mediaSensitiveCfg: 'media/management/sensitiveCfg',
        mediaSensitiveCfgAdd: 'media/management/sensitiveCfg:add',
        mediaSensitiveCfgEdit: 'media/management/sensitiveCfg:edit',
        mediaSearchAcc: 'media/dashboard:mediaSearchAcc',
        meidiaAdxAdd: 'media/management/adx:addAdx',
        meidiaAdxModify: 'media/management/adx:modifyAdx',
        meidiaSetWhiteForD: 'media/management/adx:setWhiteForD',
        meidiaSetDisplaySsp: 'media/management/adx:setDisplaySsp',
        meidiaSite: 'media/management/site',
        meidiaTag: 'media/management/tag',
        mediaBWconfig: 'media/management/mediaBWconfig',
        mediaBWconfigSetWhite: 'media/management/mediaBWconfig:setWhite',
        // ecommerce action
        createEmailTemplate: 'platform/ecommerceTool/customerManagement',
        searchSimilarAction: 'platform/searchSimilarBtn',
        searchSimilar: 'platform/searchSimilarBtn',
        creativeIdBlackList: 'media/management/creativeIdBlackList',
        cruiseRejectBlackList: 'media/management/cruiseRejectBlackList',
        assetSiteFilter: 'media/management/assetSiteFilter',
        auditRejectOnce: 'media/management/auditRejectOnce',
        creativeRisk: 'media/management/creativeRisk',
        discoveryBWconfig: 'media/management/discoveryBWconfig',
        accountSiteFilter: 'media/management/accountSiteFilter'
        // searchSimilarBtn: 'platform/audit:searchSimilar'
    }, {

        /**
         * 代理每个api路径获取都加上BASE_URI
         * @param  {Object} target 原对象
         * @param  {string} name 要获取的键值
         * @return {string}
         */
        get: (target, name) => {
            return target[name]
                ? env['VUE_APP_BASE_URI'] + '/' + target[name]
                : '/';
        }
    }
);
