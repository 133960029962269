<template>
    <div class="msn-checkbox">
        <div v-if="!isDiscovery">
            <el-checkbox v-if="!hidePrivacy" v-model="check[0]">
                {{ $t('msn.supplement.privacyPolicy1') }}
                <a
                    href="https://www.mediago.io/privacy"
                    class="msn-checkbox--link"
                    target="_blank"
                >
                    {{ $t('msn.supplement.privacyPolicy2') }}
                </a>
                {{ $t('msn.supplement.privacyPolicy3') }}
                <a
                    href="https://www.mediago.io/terms" class="msn-checkbox--link"
                    target="_blank"
                >
                    {{ $t('msn.supplement.privacyPolicy4') }}
                </a>
                {{ $t('msn.supplement.privacyPolicy5') }}
            </el-checkbox>
        </div>
        <el-checkbox v-model="check[1]">
            {{ $t('msn.permission.submitProtocol') }}
        </el-checkbox>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Protocol',
    props: {
        value: {
            type: Array,
            required: true
        },
        hidePrivacy: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('common', {
            isDiscovery: 'getIsDiscovery'
        }),
        check: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.msn-checkbox {
    text-align: left;
    padding-left: 20px;
    & /deep/ .el-checkbox__inner {
        border-color: #000000;
    }
    & /deep/ .el-checkbox__inner:hover {
        border-color: #0067ff;
    }
    & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #0067ff;
    }
    & /deep/ .el-checkbox__label {
        font-size: 12px;
        color: #afb6bf;
    }
    & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #0067ff;
        border-color: #0067ff;
    }
    & /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #afb6bf;
    }
    &--link {
        color: #0067ff;
        text-decoration: none;
    }
}
</style>
