<template>
    <div class="dynamic-form">
        <el-form
            ref="form"
            :model="formData"
            :inline="isInlineForm"
            :rules="formRules"
            :label-width="isInlineForm ? '' : '775px'"
            :label-position="labelPosition"
        >
            <span
                v-for="field in handleIsInlineForm" :key="field.modelAndProp"
                :class="isInlineForm ? 'dynamic-form-span' : ''"
            >
                <el-form-item
                    :label="field.useTranslate ? $t(field.label) : field.label"
                    :label-width="field.labelWidth || '340px'"
                    :prop="field.modelAndProp"
                    :class="field.itemClass || '' + ' form-item'"
                    :style="field.itemStyle || ''"
                    :rules="handleFormItemRules(field)"
                >
                    <div
                        v-if="field.tipShow"
                        slot="label"
                        class="form-item-tip"
                    >
                        {{ field.useTranslate ? $t(field.label) : field.label }}
                        <msn-tip
                            width="250"
                            placement="bottom" :content="field.useTranslate && field.tipContent ? $t(field.tipContent) : (field.tipContent ? field.tipContent : '')"
                        />
                    </div>
                    <MsnSelect
                        v-if="field.componentName === 'MsnSelect'"
                        v-model="formData[field.modelAndProp]"
                        :class="field.componentClass || ''"
                        :prop="field.modelAndProp"
                        :placeholder="field.useTranslate ? $t(field.placeholder) : field.placeholder"
                        :option-list="field.options"
                        :style="field.componentStyle || 'width:100%'"
                        :use-translate="field.useTranslate || false"
                        :multiple="field.multiple || false"
                        :clearable="field.clearable || false"
                        :filterable="field.filterable || false"
                        :collapse-tags="field.collapseTags || false"
                        :sort="field.sort || false"
                        @change="handleChange"
                    />
                    <MsnNoramlInput
                        v-if="field.componentName === 'MsnNoramlInput'"
                        v-model="formData[field.modelAndProp]"
                        :class="field.componentClass || ''"
                        :prop="field.modelAndProp"
                        :placeholder="field.useTranslate ? $t(field.placeholder) : field.placeholder"
                        :type="field.type || ''"
                        :rows="field.rows"
                        :maxlength="field.maxlength"
                        @change="handleChange"
                    />
                    <el-switch
                        v-if="field.componentName === 'el-switch'"
                        v-model="formData[field.modelAndProp]"
                        :class="field.componentClass || ''"
                        :active-color="field.activeColor || '#2DD1AC'"
                        :inactive-color="field.inactiveColor || '#858D94'"
                        :active-value="1"
                        :inactive-value="0"
                        @change="handleChange"
                    />
                    <MsnRadioGroup
                        v-if="field.componentName === 'MsnRadioGroup'"
                        v-model="formData[field.modelAndProp]"
                        :class="field.componentClass || ''"
                        :radio-opts="field.radioList || []"
                        :use-translate="field.useTranslate || false"
                        @change="handleChange"
                    />
                    <CmpCustomSelect
                        v-if="field.componentName === 'CmpCustomSelect'"
                        :ref="field.ref || ''"
                        v-model="formData[field.modelAndProp]"
                        :prop="field.modelAndProp"
                        :placeholder="field.useTranslate ? $t(field.placeholder) : field.placeholder"
                        :class="field.componentClass || 'msn-multi-select form-item-style'"
                        :show-option-append="field.showOptionAppend || false"
                        :use-translate="field.useTranslate || false"
                        :multiple="field.multiple || false"
                        :clearable="field.clearable || false"
                        :filterable="field.filterable || false"
                        :collapse-tags="field.collapseTags || false"
                        :sort="field.sort || false"
                        :select-params="field.selectParams || {}"
                        :remote-url="field.remoteUrl || ''"
                        @change="handleChange"
                    />
                    <component
                        :is="field.component || ''"
                        v-if="field.component"
                        :ref="field.ref || ''"
                        v-model="formData[field.modelAndProp]"
                        :info="field"
                        :style="field.componentStyle"
                        :class="field.componentClass || ''"
                        @change="handleChange"
                    />
                    <!-- 底部tip -->
                    <span v-if="field.bottomTipContent" class="form-item-bottom-tip">{{ field.useTranslate ? $t(field.bottomTipContent) : field.bottomTipContent }}</span>
                </el-form-item>
            </span>
            <span v-if="isInlineForm">
                <slot name="buttonSlot"></slot>
            </span>
        </el-form>
        <div v-if="!isInlineForm">
            <slot name="buttonSlot"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DynamicForm',
    props: {
        formFields: {
            type: Array,
            required: true
        },
        inlineFormFields: {
            type: Array,
            default: () => []
        },
        labelPosition: {
            default: 'right',
            type: String
        },
        isInlineForm: {
            default: false,
            type: Boolean
        },
        // 数据回显或者初始化数据
        initFormData: {
            default: () => ({}),
            type: Object
        },
        formRules: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        formData: {
            get() {
                return this.initFormData;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        handleIsInlineForm(){
            return this.isInlineForm ? this.inlineFormFields : this.formFields;
        }
    },
    methods: {
        handleFormItemRules(field) {
            const {modelAndProp, required, useTranslate, errorMessage} = field;
            if(this.formRules[modelAndProp]){
                return this.formRules[modelAndProp];
            }
            if(required){
                let message = useTranslate ? this.$t(errorMessage) : errorMessage;
                return [{required: true, message, trigger: 'blur'}];
            }
            return [];
        },
        // 保存校验数据
        onFormSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$emit('save', this.formData);
                } else {
                    this.$emit('checkError', this.formData);
                }
            });
        },
        handleResetFields() {
            this.$refs.form.resetFields();
        },
        // 清理重置表单
        onFormCancel() {
            this.handleResetFields();
            this.$emit('cancel');
        },
        // 拿到所有的监听数据给父级
        handleChange() {
            this.$emit('getFormData', this.formData);
        }
    }
};
</script>

<style lang="scss" scoped>
/* 样式定义 */
.dynamic-form{
    .form-item{
        width: 400px;
        &-style{
            width:100%
        }
        &-tip{
            position: relative;
            display: inline-block;
        }
        /deep/ .el-form-item__content{
            // 减小form-item-bottom-tip与输入框的间隙
            line-height: 1;
        }
        .form-item-bottom-tip{
            display: inline-block;
            width: 100%;
            padding: 6px 0;
            text-align: left;
            color: rgba(83, 87, 91, 0.5);
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
        }
    }
    &-span{
        vertical-align: middle;
        display: inline-block;
    }
}
</style>