/*
 * @file utils.js
 * @author liushengxgi
 * @date 2020-02-17 15:50:19
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-02-17 15:50:22
 */
import moment from 'moment';
import { timezoneOffset } from '@/modules/msn/constants/';
import { newCurrencyList } from '@/modules/msn/constants';
// 从选择项中，根据value值获取label，默认获取label值
const getLabelFromVal = (val, list, key = 'label') => {
    let item = list ? list.find(l => String(val) === String(l.value) && l.value !== '') : '';
    return (item && item[key]) || '';
};

// url 校验
/* eslint-disable */
const httpReg = /^https?:\/\/(?:(?:(?:[\w\.\-\+!=+$&'\(\)*\+,;=]|%[0-9a-f]{2})+:)*(?:[\w\.\-\+%!$&'\(\)*\+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-\.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[\/|\?](?:[\w#!:\.\?\+=&@+|#!$'~*,;\/\{\}\(\)\[\]\-]|%[0-9a-f]{2})*)?$/i
const emailReg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
const pwdReg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*])[a-z\d#@!~%^&*]{8,}/i;
// >= 0的两位小数
const numRegNotNg = /^(([0-9]\d{0,})|(([0]\.\d{1,2}|[1-9]\d{0,}\.\d{1,2})))$/;
// 所有数据的两位小数
const numReg = /^-?(([1-9]\d{0,})|[0]|(([0]\.\d{1,2})|([1-9]\d{0,}\.\d{1,2})))$/;
/* eslint-enable */

// 最近时间
const timeSplit = [
    {
        value: duration => duration.get('years'),
        unit: 'year'
    },
    {
        value: duration => duration.get('months'),
        unit: 'month'
    },
    {
        value: duration => duration.get('weeks'),
        unit: 'week'
    },
    {
        value: duration => duration.get('days'),
        unit: 'day'
    },
    {
        value: duration => duration.get('hours'),
        unit: 'hr'
    },
    {
        value: duration => duration.get('minutes'),
        unit: 'min'
    }
];
const timeManipulate = function (time, format) {
    const now = moment();
    const curTime = moment(time, format).utcOffset(8);
    let duration = moment.duration(now.diff(curTime));
    let str = 'Just Now';
    for (let i = 0; i < timeSplit.length; i++) {
        let curSplit = timeSplit[i];
        let time = curSplit.value(duration);
        if (time > 0) {
            str = `${time} ${curSplit.unit}${time > 1 ? 's' : ''} ago`;
            break;
        }
    }
    return str;
};

/**
 * 判断两个数组是否相同
 * @param {Array} a
 * @param {Array} b
 * @returns {boolean}
 */
const isSameArr = function (a, b) {
    if ((a instanceof Array) && (b instanceof Array)) {
        if (a.length !== b.length) {
            return false;
        } else {
            const stringify = JSON.stringify;
            return stringify(a.sort((i, j) => {
                return i > j ? 1 : -1;
            })) === stringify(b.sort((i, j) => {
                return i > j ? 1 : -1;
            }));
        }
    } else if ((a instanceof Array) || (b instanceof Array)) {
        return false;
    } else {
        return a === b;
    }
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

// 计算冬夏令时的offset
const getOffset = timezone => {
    // 处理时区问题，当没有时区信息时，需要默认给个offset，否则会报错；
    let offset = timezoneOffset[timezone] || 0;
    if (timezone === 'EST') {
        // 三月的第二个星期日
        let stdDay = moment(`${moment().get('year')}-03-08`, 'YYYY-MM-DD').format('E');
        let marSun = null;
        if (+stdDay === 7) {
            marSun = moment(`${moment().get('year')}-03-08 02:00:00`);
        } else {
            marSun = moment().add(7 - stdDay, 'days').set('hour', 2).set('minute', 0).set('second', 0);
        }
        let endStdDay = moment(`${moment().get('year')}-11-01`, 'YYYY-MM-DD').format('E');
        let novSun = null;
        // 十一月的第一个星期日
        if (+endStdDay === 7) {
            novSun = moment(`${moment().get('year')}-11-01 02:00:00`);
        } else {
            novSun = moment().add(7 - stdDay, 'days').set('hour', 2).set('minute', 0).set('second', 0);
        }
        if (moment().utcOffset(offset).isBetween(marSun, novSun)) {
            offset += 1;
        }
    }
    return offset;
};

// 判断一个字符串在另一个字符串中出现几次
const matchString = (str, originStr) => {
    if (!originStr) {
        originStr = '';
    }
    const rs = originStr.match(new RegExp(str, 'g'));
    return rs ? rs.length : 0;
};

// 获取数据子路径
/**
 * 获取子路径
 * 若没有子路径则返回本身，否则返回第一个子路径
 * @param {Array} menu 有权限的菜单
 * @param {string} curPath 当前菜单路径
 */
const getSubPath = (menuObj, curPath) => {
    const menuList = Object.keys(menuObj) || [];
    const subPaths = menuList.filter(path => {
        return path.startsWith(curPath) && path !== curPath &&  path.split(':').length === 1;
        // return path.startsWith(curPath) && path !== curPath && path.lastIndexOf('/') !== curPath.lastIndexOf('/') && path.split(':').length === 1;
    });
    return subPaths.length > 0 ? subPaths[0] : curPath;
};

/**
 * 获取是否包含domain
 * @param {string} domain
 * @return {boolean}
 */
export const isTargetDomain = (domain) => {
    const hostname = window.location.hostname;
    return hostname.indexOf(domain) > -1;
};

/**
 *
 * @param {string} key : usd
 * @returns {string} :$
 */
 export const currencySymbolFormat = (key) => {
    const currency = newCurrencyList.find(currency => currency.value === key);
    return currency ? currency.symbol : '';
};

/**
 *
 * @param {string} key : usd
 * @returns {string} :$
 */
export const currencyNewSymbolFormat = (key) => {
    const currency = newCurrencyList.find(currency => currency.value === key);
    return currency ? currency.newSymbol : '';
};
/**
 *
 * @param {string} key : usd
 * @returns {string} :USD $
 */
export const currencyFinanceFormat = (key) => {
    const currency = newCurrencyList.find(currency => currency.value === key);
    return currency ? currency.finance : '';
};
/**
 *
 * @param {string} currency: usd, jpy...
 * @param {number} num: 1000
 * @returns {string} $20
 */
export const getCurrencyDataFormat = (currency, num) => {
    const symbol = currencySymbolFormat(currency) || '';
    // 币种符号显示
    return `${symbol}${num}`;
    // 币种符号不显示
    // return `${num}`;
};
/**
 *
 * @param {string} currency: usd, jpy...
 * @param {number} num: 1000
 * @returns {string} $20
 */
export const getCurrencyFinanceFormat = (currency, num) => {
    const finance = currencyFinanceFormat(currency) || '';
    // 币种符号显示
    return `${finance}${num}`;
    // 币种符号不显示
    // return `${num}`;
};

/**
 * 对象数组去重
 * @param {Array} arr
 * @param {string} key
 * @returns {Array} arr
 */
export const uniqObjArr = (arr, key = 'value') => {
    let obj = {};
    if (arr && arr.length) {
        return arr.reduce((pre, next) => {
            if (!obj[next[key]]) {
                pre.push(next);
                obj[next[key]] = true;
            }
            return pre;
        }, []);
    }
    return arr;
};

/**
 * 处理分页数据
 * @param {string} text
 * @param {number} pageIndex
 * @param {number} pageSize
 * @param {Array} list
 * @returns {Array}
 */
export const handlePageData = (text, pageIndex, pageSize, list) => {
    if (text && text !== '') {
        return list.filter(item => item.label.toLowerCase().includes(text.toLowerCase())).slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
    }
    return list.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
};
/**
 * 合并树形数据
 * @param {Array} data1
 * @param {Array} data2
 * @param {string} id
 * @returns {Array}
 */
 const handleTreeData = (data1, data2, id = 'id') => {
    if (!data1 || !data2) {
        return [];
    }
    let res = data1.map(item => item);
    data2.forEach(item => {
        let cur = res.find(g => g[id] === item[id]);
        if (cur) {
            let relate = {
                ...item,
                children: uniqObjArr([...cur.children, ...item.children], 'id')
            };
            res = res.map(g => g.id === relate.id ? relate : g);
        } else {
            res.push(item);
        }
    });
    return res;
};

/**
 * 判断当前币种，返回相应的input classname
 * @param {string} currency
 * @returns {string}
 */
export const currencyInputStyle = (currency) => {
    const len = currency ? currency.length : 1;
    if (len === 3) {
        return 'max';
    } else if (len === 2) {
        return 'middle';
    }
    return '';
};

/**
 *
 * @param {number} baseSize
 * @param {number} size
 * @param {number} offset 0.02
 */

export const offsetInValid = (baseSize, size, offset) => {
    return  Math.abs(baseSize-size) / baseSize > offset;
 };

export default {
    getLabelFromVal,
    httpReg,
    emailReg,
    pwdReg,
    timeManipulate,
    deepClone,
    isSameArr,
    getOffset,
    matchString,
    getSubPath,
    numRegNotNg,
    numReg,
    isTargetDomain,
    currencySymbolFormat,
    currencyNewSymbolFormat,
    getCurrencyFinanceFormat,
    getCurrencyDataFormat,
    uniqObjArr,
    handleTreeData,
    handlePageData,
    currencyInputStyle,
    offsetInValid
};
