<template>
    <div
        class="search-bar" :style="{width}"
        @click.stop
    >
        <div
            class="search-bar--btn"
            :class="
                validate ? 'search-bar--btn__normal' : 'search-bar--btn__warn'
            "
            @click="switchOptions()"
        >
            <div class="search-bar--btn--content">
                <!-- 展示按钮 -->
                <template v-if="labeltemp && labeltemp.length > 0">
                    <div
                        v-for="index in labeltemp.length > showLabelNum
                            ? showLabelNum
                            : labeltemp.length"
                        :key="labeltemp[index - 1]"
                        class="search-bar--btn--content--item"
                        v-text="supportLang(labeltemp[index - 1])"
                    ></div>
                    <div
                        v-show="labeltemp.length > showLabelNum"
                        class="search-bar--btn--content--item"
                        v-text="`+ ${labeltemp.length - showLabelNum}`"
                    ></div>
                </template>
                <!-- placeholder -->
                <template v-else>
                    <div
                        class="search-bar--btn--content--placeholder"
                        v-text="placeholder"
                    ></div>
                </template>
            </div>
            <div
                class="search-bar--btn--icon"
                :class="{'search-bar--btn--icon__active': isOptions}"
            >
                <i class="el-icon-arrow-down"></i>
            </div>
        </div>
        <!-- 弹出框 -->
        <template v-if="isOptions">
            <div class="search-bar--box" :style="{width}">
                <div class="search-bar--filter">
                    <input
                        ref="filter"
                        v-model="filterWord"
                        type="text"
                        :placeholder="$t('msn.create.search')"
                    >
                </div>
                <div class="search-bar--options">
                    <!-- ALL样式 -->
                    <option-item
                        v-show="!isFilter"
                        :temp="isAll"
                        :all-btn="temp.length === 0 ? 1 : 2"
                        :label="supportLang('msn.create.allSelect1')"
                        @click.native="handleAll"
                    />
                    <!-- 选项样式 -->
                    <option-item
                        v-for="obj in realOptions"
                        :key="obj.label + obj.value"
                        :temp="temp.includes(obj.value)"
                        :label="supportLang(obj.label)"
                        @click.native="select(obj.value)"
                    />
                </div>
            </div>
        </template>
        <!-- 验证错误提示框 暂时还没用到-->
        <div
            v-show="!validate && !isOptions"
            class="search-bar--tip"
            v-text="validateText"
        ></div>
    </div>
</template>

<script>
import OptionItem from './optionItem';
export default {
    components: {
        OptionItem
    },
    props: {
        options: {},
        value: {
            type: Array,
            default: function () {
                return [];
            }
        },
        placeholder: {},
        width: {
            default: '200px'
        }
    },
    data() {
        return {
            // 根据 vue 单向流原则:在组件内应该操作 temp 不操作 props 中的 value
            temp: null,
            labeltemp: null,
            filterWord: null,
            // 展示 label 的个数
            showLabelNum: 1,
            isOptions: false,
            // ture 为验证成功样式
            validate: true,
            validateText: ''
        };
    },
    methods: {
        validateFn(flag, tip = '不合法') {
            this.validate = flag;
            this.validateText = tip;
        },
        select(value) {
            const index = this.temp.findIndex(e => e === value);
            if (index === -1) {
                // 不存在加入
                this.temp.push(value);
            } else {
                // 存在则删除
                this.temp.splice(index, 1);
            }
        },
        handleAll() {
            if (this.isAll) {
                this.temp.splice(0);
            } else {
                this.temp.splice(0);
                this.temp.push(...this.options.map(e => e.value));
            }
        },
        switchOptions(flag) {
            if (flag === void 0) {
                this.isOptions = !this.isOptions;
                // 出现时聚焦
                if (this.isOptions) this.$nextTick(() => this.$refs.filter.focus());
            } else {
                this.isOptions = flag;
            }
        },
        filterHandle(label, filterWord) {
            // 搜索校验
            // 类似Array.prototype.filter 返回真代表需要 假代表不需要
            // label 为展示名称 filterWord为 用户输入
            return this.supportLang(label).includes(filterWord);
        },
        // 多语言支持
        supportLang(v) {
            // 后期不需要支持多语言这里修改即可 return v;
            return this.$t(v);
        }
    },
    computed: {
        isFilter() {
            // 是否输入查询条件 以下代码排除假值影响
            return this.filterWord !== null && this.filterWord.length !== 0;
        },
        filterOptions() {
            const _ = [];
            if (this.filterWord === '') {
                return _;
            }
            for (let option of this.options) {
                const { label } = option;
                if (this.filterHandle(label, this.filterWord)) _.push(option);
            }
            return _;
        },
        realOptions() {
            return this.isFilter ? this.filterOptions : this.options;
        },
        isAll() {
            return this.temp.length === this.options.length;
        }
    },
    watch: {
        value: {
            handler() {
                this.temp = this.value;
            },
            immediate: true
        },
        temp() {
            this.$emit('input', this.temp);
            // 每次 temp 改变要修改展示数组 labeltemp
            this.labeltemp = this.options
                .filter(e => this.temp.includes(e.value))
                .map(e => e.label);
        }
    },
    created() {
        const _ = () => {
            this.switchOptions(false);
        };
        this.$once('hook:mounted', function () {
            window.addEventListener('click', _);
        });
        this.$once('hook:beforeDestroy', function () {
            window.removeEventListener('click', _);
        });
    }
};
</script>

<style lang="scss" scoped>
.search-bar {
    position: relative;
    &--btn {
        width: 100%;
        background-color: #eef1f4;
        border: 1px solid transparent;
        padding: 0 15px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        box-sizing: border-box;
        cursor: pointer;
        &--icon {
            font-size: 14px;
            color: #c0c4cc;
            height: 100%;
            overflow: hidden;
            line-height: 33px;
            flex: none;
            // 动画
            transition: all 0.3s;
            transition-property: all;
            transition-duration: 0.3s;
            transition-timing-function: ease;
            transition-delay: 0s;
        }
        &--icon__active {
            transform: rotate(-180deg);
        }
        &--content {
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            outline: none;
            border: none;
            background-color: inherit;
            &--item {
                line-height: 22px;
                font-size: 13px;
                background: #2dd1ac;
                color: #fff;
                border-radius: 11px;
                margin: 2px 0px 2px 8px;
                padding: 0 8px;
                border: 1px solid transparent;
                flex:none;
            }
            &--placeholder {
                font-size: 13px;
                background-color: inherit;
                border: none;
                outline: none;
                color: #c1c4cb;
            }
        }
        &__normal {
            &:hover {
                border-color: #2dd1ac;
            }
        }
        &__warn {
            border: 1px solid #e92754;
        }
    }
    &--box {
        box-shadow: 0 2px 10px 0 rgba(119, 151, 178, 0.5);
        border-radius: 6px;
        padding-bottom: 10px;
        position: absolute;
        z-index: 9999;
        background-color: white;
        top:130%;
    }
    &--filter {
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        height: 35px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        overflow: hidden;
        border-bottom: 1px solid #eee;
        & > input {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 0 0px;
            margin: 0;
            color: #53575b;
            font-size: 13px;
            outline: none;
            border: none;
            &::placeholder {
                color: #c1c4cb;
            }
        }
    }
    &--options {
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        overflow: auto;
    }
    &--tip {
        color: #e92754;
        font-size: 12px;
        line-height: 1;
        padding-top: 6px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>
