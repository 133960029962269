/*
 * @file country.js
 * @author liushengxgi
 * @date 2019-12-31 11:21:55
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2019-12-31 11:22:16
 */

export default {
  ABW:	'아루바',
  AFG:	'아프가니스탄',
  AGO:	'앙골라',
  AIA:	'앵귈라',
  ALA:	'알란드 섬',
  AND:	'안도라',
  ARE:	'아랍에미리트',
  ARG:	'아르헨티나',
  ARM:	'아르메니아',
  ASM:	'아메리칸사모아',
  ATA:	'남극 대륙',
  ATF:	'프랑스령 남방 영토',
  ATG:	'앤티가 바부다',
  AUS:	'호주',
  AUT:	'오스트리아',
  AZE:	'아제르바이잔',
  BDI:	'부룬디',
  BEL:	'벨기에',
  BEN:	'베냉',
  BES:	'카리브해 네덜란드',
  BFA:	'부르키나',
  BGD:	'방글라데시',
  BGR:	'불가리아',
  BHR:	'바레인',
  BHS:	'바하마',
  BIH:	'보스니아 헤르체고비나',
  BLM:	'생바르텔레미',
  BLR:	'벨라루스',
  BLZ:	'벨리즈',
  BMU:	'버뮤다',
  BOL:	'볼리비아',
  BRA:	'브라질',
  BRB:	'바베이도스',
  BRN:	'브루나이',
  BTN:	'부탄',
  BVT:	'부벳 섬',
  BWA:	'보츠와나',
  CAF:	'중앙아프리카 공화국',
  CAN:	'캐나다',
  CCK:	'코코스(킬링) 제도',
  CHE:	'스위스',
  CHL:	'칠리',
  CHN:	'중국',
  CIV:	'코트디부아르',
  CMR:	'카메룬',
  COD:	'콩고 민주 공화국',
  COG:	'콩고 공화국',
  COK:	'쿡 제도',
  COL:	'콜롬비아',
  COM:	'코모로',
  CPV:	'카보베르데',
  CRI:	'코스타리카',
  CUB:	'쿠바',
  CXR:	'크리스마스 섬',
  CYM:	'케이맨 제도',
  CYP:	'키프로스',
  CZE:	'체코 공화국',
  DEU:	'독일',
  DJI:	'지부티',
  DMA:	'도미니카',
  DNK:	'덴마크',
  DOM:	'도미니카 공화국',
  DZA:	'알제리',
  ECU:	'에콰도르',
  EGY:	'이집트',
  ERI:	'에리트레아',
  ESH:	'서사하라',
  ESP:	'스페인',
  EST:	'에스토니아',
  ETH:	'에티오피아',
  FIN:	'핀란드',
  FJI:	'피지',
  FLK:	'포클랜드 제도',
  FRA:	'프랑스',
  FRO:	'페로 제도',
  FSM:	'미크로네시아 연방',
  GAB:	'가봉',
  GBR:	'영국',
  GEO:	'조지아 주',
  GGY:	'건지',
  GHA:	'가나',
  GIB:	'지브롤터',
  GIN:	'기니',
  GLP:	'과들루프',
  GMB:	'감비아',
  GNB:	'기니비사우',
  GNQ:	'적도기니',
  GRC:	'그리스',
  GRD:	'그레나다',
  GRL:	'목장',
  GTM:	'과테말라',
  GUF:	'프랑스령 기아나',
  GUM:	'괌',
  GUY:	'가이아나',
  HKG:	'홍콩',
  HMD:	'허드 섬과 맥도날드 제도',
  HND:	'온두라스',
  HRV:	'크로아티아',
  HTI:	'아이티',
  HUN:	'헝가리',
  IDN:	'인도네시아',
  IMN:	'맨 섬',
  IND:	'인도',
  IOT:	'영국령 인도양 준주',
  IRL:	'아일랜드',
  IRN:	'이란',
  IRQ:	'이라크',
  ISL:	'아이슬란드',
  ISR:	'이스라엘',
  ITA:	'이탈리아',
  JAM:	'자메이카',
  JEY:	'저지',
  JOR:	'조던',
  JPN:	'일본',
  KAZ:	'카자흐스탄',
  KEN:	'케냐',
  KGZ:	'키르기스스탄',
  KHM:	'캄보디아',
  KIR:	'키리바시',
  KNA:	'세인트키츠 네비스',
  KOR:	'대한민국',
  KWT:	'쿠웨이트',
  LAO:	'라오스',
  LBN:	'레바논',
  LBR:	'라이베리아',
  LBY:	'리비아',
  LCA:	'세인트루시아',
  LIE:	'리히텐슈타인',
  LKA:	'스리랑카',
  LSO:	'레소토',
  LTU:	'리투아니아',
  LUX:	'룩셈부르크',
  LVA:	'라트비아',
  MAC:	'마카오',
  MAF:	'생마르탱 (프랑스)',
  MAR:	'모로코',
  MCO:	'모나코',
  MDA:	'몰도바',
  MDG:	'마다가스카르.',
  MDV:	'몰디브',
  MEX:	'멕시코',
  MHL:	'마셜 제도',
  MKD:	'마케도니아 공화국',
  MLI:	'말리',
  MLT:	'몰타',
  MMR:	'미얀마',
  MNE:	'몬테네그로',
  MNG:	'몽골',
  MNP:	'북마리아나 제도',
  MOZ:	'모잠비크',
  MRT:	'모리타니',
  MSR:	'몬세랫',
  MTQ:	'마르티니크',
  MUS:	'모리셔스',
  MWI:	'말라위',
  MYS:	'말레이시아',
  MYT:	'마요트',
  NAM:	'나미비아',
  NCL:	'누벨칼레도니아',
  NER:	'니제르',
  NFK:	'노퍽 섬',
  NGA:	'나이지리아',
  NIC:	'니카라과',
  NIU:	'니우에',
  NLD:	'네덜란드',
  NOR:	'노르웨이',
  NPL:	'네팔',
  NRU:	'나우루',
  NZL:	'뉴질랜드',
  OMN:	'오만',
  PAK:	'파키스탄',
  PAN:	'파나마',
  PCN:	'핏케언 제도',
  PER:	'페루',
  PHL:	'필리핀',
  PLW:	'팔라우',
  PNG:	'파푸아뉴기니',
  POL:	'폴란드',
  PRI:	'푸에르토리코',
  PRK:	'북한',
  PRT:	'포르투갈',
  PRY:	'파라과이',
  PSE:	'팔레스타인 영토',
  PYF:	'프랑스령 폴리네시아',
  QAT:	'카타르',
  REU:	'레위니옹',
  ROU:	'루마니아',
  RUS:	'러시아 연방',
  RWA:	'르완다',
  SAU:	'사우디아라비아',
  SDN:	'수단',
  SEN:	'세네갈',
  SGP:	'싱가포르',
  SGS:	'사우스조지아 사우스샌드위치 제도',
  SHN:	'세인트헬레나 & 의존성',
  SJM:	'템플릿',
  SLB:	'솔로몬 제도',
  SLE:	'시에라리온',
  SLV:	'엘살바도르',
  SMR:	'산마리노',
  SOM:	'소말리아',
  SPM:	'생피에르 미켈롱',
  SRB:	'세르비아',
  SSD:	'남수단',
  STP:	'상투메프린시페',
  SUR:	'수리남',
  SVK:	'슬로바키아',
  SVN:	'슬로베니아',
  SWE:	'스웨덴',
  SWZ:	'스와질란드',
  SYC:	'세이셸',
  SYR:	'시리아',
  TCA:	'터크스 케이커스 제도',
  TCD:	'차드',
  TGO:	'포장이요',
  THA:	'태국.',
  TJK:	'타지키스탄',
  TKL:	'토켈라우',
  TKM:	'투르크메니스탄',
  TLS:	'동티모르',
  TON:	'통가',
  TTO:	'트리니다드 토바고',
  TUN:	'튀니지',
  TUR:	'터키',
  TUV:	'투발루',
  TWN:	'타이완',
  TZA:	'탄자니아',
  UGA:	'우간다',
  UKR:	'우크라이나',
  UMI:	'미국 군소 제도',
  URY:	'우루과이',
  USA:	'미국',
  UZB:	'우즈베키스탄',
  VAT:	'바티칸',
  VCT:	'세인트빈센트 그레나딘',
  VEN:	'베네수엘라',
  VGB:	'영국령 버진아일랜드',
  VIR:	'미국령 버진아일랜드',
  VNM:	'베트남',
  VUT:	'바누아투',
  WLF:	'왈리스와 푸투나',
  WSM:	'사모아',
  YEM:	'예멘',
  ZAF:	'남아프리카 공화국',
  ZMB:	'잠비아',
  ZWE:	'짐바브웨',
  ALB:	'알바니아'
};