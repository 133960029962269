/**
 * @file 中文文案
 * @author  fangsimin
 * @date    2019-01-02 09:54:13
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:33:58
 */

import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import msnlang from './msn/cn'; // msn模块的语言
import medialang from './media/cn'; // msn模块的语言

const cn = {
    message: {
        submit: '提交',
        reset: '重置',
        login: '登录',
        password: '密码',
        username: '用户名',
        hintRequireUsername: '请输入用户名',
        hintRequirePassword: '请输入密码',
        networkError: '网络错误',
        somethingWrong: '未知错误',
        loginError: '登录错误',
        notFound: '无相应页面',
        triggerType: '触发类型',
        triggerWord: '触发词',
        language: '触发词',
        permission: '暂无权限访问',
        triggerTypes: {
            keyword: '关键词',
            category: '分类',
            source: '新闻源',
            author: '作者',
            picSex: '色情图片'
        },
        button: {
            add: '增加',
            edit: '编辑',
            delete: '删除',
            cancel: '取消',
            confirm: '确定',
            submit: '提交',
            reset: '重置',
            previous: '上一页',
            next: '下一页',
            logout: '登出',
            login: '登录',
            simulatedLogin: '进入账户',
            returnAccount: '返回管理账户',
            addAccount: '添加账户',
            detailConfig: '运营配置',
            export: '导出',
            search: '查询',
            create: '创建'
        },
        isConfirmDelete: '确定删除吗？',
        deleteScuess: '删除成功'
    },
    ...zhLocale,
    msn: msnlang,
    media: medialang
};

export default cn;
