<template>
    <el-checkbox
        v-model="isChecked"
        class="msn-checkbox"
        v-bind="$props"
        @change="() => $emit('change')"
    >
        {{ useTranslate ? $t(option.label) : option.label }}
    </el-checkbox>
</template>

<script>
import {Checkbox} from 'element-ui';
export default {
    name: 'MsnRadioGroup',
    props: {
        value: {
            default: null
        },
        option: {
            default: () => ({})
        },
        useTranslate: {
            default: true
        },
        ...Checkbox.props
    },
    computed: {
        isChecked: {
            get() {
                return this.value === this.option.value;
            },
            set(val) {
                this.$emit('input', val ? this.option.value : '');
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import './ckb.scss';
</style>
