/**
 * @file 每个菜单项，用于递归显示子菜单
 * @author  fangsimin
 * @date    2019-01-03 12:14:37
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:40:04
 */

<template>
    <div v-if="data && data['key']">
        <el-submenu
            v-if="data['children']"
            :key="data['key']"
            :index="getKey(routekey)"
        >
            <template slot="title">
                <i
                    v-if="data['icon']"
                    :class="[getIconClass(data['icon'])]"
                ></i>
                <span slot="title">{{ $i18n.t(data['title']) }}</span>
            </template>
            <template v-for="child in data['children']">
                <menu-item
                    v-if="child && child['key']"
                    :key="child['key']"
                    :routekey="getKey(routekey, child['key'])"
                    :data="child"
                />
            </template>
        </el-submenu>
        <el-menu-item
            v-else
            :key="data['key']"
            :index="getKey(routekey)"
        >
            <i
                v-if="data['icon']"
                :class="[getIconClass(data['icon'])]"
            ></i>
            <span slot="title">{{ $i18n.t(data['title']) }}</span>
        </el-menu-item>
    </div>
</template>

<script>

/**
 * 获取key，多级key之间用 - 分割
 * @param  {...string} args key数组
 * @return {string}
 */
export function getMenuKey(...args) {
    return args.join('/');
}

export default {
    name: 'MenuItem',
    props: {
        routekey: {
            default: ''
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {

        };
    },
    methods: {

        /**
         * 获取key，多级key之间用 - 分割
         * @param  {...string} args key数组
         * @return {string}
         */
        getKey: getMenuKey,

        /**
         * 获取icon的类名
         * @param  {string} icon icon名
         * @return {string}
         */
        getIconClass: function (icon) {
            return `menu-icon fa fa-${icon}`;
        }
    }
};
</script>
<style type="text/less">
.menu-icon {
    margin-right: 5px;
}
</style>
