<template>
    <section
        v-loading="loading" class="page-open-account"
        element-loading-spinner="el-icon-loading"
    >
        <header class="open-account-header">
            <div class="header-logo modify-pwd-logo"></div>
        </header>
        <section v-show="!showRes && !showInvalidToken" class="page-content">
            <div v-if="!!agentInfo.companyName" class="agent-header">
                <img src="../../assets/images/icon/file.png">
                <div class="agent-title">
                    {{ agentInfo.companyName }}{{ $t('msn.openAccount.inviteMsg') }}
                </div>
                <!-- <el-form class="form-container" label-position="top" :model="agentInfo">
                    <el-form-item :label="$t('msn.openAccount.agentCompanyName')">{{agentInfo.companyName}}</el-form-item>
                    <el-form-item :label="$t('msn.openAccount.agentAccountName')">{{agentInfo.accountName}}</el-form-item>
        </el-form>-->
            </div>
            <div class="form-header">
                <div class="form-title">
                    {{ $t('msn.openAccount.accountTitle') }}
                </div>
                <div class="form-text">
                    {{ $t('msn.openAccount.accountTitleDesc') }}
                </div>
            </div>
            <el-form
                ref="account"
                class="form-container"
                label-position="top"
                :hide-required-asterisk="true"
                :model="account"
                :rules="accountRules"
            >
                <el-form-item prop="accountName">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.accountName') }}</label>
                    <el-input v-model="account.accountName" :placeholder="$t('msn.openAccount.accountName')"/>
                </el-form-item>
                <el-form-item prop="email">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.accountEmail') }}</label>
                    <el-input v-model="account.email" :placeholder="$t('msn.openAccount.accountEmail')"/>
                </el-form-item>
                <el-form-item prop="budget">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.totalBudget') }}</label>
                    <el-input v-model="account.budget" :placeholder="$t('msn.openAccount.totalBudget')"/>
                </el-form-item>
                <el-form-item prop="payMethod">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.payMethod') }}</label>
                    <msn-radio
                        v-model="account.payMethod"
                        class="account-radio"
                        :radio-opts="[
                            {value: '预付', label: 'msn.openAccount.prepayment'},
                            {value: '到付', label: 'msn.openAccount.delivery'},
                        ]"
                    />
                </el-form-item>
                <el-form-item prop="adMethod">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.adMethod') }}</label>
                    <msn-radio
                        v-model="account.adMethod"
                        class="account-radio"
                        :radio-opts="[
                            {value: '自投', label: 'msn.openAccount.selfService'},
                            {value: '代投', label: 'msn.supplement.managed'},
                        ]"
                    />
                </el-form-item>
                <el-form-item prop="timezone">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.timezone') }}</label>
                    <msn-select
                        v-model="account.timezone"
                        class="account-select"
                        popper-class="account-select-dropdown"
                        style="width: 100%"
                        :option-list="timezoneList"
                    />
                </el-form-item>
                <el-form-item prop="country">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.country') }}</label>
                    <msn-select
                        v-model="account.country"
                        class="account-select"
                        popper-class="account-select-dropdown"
                        style="width: 100%"
                        filterable
                        :option-list="countryList"
                    />
                </el-form-item>
                <el-form-item prop="companyName">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.companyName') }}</label>
                    <el-input v-model="account.companyName" :placeholder="$t('msn.openAccount.companyName')"/>
                </el-form-item>
                <el-form-item prop="registerAddress">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.registerAddr') }}</label>
                    <el-input
                        v-model="account.registerAddress"
                        :placeholder="$t('msn.openAccount.registerAddr')"
                    />
                </el-form-item>
                <el-form-item prop="businessAddress">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >
                        {{ $t('msn.openAccount.operateAddr') }}
                    </label>
                    <el-input
                        v-model="account.businessAddress"
                        :placeholder="$t('msn.openAccount.operateAddr')"
                    />
                </el-form-item>
                <el-form-item prop="website">
                    <label
                        slot="label"
                        class="el-form-item__label required-after"
                    >{{ $t('msn.openAccount.website') }}</label>
                    <el-input v-model="account.website" :placeholder="$t('msn.openAccount.website')"/>
                </el-form-item>
            </el-form>
            <div class="form-header">
                <div class="form-title">
                    {{ $t('msn.openAccount.userTitle') }}
                </div>
                <div class="form-text">
                    {{ $t('msn.openAccount.userTitleDesc') }}
                </div>
            </div>
            <div class="form-wrapper">
                <div class="form-wrapper-title">
                    <msn-checkbox
                        v-model="oldAccount"
                        :disabled="!newAccount"
                        :option="{label: 'msn.openAccount.addExistAccount', value: 1}"
                        @change="handleCheckBoxChange('oldUserList')"
                    />
                </div>
                <div v-for="(oldUser, index) in oldUserList" :key="'email_' + index">
                    <el-form
                        ref="oldUser"
                        class="form-container"
                        label-position="top"
                        :model="oldUser"
                        :hide-required-asterisk="true"
                        :rules="oldUserRules[index]"
                    >
                        <el-form-item prop="email" :error="bindUserError[index]">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.email') }}</label>
                            <el-input v-model="oldUser.email" :placeholder="$t('msn.openAccount.email')"/>
                        </el-form-item>
                    </el-form>
                    <div class="user-opt-container">
                        <span
                            v-show="oldUserList.length > 1"
                            class="icon-btn icon-btn-del"
                            @click="handleDelete(index, 'old')"
                        >{{ $t('msn.openAccount.del') }}</span>
                        <span
                            v-show="index === oldUserList.length - 1"
                            class="icon-btn icon-btn-add"
                            @click="handleAdd('old')"
                        >{{ $t('msn.openAccount.add') }}</span>
                    </div>
                </div>
            </div>
            <div class="form-wraper">
                <div class="form-wrapper-title">
                    <msn-checkbox
                        v-model="newAccount"
                        :disabled="!oldAccount"
                        :option="{label: 'msn.openAccount.registAccount', value: 1}"
                        @change="handleCheckBoxChange('userList')"
                    />
                </div>
                <div v-for="(user, index) in userList" :key="'user_' + index">
                    <el-form
                        ref="user"
                        class="form-container"
                        label-position="top"
                        :model="user"
                        :hide-required-asterisk="true"
                        :rules="userRules[index]"
                    >
                        <el-form-item prop="country" :label="$t('msn.openAccount.country')">
                            <el-input v-model="user.country" :placeholder="$t('msn.openAccount.country')"/>
                        </el-form-item>
                        <el-form-item prop="firstName">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.firstName') }}</label>
                            <el-input v-model="user.firstName" :placeholder="$t('msn.openAccount.firstName')"/>
                        </el-form-item>
                        <el-form-item prop="lastName">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.lastName') }}</label>
                            <el-input v-model="user.lastName" :placeholder="$t('msn.openAccount.lastName')"/>
                        </el-form-item>
                        <el-form-item prop="email" :error="emailError[index]">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.email') }}</label>
                            <el-input v-model="user.email" :placeholder="$t('msn.openAccount.email')"/>
                        </el-form-item>
                        <el-form-item prop="jobTitle">
                            <label
                                slot="label"
                                class="el-form-item__label required-after"
                            >{{ $t('msn.openAccount.jobTitle') }}</label>
                            <el-input v-model="user.jobTitle" :placeholder="$t('msn.openAccount.jobTitle')"/>
                        </el-form-item>
                        <el-form-item :label="$t('msn.openAccount.phone')" prop="phoneNumber">
                            <el-input v-model="user.phoneNumber" :placeholder="$t('msn.openAccount.phone')"/>
                        </el-form-item>
                    </el-form>
                    <div class="user-opt-container">
                        <span
                            v-show="userList.length > 1"
                            class="icon-btn icon-btn-del"
                            @click="handleDelete(index)"
                        >{{ $t('msn.openAccount.del') }}</span>
                        <span
                            v-show="index === userList.length - 1"
                            class="icon-btn icon-btn-add"
                            @click="handleAdd"
                        >{{ $t('msn.openAccount.add') }}</span>
                    </div>
                </div>
            </div>
            <div class="submit-container">
                <el-button type="submit-btn" @click="handleApply">
                    {{ $t('msn.openAccount.apply') }}
                </el-button>
                <div class="submit-text">
                    {{ $t('msn.openAccount.submitAppend') }}
                    <a
                        class="link"
                        href="https://www.mediago.io/privacy"
                        target="_blank"
                        :style="lang === 'en' ? {'margin-left': '5px'} : {}"
                    >{{ $t('msn.openAccount.formAppendLink') }}</a>
                </div>
            </div>
        </section>
        <section v-show="showRes || showInvalidToken" class="page-feedback">
            <section v-if="showInvalidToken" class="content">
                <img src="../../assets/images/icon/alert.png">
                <div class="card-text">
                    <div class="text">
                        {{ $t('msn.openAccount.invalidToken') }}
                    </div>
                </div>
            </section>
            <section v-else class="content">
                <img src="../../assets/images/icon/success.png">
                <div class="card-text">
                    <div class="text">
                        {{ $t('msn.openAccount.info') }}{{ $t('msn.openAccount.info1') }}
                    </div>
                </div>
            </section>
            <section class="card-footer">
                <div class="text">
                    {{ $t('msn.openAccount.footer1') }}
                    <a
                        class="link"
                        href="mailto:ext_mediago_am@baidu.com"
                    >ext_mediago_am@baidu.com</a>
                </div>
                <div class="text">
                    {{ $t('msn.openAccount.footer2') }}
                </div>
            </section>
        </section>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/constants/api';
import { countryList } from '@/constants/countryList';
import { userRules, accountRules, oldUserRules } from './constants';
import MsnSelect from '@/components/MsnSelect/Select';
import MsnRadio from '@/components/MsnRadio/';
import MsnCheckbox from '@/components/MsnCheckbox/Checkbox';
import { timezoneList } from '@/modules/msn/constants/';
const urlList = {
    openAccount: api.openAccount,
    getTokenInfo: api.getTokenInfo
};
const USER = {
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: ''
};
const OLD_USER = { email: '' };
export default {
    name: 'OpenAccountAndUser',
    data() {
        return {
            loading: false,
            token: '',
            agentInfo: {},
            account: {
                payMethod: '预付',
                adMethod: '自投',
                timezone: 'EST'
            },
            accountRules: accountRules.call(this),
            userList: [Object.assign({}, USER)],
            userRules: [userRules.call(this)],
            emailError: [],
            showRes: false,
            timezoneList,
            showInvalidToken: false,
            newAccount: 1,
            oldUserList: [Object.assign({}, OLD_USER)],
            oldUserRules: [oldUserRules.call(this)],
            oldAccount: 1,
            bindUserError: []
        };
    },
    computed: {
        ...mapGetters('common', {
            lang: 'getLang'
        }),
        countryList() {
            return countryList.map(c => {
                return {
                    label: `msn.${c}`,
                    value: c
                };
            });
        }
    },
    components: {
        'msn-select': MsnSelect,
        'msn-radio': MsnRadio,
        'msn-checkbox': MsnCheckbox
    },
    methods: {
        getAgentInfo() {
            this.loading = true;
            this.$request(
                {
                    method: 'get',
                    url: urlList.getTokenInfo,
                    success(res) {
                        this.loading = false;
                        this.agentInfo = res.data || {};
                        this.showInvalidToken = !res.data;
                    },
                    error() {
                        this.loading = false;
                    }
                },
                {
                    token: this.token
                }
            );
        },
        handleApply() {
            let isValid = true;
            // 校验注册账户
            this.$refs.account.validate(valid => {
                isValid = valid;
            });
            if (!isValid) {
                return;
            }
            // 当没有绑定用户时，不能创建
            if (!this.oldAccount && !this.newAccount) {
                this.$message.warning(this.$t('msn.validations.selectUser'));
                return;
            }
            // 校验绑定用户
            isValid = this.oldUserList.every((u, i) => {
                let flag = true;
                this.$refs.oldUser[i].validate(valid => {
                    flag = valid;
                });
                return flag;
            });
            if (!isValid) {
                return;
            }
            // 校验注册用户
            isValid = this.userList.every((u, i) => {
                let flag = true;
                this.$refs.user[i].validate(valid => {
                    flag = valid;
                });
                return flag;
            });
            if (!isValid) {
                return;
            }

            this.handleConfirmApply();
        },
        handleConfirmApply() {
            this.loading = true;
            let params = {
                token: this.token,
                accountName: this.account.accountName,
                email: this.account.email,
                budget: +this.account.budget,
                payMethod: this.account.payMethod,
                adMethod: this.account.adMethod,
                timezone: this.account.timezone,
                companyName: this.account.companyName,
                registerAddress: this.account.registerAddress,
                businessAddress: this.account.businessAddress,
                website: this.account.website,
                applyUsers: this.userList,
                bindUsers: (this.oldUserList || []).map(odl => odl.email),
                country: this.account.country
            };
            this.emailError = [];
            this.bindUserError = [];
            this.$request(
                {
                    url: urlList.openAccount,
                    method: 'post',
                    contentType: 'application/json; charset=UTF-8',
                    success(res) {
                        this.loading = false;
                        if (res.error) {
                            let emailError = res.error.email || [];
                            emailError.forEach(e => {
                                this.emailError[e] = this.$t('msn.validations.repeatEmail');
                            });
                            let bindError = res.error.bindEmail || [];
                            bindError.forEach(e => {
                                this.bindUserError[e] = this.$t('msn.validations.emailNotExist');
                            });
                        } else {
                            this.showRes = true;
                        }
                    },
                    error() {
                        this.loading = false;
                    }
                },
                params
            );
        },
        handleDelete(index, key) {
            if (key === 'old') {
                this.oldUserList.splice(index, 1);
                this.oldUserRules.splice(index, 1);
                this.$nextTick(() => {
                    this.$refs.oldUser[index] && this.$refs.oldUser[index].clearValidate();
                });
            } else {
                this.userList.splice(index, 1);
                this.userRules.splice(index, 1);
                this.$nextTick(() => {
                    this.$refs.user[index] && this.$refs.user[index].clearValidate();
                });
            }
        },
        handleAdd(key) {
            if (key === 'old') {
                this.oldUserList.push(Object.assign({}, OLD_USER));
                this.oldUserRules.push(oldUserRules.call(this));
            } else {
                this.userList.push(Object.assign({}, USER));
                this.userRules.push(userRules.call(this));
            }
        },
        // 是否创建账户
        handleCheckBoxChange(key) {
            if (key === 'userList') {
                this.userList = this.newAccount ? [Object.assign({}, USER)] : [];
            } else {
                this.oldUserList = this.oldAccount ? [Object.assign({}, OLD_USER)] : [];
            }
        }
    },
    mounted() {
        this.token = this.$route.query && this.$route.query.token;
        if (this.token) {
            this.getAgentInfo();
        } else {
            this.showInvalidToken = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.page-open-account {
    text-align: left;
    min-height: calc(100vh - 30px);
    background-color: #f9f9f9;
    padding-bottom: 30px;
    .open-account-header {
        height: 50px;
        background: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(107, 119, 153, 0.1);
        display: flex;
        align-items: center;
        .header-logo {
            margin-left: 50px;
            height: 18px;
            width: 102px;
        }
    }
    .page-content {
        width: 960px;
        margin: 30px auto 0;
        .agent-header {
            display: flex;
            align-items: center;
            padding: 20px 0 0;
            img {
                width: 30px;
            }
            .agent-title {
                margin-left: 20px;
                font-size: 20px;
                color: #0b1531;
                font-weight: 700;
            }
        }
        .form-container {
            background: #ffffff;
            border: 1px solid #eaecf0;
            box-shadow: 0 2px 20px 0 rgba(107, 119, 153, 0.1);
            border-radius: 6px;
            padding: 25px;
            padding-bottom: 0;
        }
        .user-opt-container {
            font-size: 14px;
            line-height: 20px;
            padding: 5px 0;
            text-align: right;
            font-weight: 700;
            .icon-btn {
                position: relative;
                cursor: pointer;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background-position: center center;
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .icon-btn-add {
                color: #0067ff;
                margin-left: 30px;
                &:before {
                    background-image: url("../../assets/images/icon/add-oa.png");
                }
                &:hover {
                    color: #003d99;
                    &:before {
                        background-image: url("../../assets/images/icon/add-oa-h.png");
                    }
                }
            }
            .icon-btn-del {
                color: #e92754;
                &:before {
                    background-image: url(../../assets/images/icon/del-oa.png);
                }
                &:hover {
                    color: #b8002c;
                    &:before {
                        background-image: url(../../assets/images/icon/del-oa-h.png);
                    }
                }
            }
        }
        .form-header {
            margin-top: 35px;
            padding: 15px 0;
            .form-title {
                font-size: 20px;
                color: #0067ff;
                line-height: 14px;
                font-weight: 700;
                position: relative;
                padding-left: 15px;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 6px;
                    height: 20px;
                    background-color: #0067ff;
                }
            }
            .form-text {
                margin-top: 10px;
                line-height: 18px;
                padding-left: 15px;
                color: #53575b;
                font-size: 12px;
            }
        }
        .form-wrapper-title {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }
    }
    .submit-container {
        text-align: center;
        padding: 30px;
        padding-bottom: 70px;
        .submit-text {
            font-size: 12px;
            color: #53575b;
            margin-top: 10px;
            line-height: 22px;
        }
    }
    .link {
        text-decoration: none;
        color: #0067ff;
        display: inline-block;
        &:visited,
        &:hover,
        &:active,
        &:focus {
            color: #0067ff;
        }
    }
    .page-feedback {
        text-align: center;
        width: 500px;
        margin: 200px auto;
        padding: 20px;
        .content {
            img {
                width: 89px;
                margin-bottom: 40px;
            }
            .card-text {
                font-size: 20px;
                color: #000;
                font-weight: 900;
                line-height: 1.5;
            }
        }
        .card-footer {
            margin-top: 35px;
            font-size: 14px;
            line-height: 24px;
        }
    }
}
</style>
<style lang="scss">
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
textarea,
input,
button,
select {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.page-open-account {
    .required-after {
        margin-bottom: 0 !important;
    }
    .el-form-item {
        margin-bottom: 25px !important;
        .el-form-item__content {
            line-height: 1 !important;
        }
        .el-form-item__label {
            margin-bottom: 10px;
        }
        label {
            color: #000;
            font-size: 14px;
        }
        .el-input__inner {
            height: 40px;
            background: #fff;
            border: 1px solid #c4c9d6;
            box-sizing: border-box;
            font-size: 14px;
            border-radius: 3px;
            color: #53575b;
            font-weight: 500;
            &:active,
            &:hover,
            &:focus {
                // background: rgba(236, 236, 236, .5);
                border: 1px solid #0067ff;
            }
            &::placeholder {
                color: rgba(83, 87, 91, 0.3);
                font-weight: 500;
            }
        }
    }
    .el-button--submit-btn {
        background-color: #0067ff;
        color: #fff;
        border: none;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        width: 290px;
        height: 45px;
        border-radius: 22px;
        font-weight: 700;
        span {
            font-size: 20px;
        }

        &:active,
        &:hover,
        &:focus {
            color: #fff;
            background-color: #003d99;
        }
    }
    .account-radio.el-radio-group {
        .el-radio__input.is-checked .el-radio__inner {
            border-color: #0067ff;
            background: #0067ff;
            &:after {
                width: 6px;
                height: 6px;
            }
        }
        .el-radio__inner {
            box-shadow: none !important;
            border-color: #6b7799;
        }
        .el-radio__input .el-radio__inner {
            &:hover {
                border: 1px solid #0067ff;
            }
        }
        .el-radio__input.is-checked + .el-radio__label {
            color: #000;
        }
    }
    .account-select.el-select {
        height: 40px;
        border-radius: 6px;
        box-sizing: border-box;
        .el-input__inner {
            border: 1px solid #c4c9d6;
            height: 40px;
            border-radius: 6px;
            background: #fff;
            font-weight: 500;
            font-size: 13px;
            color: #53575b;
            &:hover,
            &:focus {
                border-color: #0067ff;
            }
        }
        &:hover,
        &:focus {
            border-color: #0067ff;
        }
        .el-input.is-focus .el-input__inner {
            border-color: #0067ff !important;
        }
        &:hover .el-input__inner {
            border-color: #0067ff;
        }
        .el-input .el-select__caret {
            color: #6b7799;
        }
    }
}
.account-select-dropdown {
    .el-select-dropdown__item {
        font-size: 13px;
        color: #53575b !important;
        font-weight: 400;
        text-align: left;
        &.hover {
            background: #eef4f9 !important;
        }
        &.selected {
            color: #0067ff !important;
            font-weight: 400;
        }
    }
}
</style>
