/**
 * @file element ui plugin
 * @author  fangsimin
 * @date    2019-01-02 13:29:03
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-02 13:29:17
 */

import Vue from 'vue';
import Element, {Table} from 'element-ui';
import '../element-variables.scss';

const fixElTableErr = table => {
    const oldResizeListener = table.methods.resizeListener;
    table.methods.resizeListener = function () {
        window.requestAnimationFrame(oldResizeListener.bind(this));
    };

};

fixElTableErr(Table);

Vue.use(Element);
